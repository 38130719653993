import React from "react";

const Checkbox = ({
  flowQuestion,
  values,
  flowCaptures,
  questionIndex,
  currentIndex,
  setFieldValue,
}) => (
  <div key={questionIndex} className="my-3">
    <div class="d-flex align-items-center">
      <label class="input-label mr-4" for="routine_name">
        {flowQuestion.label(values, flowCaptures, currentIndex)}
        <input 
          style={{ width: "1px", height: "1px", border: "none", margin: "0", padding: "0" }}
          type="text"
          required={
            !values.skip &&
            (values[flowQuestion.name] == undefined || values[flowQuestion.name] == null)
          }
        />
      </label>
      <div className="d-flex align-items-center">
        <input 
          type="button" 
          className={`btn btn-sm py-1 ml-2 px-4 btn-lighter ${values[flowQuestion.name] == true ? "selected" : ""}`}
          onClick={() => setFieldValue(flowQuestion.name, true)}
          value={flowQuestion.options && flowQuestion.options.true_label ? flowQuestion.options.true_label : "Si"} />
      </div>
      <div className="d-flex align-items-center">
        <input 
          type="button" 
          className={
            `btn btn-sm py-1 ml-2 px-4 btn-lighter btn-lighter ${values[flowQuestion.name] == false ? "selected" : ""}`
          }
          onClick={() => setFieldValue(flowQuestion.name, false)}
          value={flowQuestion.options && flowQuestion.options.false_label ? flowQuestion.options.false_label : "No"} />
      </div>
    </div>
  </div>
);

export default Checkbox;