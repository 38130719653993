import * as lib from "components/flow/board/nodes/Node.lib";
import { APP_URL } from "settings";

const canExecuteNode = (nodes, edges, actions, rootId, flowId) => {

    const getNodeWithWarningErrors = (root, adjacency, nodes, nodesWithErrors) => {
        const errors = root.data?.errors ?? [];
        const warnings = root.data?.warnings ?? [];
        if (errors.length > 0 || warnings.length > 0) nodesWithErrors.push(root);

        let childrens = adjacency[root.id];
        for (const child of childrens) {
            getNodeWithWarningErrors(child, adjacency, nodes, nodesWithErrors);
        }
    }

    const adjacency = {};
    const rootNode = nodes.find((node) => node.remoteId == rootId);
    if (!rootNode) return [false, ""];

    for (const node of nodes) {
        adjacency[node.id] = [];
    }

    for (const edge of edges) {
        const node = nodes.find((node) => node.id == edge.target);
        adjacency[edge.source].push(node)
    }

    const nodesWithErrors = [];
    getNodeWithWarningErrors(rootNode, adjacency, nodes, nodesWithErrors);
    if (nodesWithErrors.length == 0) return [true, ""];

    let errorMessage = `<p class="mb-0">Antes de ejecutar revisa las siguientes acciones:</p><hr class="my-2" />`;
    errorMessage += `<ul class="list-unstyled mt-3">`;

    for (const nodeWithError of nodesWithErrors) {
        const action = actions.find((action) => action.id == nodeWithError.data.actionId);
        if (!action) continue;

        const uiSettings = action.uiSettings;
        const errors = nodeWithError.data?.errors;
        const warnings = nodeWithError.data?.warnings;
        const editLinkNode = `${APP_URL}/app/flow/${flowId}/flow/edit/${nodeWithError.data?.remoteId}`;

        for (const error of errors) {
            errorMessage += `
                <li>
                    <div class="d-flex mb-2 ml-2">
                        <div class="mr-1"> 
                            <i class="fa-solid fa-circle-xmark text-danger"></i> 
                        </div>
                        <div>
                            <p class="mb-0 text-left">
                                ${lib.errorMapper(error, uiSettings)}
                                <a href="${editLinkNode}">Ver</a>
                            </p>
                        </div>
                    </div>
                </li>
            `;
        }

        for (const warning of warnings) {
            errorMessage += `
                <li>
                    <div class="d-flex mb-2">
                        <div class="mr-1"> 
                            <i class="fa-solid fa-triangle-exclamation text-warning"></i> 
                        </div>
                        <div>
                            <p class="mb-0 text-left">
                                ${lib.warningMapper(warning, uiSettings)}
                                 <a href="${editLinkNode}">Ver</a>
                            </p>
                        </div>
                    </div>
                <li>
            `;
        }
    }

    errorMessage += `</ul>`;

    return [false, errorMessage];
}


export { canExecuteNode }
