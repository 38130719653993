import React from "react";
import { TourProvider } from '@reactour/tour'
import PopoverContent from "components/misc/tour/Tour.sub.Popover.view";
import Main from "components/misc/tour/Main";


const TourView = ({ steps, show, children, startAt, currentStep, onClickMask, onClickClose, onClickHighlighted, onChangeCurrentStep }) => (
  <TourProvider
    steps={steps}
    currentStep={currentStep}
    startAt={startAt}
    scrollSmooth={true}
    disableInteraction={true}
    showCloseButton={false}
    showPrevNextButtons={false}
    badgeContent={({ totalSteps, currentStep }) => currentStep + 1 + "/" + totalSteps}
    setCurrentStep={onChangeCurrentStep}
    ContentComponent={PopoverContent}
    onClickMask={onClickMask}
    onClickClose={onClickClose}
    onClickHighlighted={onClickHighlighted}
  >
    <Main show={show}>
      {children}
    </Main>
  </TourProvider>
)

export default TourView;