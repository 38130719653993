import React from "react";
import { Formik, Field, Form } from "formik";
import { FileField } from "seed/helpers";
import { ColumnTypes, ColumnTypeLabels, getSelectTypes } from "components/tables/util/datatypes";

const CollectionFormView = ({
  formRef,
  error,
  isGenerated,
  isLoading,
  isLoadingGenerate,
  initialColumns = [],
  hasSuggestions = false,
  showImport = false,
  collection = {},
  setImportFile = () => { },
  onSubmit = () => { },
  onGenerate = () => { }
}) => (
  <div className="card">

    <div className="card-header">
      <h3 class="mb-0">{collection.id ? "Editar tabla" : "Crear tabla"}</h3>
    </div>

    <div className="card-body px-4">

      <Formik
        innerRef={formRef}
        initialValues={{
          ...collection,
          structure: initialColumns
        }}
        onSubmit={() => { }}
      >
        {({ values, setFieldValue }) =>
          <Form id="collection-form">

            <div class="form-group mb-4">
              <span class="d-flex justify-content-between dropdown-header pt-0 pb-2 px-0">
                <span style={{color: "#777"}}>Nombre</span>
              </span>
              <div class="input-group">
                <Field
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Nombre de la tabla"
                  class="form-control"
                  autocomplete="off"
                  required={true}
                  autoFocus={true}
                />
               
              </div>
            </div>

            {
              !showImport 
                ? <div>
                    <span class="d-flex justify-content-between dropdown-header pt-0 pb-3 px-0">
                      <span style={{color: "#777"}}>Columnas
                      {
                        !collection.id && values?.name?.length > 3 &&
                          <button
                            type="button"
                            class="btn btn-sm btn-link ml-1 px-2 mb-2 py-1 animate__animated animate__fadeIn animate__faster pt-2"
                            onClick={() => onGenerate(values?.name)}
                          >
                            {
                              isLoadingGenerate && <>
                                <span class="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" />
                                Generando...
                              </>
                            }
                            {
                              !isLoadingGenerate && <>
                                <i class="tio-magic-wand mr-1"></i> Sugerir columnas
                              </>
                            }
                            
                          </button>
                      }
                      </span>
                    </span>

                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th className="text-capitalize text-center">Nombre</th>
                          <th className="text-capitalize text-center">Tipo</th>
                          <th className="text-capitalize text-center"></th>
                          <th className="text-capitalize text-center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          !isLoadingGenerate && 
                            <>
                              {
                                values?.structure?.map((field, idx) =>
                                  <tr key={idx} className={isGenerated ? " animate__animated animate__fadeIn animate__faster" : ""}>
                                    <td>
                                      <input
                                        type="text"
                                        name={`label_${idx}`}
                                        id={`label_${idx}`}
                                        className="form-control"
                                        value={field.label}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "structure",
                                            values?.structure?.map((f, i) => {
                                              if (i == idx) return { ...f, label: e.target.value, name: e.target.value.toLocaleLowerCase() };
                                              return f;
                                            })
                                          );
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <select
                                        name={`structure_${idx}`}
                                        id={`structure_${idx}`}
                                        className="form-control"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "structure",
                                            values?.structure?.map((f, i) => {
                                              if (i == idx) return { ...f, type: e.target.value };
                                              return f;
                                            })
                                          );
                                        }}
                                      >
                                        {getSelectTypes().map((type, i) => (
                                          <option
                                            key={i}
                                            value={type}
                                            selected={type == field.type}
                                          >
                                            {ColumnTypeLabels[type]}
                                          </option>
                                        ))}
                                      </select>
                                    </td>
                                    <td>
                                      <div class="btn-group" role="group">
                                        <button
                                          type="button"
                                          class="btn btn-sm btn-outline-secondary"
                                          disabled={idx == 0}
                                          onClick={() => {
                                            let auxStructure = values?.structure??[];
                                            let newStructure = [...auxStructure];
                                            let tmp = newStructure[idx - 1];
                                            newStructure[idx - 1] = newStructure[idx];
                                            newStructure[idx] = tmp;
                                            setFieldValue("structure", newStructure);
                                          }}
                                        >
                                          <i class="fas fa-arrow-up"></i>
                                        </button>
                                        <button
                                          type="button"
                                          class="btn btn-sm btn-outline-secondary"
                                          disabled={idx == values?.structure?.length - 1}
                                          onClick={() => {
                                            let auxStructure = values?.structure??[];
                                            let newStructure = [...auxStructure];
                                            let tmp = newStructure[idx + 1];
                                            newStructure[idx + 1] = newStructure[idx];
                                            newStructure[idx] = tmp;
                                            setFieldValue("structure", newStructure);
                                          }}
                                        >
                                          <i class="fas fa-arrow-down"></i>
                                        </button>
                                      </div>
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-outline-danger"
                                        onClick={() => {
                                          setFieldValue("structure", values?.structure?.filter((f, i) => i != idx));
                                        }}
                                      >
                                        <i class="fas fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                                )
                              }
                            </>
                        }
                      </tbody>
                      <tfoot>
                        <tr>
                          <td className="py-0" colSpan="3">
                            <button
                              type="button"
                              class="btn btn-sm btn-outline-primary text-left btn-block mt-2 mb-2 w-auto"
                              onClick={() => {
                                setFieldValue("structure", [
                                  ...values?.structure ?? [],
                                  {
                                    label: "",
                                    type: ColumnTypes.STRING
                                  }
                                ]);
                              }}
                            >
                              + Nueva columna
                            </button>
                          </td>
                        </tr>
                      </tfoot>
                    </table>

                  </div> 
                : null
            }

    

            {!collection.id && showImport ?
            <div className="form-group px-3 pt-2 pb-4 mt-3" style={{background: "#fafafa", border: "1px solid #f0f0f0"}}>
            <span class="d-flex justify-content-between dropdown-header pt-2 pb-2 px-0">
              <span style={{color: "#777"}}>Crear desde excel</span>
              </span>
              <FileField
                name="file"
                id="file"
                required={true}
                accept=".xlsx,.xls,.csv"
                class="form-control form-control-lg"
                setFieldValue={() => { }}
                onCompleted={(data) => setImportFile(data)}
              />
            </div>: null}

            {
              error &&
              <div class="alert alert-danger" role="alert">
                {error}
              </div>
            }


            <div className="d-flex justify-content-between">
              <button
                type="button"
                class="btn btn-block btn-primary mt-3"
                disabled={isLoading}
                onClick={() => {
                  let form = document.getElementById("collection-form")
                  if (form.checkValidity())
                    onSubmit(values)
                  else
                    form.reportValidity()                    
                }}
              >
                {
                  isLoading &&
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                }
                {
                  !isLoading && <>
                    {collection.id ? "Editar tabla" : "Crear tabla"}
                  </>
                }
              </button>

            </div>



          </Form>}
      </Formik>
    </div>

  </div>
);

export default CollectionFormView;