const SELECT_FLOW = {
  "explorer_select_00": {
    "name": "SELECT_TYPE",
    "definition": (answers, captures, index) => 
      "SELECT_FILE"
  }
}

const CREATE_FLOW = {
  "explorer_create_00": {
    "name": "CREATE_TYPE",
    "definition": (answers, captures, index) =>
      "CREATE_FILE"
  }
}

const COPY_FLOW = {
  "explorer_copy_00": {
    "name": "COPY_TYPE",
    "definition": (answers, captures, index) =>
      "COPY_FILE"
  }
}

const DELETE_FLOW = {
  "explorer_delete_00": {
    "name": "DELETE_TYPE",
    "definition": (answers, captures, index) =>
      "DELETE_FILE"
  }
}

const MOVE_FLOW = {
  "explorer_move_00": {
    "name": "MOVE_TYPE",
    "definition": (answers, captures, index) =>
      "MOVE_FILE"
  }
}

const OTHER_FLOW = {
  "explorer_other_00": {
    "name": "OTHER_TYPE",
    "label": (answers, captures, index) =>  
      "Describe la acción",
    "component": "TEXT",
    "definition": (answers, captures, index) =>
      "OTHER_FILE"
  }
}

const FLOW_QUESTIONS = {
  "explorer_00": {
    "name": "EXPLORER_TYPE",
    "label": (answers, captures, index) =>
      "Ejecutaste alguna de estas acciones?",
    "component": "SELECT_HORIZONTAL",
    "options": [
      {
        "label": "Seleccionar / Abrir un archivo",
        "value": "SELECT_FILE",
      },
      {
        "label": "Crear / Renombrar un archivo",
        "value": "CREATE_FILE",
      },
      {
        "label": "Otro",
        "value": "OTHER",
      }
    ],
    "next": [
      {
        "id": "explorer_select_00",
        "condition": (answers, captures, index) =>
          answers["EXPLORER_TYPE"] == "SELECT_FILE",
      },
      {
        "id": "explorer_create_00",
        "condition": (answers, captures, index) =>
          answers["EXPLORER_TYPE"] == "CREATE_FILE",
      },
      {
        "id": "explorer_01",
        "condition": (answers, captures, index) =>
          answers["EXPLORER_TYPE"] == "OTHER",
      }
    ]
  },
  "explorer_01": {
    "id": "",
    "name": "ACTION_TYPE",
    "label": (answers, captures, index) => "¿Qué acción realizaste?",
    "component": "SELECT_HORIZONTAL",
    "options": [
      {
        "label": "Copiar archivo",
        "value": "COPY_FILE",
      },
      {
        "label": "Eliminar archivo",
        "value": "DELETE_FILE",
      },
      {
        "label": "Mover archivo",
        "value": "MOVE_FILE",
      },
      {
        "label": "Otro",
        "value": "OTHER",
      }
    ],
    "next": [
      {
        "id": "explorer_copy_00",
        "condition": (answers, captures, index) =>
          answers["ACTION_TYPE"] == "COPY_FILE",
      },
      {
        "id": "explorer_delete_00",
        "condition": (answers, captures, index) =>
          answers["ACTION_TYPE"] == "DELETE_FILE",
      },
      {
        "id": "explorer_move_00",
        "condition": (answers, captures, index) =>
          answers["ACTION_TYPE"] == "MOVE_FILE",
      },
      {
        "id": "explorer_other_00",
        "condition": (answers, captures, index) =>
          answers["ACTION_TYPE"] == "OTHER",
      }
    ]
  },
  ...SELECT_FLOW,
  ...CREATE_FLOW,
  ...COPY_FLOW,
  ...DELETE_FLOW,
  ...MOVE_FLOW,
  ...OTHER_FLOW
};

export default FLOW_QUESTIONS;