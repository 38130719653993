import React, { useState } from "react";
import swal from "sweetalert";
import { useQuery } from "seed/gql";
import { usePost } from "seed/api";
import View from "components/start/templates/Templates.view";

function Templates() {

  const userId = sessionStorage.getItem("id");
  const [selectedProfile, setSelectedProfile] = useState(null);

  const [callCloneTemplate, reqCloneTemplate] = usePost('/flows/clone_template', {
    onCompleted: (data) => {
      const clonedFlowId = data?.cloned_flow_id;
      if (clonedFlowId) {
        window.location.replace(`/app/flow/${clonedFlowId}/flow`);
      }
    },
    onError: (error) => {
      console.log(error);
      swal("Error al usar plantilla", "Ha ocurrido un error inesperado, por favor intente de nuevo mas tarde", "error")
    }
  })

  const onChangeProfile = (newProfile) => {
    setSelectedProfile(newProfile);
  }

  const onCloneTemplate = (flowId) => {
    callCloneTemplate({
      flow_id: flowId,
      user_id: userId,
      template_category_id: selectedProfile
    })
  }

  const queryFlowTemplates = `isTemplate=True ${selectedProfile ? `AND templateCategories.id=${selectedProfile.id}` : ""}`;

  return (
    <View
      selectedProfile={selectedProfile}
      queryFlowTemplates={queryFlowTemplates}
      onChangeProfile={onChangeProfile}
      onCloneTemplate={onCloneTemplate}
    />
  );
}

Templates.propTypes = {};

export default Templates;