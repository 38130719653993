import React from "react";
import View from "components/misc/plans/Plan.view";

function Plan({ name, price, features = [], totalCredits, usedCredits }) {

  let usage = null;
  if (totalCredits != null && usedCredits != null) {
    usage = `${Math.floor((usedCredits / totalCredits) * 100)}`;
  }

  return (
    <View
      name={name}
      price={price}
      usage={usage}
      usedCredits={usedCredits}
      totalCredits={totalCredits}
      features={features}
    />
  );
}

export default Plan;