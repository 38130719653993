import React, { useEffect } from "react";
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import { usePost } from "seed/api";
import View from "components/presentation/templates/side_template/Section.view";

function Section({ match, flowId }) {

  let sectionIdx = match.params?.sectionIdx
  if (!sectionIdx) window.location.replace("/app");
  sectionIdx = parseInt(sectionIdx);

  const reqFlow = useDetail(`{
    flow {
      name
      presentationSettings
    }
  }`, flowId)

  const [callGetCollection, reqCollection] = usePost("/collections/get_collection_by_query");

  const { flow = {} } = reqFlow.data;
  const presentationSettings = flow?.presentationSettings ?? {};
  const sections = presentationSettings?.sections ?? [];
  let section = null;
  if (sections.length > 0) section = sections[sectionIdx];

  useEffect(() => {
    callGetCollection({
      flow_id: flowId,
      query: section?.data?.collection ?? "",
    });
  }, [section?.data?.collection]);

  const collection = reqCollection.data?.collection;

  if (reqFlow.loading || reqCollection.loading) return <Loading />;
  if (reqFlow.error || reqCollection.error) return "Error!";

  return (
    <View
      flowId={flowId}
      section={section}
      collection={collection}
    />
  );
}

export default Section;