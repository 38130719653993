import React, { useState } from "react";

const ExportFile = ({ onExport = () => {} }) => {

  const [extension, setExtension] = useState("xlsx");

  return <div className="card">

    {/* Temp all csv
    <div className="form-group">
      <label>Formato</label>
      <select className="form-control" value={extension} onChange={(e) => setExtension(e.target.value)}>
        <option value="xlsx">Excel</option>
        <option value="csv">CSV</option>
      </select>
    </div>*/}

    <div class="card-header">
      <h3 class="mb-0">{"Descargar excel"}</h3>
    </div>
    <div class="card-body py-4">
      <button type="button" className="btn w-100 btn-primary" onClick={() => onExport(extension)}>
        Descargar archivo
      </button>
    </div>
  </div>
}

export default ExportFile;