
const getAppName = (app) => {
    const appUiSettings = (app.uiSettings);
    return appUiSettings?.label ?? app.name;
}

const getActionName = (action) => {
    if (!action.uiSettings) return "";
    let actionSettings = (action.uiSettings);
    if (actionSettings?.dummy == true) return "Dummy node";
    else return actionSettings?.general?.title ?? "";
}

export { getActionName, getAppName };