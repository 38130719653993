
const executionStatus = {
	"OK": {
		color: "text-success",
		icon: "fas fa-check-circle",
		text: "Correcto"
	},
	"AWAITING": {
		color: "text-warning",
		icon: "fas fa-clock",
		text: "En espera"
	},
	"RUNNING": {
		color: "text-primary",
		icon: "fas fa-play-circle",
		text: "Ejecutándose"
	},
	"COMPLETED": {
		color: "text-success",
		icon: "fas fa-check-circle",
		text: "Completado"
	},
	"FAILED": {
		color: "text-danger",
		icon: "fas fa-times-circle",
		text: "Fallido"
	},
	"ABORTED": {
		color: "text-danger",
		icon: "fas fa-check-circle",
		text: "Abortado"
	}
}

export { executionStatus }