import React from "react";
import { postMessage, addListener } from "components/util/win";
import jsxToString from 'jsx-to-string';
import Modal from "components/util/helpers/Modal";

/* 

  How to use:
    <Window 
      width={500}
      height={500}
      callback={(data) => { console.log(data)}}
    >
      <Foo />
    </Window>

  Requirements:

  1. The component must not receive props. E.g.
    const Foo = () => {
      const onChange = (e) => {
          if(e.keyCode == 13) {
              setResponse(e.target.value);
              closeWindow();
          }
      }
      return <div>
          <input type="text" onKeyUp={onChange} />
      </div>
  }

  2. Component must be registered in src/components/util/WindowRoute.js
    const Components = {
      ...,
      Foo
    };

*/

const componentToString = (component) => {
  return jsxToString(component, {
    functionNameOnly: true,
    useFunctionCode: true
  });
}

class ModalWindow extends React.Component {

	render() {
 
		const {
      id = Math.random().toString(36).substring(7),
      width = 500,
      height = 500,
      isEmbbeded = true,
      children,
      parameters = {},
      callback = () => {},
      onClose = () => {}
    } = this.props;

    if(isEmbbeded) {
      addListener((data) => {
        if(data.type == "response" && data.id == id)
          callback(data.response);
      });
      postMessage({ 
        id,
        type: "modal", 
        width, 
        height, 
        parameters,
        component: componentToString(children) 
      });
      return <></>;
    }

    return <Modal width={width} height={height} onClose={onClose}>
      <div className='card card-body border-0 '>
        {children}
      </div>
    </Modal>;
    
	}

}

export default ModalWindow;