const formatNumber = (number) => {
  return number?.toString()?.padStart(3, '0');
}

const toCamelCase = (str) => {
	return str.replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
		.replace(/\s/g, '')
		.replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
}

export {
  formatNumber,
  toCamelCase
}