import React, { useState, useRef, useEffect } from "react";
import "styles/css/ScrollArrow.css";


function ScrollArrow({
	children,
	mainContainerStyle = {},
	itemsContainerStyle = {},
	leftButtonStyle = {},
	rightButtonStyle = {},
	scrollAmount = 100
}) {

	const [itemsContainerMaxScroll, setItemsContainerMaxScroll] = useState(300);
	const [scrollPosition, setScrollPosition] = useState(0)
	const [showArrows, setShowArrows] = useState(false);
	const [showLeftArrow, setShowLeftArrow] = useState(false);
	const [showRightArrow, setShowRightArrow] = useState(false);

	const scrollTabsContainerRef = useRef();

	const handleHorizontalScroll = (scrollAmount) => {
		let newScrollPosition = scrollPosition + scrollAmount;

		if (newScrollPosition < 0) {
			newScrollPosition = 0
			// setShowLeftArrow(false);
		}

		if (newScrollPosition >= itemsContainerMaxScroll) {
			newScrollPosition = itemsContainerMaxScroll;
			// setShowRightArrow(false);
		}

		setScrollPosition(newScrollPosition);
		scrollTabsContainerRef.current.scrollLeft = newScrollPosition;
	}

	const onClickScrollLeft = () => {
		handleHorizontalScroll(-scrollAmount);
	};

	const onClickScrollRight = () => {
		handleHorizontalScroll(scrollAmount);
	};

	useEffect(() => {
		const scrollContainer = scrollTabsContainerRef.current;
		if (scrollContainer.scrollWidth > scrollContainer.clientWidth) {
			setShowLeftArrow(false);
			setShowRightArrow(true);
			setShowArrows(true);
		} else {
			setShowLeftArrow(false);
			setShowRightArrow(false);
			setShowArrows(false);
		}
		setItemsContainerMaxScroll(scrollTabsContainerRef.current ? scrollTabsContainerRef.current.scrollWidth : 300);
	}, [children])

	useEffect(() => {
		if (showArrows) {
			if (scrollPosition <= 0) {
				setShowLeftArrow(false);
				setShowRightArrow(true);
			} else if (scrollPosition >= itemsContainerMaxScroll) {
				setShowLeftArrow(true);
				setShowRightArrow(false);
			} else {
				setShowLeftArrow(true);
				setShowRightArrow(true);
			}
		}
	}, [scrollPosition, itemsContainerMaxScroll])

	return (
		<div id="scroll-arrow-main-container" style={mainContainerStyle}>
			{showLeftArrow && (
				<button className="scroll-btn left-btn" onClick={onClickScrollLeft} style={leftButtonStyle}>
					&#10094;
				</button>
			)}
			<div className={`scroll-container ${!showArrows ? "justify-content-center" : ""}`}
				style={itemsContainerStyle} ref={scrollTabsContainerRef}
			>
				{children}
			</div>
			{showRightArrow && (
				<button className="scroll-btn right-btn" onClick={onClickScrollRight} style={rightButtonStyle}>
					&#10095;
				</button>
			)}
		</div>
	)

}

export default ScrollArrow;