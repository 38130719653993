import React, { useState, useEffect } from "react";
import { APP_URL } from "settings";
import { useQuery, useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import { presentationTutorialSteps } from "components/presentation/util/tutorial";
import Tour from "components/misc/tour/Tour"
import View from "components/presentation/PresentationMode.view";

function PresentationMode({ match }) {

  const token = match.params?.token;
  const userId = sessionStorage.getItem("id");
  const [reset, setReset] = useState(0);
  const [isTutorialFinished, setIsTutorialFinished] = useState(null)
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const [isTemplatesShown, setIsTemplatesShown] = useState(false);
  const [isShareShown, setIsShareShown] = useState(false);


  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin != APP_URL) return;
      const message = event.data;
      if (message == "edit_presentation")
        setIsEditModalShown(true);
      else if (message == "templates_presentation")
        setIsTemplatesShown(true);
      else if (message == "share_presentation")
        setIsShareShown(true);

    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };

  }, [])

  const reqFlow = useQuery(`{
    flows {
      name
      token
      presentationSettings
    }
  }`, `token=${token}`);

  const reqUser = useDetail(`{
    user {
      firstName
      lastName
      email
      license {
        name
      }
      accountSettings
    }
  }`, userId)

  if (reqFlow.loading || reqUser.loading) return <Loading />;
  if (reqFlow.error) return "Error";

  const { user = null } = reqUser.data || {};
  const { flows = [] } = reqFlow.data;
  if (flows.length == 0) window.location.replace("/app");
  const flow = flows[0];

  const onResetPresentation = () => {
    setReset((prev) => prev + 1);
    setIsEditModalShown(false);
    setIsTemplatesShown(false);
    setIsShareShown(false);
  }

  const onCloseEditModal = () => {
    setIsEditModalShown(false);
  }

  const onCloseTemplatesModal = () => {
    setIsTemplatesShown(false);
  }

  const onCloseShareModal = () => {
    setIsShareShown(false);
  }

  const link = `${APP_URL}/p/${flow.token}`

  let currentTutorialStep = 0;
  if (isTutorialFinished == null){
    if (user != null) {
      const tutorialSettings = user.accountSettings?.tutorial ?? []
      const presentationTutorial = tutorialSettings.find((tutorial) => tutorial.name == "presentation") || {};
      setIsTutorialFinished(presentationTutorial?.is_finished ?? false)
      currentTutorialStep = presentationTutorial?.current_step ?? 0;
    }else setIsTutorialFinished(true)
  }

  const view =
    <View
      flow={flow}
      reset={reset}
      isEditModalShown={isEditModalShown}
      isTemplatesShown={isTemplatesShown}
      isShareShown={isShareShown}
      isTutorialFinished={isTutorialFinished}
      onCloseEditModal={onCloseEditModal}
      onCloseTemplatesModal={onCloseTemplatesModal}
      onCloseShareModal={onCloseShareModal}
      onResetPresentation={onResetPresentation}
    />;

  return (
    <Tour
      steps={presentationTutorialSteps}
      startAt={currentTutorialStep}
      show={!isTutorialFinished}
      tutorialName={"presentation"}
      onCompleted={() => setIsTutorialFinished(true)}>
      <div className="content container-fluid px-1 px-md-3 py-3 py-md-4">
        {view}
      </div>
    </Tour>
  )
}

export default PresentationMode;