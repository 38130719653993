const getFlowName = (profile, flow) => {
    const templateSettings = flow?.templateSettings ?? {};
    const templateSettingsName = templateSettings?.name ?? {};
  
    if (typeof templateSettingsName === "string")
      return templateSettingsName;
  
    const templateSettingsNameKeys = Object.keys(templateSettingsName)
  
    if (profile && templateSettingsNameKeys.includes(profile?.name)) {
      return templateSettingsName[profile.name];
    }
  
    if (templateSettingsNameKeys.length > 0) {
      const firstKey = templateSettingsNameKeys[0];
      return templateSettingsName[firstKey];
    }
  
    return flow?.name ?? "";
  }

export { getFlowName };