import React, { useState, useEffect, useRef } from "react";
import { Modal } from "seed/helpers";
import { ColumnTypes, ColumnTypeLabels } from "components/tables/util/datatypes";

const HeaderCell = ({
  collections = [],
  filteredColumns = [],
  style = {},
  isReadOnly = false,
  isActive = false,
  column,
  onChange = () => { },
  onDelete = () => { },
  onDownloadZip = () => { },
  setSelectedCell = () => { },
}) => {

  const cellRef = useRef(null);
  const contextMenuRef = useRef(null);
  const inputRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isContextMenuShown, setIsContextMenuShown] = useState(false);
  const [isCollectionModalShown, setIsCollectionModalShown] = useState(false);
  const [isEnumModalShown, setIsEnumModalShown] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const componentId = useRef(Math.random().toString(36).substring(7));

  useEffect(() => {

    document.addEventListener("click", (e) => {
      if (cellRef?.current && !cellRef?.current?.contains(e.target) &&
        e.target?.getAttribute("ignore-onclickoutside") != componentId.current) {
        setIsEditing(false);
      }
    });

    // every right click will close the context menu
    document.addEventListener("mousedown", (e) => {
      if (cellRef?.current &&
        !cellRef?.current?.contains(e.target) &&
        e.target?.getAttribute("ignore-onclickoutside") != componentId.current
      ) {
        setIsContextMenuShown(false);
      }
    });

  }, []);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [isEditing]);

  useEffect(() => {
    setTimeout(() => {
      if(isActive && !isReadOnly) setIsEditing(true);
    }, 15);
  }, [isActive, isReadOnly]);

  const onClick = (e) => {
    if(isReadOnly) return;
    setIsEditing(true);
    e.stopPropagation();
  }

  const onContextMenu = (e) => {

    e.preventDefault();
    e.stopPropagation();
    setIsContextMenuShown(true);

    const x = e.clientX - cellRef.current.getBoundingClientRect().x;
    const y = e.clientY - cellRef.current.getBoundingClientRect().y;
    setCoords({ x, y });

  }

  const onChangeLabel = (e) => {
    setIsEditing(false);
    onChange({
      ...column,
      label: e.target.value,
    });
  }

  const onHideCollectionModal = () => 
    setIsCollectionModalShown(false);

  const _onShowEnumModal = (e) => {
    setIsContextMenuShown(false);
    setIsEnumModalShown(true);
    setIsEditing(false);
    setSelectedCell(null);
    e.stopPropagation();
  }

  const _onShowCollectionModal = (e) => {
    setIsContextMenuShown(false);
    setIsCollectionModalShown(true);
    setIsEditing(false);
    e.stopPropagation();
  }

  if(column.visible === false) return null;

  return (
    <>
    
      <th
        ref={cellRef}
        className="m-0 p-0 h5 text-dark font-weight-bold"
        onClick={onClick}
        onContextMenu={onContextMenu}
        style={{
          ...style,
          position: "relative",
          minWidth: "210px", 
          maxWidth: "450px",
          height: "40px",
        }}
      >

        {
          isEditing ? (
            <input
              ref={inputRef}
              type="text"
              className="form-control form-control-sm d-flex flex-wrap py-0 m-0 w-100 h-100"
              defaultValue={column.label}
              onKeyUp={(e) => {
                if(e.key === "Enter") 
                  onChangeLabel(e);
              }}
              onBlur={onChangeLabel}
            />
          ) : (
            <div
              className="d-flex align-items-center px-2 py-1 m-0 w-100 h-100 text-truncate"
              style={{ maxWidth: "100%" }}
            >

              <div className="d-flex flex-wrap align-items-center w-100 h-100 text-truncate">

                <div className="text-truncate" style={{ maxWidth: "300px" }}>
                  <span style={{fontSize: "0.95em"}}>{column?.label??column.name}</span>
                </div>
                {
                  ((column.type == ColumnTypes.ENUM || column.type == ColumnTypes.COLLECTION) && !column.structure) && (
                    <i className="fas fa-exclamation-circle ml-1 text-danger"></i>
                  )
                }
                <small className="ml-1" style={{ color: "#858a90" }}> 
                  (
                    { ColumnTypeLabels[column.type] }
                    { column.type == ColumnTypes.COLLECTION && column.structure && (" - " + column.structure.type) }
                  ) 
                </small>

              </div>

              <div>
                <i 
                  style={{ cursor: "pointer", fontSize: "0.8em" }}
                  className="fas fa-chevron-down ml-1 px-2" 
                  ignore-onclickoutside={componentId.current}
                  onClick={onContextMenu}
                />
              </div>

            </div>
          )
        }

        {
          isContextMenuShown && (
            <div
              ref={contextMenuRef}
              class="dropdown show"
              style={{
                position: "absolute",
                top: coords.y,
                left: coords.x,
                zIndex: 1000,
              }}
            >
              <div class="dropdown-menu show" aria-labelledby="add-column-dropdown">

                {
                  column.type == ColumnTypes.ENUM && !isReadOnly &&
                    <a 
                      className="dropdown-item" 
                      href="#" 
                      ignore-onclickoutside={componentId.current}
                      onClick={_onShowEnumModal}
                    >
                      Editar enum
                    </a>
                }

                {
                  column.type == ColumnTypes.COLLECTION && !isReadOnly &&
                    <a 
                      className="dropdown-item" 
                      href="#" 
                      ignore-onclickoutside={componentId.current}
                      onClick={_onShowCollectionModal}
                    >
                      Seleccionar tabla
                    </a>
                }

                {
                  column.type == ColumnTypes.FILE && (
                    <a 
                      className="dropdown-item" 
                      href="#" 
                      ignore-onclickoutside={componentId.current}
                      onClick={() => onDownloadZip(column.name)}
                    >
                      Descargar zip
                    </a>
                  )
                }

                { 
                  !isReadOnly && (
                    <a
                      className="dropdown-item"
                      href="#"
                      ignore-onclickoutside={componentId.current}
                      onClick={() => {
                        setIsContextMenuShown(false);
                        setIsEditing(true);
                      }}
                    >
                      Cambiar nombre
                    </a>
                  )
                }

                {
                  !isReadOnly && (
                    <a
                      className="dropdown-item"
                      href="#"
                      ignore-onclickoutside={componentId.current}
                      onClick={() => {
                        setIsContextMenuShown(false);
                        onDelete();
                      }}
                    >
                      Eliminar
                    </a>
                  )
                }

              </div>
            </div>
          )
        }

      </th>

      {
        isCollectionModalShown && <Modal
          width="500"
          height="300"
          onClose={onHideCollectionModal}
          component={() => 
            <div className="card card-body">
              <h4>Seleccionar tabla</h4>
              <div className="form-group">
                <select
                  className="form-control form-control-sm"
                  onChange={(e) => {

                    if(e.target.value == -1) return onChange({ ...column, structure: null });

                    const collection = collections[e.target.value];
                    onChange({
                      ...column,
                      structure: {
                        collection_id: collection.id,
                        type: "@" + collection.name,
                      }
                    });

                    onHideCollectionModal();

                  }}
                >
                  <option value={-1}>Seleccione una tabla</option>
                  {
                    collections.map((c, i) => (
                      <option 
                        key={i} 
                        value={i}
                        selected={column.structure?.collection_id == c.id}
                      > {c.name} </option>
                    ))
                  }
                </select>
              </div>
            </div>
          }
        />
      }

      {
        isEnumModalShown && <Modal
          width="500"
          height="400"
          onClose={() => setIsEnumModalShown(false)}
          component={() => 
            <div className="card card-body">
              <h4>Editar enum</h4>
              <div className="form-group">

                {
                  column.structure?.values?.map((value, i) => (
                    <div key={i} className="d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control form-control-sm my-2"
                        defaultValue={value}
                        onBlur={(e) => {
                          const values = [...column.structure.values];
                          values[i] = e.target.value;
                          onChange({
                            ...column,
                            structure: {
                              ...column.structure,
                              values,
                            }
                          });
                        }}
                      />
                      <button
                        className="btn btn-sm btn-danger ml-1"
                        onClick={() => {
                          const values = [...column.structure.values];
                          values.splice(i, 1);
                          onChange({
                            ...column,
                            structure: {
                              ...column.structure,
                              values,
                            }
                          });
                        }}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  ))
                }

                <button
                  className="btn btn-sm btn-primary float-right mt-2"
                  onClick={() => {
                    const values = [...(column?.structure?.values??[])];
                    values.push("");
                    onChange({
                      ...column,
                      structure: {
                        ...column.structure,
                        values,
                      }
                    });
                  }}
                >
                  Agregar
                </button>

              </div>
            </div>
          }
        />
      }

    </>
  );
}

export default HeaderCell;