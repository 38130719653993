import { events, eqMultiEvents } from "components/misc/apps/rpa/generator/util/events";

const DRAG_RCLICK_LCLICK_FLOW = {}
const RCLICK_LCLICK_FLOW = {}

const DRAG_RCLICK_FLOW = {
  "drag_rclick_01": {
    "name": "ELEMENT_REGION",
    "label": (answers, captures, index) =>
      "¿Podrías seleccionar el elemento que presionaste?",
    "component": "PICKER",
    "definition": (answers, captures, index) => "DRAG_RCLICK",
  },
}

const DRAG_KEY_FLOW = {
  "drag_key_01": {
    "name": "ELEMENT_REGION",
    "label": (answers, captures, index) =>
      "¿Podrías seleccionar el elemento que presionaste?",
    "component": "PICKER",
    "definition": (answers, captures, index) => "DRAG_KEY",
  },
}

const LCLICK_TEXT_FLOW = {
  "lclick_text_01": {
    "name": "ELEMENT_REGION",
    "label": (answers, captures, index) =>
      "¿Podrías seleccionar el elemento que presionaste?",
    "component": "PICKER",
    "definition": (answers, captures, index) => "CLICK",
    "next": [
        {
          "id": "lclick_text_02",
          "condition": (answers, captures, index) =>
            answers["ELEMENT_REGION"]
        }
    ]
  },
  "lclick_text_02": {
    "name": "MANUAL_INPUT",
    "label": (answers, captures, index) => 
      `¿Necesitas teclear el texto "${captures[index]?.combination[1]?.value?.substring(0,20)}" manualmente?`,
    "component": "CHECKBOX",
    "options": {
      "true_label": "Sí",
      "false_label": "No, el texto viene de otro lado",
    },
    "definition": (answers, captures, index) =>
      "LCLICK_TEXT"
  },
}

const LCLICK_KEY_FLOW = {
  "lclick_key_01": {
    "name": "ELEMENT_REGION",
    "label": (answers, captures, index) =>
      "¿Podrías seleccionar el elemento que presionaste?",
    "component": "PICKER",
    "definition": (answers, captures, index) => "LCLICK_KEY",
  },
}

const LCLICK_LCLICK_FLOW = {
  "lclick_lclick_01": {
    "name": "ELEMENT_REGION",
    "label": (answers, captures, index) =>
      "¿Podrías seleccionar el elemento que presionaste?",
    "component": "PICKER",
    "definition": (answers, captures, index) => "LCLICK_LCLICK",
  },
}

const FLOW_QUESTIONS = {
  "mouse_multiple_00": {
    "name": "MULTIPLE_TYPE",
    "next": [
      {
        "id": "drag_key_01",
        "condition": (answers, captures, index) =>
          eqMultiEvents(captures[index], events.DRAG_EVENT, events.KEY_EVENT),
      },
      {
        "id": "drag_rclick_01",
        "condition": (answers, captures, index) =>
          eqMultiEvents(captures[index], events.DRAG_EVENT, events.RIGHT_CLICK_EVENT),
      },
      {
        "id": "lclick_text_01",
        "condition": (answers, captures, index) =>
          eqMultiEvents(captures[index], events.LEFT_CLICK_EVENT, events.TEXT_EVENT),
      },
      {
        "id": "lclick_key_01",
        "condition": (answers, captures, index) =>
          eqMultiEvents(captures[index], events.LEFT_CLICK_EVENT, events.KEY_EVENT),
      },
      {
        "id": "lclick_lclick_01",
        "condition": (answers, captures, index) =>
          eqMultiEvents(captures[index], events.LEFT_CLICK_EVENT, events.LEFT_CLICK_EVENT),
      },
      {
        "id": "mouse_01",
        "condition": (answers, captures, index) =>
          eqMultiEvents(captures[index], events.LEFT_CLICK_EVENT, events.LEFT_CLICK_EVENT),
      },
      {
        "id": "context_00",
        "condition": (answers, captures, index) =>
          eqMultiEvents(captures[index], events.RIGHT_CLICK_EVENT, events.LEFT_CLICK_EVENT) ||
          eqMultiEvents(captures[index], events.DRAG_EVENT, events.RIGHT_CLICK_EVENT, events.LEFT_CLICK_EVENT),
      },
    ]
  },
  ...DRAG_RCLICK_FLOW,
  ...LCLICK_KEY_FLOW,
  ...LCLICK_LCLICK_FLOW,
  ...LCLICK_TEXT_FLOW,
  ...DRAG_KEY_FLOW,
  ...DRAG_RCLICK_LCLICK_FLOW,
  ...RCLICK_LCLICK_FLOW
};

export default FLOW_QUESTIONS;