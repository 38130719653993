import React from 'react';
import SchemaForm from 'components/schema_form/SchemaForm';
import moment from "moment";
import { APP_URL } from 'settings';
import { Loading } from 'seed/helpers';


const SubmitComponent = ({ setFieldValue }) =>
  <div className='w-100 d-flex justify-content-between'>
    <button type="submit" class="btn btn-outline-success" style={{ width: "48.7%" }}
      onClick={() => setFieldValue("approval_status", true)}>Aprobar</button>
    <button type="submit" class="btn btn-outline-danger" style={{ width: "48.7%" }}
      onClick={() => setFieldValue("approval_status", false)}>Rechazar</button>
  </div>


const SchemaComponent = ({ values, schema, formTitle, onFormSubmit, onClickBack }) =>
  <div class="card animate__animated animate__fadeInUp animate__faster">
    <div class="card-header">
      <h3> <i
        className="fas fa-arrow-left mr-4"
        style={{ cursor: "pointer", color: "#aaa" }}
        onClick={onClickBack}
      />
        {formTitle != "" ? formTitle : "Formulario"}</h3>
    </div>
    <div class="card-body animated__animated animated_fadeIn">
      <SchemaForm onSubmit={onFormSubmit} schema={schema} initialValues={values}
        isReadOnly={true} isCommentEnabled={true} submitComponent={SubmitComponent} />
    </div>
  </div>

const Message = ({ icon, iconColor = "#607080", title, subtitle, cta, onClickBack = () => { } }) =>
  <div class="text-center animate__animated animate__fadeInUp animate__faster">
    {icon ? <i className={icon + " mb-5"} style={{ fontSize: "4em", color: iconColor }}></i> : null}
    {title ? <h2>{title}</h2> : null}
    {subtitle ? <p className='pb-0'>{subtitle}</p> : null}
    {cta ? <button className='btn btn-outline-primary' onClick={onClickBack}>{cta}</button> : null}
  </div>

const MessageComponent = ({ formStatus, approvalStatus, onClickBack }) =>
  <div class="card py-5">
    <div class="card-body py-10">
      {formStatus == "LOADING" ? <Loading /> : null}
      {formStatus == "NOT_FOUND" ? <Message title={"Aprobación no encontrada"} subtitle={"Revisa el acceso o si ya fue aprobada"} /> : null}
      {formStatus == "ERROR" ?
        <Message icon={"far fa-times-circle"} title={"Ha ocurrido un error en el envío"} subtitle={"Prueba de nuevo"} /> : null}
      {formStatus == "COMPLETED" ?
        <Message icon={"fas fa-check-circle"} iconColor={"#00C9A7"}
          title={"Aprobación enviada exitosamente"} subtitle={`Estatus: ${approvalStatus ? "Aprobada" : "Rechazada"}`}
          cta={"Ver aprobaciones"}
          onClickBack={onClickBack}
        /> : null}
    </div>
  </div>

const ApprovalItem = ({ approval, onSelectApproval }) =>
  <div
    className="d-flex justify-content-between align-items-center py-2 pr-1 pl-3 btn-lighter"
    style={{ borderRadius: "0.25rem", border: "1px solid rgb(218, 221, 224)", cursor: "pointer" }}
    onClick={() => approval.approve_status == "PENDING" ? onSelectApproval(approval.approve_token) : null}>
    <div className="d-flex flex-column pr-2">
      <div style={{ color: "#555" }}>
        Aprobación {approval.approve_token.substring(0, 4).toUpperCase()}
        {(approval.title ?? "") != "" ?
          <small className="text-muted ml-2">
            ({approval.title ?? ""})
          </small> : null}
      </div>
      <div className="text-muted">

      </div>
      <div className="text-muted">
        <small>
          {approval.approve_status == "APPROVED" ? "Aprobado el " : ""}
          {approval.approve_status == "REJECTED" ? "Rechazado el " : ""}
          {approval.approve_status == "APPROVED" || approval.approve_status == "REJECTED" ?
            moment(new Date(approval.approve_date)).format("DD.MM.YYYY") : ""}

          {approval.approve_status == "PENDING" ? "Enviado el " : ""}
          {approval.approve_status == "PENDING" || approval.approve_status == "REJECTED" ?
            moment(new Date(approval.created_date)).format("DD.MM.YYYY") : ""}

        </small>
      </div>

    </div>
    <div className="d-flex align-items-center">

      <button
        className={"btn btn-sm mr-2 " +
          (approval.approve_status == "APPROVED" ? "btn-success" :
            approval.approve_status == "REJECTED" ? "btn-danger" : "btn-primary")}
        disabled={approval.approve_status != "PENDING"}
        onClick={() => approval.approve_status == "PENDING" ? onSelectApproval(approval.approve_token) : null}
      >
        {approval.approve_status == "APPROVED" ? "Aprobado" : approval.approve_status == "REJECTED" ? "Rechazado" : "Aprobar"}
      </button>
    </div>
  </div>

const ApprovalList = ({ pendingApprovalList, pastApprovalList, onSelectApproval }) =>
  <div class="card animate__animated animate__fadeInUp animate__faster">
    <div class="card-header">
      <h3>Aprobaciones pendientes</h3>
    </div>
    <div class="card-body animated__animated animated_fadeIn">
      {pendingApprovalList.length > 0 ?
        <div className="pb-3">
          {
            pendingApprovalList.map((approval) =>
              <ApprovalItem
                key={approval.approval_token}
                approval={approval}
                onSelectApproval={onSelectApproval}
              />
            )
          }
        </div> : null}

      {pastApprovalList.length > 0 ?
        <a
          class="btn btn-sm btn-lighter py-2 px-2 ml-0 text-left" data-toggle="collapse"
          style={{ border: "none", color: "#959595" }}
          href={"#past-approvals"} role="button" aria-expanded="false" aria-controls="schema-builder-hidden">
          Aprobaciones pasadas
          <i style={{ color: "#aaa" }} class="ml-1 fas fa-chevron-down"></i>
        </a> : null
      }
      <div id="past-approvals" className='collapse'>
        {
          pastApprovalList.map((approval) =>
            <ApprovalItem
              key={approval.approval_token}
              approval={approval}
              onSelectApproval={onSelectApproval}
            />
          )
        }
      </div>



    </div>
  </div>



const RouteApprovalView = ({
  values, schema, token, formStatus, formTitle,
  approvalStatus, pendingApprovalList, pastApprovalList, selectedToken,
  onSelectApproval, onFormSubmit, onClickBack }) =>
  <main id="content" role="main" class="main pl-0">

    <div class="container py-5 py-sm-7">

      <a class="d-flex justify-content-center mb-5" href={`${APP_URL}/a/${token}`}>
        <img class="z-index-2"
          src="/theme/svg/logos/logo-white.svg" alt="Logo" style={{ height: "2.2rem" }} />
      </a>

      <div class="row justify-content-center">
        {formStatus != "NOT_FOUND" && selectedToken == null ?
          <div class="col-md-7 col-lg-6">
            <ApprovalList
              pendingApprovalList={pendingApprovalList}
              pastApprovalList={pastApprovalList}
              onSelectApproval={onSelectApproval} />
          </div> : null}

        {formStatus == "ACTIVE" && selectedToken != null ?
          <div class="col-md-7 col-lg-6">
            <SchemaComponent
              values={values}
              schema={schema}
              formTitle={formTitle}
              onFormSubmit={onFormSubmit}
              onClickBack={onClickBack} />
          </div> : null}
        {formStatus != "ACTIVE" && formStatus != null ?
          <div class="col-md-7 col-lg-6">
            <MessageComponent
              formStatus={formStatus}
              approvalStatus={approvalStatus}
              onClickBack={onClickBack} />
          </div> : null}
      </div>

    </div>
  </main>

export default RouteApprovalView;