import React from "react";
import View from "components/start/executions/ExecutionDetails.view";

function ExecutionDetails({ idx, execution, onResumeExecution, onCancelExecution }) {

  const isDeveloperMode = sessionStorage.getItem("developer_mode") == "true";

  return (
    <View
      idx={idx}
      execution={execution}
      isDeveloperMode={isDeveloperMode}
      onResumeExecution={onResumeExecution}
      onCancelExecution={onCancelExecution}
    />
  );
}

export default ExecutionDetails;