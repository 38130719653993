
const heatmapChartOptions = {
    chart: {
        type: 'heatmap'
    },
    dataLabels: {
        enabled: false
    },
    colors: ["#008FFB"],
    // xaxis: {
    //   categories: ['Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre', 'Enero',
    //     'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
    // }
    stroke: {
        show: true,
        width: 0.05,
        colors: ['#000000']
    },
    // xaxis: {
    //   categories: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
    // },
    // yaxis: {
    //   title: {
    //     text: 'Número de créditos'
    //   }
    // },
    // fill: {
    //   colors: ['#1A73E8', '#B32824']
    // },
    // tooltip: {
    //   y: {
    //     formatter: function (val) {
    //       return val + " créditos"
    //     }
    //   }
    // }
};

const lineChartOptions = {
    chart: {
        type: 'line',
    },
    dataLabels: {
        enabled: true,
    },
    stroke: {
        curve: 'smooth'
    },
    colors: ['#77B6EA'],
    grid: {
        borderColor: '#e7e7e7',
        row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
        },
    },
    markers: {
        size: 1
    }
}


const pieChartOptions = {
    chart: {
        type: 'pie',
    },
    theme: {
        monochrome: {
            enabled: true,
        },
    },
    legend: {
        show: false,
    },
    grid: {
        padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
    },
    dataLabels: {
        formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            return [name, val.toFixed(1) + '%']
        },
    },
    title: {
        text: "Ejecuciones por aplicación",
        align: "center",
        style: {
            fontSize: '12px',
            fontWeight: 'bold',
        },
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val + " ejecuciones"
            }
        }
    }
}

const barChartOptions = {
    chart: {
        type: "bar"
    },
    plotOptions: {
        bar: {
            horizontal: true
        }
    },
    legend: {
        show: true,
        showForSingleSeries: true,
    }
}


export { heatmapChartOptions, lineChartOptions, pieChartOptions, barChartOptions }


