import React, { useState, useEffect, useRef } from "react";
import { useQuery, useSet } from "seed/gql";
import { SET_SUGGESTION } from "seed/gql/queries";
import { usePost } from "seed/api";
import moment from "moment";
import View from "components/flow/suggestions/Suggestions.view"

const Suggestions = ({
  flowId
}) => {

  const promptRef = useRef(null);
  const [text, setText] = useState("");
  const [active, setActive] = useState(false);
  const [result, setResult] = useState(JSON.parse(sessionStorage.getItem("promptResult")) || []);
  const [examples, setExamples] = useState([
    {
      "response": {
        "type": "suggestion",
        "text": "Ideas para procesos contables"
      }
    },
    {
      "response": {
        "type": "instruction",
        "text": "Cómo conectar mi CRM"
      }
    },
    {
      "response": {
        "type": "question",
        "text": "¿Para qué es la pestaña de datos?"
      }
    }
  ]);

  const reqLastSuggestion = useQuery(`{
    suggestions {
      id
      type
      description
      response
      isFavorite
      isShown
      extraData
    }
  }`, `(flow.id=${flowId} AND is_favorite=false AND is_shown=true AND type=SUGGESTION)`, { limit: 3, orderBy: "-created_at" });

  const [callGenerateResponse, reqGenerateResponse] = usePost('/flows/generate_flow_suggestions', {
    onCompleted: (data) => {
      const newResult = [
        { 
          ...data, 
          prompt: text,
          id: moment().format("DDMMYYYYHHmmss"),
        }, 
        // ...(result??[])
      ];
      setResult(newResult);
      sessionStorage.setItem("promptResult", JSON.stringify(newResult));
    },
    onError: (error) => {
      alert("Ha ocurrido un error, prueba de nuevo")
    }
  });

  const [callGenerateExamples, reqGenerateExamples] = usePost('/flows/generate_suggestion_examples', {
    onCompleted: (data) => {
      setExamples(data?.examples);
    },
    onError: (error) => {
      alert("Ha ocurrido un error, prueba de nuevo")
    }
  });

  const [callSetSuggestions, reqSetSuggestions] = useSet(SET_SUGGESTION, {
    onCompleted: (data) => {
      reqLastSuggestion.refetch();
    }
  });

  const onGenerateResponse = (text) => {
    if(reqGenerateResponse.loading) return;
    setActive(false);
    setText(text);
    callGenerateResponse({ 
      text,
      n_results: 3,
      flow_id: flowId,
      context: JSON.parse(sessionStorage.getItem("suggestion_context")) || {}
    });
  }

  const onPromptRemove = () => {
    setText("")
    document.querySelector(".prompt-input").focus()
  }

  const onGenerateExamples = () =>
    callGenerateExamples({
      n_results: 3,
      flow_id: flowId,
      context: JSON.parse(sessionStorage.getItem("suggestion_context")) || {}
    });

  const onDocumentClick = (e) => {
    if(!e?.target?.getAttribute("ignore-blur") && promptRef.current && !promptRef.current.contains(e.target))
      setActive(false);
  }

  const onCloseResult = (idx, id) => {
    
    result[idx].suggestions.forEach(s => {
      callSetSuggestions({ id: s.id, isShown: false });
    });

    const newResult = result?.filter((_, i) => i !== idx);
    setResult(newResult);
    sessionStorage.setItem("promptResult", JSON.stringify(newResult));
    sessionStorage.removeItem("selectedSuggestion" + id);
    
  }

  const { suggestions } = reqLastSuggestion.data || {};

  useEffect(() => {
    document.addEventListener("click", onDocumentClick);
    return () => document.removeEventListener("click", onDocumentClick);
  }, [])

  useEffect(() => {
    if((!result || result?.length == 0) && suggestions?.length > 0) {
      setResult([{ suggestions }]);
      sessionStorage.setItem("promptResult", JSON.stringify([{ suggestions }]));
    }
  }, [suggestions, result])

  const isLoading = reqGenerateResponse.loading;
  const isExamplesLoading = reqGenerateExamples.loading;

  return <View 
    flowId={flowId}
    promptRef={promptRef}
    isLoading={isLoading}
    isExamplesLoading={isExamplesLoading}
    text={text}
    active={active}
    result={result}
    examples={examples}
    setText={setText}
    setActive={setActive}
    onGenerateResponse={onGenerateResponse}
    onGenerateExamples={onGenerateExamples}
    onCloseResult={onCloseResult}
    onPromptRemove={onPromptRemove}
  />

}

export default Suggestions;