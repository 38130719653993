/* eslint-disable max-len */
import React from "react";
import { Handle, Position } from 'reactflow';

const defaultPoints = [
    { "mode": "NEXT", "position": "TOP" },
    { "mode": "NEXT", "position": "RIGHT" },
    { "mode": "NEXT", "position": "BOTTOM" },
    { "mode": "NEXT", "position": "LEFT" }
]

function TargetPoints({ action }) {

    const actionName = action.name;
    const uiSettings = action.uiSettings;
    const relationships = uiSettings?.relationships ?? defaultPoints;
    const isControl = actionName == "conditional" || actionName == "iterate";
    const points = [];

    for (const idx in relationships) {
        
        const relationship = relationships[idx]
        const controlStyle = {};
        const mode = relationship.mode.toLowerCase();
        const position = relationship.position.toLowerCase();
        let visualPosition = "";

        if (position == "top") {
            visualPosition = Position.Top;
            controlStyle.top = "-17px";
        } else if (position == "bottom") {
            visualPosition = Position.Bottom;
            controlStyle.bottom = "-17px";
            if (isControl) controlStyle.display = "none" // TODO Check
        } else if (position == "left") {
            visualPosition = Position.Left;
            controlStyle.left = "-17px";
        } else if (position == "right") {
            visualPosition = Position.Right;
            controlStyle.right = "-17px";
        }

        const props = {
            id: `${mode}_${position}`,
            style: {},
            position: visualPosition
        }

        if (isControl) props.style = controlStyle;
        points.push(<Handle key={idx} {...props} />);
    }

    return <div>{points.map((point) => point)}</div>
}

export default TargetPoints;
