import React from "react";
import Tables from "components/tables/Tables";


const SectionView = ({ flowId, section, collection }) => (
  <>
    {section ? (
      <>

        {collection && (
          <div className="w-100 mt-n3">
            <div className="page-header pb-4">
              <div className="row align-items-end">
                <div className="col-sm">
                  <h2 className="page-header-title">{section.title}</h2>
                </div>

                <div className="col-sm-auto d-flex">
                  {(section?.actions ?? []).map((action, key) => (
                    <div className="mx-1" key={key}>
                      <a className="btn btn-primary mr-3" role="button">
                        <i class="fa-solid fa-play mr-2"></i>
                        {action.name}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {collection ? (
          <div className="card-body" style={{ height: "630px", backgroundColor: "#fff" }}>
            <Tables
              flowId={flowId}
              showTabs={false}
              initialCollectionId={collection.id}
            />
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <div id="no-collection-card" class="card border">

              <img class="card-img-top" src="/theme/img/background.jpg" alt="Card cap" height="150px" />

              <div class="card-body text-center">
                <div class="avatar avatar-xl avatar-soft-primary avatar-circle avatar-border-lg avatar-centered mb-3">
                  <span class="avatar-initials">{section.title.charAt(0)}</span>
                </div>

                <h3 class="mb-1">
                  <a class="text-dark">{section.title}</a>
                </h3>

              </div>

              <div class="card-footer">
                <div class="row justify-content-center align-items-center">
                  {(section?.actions ?? []).map((action, key) => (
                    <div class="col-auto py-1" key={key}>
                      <a className="btn btn-primary" role="button">
                        <i class="fa-solid fa-play mr-2"></i>
                        {action.name}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    ) : (
      <div></div>
    )}
  </>
)

export default SectionView;