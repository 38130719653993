

const getUpperLeftNodePosition = (nodes) => {
  const normalizedNodes = nodes.map((node) => {
    let newNode = { ...node };
    if (node.position?.x != null && node.position?.y != null) {
      let newPosition = {
        x: node.position.x,
        y: -node.position.y
      };
      newNode.position = newPosition;
    }
    return newNode
  })
  let upperLeftNode = null;
  let minValue = 10000000;
  let maxY = -10000000;

  for (const node of normalizedNodes) {
    if (node.position?.y > maxY) maxY = node.position.y;
  }

  for (const node of normalizedNodes) {
    const value = node.position.x + maxY - node.position.y;
    if (value < minValue) {
      minValue = value;
      upperLeftNode = node;
    }
  }

  let position = null;
  if (upperLeftNode) {
    position = {};
    position.x = upperLeftNode.position.x;
    position.y = -upperLeftNode.position.y;
  }

  return position
}

const getInitialBoardRect = (nodes) => {

  let minX = 1000000, minY = 1000000;
  let maxX = -1000000, maxY = -1000000;

  if (nodes.length == 0) {
    return { x: 0, y: 0, width: 0, height: 0 };
  }

  for (const node of nodes) {
    if (node?.position?.x < minX) minX = node?.position?.x;
    if (node?.position?.y < minY) minY = node?.position?.y;

    if (node?.position?.x > maxX) maxX = node?.position?.x;
    if (node?.position?.y > maxY) maxY = node?.position?.y;
  }

  const xThreshold = 500;
  const yThreshold = 600;

  const xDistance = Math.abs(maxX - minX);
  const yDistance = Math.abs(maxY - minY);

  if (xDistance > xThreshold || yDistance > yThreshold) {
    return { x: minX, y: minY, width: xDistance, height: yDistance }
  }

  const boardFlowX = 800;
  maxX += 150;
  minY -= 100;
  const xStart = minX - (boardFlowX - xDistance) / 2
  return { x: xStart, y: minY+50, width: xDistance, height: yDistance };

}

export { getUpperLeftNodePosition, getInitialBoardRect }