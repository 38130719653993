import React from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Modal } from "seed/helpers"
import SaveTable from "components/tables/forms/FormSave";
import BaseField from "components/schema_form/fields/custom_fields/BaseField";
import { CategoryHeader, CollectionItem, AddCollectionItem, VariableItem, AttributeItem }
  from "components/schema_form/fields/variable_selector/VariableSelector.sub.view";
import FilterField from "components/schema_form/fields/custom_fields/FilterField";
import lodash from "lodash";



const VariableSelectorView = ({
  name, label, required, disabled,openText, enableAttributes, caption, helpMessage, placeholder, textType, flowId,  values,
  state, nextState, showModal, showOptionsWhenEmpty, showCondition, 
  conditionTempName, conditionStructure, conditionColumn, conditionState, conditionStr,  
  setFieldValue, saveCollectionSchema, showCollectionCondition, onInputChange, onClickArrow, onClickAttributeButton, 
  onCloseCollectionModal, onSaveCollection, onClickAddCollection, onConditionSelectChange, onCollectionFilterChange
}) => (
  <BaseField name={name} label={label} required={required} caption={caption} helpMessage={helpMessage}
    enableComments={false} values={values} setFieldValue={setFieldValue}>
    <div>
      {state && state.map((item, idx) => (
        <div key={idx} style={{ marginLeft: (2.25 * idx) + "rem" }} >
          <div className={`w-100 ${idx > 0 ? "mt-2" : "mt-0"}`} >
            <Autocomplete
              id={`${name}_${idx}`}
              freeSolo={openText}
              forcePopupIcon
              value={item?.selectedOption}
              options={item.options}
              required={required}
              disabled={disabled}
              filterOptions={(options, state) => {
                if (state.inputValue == "")
                  return options
                return options.filter(option => (option.label ?? "").includes(state.inputValue))
              }}
              getOptionLabel={(option) => option.label}
              groupBy={(option) => option.category == "addCollection" ? "collection" : option.category}
              renderGroup={(params) => {
                return (
                  <li key={params.key}>
                    <CategoryHeader value={params.group} />
                    <div>{params.children}</div>
                  </li>
                )
              }}
              renderInput={(params) => {
                if (item?.selectedOption != null)
                  params.inputProps.value = item?.selectedOption
                let value = params.inputProps.value
                let selectedOption = item.options.find(opt => opt.value == value && value != "")
                return <div className="w-100">
                  <TextField {...params} placeholder={idx == 0 ? placeholder : "Selecciona un valor"} variant="outlined"
                    required={required} type={selectedOption || idx > 0 ? "text" : textType}
                    className={(!openText || idx > 0 ? "no-autocomplete " : " ") +
                      (selectedOption ? "hidden-input " : " ") +
                      (!selectedOption && (textType == "date " || textType == "datetime-local ") ? "date-input " : " ")
                    } />

                  {selectedOption?.category == "collection" ?
                    <CollectionItem collection={selectedOption.data} isOnSelector={true} /> :
                    selectedOption?.category == "variable" ?
                      <VariableItem node={selectedOption.data} action={selectedOption.action} isOnSelector={true} /> :
                      selectedOption ?
                        <AttributeItem label={selectedOption.label} isOnSelector={true} /> : null}

                    {nextState.length > state.length && idx == state.length - 1 && enableAttributes ?
                    <div className="attribute-button" onClick={onClickAttributeButton}><i class="fas fa-indent"></i></div>
                    : null }
                </div>
              }}
              renderOption={(option, { selected, inputValue }) => {
                if (option.category == "collection")
                  return <CollectionItem collection={option.data} />
                if (option.category == "addCollection")
                  return <AddCollectionItem onClickAddCollection={onClickAddCollection} />
                else if (option.category == "variable")
                  return <VariableItem node={option.data} />
                return <AttributeItem label={option.label} />
              }}
              onOpen={(event) => {
                if (event.type == "click" && event.target.classList.contains("MuiSvgIcon-root")) onClickArrow(idx)
              }}
              onClose={(event) => {
                if (event.type == "click" && event.target.classList.contains("MuiSvgIcon-root")) onClickArrow(idx)
              }}
              onChange={(event, newValue, reason) => onInputChange(idx, newValue?.value ?? "", reason)}
              onInputChange={(event, newValue, reason) =>
                openText && (reason == "input" || reason == "clear") ? onInputChange(idx, newValue, reason) : null}
            />
          </div>
        </div>
      ))}
      {showCollectionCondition && conditionStructure ?
        <div className="ml-4 mt-2">
          <div class="mt-1 mb-0" style={{ color: "#a0a0a0", fontSize: "0.97em" }}><i>Utilizar</i></div>
          <select class="form-control select-field mt-1"
            onChange={e => onConditionSelectChange(e.target.value)}
            value={showCondition || conditionStr !="" ? "filter" : "all"}>
            <option value="all">Todos los registros de la tabla</option>
            <option value="filter">Registros especificos</option>
          </select>
          {showCondition || conditionStr != "" ?
            <div class="mt-2 ml-4">
              <div class="mb-0" style={{ color: "#a0a0a0", fontSize: "0.97em" }}><i>Donde</i></div>
              <FilterField
                name={`temp_filter_${conditionTempName}`}
                values={values}
                flowId={flowId}
                required={true}
                valueAPlaceholder={"Selecciona una columna"}
                valueAAttrs={conditionStructure}
                valueADefault={conditionState.value_a ?? conditionColumn}
                conditionDefault={conditionState.condition ?? "="}
                valueBDefault={conditionState.value_b ?? ""}
                value={lodash.get(values, `${conditionTempName}_filter`)}
                setFieldValue={setFieldValue}
                onChange={onCollectionFilterChange}
              />
            </div>
            : null}
        </div>
        : null}

      {showModal ?
        <Modal
          onClose={onCloseCollectionModal}
          width="600"
          height="550"
          component={() =>
            <SaveTable
              flowId={flowId}
              suggestions={saveCollectionSchema}
              onSave={onSaveCollection}
            />
          } /> : null}

    </div>
  </BaseField>
);

export default VariableSelectorView;