import React from "react";
import { Link } from "react-router-dom";

const VerifyEmailView = ({ verifyError, onClickGenerate }) => (

  <main id="content" role="main" class="main pl-0">

    <div class="container py-5 py-sm-7">
      <a class="d-flex justify-content-center mb-6" href={"/"}>
        <img class="z-index-2" src="/theme/svg/logos/logo-white.svg" alt="Logo" style={{ height: "2.65rem" }} />
      </a>

      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card card-lg mb-5 pt-1 px-1 pb-2 animate__animated animate__fadeInUp animate__faster">
            <div class="card-body">

              <div class="text-center">
                <div class="mb-5">
                  <h1 class="display-4">Verificación de cuenta</h1>
                </div>
              </div>

              {verifyError ? (
                <>
                  <h4 class="mb-4">
                    El link ya no es válido, han pasado mas de 20 minutos desde que se generó
                  </h4>

                  <button
                    class="btn btn-primary btn-block"
                    onClick={onClickGenerate}
                  >
                    Generar nuevo link
                  </button>
                </>
              ) : (
                <>
                  <h4 class="mb-4">
                    Correo verificado exitosamente, ahora puedes iniciar sesión
                  </h4>
                  <Link
                    to="/login"
                    class="btn btn-primary btn-block"
                  >
                    Iniciar sesión
                  </Link>
                </>
              )}

            </div>
          </div>
        </div>
      </div>

    </div>
  </main>
);

export default VerifyEmailView;