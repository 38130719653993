import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import moment from "moment";
import { API_URL } from "settings";
import { ColumnTypes } from "components/tables/util/datatypes";
import { filterBySearch } from "components/tables/util/filters";
import { formatNumber } from "components/tables/util/format";
import { Modal } from "seed/helpers";

const getDateFormats = () => {

  const separators = ["/", "-", ".", ""]
  const dateFormats = ["YYYY-MM-DD", "YYYY-MM-DDTHH:mm:ss.SSSZ", "YYYY-MM-DDTHH:mm:ss.SSS", "YYYY-MM-DDTHH:mm:ss", "YYYY-MM-DD HH:mm:ss", 
    "YYYY-MM-DD HH:mm", "DD-MM-YYYY", "DD-MM-YYYYTHH:mm:ss.SSSZ", "DD-MM-YYYYTHH:mm:ss.SSS", "DD-MM-YYYYTHH:mm:ss", "DD-MM-YYYY HH:mm:ss", 
    "DD-MM-YYYY HH:mm", "MM-DD-YYYY", "MM-DD-YYYYTHH:mm:ss.SSSZ", "MM-DD-YYYYTHH:mm:ss.SSS", "MM-DD-YYYYTHH:mm:ss", "MM-DD-YYYY HH:mm:ss", 
    "MM-DD-YYYY HH:mm"]

  const formats = [];
  dateFormats.forEach((format) => {
    separators.forEach((separator) => {
      formats.push(format.replace(/-/g, separator));
    });
  });

  return formats;
    
}

const getDate = (value, format = "DD/MM/YYYY") => {
  const formats = getDateFormats();
  let date = null;
  for(let i = 0; i < formats.length; i++) {
    date = moment.utc(value, formats[i]);
    if(date.isValid()) break;
  }
  if(!date.isValid()) date = null;
  if(format == "iso") return date?.toISOString() ?? "";
  return date?.format(format) ?? "";
}

const Cell = ({
  column,
  collectionColumns,
  value,
  isReadOnly,
  isActive,
  onClick = () => { },
  onChange = () => { },
  onDelete = () => { },
  onPreviewFile = () => { },
  onTypeEnter = () => { },
  onInsert = () => { },
}) => {

  const cellRef = useRef(null);
  const contextMenuRef = useRef(null);
  const inputRef = useRef(null);
  const [showSearch, setShowSearch] = useState(false);
  const [isContextMenuShown, setIsContextMenuShown] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [search, setSearch] = useState("");
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const componentId = useRef(Math.random().toString(36).substring(7));

  useEffect(() => {
    document.addEventListener("mousedown", (e) => {
      if (cellRef?.current &&
        !cellRef?.current?.contains(e.target) &&
        e.target?.getAttribute("ignore-onclickoutside") != componentId.current
      ) {
        setIsContextMenuShown(false);
      }
    });
  }, []);

  useEffect(() => {
    if(!isActive || isReadOnly || column.type == ColumnTypes.OBJECT) return;
      inputRef?.current?.focus();
  }, [isActive]);

  const onChangeFile = (e) => {

    const onUploadFile = (formWrapper) => {
      let url = `${API_URL}/files/`;
      $.ajax({
        url: url,
        type: "POST",
        headers: {
          "Authorization": `Token ${sessionStorage.getItem("token")}`
        },
        data: new FormData(formWrapper),
        cache: false,
        contentType: false,
        processData: false,
        xhr: function () {
          var myXhr = $.ajaxSettings.xhr();
          myXhr.upload.addEventListener("progress", function(evt) {
            if(evt.lengthComputable) {
              var percentComplete = (evt.loaded / evt.total) * 100;
              setUploadProgress(parseInt(percentComplete));
            }
       }, false);
          return myXhr;
        },
        success: (res) => {
          onChange(res);
          setIsUploading(false);
        },
        error: (error) => {
          setIsUploading(false);
          console.log(error);
        }
      });
    };

    setIsUploading(true);
    onUploadFile(e.target.form);

  }

  const onClickSelectFile = () => {

    let form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("enctype", "multipart/form-data");

    let input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("name", "file");
    input.setAttribute("accept", ".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.jpg,.jpeg,.png,.gif,.mp4,.mp3,.zip,.rar,.7z");
    input.setAttribute("style", "display: none");
    input.setAttribute("ignore-onclickoutside", componentId.current);
    input.addEventListener("change", (e) => onChangeFile(e));

    form.appendChild(input);
    document.body.appendChild(form);

    input.click();

  }

  if(column.visible === false) return null;

  return (
    <>
      <td
        ref={cellRef}
        className="m-0 py-0 px-2 border"
        onClick={onClick}
        onContextMenu={(e) => {

          e.preventDefault();
          setIsContextMenuShown(true);

          const x = e.clientX - cellRef.current.getBoundingClientRect().x;
          const y = e.clientY - cellRef.current.getBoundingClientRect().y;
          setCoords({ x, y });

        }}
        style={{
          minWidth: "210px", 
          maxWidth: "450px",
          position: "relative",
          backgroundColor: "#FFF",
        }}
      >

        {
          isActive && !isReadOnly 
          ? (
              <>

                {
                  column.type === ColumnTypes.STRING && (
                    <input
                      ref={inputRef}
                      type="text"
                      className="form-control form-control-sm d-block w-100 h-100 py-0 m-0 "
                      style={{
                        minWidth: "280px"
                      }}
                      value={value}
                      ignore-onclickoutside={componentId.current}
                      onChange={(e) => onChange(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.key === "Enter")
                          onTypeEnter();
                      }}
                    />
                  )
                }

                {
                  column.type === ColumnTypes.FLOAT && (
                    <input
                      ref={inputRef}
                      type="number"
                      className="form-control form-control-sm d-flex flex-wrap py-0 m-0 w-100 h-100"
                      style={{
                        minWidth: "280px"
                      }}
                      value={value}
                      ignore-onclickoutside={componentId.current}
                      onChange={(e) => onChange(parseFloat(e.target.value))}
                      onKeyUp={(e) => {

                        if (e.key === "Enter")
                          onTypeEnter();

                        // Validate if the input is a number
                        if (e.key == "e")
                          e.preventDefault();

                      }}
                      onKeyDown={(e) => {

                        // Validate if the input is a number
                        if (e.key == "e")
                          e.preventDefault();

                      }}
                    />
                  )
                }

                {
                  column.type === ColumnTypes.DATE && (
                    <input
                      ref={inputRef}
                      type="date"
                      className="form-control form-control-sm d-flex flex-wrap py-0 m-0 w-100 h-100"
                      style={{
                        minWidth: "280px"
                      }}
                      value={getDate(value, "YYYY-MM-DD")}
                      ignore-onclickoutside={componentId.current}
                      // iso date format
                      onChange={(e) => onChange(getDate(e.target.value, "iso"))}
                    />
                  )
                }

                {
                  column.type === ColumnTypes.FILE && (
                    <div
                      className="d-flex justify-content-between align-items-center w-100 h-100 px-2"
                      style={{
                        cursor: "pointer",
                        border: isActive ? "1px solid #9FC8F4" : "1px solid #E7EAF3",
                        borderRadius: "5px",
                      }}
                    >

                      <div
                        className="d-flex justify-content-between align-items-center w-100 h-100 px-3"
                      >

                        {
                          isUploading
                            ? <div className="d-flex align-items-center">
                                <i class="fas fa-spinner fa-spin mr-2"></i>
                                {
                                  uploadProgress != 100 ?
                                    <span>Subiendo archivo ({uploadProgress}%)</span>
                                    : <span>Procesando archivo...</span>
                                }
                              </div>
                            : <>
                                { 
                                  value 
                                      ? <span 
                                        className="d-flex align-items-center btn-lighter px-2 py-1"
                                        style={{ border: "1px solid #e0e0e0", width: "75%", borderRadius: "0.25rem", cursor: "pointer" }}
                                        onClick={() => onPreviewFile(value)}
                                      > 
                                        <i class="far fa-file-alt mr-2 ml-2" style={{ fontSize: "1.2em" }}></i>
                                        <span className="text-truncate">{value?.name?.substring(33)}</span>
                                      </span>
                                    : <span className="text-truncate">Sin archivo</span>
                                }
                              </>
                        }
                  
                        <i 
                          className="fas fa-pencil-alt ml-2 mt-1"
                          style={{ cursor: "pointer" }}
                          onClick={onClickSelectFile}
                        />
                  
                      </div>

                    </div>
                  )
                }

                {
                  column.type === ColumnTypes.BOOLEAN && (

                    <div
                      className="d-flex justify-content-center align-items-center w-100 h-100"
                      style={{
                        cursor: "pointer",
                        border: isActive ? "1px solid #9FC8F4" : "1px solid #E7EAF3",
                        borderRadius: "5px",
                      }}
                    >
                      <input
                        ref={inputRef}
                        type="checkbox"
                        className="form-check-input d-flex flex-wrap m-0 w-50 h-50"
                        ignore-onclickoutside={componentId.current}
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  )
                }

                {
                  column.type === ColumnTypes.COLLECTION && (
                    <div 
                      className="d-flex justify-content-between align-items-center w-100 h-100 px-3"
                      style={{
                        cursor: "pointer",
                        border: isActive ? "1px solid #9FC8F4" : "1px solid #E7EAF3",
                        borderRadius: "5px",
                      }}
                    >
                      
                      { 
                        value 
                          ? <span className="d-flex align-items-center px-2 py-1"> 
                              <span className="text-truncate">
                                {
                                  function(){
                                    let collectionColumn = collectionColumns.find(col => col.id == column?.structure?.collection_id)
                                    let item = collectionColumn?.collectionDataes.find(item => item.id == value.id);
                                    if(!item) return "No encontrado";
                                    return collectionColumn?.initials + '-' + formatNumber(value?.folio) + ' ' + 
                                      (item?.data[Object.keys(item?.data)[0]] ?? "");
                                  }()
                                }
                              </span>
                            </span>
                          : <span className="text-truncate">Sin selección</span>
                      }
                
                      <i 
                        className="fas fa-pencil-alt ml-2 mt-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowSearch(true)}
                      />
                
                    </div>
                  )
                }

                {
                  column.type === ColumnTypes.ENUM && (
                    <select
                      ref={inputRef}
                      className="form-control form-control-sm d-flex flex-wrap py-0 m-0 w-100 h-100"
                      ignore-onclickoutside={componentId.current}
                      defaultValue={value}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      <option value="">Seleccionar...</option>
                      {
                        column?.structure?.values?.map((item, i) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))
                      }
                    </select>
                  )
                }

              </>
            ) 
          : (
              <div
                className="d-flex flex-wrap align-items-center py-0 px-2 m-0 w-100 h-100 text-truncate"
                ignore-onclickoutside={componentId.current}
              >
                {
                  function () {

                    if(column.type == ColumnTypes.FILE)
                      return <div
                        className="d-flex justify-content-between align-items-center w-100 h-100 px-3"
                      >
                  
                        { 
                          value 
                              ? <span 
                                className="d-flex align-items-center btn-lighter px-2 py-1"
                                style={{ border: "1px solid #e0e0e0", width: "75%", borderRadius: "0.25rem", cursor: "pointer" }}
                                onClick={() => onPreviewFile(value)}
                              > 
                                <i class="far fa-file-alt mr-2 ml-2" style={{ fontSize: "1.2em" }}></i>
                                <span className="text-truncate">{value?.name?.substring(33)}</span>
                              </span>
                            : <span className="text-truncate">Sin archivo</span>
                        }
                  
                        <i 
                          className="fas fa-pencil-alt ml-2 mt-1"
                          style={{ cursor: "pointer" }}
                          onClick={onClickSelectFile}
                        />
                  
                      </div>

                    if(column.type == ColumnTypes.COLLECTION) {
                      const collectionColumn = collectionColumns.find(col => col.id == column?.structure?.collection_id);
                      if(!value) return "";
                      if(!collectionColumn) return "";
                      return <div 
                        className="d-flex justify-content-between align-items-center w-100 h-100 px-2"
                        style={{
                          cursor: "pointer",
                          borderRadius: "5px",
                        }}
                      >
                        
                        { 
                          value 
                            ? <span className="d-flex align-items-center px-2 py-1"> 
                                <span className="text-truncate">
                                  {
                                    function(){
                                      let collectionColumn = collectionColumns.find(col => col.id == column?.structure?.collection_id)
                                      let item = collectionColumn?.collectionDataes.find(item => item.id == value.id);
                                      if(!item) return "No encontrado";
                                      return collectionColumn?.initials + '-' + formatNumber(value?.folio) + ' ' + 
                                        (item?.data[Object.keys(item?.data)[0]] ?? "");
                                    }()
                                  }
                                </span>
                              </span>
                            : <span className="text-truncate">Sin selección</span>
                        }
                  
                        <i 
                          className="fas fa-pencil-alt ml-2 mt-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowSearch(true)}
                        />
                  
                      </div>
                    }

                    if(column.type == ColumnTypes.BOOLEAN)
                      return <div
                        className="d-flex justify-content-center align-items-center w-100 h-100"
                      >
                        <input
                          ref={inputRef}
                          type="checkbox"
                          className="form-check-input d-flex flex-wrap m-0 w-50 h-50"
                          ignore-onclickoutside={componentId.current}
                          defaultChecked={value}
                        />
                      </div>

                    if(column.type == ColumnTypes.DATE)
                      return <div> { getDate(value, "DD/MM/YYYY") } </div>

                    if(column.type == ColumnTypes.OBJECT && value)
                      return <table className="table-sm w-100">
                        <thead>
                          <tr>
                            <th style={{ minWidth: "80px" }}>Llave</th>
                            <th style={{ minWidth: "80px" }}>Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            Object?.keys(value).map((key, i) => (
                              <tr key={i}>
                                <td>{key}</td>
                                <td>{value[key]?.toString()}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>

                    return value?.toString();

                  }()
                }
              </div>
            )
        }

        {
          isContextMenuShown && (
            <div
              ref={contextMenuRef}
              class="dropdown show"
              style={{
                position: "absolute",
                top: coords.y,
                left: coords.x,
                zIndex: 1000,
              }}
            >
              <div class="dropdown-menu show" aria-labelledby="add-column-dropdown">

                {
                  !isReadOnly && 
                    <a
                      className="dropdown-item"
                      href="#"
                      ignore-onclickoutside={componentId.current}
                      onClick={(e) => {
                        onClick(e);
                        setIsContextMenuShown(false);
                      }}
                    >
                      Editar
                    </a>
                }

                {
                  !isReadOnly && 
                    <a
                      className="dropdown-item"
                      href="#"
                      ignore-onclickoutside={componentId.current}
                      onClick={() => {
                        setIsContextMenuShown(false);
                        onInsert();
                      }}
                    >
                      Insertar fila
                    </a>
                }

                {
                  !isReadOnly && 
                    <a
                      className="dropdown-item"
                      href="#"
                      ignore-onclickoutside={componentId.current}
                      onClick={() => {
                        setIsContextMenuShown(false);
                        onDelete();
                      }}
                    >
                      Eliminar fila
                    </a>
                }

              </div>
            </div>
          )
        }

        {
          showSearch && column?.structure?.collection_id &&
            <Modal
              width="600"
              height="400"
              onClose={() => setShowSearch(false)}
              component={() => 
                <div className="card card-body">

                  <div className="d-flex justify-content-between align-items-center w-100 h-100 py-2">

                    <div 
                      className="bg-white px-2 py-1 rounded mr-2"
                      style={{ border: "1px solid #dadde0"}}
                    >
                      <i className="fas fa-search mr-3"></i>
                      <input 
                        ref={inputRef}
                        type="text"
                        className="border-0"
                        placeholder="Buscar"
                        ignore-onclickoutside={componentId.current}
                        onBlur={() => {
                          setSearch("");
                        }}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>

                  </div>
                  
                  <div className="table-responsive" style={{ maxHeight: "300px", overflowY: "auto" }}>
                    <table className="table table-sm table-bordered table-hover m-0">
                      {
                        function(){
                          
                          const collectionColumn = collectionColumns.find(col => col.id == column?.structure?.collection_id);
                          let data = collectionColumn?.collectionDataes??[];  
                          data = data.sort((a, b) => a.folio - b.folio);

                          return <>
                            <thead>
                              <tr>
                                <th style={{ minWidth: "80px" }}></th>
                                {
                                  collectionColumn?.schema?.structure?.map((col, i) => (
                                    <th 
                                      key={i}
                                      style={{
                                        minWidth: "90px",
                                      }}
                                    >
                                      {col.label}
                                    </th>
                                  ))
                                }
                                <th>
                                  Acciones
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                filterBySearch(data, search).map((item, i) => (
                                  <tr key={i}>
                                    <td style={{background: "#fff"}}>
                                      <div className="d-flex align-items-center w-100 h-100">
                                        {`${collectionColumn?.initials}-${formatNumber(item?.folio)}`}
                                      </div>
                                    </td>
                                    {
                                      collectionColumn?.schema?.structure?.map((col, j) => (
                                        <td key={j}>
                                          <div className="d-flex align-items-center w-100 h-100">
                                            {
                                              function(){

                                                if(col.type === ColumnTypes.FILE)
                                                  return <div
                                                    className="d-flex justify-content-between align-items-center w-100 h-100 px-2"
                                                  >
                                                    <span
                                                      className="d-flex align-items-center btn-lighter px-2 py-1"
                                                      style={{ border: "1px solid #e0e0e0", cursor: "pointer" }}
                                                      onClick={() => onPreviewFile(item.data[col.name])}
                                                    >
                                                      <i class="far fa-file-alt mr-2 ml-2" style={{ fontSize: "1.2em" }}></i>
                                                      <span className="text-truncate">{item.data[col.name]?.name?.substring(33)}</span>
                                                    </span>
                                                  </div>

                                                if(col.type === ColumnTypes.COLLECTION) {
                                                  const auxColumn = collectionColumns.find(auxCol => auxCol?.id == col?.structure?.collection_id);
                                                  const auxValue = item.data[col.name];
                                                  if(!auxValue) return "";
                                                  if(!auxColumn) return "";
                                                  return `${auxColumn?.initials}-${formatNumber(auxValue.folio)}`;
                                                }

                                                if(col.type === ColumnTypes.BOOLEAN)
                                                  return <input
                                                    type="checkbox"
                                                    className="form-check-input d-flex flex-wrap m-0 w-50 h-50"
                                                    ignore-onclickoutside={componentId.current}
                                                    defaultChecked={item.data[col.name]}
                                                    disabled
                                                  />

                                                return item.data[col.name]?.toString();

                                              }()
                                            }
                                          </div>
                                        </td>
                                      ))
                                    }
                                    <td style={{background: "#fff"}}>
                                      <div
                                        className="d-flex align-items-center w-100 h-100 justify-content-center"
                                      >
                                        <button
                                          className={`btn ${item.id == value?.id ? 'btn-outline-primary' : 'btn-link'}  m-0 p-1 px-3`}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            onChange({ id: item.id, folio: item.folio });
                                            setShowSearch(false);
                                          }}
                                        >
                                          Seleccionar
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </>

                        }()
                      }
                    </table>
                  </div>

                </div>
              }
            />
        }

      </td>
    </>
  );
}

export default Cell;