import React from "react";
import AppsList from 'components/flow/toolbox/apps/AppList';

const Apps = ({
	search,
	flowId,
	activeTab,
	setActiveTab,
	onSearchApps,
	onUpdateAppsQuery
}) => (
	<div style={{height: "530px", overflow: "hidden"}}>

		<div class="px-4 mt-4 mb-3">
			<input type="text" placeholder="Buscar" class="py-1 pl-7 pr-2 rounded w-100"
				style={{ border: "1px solid #d0deda" }} value={search}
				onChange={(event) => onSearchApps(event.target.value)}
			></input>
			<i className="fas fa-search d-block ml-3" style={{ fontSize: "1rem", marginTop: "-1.5rem" }}></i>
		</div>

		{search == "" ? (
			<div id="toolbox-apps-tab" className="tabs mb-3 mt-4 col-md-12 d-flex"
				style={{ borderBottom: '2px solid #dee2e6' }}>
				<button
					className={"dropdown-header px-3 py-2 " + (activeTab == 'all' ? "active" : "")}
					onClick={() => setActiveTab('all')}>
					Disponibles
				</button>
				<button
					className={"dropdown-header px-3 py-2 " + (activeTab == 'installed' ? "active" : "")}
					onClick={() => setActiveTab('installed')}>
					Instaladas
				</button>
			</div>) : <div class="mt-4"></div>
		}
		<div className="px-3 scrollbar-narrow" style={{ height: "400px", overflowY: "auto" }}>
			<AppsList
				search={search}
				flowId={flowId}
				activeTab={activeTab}
				onUpdateAppsQuery={onUpdateAppsQuery}
			/>
		</div>

	</div>
);

export default Apps;
