const filterByQuery = (data, query) => {

	if(!query || query.length === 0) return data;

	const operators = {
		"=": (key, value) => `item.data['${key}'] == '${value}'`,
		"<>": (key, value) => `item.data['${key}'] != '${value}'`,
		">": (key, value) => `item.data['${key}'] > ${value}`,
		"<": (key, value) => `item.data['${key}'] < ${value}`,
		">=": (key, value) => `item.data['${key}'] >= ${value}`,
		"<=": (key, value) => `item.data['${key}'] <= ${value}`,
		"contains": (key, value) => `item.data['${key}'].includes('${value}')`,
		"not contains": (key, value) => `!item.data['${key}'].includes('${value}')`,
	}

	let keyExpression = new RegExp(/([\w\s]+)/);
	let operatorsExpression = new RegExp("("+ Object.keys(operators).join("|") +")");
	let valueExpression = /([\w\d'"\s]+)/;
	let expression = new RegExp(`${keyExpression.source}${operatorsExpression.source}${valueExpression.source}`, "g");

	query = query.replace(" AND ", " && ").replace(" OR ", " || ");

	let tokens = [];
	query.split(" && ").forEach(_query => 
		_query.split(" || ").forEach(__query => {
			tokens.push(__query);
		})
	);

	tokens.forEach(token => {

		let match = expression.exec(token);
		if(!match) return;

		let key = match[1]?.trim();
		let operator = match[2]?.trim();
		let value = match[3]?.trim().replace(/'/g, "").replace(/"/g, "");

		if(!key || !operator || !value) return;
		if(!operators[operator]) return;

		if(operators[operator])
			query = query.replace(match[0], operators[operator](key, value));

	});

	data = data.filter(item => {
		try{ return eval(query) }
		catch(e) { return false }
	});

	return data

}

const filterBySearch = (data, search) => {
	return data.filter(row => JSON.stringify(row).toLowerCase().includes(search.toLowerCase()));
}

const filterByPage = (data, pageNum, pageSize) => {
	return data.length > pageSize 
	? data.slice((pageNum - 1) * pageSize, pageNum * pageSize) 
	: data;
}

const filterData = ({ data, search, query, pageNum, pageSize, useSearch, useQuery, usePagination = false }) => {
	let filteredData = data;
	filteredData = useSearch ? filterBySearch(filteredData, search) : filteredData;
	filteredData = useQuery ? filterByQuery(filteredData, query) : filteredData;
	filteredData = usePagination ? filterByPage(filteredData, pageNum, pageSize) : filteredData;
	return filteredData;
}


const parseConditions = (conditions) => {

  let query = "";
  conditions?.forEach((condition, index) => {

    if(condition.type == "MULTIPLE") {
      if(condition.operator) query += ` ${condition.operator} `;
      query += `(${parseConditions(condition.conditions)})`;
    }
    else {
      if(condition.operator) query += ` ${condition.operator} `;
      query += `${condition.column} ${condition.condition} '${condition.value}'`;
    }

  });

  return query;

}

const getFilteredColumns = (filters) => {
	
	let columns = [];

	const parse = (conditions) => {
		conditions.forEach(condition => {
			if(condition.type == "MULTIPLE")
				parse(condition.conditions);
			else if(!columns.includes(condition.column)) 
				columns.push(condition.column);
		});
	}

	parse(filters);
	return columns;

}

export {
  filterByQuery,
  filterBySearch,
  filterByPage,
	filterData,
	parseConditions,
	getFilteredColumns
}