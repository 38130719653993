import React from 'react';
import { OverlayTrigger } from "react-bootstrap";
import Popover from 'react-bootstrap/Popover';
import * as lib from "components/flow/toolbox/util/toolbox";


const ActionElement = ({ action, app, isSuggestion = false, onClickAction, onDragStart }) => {

  const uiSettings = action.uiSettings;
  const hasSubtitle = uiSettings?.form?.subtitle != null || uiSettings?.general?.subtitle != null
  const hasCaption = uiSettings?.form?.caption != null || uiSettings?.general?.caption != null

  return (
    <OverlayTrigger key={action.id} placement="right" delay={{ show: 100 }}
      overlay={
        hasCaption ?
          <Popover id="toolbox-action-popover">
            <Popover.Body className="p-3"
              style={{
                backgroundColor: "#f0f3f5", border: "1px solid #e0e5ea",
                color: "#40454a", borderRadius: "10px", maxWidth: "12rem"
              }}>
              <div>{uiSettings?.form?.caption ?? uiSettings?.general?.caption}</div>
            </Popover.Body>
          </Popover> : <div></div>
      }>

      <a class="btn btn-outline-secondary btn-action px-3 w-100 d-inline-block"
        style={{
          marginBottom: !isSuggestion ? "0.27rem" : "0.35rem",
          paddingTop: !isSuggestion ? "0.77rem" : "0.7rem",
          paddingBottom: !isSuggestion ? "0.77rem" : "0.7rem",
          zIndex: "5"
        }}
        draggable onClick={() => onClickAction(action.id)}
        onDragStart={(event) => onDragStart(event, "node", action.id)}
      >

        <div className="d-flex align-items-center justify-content-start w-100">

          <i className={app.uiSettings?.icon} style={{ marginLeft: "0.3rem", marginRight: "1rem" }}></i>

          <div class="text-left">
            <div class="pb-0 mb-0" style={{
              paddingRight: "0rem", color: "#455a65", fontWeight: "bold",
              fontSize: "0.95em"
            }}>
              {lib.getActionName(action)}
              {action.developerOnly ? <span className="ml-1 d-block mb-1" style={{ color: "#aaa", fontSize: "0.8em" }}><i class="fas fa-code"></i> Developer only</span> : null}
              {action.underDev ? <span className="ml-1 d-block mb-1" style={{ color: "#aaa", fontSize: "0.8em" }}><i class="fas fa-wrench"></i> Under development</span> : null}

            </div>
            {hasSubtitle ? <label className="d-block" style={{ fontSize: "0.9em", color: "#999" }}>
              {uiSettings?.form?.subtitle ?? uiSettings?.general?.subtitle}</label> : null}
            {action.type == "TRIGGER" ?
              <div className={`dropdown-header pt-1 pb-0 pl-0`}
                style={{ color: "#888", fontSize: "0.55em", paddingRight: "0rem" }}>
                Evento</div> : null}
          </div>
        </div>
      </a>
    </OverlayTrigger>
  );

}

const TabButton = ({ name, label, actionFilters, onClickFilterTab, onClickFilterRemove }) => {
  const isActive = actionFilters.includes(name)
  return (
    <button className={"d-flex align-items-center rounded " + (isActive ? "active" : "")}
      style={{paddingLeft: "0.75rem", paddingRight: (isActive ? "0.4rem" : "0.75rem")}}
      onClick={() => onClickFilterTab(name)}>
      {label}
      <i class="ml-2 fas fa-times-circle" onClick={() => onClickFilterRemove(name)}></i>
    </button>)
}



export { ActionElement, TabButton };