import React from "react";
import { Link } from "react-router-dom";
import ReactFlow, { Controls, Background, ControlButton } from 'reactflow';
import { Loading, StyleTag } from "seed/helpers";
import Node from "components/flow/board/nodes/Node";
import Edge from "components/flow/board/nodes/Edge";
import Note from "components/flow/board/nodes/Note";
import Pipe from "components/flow/board/nodes/Pipe";
import ActionItem from "components/flow/board/Board.sub.ActionItem.view";
import Suggestions from "components/flow/suggestions/Suggestions"
import 'reactflow/dist/style.css';

const nodeTypes = {
  "pipe": Pipe,
  "note": Note,
  "node": Node,
}

const edgeTypes = {
  "edge": Edge
}


const BoardView = ({
  nodes,
  edges,
  flowPages,
  flowId,
  activePageId,
  initialViewport,
  boardFitView,
  reactFlowWrapper,
  isFlowBeingSaved,
  isRPADesigner,
  isAnonymous,
  isDeleteNodesShown,
  setReactFlowInstance,
  onDropNode,
  onDragNode,
  onConnectEdge,
  onUpdateEdge,
  onNodesChange,
  onEdgesChange,
  onDragStart,
  onClickAction,
  onClickChangePage,
  onDeleteSelectedNodes,
  onClickUndo,
  onClickRedo,
}) => (

  <div
    id="board"
    style={{
      width: "100%",
      height: isRPADesigner ? 530 : "calc(100% - 110px)"
    }}>

    <div
      ref={reactFlowWrapper}
      style={{
        width: "100%",
        height: "100%",
        background: "#f8fafe",
        position: "relative",
        border: "1px solid #E7EAF3"
      }}
    >

      <div style={{ position: "absolute", top: "0.5rem", right: "0rem" }}>
        <Suggestions flowId={flowId} />
      </div>

      <ReactFlow
        defaultViewport={initialViewport}
        fitView={boardFitView}
        fitViewOptions={{
          padding: 1,
          minZoom: 1.1,
          maxZoom: 1.5
        }}
        minZoom={0.5}
        maxZoom={1.7}
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        connectionMode={"loose"}
        nodesFocusable={false}
        edgesFocusable={false}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnectEdge}
        onEdgeUpdate={onUpdateEdge}
        onInit={setReactFlowInstance}
        onDrop={onDropNode}
        onDragOver={onDragNode}
      >

        <div className="mt-1 px-1 mr-2 py-1" style={{ "position": "absolute", top: "60px", right: "0", zIndex: 5, background: "#f8fafe" }}>
          {isDeleteNodesShown && (
            <button className="btn btn-sm btn-block btn-danger mt-2" onClick={onDeleteSelectedNodes}>
              <i className="fa-solid fa-trash mr-2"></i>Eliminar acciones
            </button>
          )}
        </div>

        <div className="mt-1 px-1 mr-2 py-1" style={{ "position": "absolute", bottom: "17px", right: "0", zIndex: 5, background: "#f8fafe" }}>

          <div className="dropdown mt-1">
            {!isAnonymous ?
              <div className="d-flex justify-content-between align-items-center dropdown-toggle btn-lighter-white"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                style={{ border: "1px solid #ccc", background: "white", cursor: "pointer", paddingTop: "0.28rem", paddingBottom: "0.28rem" }}>

                <div></div>

                <div className="d-flex align-items-center" style={{ color: "#777", fontSize: "0.9em" }}>
                  <span className="text-truncate text-center" style={{ maxWidth: "15.7rem", minWidth: "11.7rem" }}>
                    {flowPages.find((page) => page.id == activePageId)?.name ?? "Página 1"}
                  </span>
                </div>
                <i class="fa-solid fa-caret-down px-1 mr-2"></i>

              </div> : null}

            <StyleTag content={`
              .page-item .page-item-edit{
                display: none;
                transition: 0.3s;
              }
              .page-item:hover .page-item-edit{
                display: inline-block;
              }
            `} />

            <div class="dropdown-menu w-100">
              {flowPages.map((page) => (
                <a class="dropdown-item page-item d-flex justify-content-between align-items-center" key={page.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => onClickChangePage(page.id)}>
                  <div class="py-1">{page.name}</div>
                  <Link class="px-3 py-1 btn-lighter page-item-edit rounded"
                    to={`/flow/edit_page/${page.id}`}>
                    <i class="fa-solid fa-pen" style={{ fontSize: "0.9em", color: "#999", border: "none" }}></i></Link>
                </a>
              ))}
              <div class="dropdown-divider"></div>
              <Link class="dropdown-item" to={`/flow/create_page`}>
                <i class="fa-solid fa-plus mr-2"></i>Crear página
              </Link>

            </div>
          </div>

        </div>

        {/* Extend Controls to implement undo and redo */}
        <Controls>
          <ControlButton onClick={onClickUndo}>
            <i class="fa-solid fa-rotate-left"></i>
          </ControlButton>
          <ControlButton onClick={onClickRedo}>
            <i class="fa-solid fa-rotate-right"></i>
          </ControlButton>
        </Controls>

        <Background />
      </ReactFlow>

      {isFlowBeingSaved && (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#f8fafe50",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 100
          }}
        >
          <Loading />
        </div>
      )}

    </div>

  </div>
);

export default BoardView;