import React, { useState, useEffect } from "react";
import ExcelJS from "exceljs";
import { FileField } from "seed/helpers";
import { usePost } from "seed/api";
import { parseExportData } from "components/tables/util/datatypes";

const ImportFile = ({
  rows = [],
  columns = [],
  onClose,
  onImport
}) => {

  const [file, setFile] = useState({});
  const [importedRows, setImportedRows] = useState([]);
  const [logs, setLogs] = useState([]);

  const [callValidateData, reqValidateData] = usePost("/collections/validate_data", {
    onCompleted: (data) => {
      setImportedRows(data.rows);
      setLogs(data.logs);
    }
  });

  useEffect(() => {

    (async () => {

      if(file) {

        let csv = null;
        let newRows = rows;

        if(file?.url?.endsWith(".csv")) {
          const response = await fetch(file.url);
          csv = await response.text();
          csv = csv.replace(/,/g, "~");

        }
        else if(file?.url?.endsWith(".xls") || file?.url?.endsWith(".xlsx")) {
          const response = await fetch(file.url);
          const buffer = await response.arrayBuffer();
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(buffer);
          const sheet = workbook.getWorksheet(1);
          csv = sheet.getSheetValues().map((row) => {
            row.shift();
            return row.join("~");
          }).join("\n");
        }

        if(!csv) return;

        let rawRows = csv.replace(/\r/g, "").replace(/\n\n/g, "\n").trim().split("\n").map((row) => row.split("~"));
        if(rawRows.length > 0) rawRows.shift();

        if(rawRows.length == 0)
          return setLogs([{ type: "error", message: "No se encontraron filas en el archivo." }]);

        newRows = rawRows.map((row) => {

          const obj = {};
          row.forEach((value, i) => {
            if(columns[i])
              obj[columns[i].name] = value;
          });

          const objId = row[columns.length] || "-1";
          const objFolio = (row[columns.length + 1]??"").split("-")[1] || "-1";

          return {
            data: obj,
            id: parseInt(objId),
            folio: parseInt(objFolio)
          }

        });

        if(newRows.length > 0)
          callValidateData({
            schema: columns,
            data: newRows,
          });

      }

    })();

  }, [file]);

  const onClickDownloadTemplate = () => {

    const sheetName = "Sheet1";
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(sheetName);

    const excelColumns = [
      ...columns.map((c) => c.label),
      "ID Sistema",
      "Folio Sistema"
    ];

    const excelRows = rows.map((row) => [
      ...columns.map((c) => parseExportData(c, row.data[c.name])),
      row.id,
      row._folio
    ]);

    sheet.addRow(excelColumns);

    const lastRow = sheet.lastRow;
    for(let i = 0; i < columns.length + 2; i++) {
      if(i < columns.length)
        lastRow.getCell(i + 1).font = { bold: true, color: { argb: "176dc7" } };
      else
        lastRow.getCell(i + 1).font = { bold: true, color: { argb: "AABBCC" } };
    }

    excelRows.forEach((row) => sheet.addRow(row));

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "template.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });

  }

  const onClickImport = () => {
    onImport(importedRows);
    onClose();
  }

  return <div className="card">

    <div class="card-header">
      <h3 class="mb-0">Cargar datos</h3>
    </div>
    <div class="card-body py-4">

      <div className="card card-body">
        <div className="text-muted mb-3">
          INSTRUCCIONES
        </div>
        <div>
          <div>
            <span className="h5 text-primary mr-1">
              Paso 1. 
            </span> 
            <span className="h5 font-weight-light">
              Descargar los datos actuales
            </span>
          </div>
          <div>
            <button class="btn btn-accent mt-2" onClick={onClickDownloadTemplate}>
              Descargar
            </button>
          </div>
        </div>
        <div className="mt-3">
          <div>
            <span className="h5 text-primary mr-1">
              Paso 2. 
            </span> 
            <span className="h5 font-weight-light">
              Modificar o carga nuevos datos
            </span>
          </div>
        </div>
        <div className="mt-2">
          <div>
            <span className="h5 text-primary mr-1">
              Paso 3. 
            </span> 
            <span className="h5 font-weight-light">
              Vuelve a cargar el archivo
            </span>
          </div>
        </div>
        <div className="mt-2">
          <div>
            <span className="h5 text-primary mr-1">
              Paso 4. 
            </span> 
            <span className="h5 font-weight-light">
              Importar los datos
            </span>
          </div>
        </div>
      </div>

      <div className="mt-3">
        <div class="form-group mb-2">
          <label class="input-label" for="file">Subir datos</label>
          <FileField
            name="file"
            id="file"
            class="form-control form-control-lg"
            accept=".csv, .xls, .xlsx"
            setFieldValue={() => { }}
            onCompleted={(data) => setFile(data)}
          />
        </div>
      </div>

      {
        logs.map((log, i) => <div key={i}>
          <div className={`text-${log.type == "success" ? "success" : "danger"}`} role="alert">
            <i className={`fa fa-${log.type == "success" ? "check" : "exclamation"}`}></i> {log.message}
          </div>
        </div>)
      }

      <button
        type="submit"
        class="btn btn-block btn-primary mt-3"
        onClick={onClickImport}
        disabled={logs.reduce((acc, log) => acc || log.type == "error", false)}
      >
        Importar
      </button>
    </div>

  </div>

}

export default ImportFile;