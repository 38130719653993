import React from "react";
import { useLocation } from 'react-router-dom';
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/presentation/PresentationPanel.view";

function PresentationPanel({ match }) {

  const location = useLocation();
  const token = match.params?.token;
  const queryParams = new URLSearchParams(location.search);
  const showTabs = (queryParams.get("tabs") ?? "true").includes("true");

  const reqFlow = useQuery(`{
    flows {
      name
      token
      presentationSettings
    }
  }`, `token=${token}`);

  if (reqFlow.loading) return <Loading />;
  if (reqFlow.error) return "Error";

  let flow = {};
  const { flows = [] } = reqFlow.data;
  if (flows.length == 0) window.location.replace("/app");
  else flow = flows[0];

  const onOpenEdit = () =>
    window.parent.postMessage("edit_presentation", '*');

  const onOpenTemplates = () => 
    window.parent.postMessage("templates_presentation", '*');

  const onOpenShare = () => 
    window.parent.postMessage("share_presentation", '*');

  return (
    <View
      flow={flow}
      showTabs={showTabs}
      onOpenEdit={onOpenEdit}
      onOpenTemplates={onOpenTemplates}
      onOpenShare={onOpenShare}
    />
  );
}

export default PresentationPanel;