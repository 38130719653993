import React from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Route, StyleTag } from "seed/helpers"
import Login from "components/misc/auth/Login";
import Signup from "components/misc/auth/Signup";
import Logout from "components/misc/auth/Logout";
import Home from "components/Home";
import AppRoutes from "components/misc/apps/AppRoutes"
import UseCases from "components/misc/use_cases/UseCases"
import UseCase from "components/misc/use_cases/UseCase"
import Invitation from "components/flow/forms/collaboration/Invitation";
import PresentationPanel from "components/presentation/PresentationPanel";
import PresentationMode from "components/presentation/PresentationMode";
import VerifyEmail from "components/misc/auth/VerifyEmail";

import 'react-toastify/dist/ReactToastify.css';
import "styles/css/App.css";
import WindowRoute from "./util/helpers/WindowRoute";

const AppView = () =>
  <div id="app">

    <div className="position-fixed top-0 right-0 left-0 bg-img-hero"
      id="background-hero"
      style={{
        pointerEvents: "none",
        height: "40rem",
      }}>

      <figure className="position-absolute top-0 right-0 left-0">
        <svg preserveAspectRatio="none" viewBox="0 0 1920 698" fill="none" xmlns="http://www.w3.org/2000/svg"
          id="background-hero"
          style={{
            height: "29.7rem",
            width: "100%"
          }}>
          <rect width="1920" height="698" fill="#E5E5E5" />
          <g clipPath="url(#clip0)">
            <path className="path-a" d="M1920 -714.403H0V1412.4H1920V-714.403Z" fill="#3075ba" />
            <path className="path-b" d="M1298.9 -5488.72L2618.5 1300.02L1702.8 1842.02C1651.6 1690.56 1569.6 1565.26 1479.9 1480.46C1338.1 1346.44 1176.9 1292.52 1020.9 1221.67C933 1182.06 832 1111.56 811.9 957.132C799 858.54 824.8 759.075 837.2 660.308C869.8 400.128 798.7 110.284 668.3 -28.0949C635.9 -62.4714 599.1 -90.391 577.4 -144.661C510.4 -312.18 639.3 -526.467 627 -729.759C622.6 -803.224 599.6 -869.01 574.6 -928.689C300.4 -1585.86 -209.4 -1749.54 -628.8 -2103.6C-751.2 -2207.08 -871.1 -2334.11 -955 -2520.83C-1038.9 -2707.72 -1081.4 -2964.58 -1036.1 -3188.29C-974.1 -3494.19 -776.5 -3644.26 -593.7 -3703.76C-410.9 -3763.27 -215.6 -3771.47 -56 -3937.77C117.6 -4118.72 216 -4451.32 351 -4713.77C418.6 -4845.17 495.6 -4958.94 577.8 -5061.9L1298.9 -5488.72Z" fill="#3060a0" />
            <path className="path-c" d="M1733.2 -6299.97L3053 487.553L2137.2 1029.55C2086 878.084 2004 752.968 1914.3 668.161C1772.5 534.145 1611.3 480.224 1455.2 409.552C1367.3 369.766 1266.3 299.443 1246.2 145.01C1233.4 46.4176 1259.2 -53.0476 1271.5 -151.815C1304.1 -411.994 1233 -701.664 1102.6 -840.217C1070.2 -874.594 1033.4 -902.514 1011.7 -956.783C944.7 -1124.3 1073.6 -1338.59 1061.3 -1541.71C1056.9 -1615.17 1033.8 -1680.96 1008.9 -1740.64C734.8 -2397.63 224.9 -2561.31 -194.5 -2915.37C-316.9 -3018.85 -436.8 -3145.71 -520.7 -3332.6C-604.6 -3519.32 -647.1 -3776.18 -601.8 -3999.89C-539.8 -4305.61 -342.2 -4455.86 -159.4 -4515.36C23.3999 -4574.87 218.7 -4583.07 378.3 -4749.37C551.8 -4930.15 650.2 -5262.75 785.2 -5525.02C852.8 -5656.42 929.8 -5770.19 1012 -5873.15L1733.2 -6299.97Z" fill="#154a8a" />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="1920" height="698" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </figure>

    </div>

    <BrowserRouter>
      <Switch>
        <Route path="/app" component={Home} />
        <Route path="/login" component={Login} />
        <Route path="/logout" component={Logout} />
        <Route path="/signup" component={Signup} />
        <Route path="/verify_email/:token" component={VerifyEmail} />
        <Route path="/invitation" component={Invitation} />
        <Route path="/modal" component={WindowRoute} />
        <Route path="/presentation/:token" component={PresentationPanel} />
        <Route path="/p/:token" component={PresentationMode} />
        <Route path="/use_case/:template_name" component={UseCase} />
        <Route path="/use_cases/:user_profile" component={UseCases} />
        <Route path="/use_cases" component={UseCases} />
        <Route path="/:app_name" component={AppRoutes} />
        <Redirect to="/app" />
      </Switch>
    </BrowserRouter>
    <ToastContainer limit={7} />

    {/* (() => { 
      const primaryColor = "#00454d"; 
      const darkColor = "#007473";
      const lightColor = "#30d060";

      return <StyleTag content={`
        #background-hero .path-a {
          fill: ${primaryColor} !important;
        }
        #background-hero .path-b {
          fill: ${darkColor} !important;
        }
        #background-hero .path-c {
          fill: ${lightColor} !important;
        }

        .btn.btn-primary {
          background: ${primaryColor} !important;
        }
        .btn.btn-primary:hover {
          background: ${darkColor} !important;
        }
        .btn.btn-outline-primary {
          border-color: ${primaryColor} !important;
          color: ${primaryColor} !important;
        }
        .btn.btn-outline-primary:hover{
          background: ${primaryColor} !important;
          color: white !important;
        }
        .btn-app.active, .btn-app:hover{
          color: ${primaryColor} !important;
        }
        #flow-tab a.active, #flow-tab a:hover{
          background: ${primaryColor} !important;
        }
    `}/>
    })() */}

  </div>

export default AppView;