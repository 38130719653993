import React from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { StyleTag } from "seed/helpers";


const getLabelName = (options, value) => {
  return options.find((option) => option.value == value)?.label ?? value;
}

const CollaborationView = ({
  shareUsers,
  shareRole,
  currentInputValue,
  participants,
  roleOptions,
  isEmailValid,
  onChangeShareUsers,
  onChangeShareRole,
  onClickShare,
  onChangeParticipantRole,
  onRemoveParticipant,
  onInputChange
}) => (
  <div className="card pb-6" style={{ overflowX: "hidden" }}>

    <div className="card-header">
      <h3>Compartir proceso</h3>
    </div>

    <div className="card-body px-5 py-3">

      <span class="d-flex justify-content-between dropdown-header pt-2 pb-3 px-2">
        <span style={{ color: '#777' }}>Compartir</span>
      </span>

      <div className="row justify-content-between align-items-end">

        <div className={shareUsers.length > 0 ? "col-md-8" : "col-md-12"}>
          <Autocomplete
            id="userSelector"
            multiple
            freeSolo
            autoFocus={true}
            options={[]}
            inputValue={currentInputValue}
            value={shareUsers}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) =>
              <TextField
                {...params}
                label=""
                autoFocus={true}
                variant="outlined"
                placeholder="Buscar usuario"
                helperText={!isEmailValid ? 'Dirección de correo inválida' : ''}
              />
            }
            onChange={onChangeShareUsers}
            onInputChange={onInputChange}
            style={{ width: "100%" }}
          />
          <StyleTag content={`
            #userSelector-helper-text {
              color: #d9534f;
            }`
          } />
        </div>

        {shareUsers.length > 0 && (
          <div className="col-md-4 d-flex flex-column align-items-center">

            <div className="px-0" style={{ width: "100%" }}>
              <Autocomplete
                id="roleSelector"
                required={true}
                value={shareRole}
                options={roleOptions.map((role) => role.value)}
                getOptionLabel={(option) => getLabelName(roleOptions, option)}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label=""
                    variant="outlined"
                    required={true}
                    placeholder="Acceso"
                  />
                }
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <span className="w-100 py-2" style={{ fontSize: "0.9em" }}>{getLabelName(roleOptions, option)}</span>
                  </React.Fragment>
                )}
                onChange={(event, newValue) => onChangeShareRole(newValue)}
                style={{ width: "100%" }}
              />
            </div>

            <button className="btn btn-primary btn-block mt-3" onClick={onClickShare}>Compartir</button>

          </div>
        )}
      </div>

      {participants.length > 0 ?
        <span class="d-flex justify-content-between dropdown-header pt-7 pb-2 px-2">
          <span style={{ color: '#777' }}>Accesos</span>
        </span> : null}

      {participants.map((participant) => (
        <div className="row justify-content-between mx-1 py-3" key={participant.id}
          style={{ borderBottom: "1px solid #e0e5ea" }}
        >

          <div className="col-md-7 px-2">
            <span className="d-block h5 text-hover-primary mb-0">
              {participant.user ? `${participant.user.firstName} ${participant.user.lastName}` : `Sin cuenta`}
            </span>
            <span className="d-block font-size-sm text-body">{participant.user ? participant.user.email : participant.email}</span>
          </div>

          <div className="col-md-4">
            <Autocomplete
              id={`roleSelectorUser${participant?.user?.id}`}
              forcePopupIcon
              value={participant.type}
              options={roleOptions.map((role) => role.value)}
              getOptionLabel={(option) => getLabelName(roleOptions, option)}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label=""
                  variant="outlined"
                  placeholder="Buscar rol"
                />
              }
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <span className="w-100 py-2" style={{ fontSize: "0.9em" }}>{getLabelName(roleOptions, option)}</span>
                </React.Fragment>
              )}
              onChange={(event, newRole) => onChangeParticipantRole(participant?.user?.id, newRole)}
              style={{ width: "100%" }}
            />
          </div>

          <div className="col-md-1">
            <a className="btn btn-sm mr-1" onClick={() => onRemoveParticipant(participant?.id)}>
              <i class="fa-solid fa-square-minus" style={{ fontSize: "1.7rem", color: "#f07070" }}></i>
            </a>
          </div>

        </div>
      ))}

    </div>

  </div>
);

export default CollaborationView;