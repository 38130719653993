import React from "react";
import { Field } from "formik";

const Autocomplete = ({
  flowQuestion,
  values,
  flowCaptures,
  questionIndex,
  currentIndex,
  setFieldValue,
  autocompletes
}) => (
  <div key={questionIndex} className="my-3">
    <div class="d-flex align-items-center">

      <label class="input-label mr-4" for="routine_name">
        {flowQuestion.label(values, flowCaptures, currentIndex)}
      </label>

      <datalist id={"autocomplete-" + questionIndex}>
        {autocompletes[flowQuestion.autocomplete].map((option, index) => <option key={index}>{option}</option>)}
      </datalist>

      <Field
        type="text"
        list={values[flowQuestion.name] && values[flowQuestion.name].length >= 2 ? "autocomplete-" + questionIndex : ""}
        autocomplete="off"
        className="form-control w-auto h-auto"
        autofocus={true}
        required={!values.skip}
        name={flowQuestion.name}
        style={{ borderColor: "#bbb", color: "#555", padding: "5px 12px", minWidth: "250px" }}
        onChange={(e) => setFieldValue(flowQuestion.name, e.target.value)}
        onKeyDown={(e) => {
          if(e.key == "Tab") {
            for(let option of autocompletes[flowQuestion.autocomplete]) {
              if(e.target.value && option.toLowerCase().startsWith(e.target.value.toLowerCase())) {
                setFieldValue(flowQuestion.name, option);
                return;
              }
            }
          }
        }}
      />
      
    </div>
  </div>
);

export default Autocomplete;