import React from "react";
import ExecutionDetails from "components/start/executions/ExecutionDetails";


const ExecutionView = ({
  execution,
  executionId,
  isDeveloperMode,
  onResumeExecution,
  onCancelExecution
}) => (
  <div className="card">
    <div className="card-header">
      <h3 className="card-title">Detalles de ejecución {isDeveloperMode && `(exec_id=${executionId})`}</h3>
    </div>
    <div className="card-body">
      <div className="w-100">
        {execution ? (
          <ExecutionDetails
            idx={1}
            execution={execution}
            onResumeExecution={onResumeExecution}
            onCancelExecution={onCancelExecution}
          />
        ) : (
          <div className="h-100">
            <div className="d-flex justify-content-start align-items-center h-100 ml-1">
              <span style={{ color: "#b0b2b2", fontSize: "0.95em" }}>Error al cargar la información</span>
            </div>
          </div>
        )}

      </div>
    </div>
  </div>
);

export default ExecutionView;