import React from "react";
import { BaseEdge, EdgeLabelRenderer } from 'reactflow';
import "styles/css/Edge.css";

const EdgeView = ({
  selected,
  edgePath,
  markerEnd,
  style,
  label,
  labelX,
  labelY,
  onDeteleEdge,
  onInsertCondition,
  onInsertIteration
}) => (
  <>
    <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
    <EdgeLabelRenderer>
      <div
        style={{
          position: 'absolute',
          transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          fontSize: "10px",
          pointerEvents: 'all',
        }}
        className="nodrag nopan"
      >
        <div class="dropdown" style={{ cursor: "pointer" }}>
          {label && (<p className="edge-label">{label}</p>)}
          <div class={`dropdown-menu py-2 ${selected && "show"}`} style={{ marginTop: "-50px" }}>
            {/* <a class="dropdown-item" style={{ fontSize: "0.6rem" }} onClick={onInsertCondition}>
              <i class="fa-solid fa-diagram-project mr-2"></i>Insertar condición
            </a>

            <a class="dropdown-item" style={{ fontSize: "0.6rem" }} onClick={onInsertIteration}>
              <i class="fa-solid fa-arrows-rotate mr-2"></i>Insertar repetir
            </a> */}

            <a class="dropdown-item" style={{ fontSize: "0.6rem" }} onClick={onDeteleEdge}>
              <i class="fas fa-trash mr-2"></i>Eliminar
            </a>
          </div>
        </div>

      </div>
    </EdgeLabelRenderer>
  </>
);

export default EdgeView;