import React from "react";
import { FileField } from "seed/helpers";
import BaseField from "components/schema_form/fields/custom_fields/BaseField";


const FileItem = ({ file }) =>
    <a className="p-2 d-flex align-items-center btn-lighter"
        href={file.url} target="_blank" without rel="noreferrer"
        style={{ border: "1px solid #e0e0e0", width: "49%", borderRadius: "0.25rem", cursor: "pointer" }}>
        <i class="far fa-file-alt mr-2 ml-2" style={{ fontSize: "1.2em" }}></i>
        <span className="text-truncate">{file?.name?.substring(33)}</span>
    </a>

const FileInput = ({
    name, label, multipleFiles, required, disabled, caption, helpMessage, value, acceptFiles,
    enableComments, values, setFieldValue
}) => {
    return (
        <BaseField
            name={name}
            label={label}
            values={values}
            required={required}
            caption={caption}
            helpMessage={helpMessage}
            enableComments={enableComments}
            setFieldValue={setFieldValue}
        >

            {value ? (
                <div className="d-flex flex-column mb-2">
                    {multipleFiles ? value.map(file => <FileItem file={file} />) : null}
                    {!multipleFiles ? <FileItem file={value} /> : null}
                </div>
            ) : null}

            {!disabled ? (
                <FileField
                    name={name}
                    accept={acceptFiles}
                    className={"mt-1"}
                    multiple={multipleFiles}
                    setFieldValue={setFieldValue}
                />
            ) : null}
        </BaseField>
    )
}

export default FileInput;