import React from "react";
import View from "components/presentation/templates/top_template/TopTemplate.view";

function TopTemplate({ flow, showTabs, onOpenEdit, onOpenTemplates, onOpenShare }) {

  return <View
    flow={flow}
    showTabs={showTabs}
    onOpenEdit={onOpenEdit}
    onOpenTemplates={onOpenTemplates}
    onOpenShare={onOpenShare} />;
}

export default TopTemplate;