import React from "react";
import ReactApexChart from 'react-apexcharts'


const HeatmapChartView = ({ options, series, height }) => (
  <div>
    <div id="chart">
      <ReactApexChart options={options} series={series} type="heatmap" height={height} />
    </div>
    <div id="html-dist"></div>
  </div>
);

export default HeatmapChartView;