import React, { useEffect, useState } from "react";
import { useStoreApi, useReactFlow } from 'reactflow';
import { useQuery } from "seed/gql";
import * as util from "components/flow/board/util/board";
import View from "components/flow/board/Board.view";


function Board({
  nodes,
  edges,
  flowId,
  activePage,
  selectedNodes,
  isFlowBeingSaved,
  isRPADesigner,
  isAnonymous,
  reactFlowWrapper,
  setActivePage,
  setReactFlowInstance,
  onDropNode,
  onDragNode,
  onConnectEdge,
  onUpdateEdge,
  onNodesChange,
  onEdgesChange,
  onClickNode,
  onClickAnnotation,
  onDeleteSelectedNodes,
  onClickUndo,
  onClickRedo,
}) {

  const store = useStoreApi();
  const { setCenter, getZoom } = useReactFlow();
  const [prevLengthNodes, setPrevLengthNodes] = useState(nodes.length);
  const isDeleteNodesShown = selectedNodes.length > 0;

  const reqPages = useQuery(`{
    flowPages {
      name
      isDefault
    }
  }`, `flow.id = ${flowId}`, {
    onCompleted: (data) => {
      let defaultPageId = 0
      for (let page of data.flowPages)
        if ((page.isDefault && defaultPageId == 0) || (page.id == sessionStorage.getItem("page_" + flowId)))
          defaultPageId = page.id
      setActivePage(defaultPageId)
      sessionStorage.setItem("page_" + flowId, defaultPageId)
    }
  })

  useEffect(() => {
    if (nodes.length > prevLengthNodes) {
      onFocusLastNode()
    }
    setPrevLengthNodes(nodes.length)
  }, [nodes])


  const onClickChangePage = pageId => {
    setActivePage(pageId);
    sessionStorage.setItem("page_" + flowId, pageId)
  }

  const onDragStart = (event, type, actionId) => {
    const data = {
      "type": type,
      "actionId": actionId
    };
    event.dataTransfer.setData('application/reactflow', JSON.stringify(data));
    event.dataTransfer.effectAllowed = 'move';
  };

  const onClickAction = (actionId, type) => {
    if (type == "annotation") {
      onClickAnnotation(actionId)
    } else if (type == "node") {
      onClickNode(actionId)
    }
  };

  const onFocusLastNode = () => {
    const { nodeInternals } = store.getState();
    const nds = Array.from(nodeInternals).map(([, node]) => node);
    const nodes = nds.filter((node) => node.isAnnotation == false);

    if (nodes.length > 0) {

      const node = nodes.reduce((maxElem, currentElem) => {
        return parseInt(currentElem.id) > parseInt(maxElem.id) ? currentElem : maxElem;
      }, nodes[0])

      const x = node.position.x + 50;
      const y = node.position.y + 25;
      onFocusPoint(x, y);
    }
  }

  const onFocusPoint = (x, y) => {
    const zoom = getZoom();
    setCenter(x, y, { zoom: zoom, duration: 1000 });
  }

  if (reqPages.loading) return <div />;
  if (reqPages.error) return "Error";

  const { flowPages = [] } = reqPages.data;


  const initialBoardRect = util.getInitialBoardRect(nodes);
  const boardFitView = initialBoardRect.width < 400;

  const initialBoardZoom = 1.32;
  let initialViewport = { //Only applies when rect.width > 400
    x: -(initialBoardRect.x - 50) * initialBoardZoom,
    y: -(initialBoardRect.y - 70) * initialBoardZoom,
    zoom: initialBoardZoom
  };


  return (
    <View
      nodes={nodes}
      edges={edges}
      flowPages={flowPages}
      flowId={flowId}
      activePageId={activePage}
      initialViewport={initialViewport}
      boardFitView={boardFitView}
      reactFlowWrapper={reactFlowWrapper}
      isFlowBeingSaved={isFlowBeingSaved}
      isRPADesigner={isRPADesigner}
      isAnonymous={isAnonymous}
      isDeleteNodesShown={isDeleteNodesShown}
      setReactFlowInstance={setReactFlowInstance}
      onDropNode={onDropNode}
      onDragNode={onDragNode}
      onConnectEdge={onConnectEdge}
      onUpdateEdge={onUpdateEdge}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onDragStart={onDragStart}
      onClickAction={onClickAction}
      onClickChangePage={onClickChangePage}
      onDeleteSelectedNodes={onDeleteSelectedNodes}
      onClickUndo={onClickUndo}
      onClickRedo={onClickRedo}
    />
  );
}

export default Board;