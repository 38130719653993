import React from "react";
import ExecutionsList from "components/start/executions/ExecutionsList";
import LineChart from "components/misc/charts/LineChart";
import BarChart from "components/misc/charts/BarChart";


const ExecutionsView = ({
  flow,
  lineChartExecutions,
  barChartExecutions
}) => (
  <div
    className="px-2 py-1"
    style={{
      width: "100%",
      height: "calc(100% - 110px)",
      background: "#f8fafe",
      border: "1px solid #E7EAF3",
      overflowX: "hidden"
    }}
  >
    <div className="w-100 p-4">

      <div className="row" style={{ height: "71vh" }}>

        <div className="col-md-5" style={{ height: "71vh" }}>
          <h3 className="mb-3">Ejecuciones</h3>
          <ExecutionsList flow={flow} placeholder="No hay acciones" />
        </div>

        <div className="col-md-7" style={{ height: "71vh" }}>
          <div className="bg-white mb-3">
            <LineChart data={lineChartExecutions.data} options={lineChartExecutions.options} height={350} />
          </div>
          <div className="bg-white">
            <BarChart data={barChartExecutions.data} options={barChartExecutions.options} height={350} />
          </div>
        </div>

      </div>

    </div>
  </div>
);

export default ExecutionsView;