import moment from 'moment';

const parseCollectionOptions = (collections, field) => {
    
  const collectionResponse = collections?.find((c) => c.name == field.collection_options.collection);
  if(collectionResponse == null) return field;

  const options = collectionResponse.data.map((value) => {
    
    const labels = [value.full_folio];
    
    collectionResponse.collection.schema.structure.forEach((schemaField) => {
      const schemaValue = value.data[schemaField.name];
      if(!schemaValue) return;
      if(schemaField.type == "string" && schemaValue != "") labels.push(schemaValue);
      if(schemaField.type == "float" && schemaValue != "") labels.push(schemaValue);
      if(schemaField.type == "date" && schemaValue != "") labels.push(moment(schemaValue).format("DD/MM/YYYY"));
      if(schemaField.type == "file" && schemaValue != "") labels.push(schemaValue.name);
      if(schemaField.type == "boolean" && schemaValue != "") labels.push(schemaValue ? "Si" : "No");
      if(schemaField.type == "collection" && schemaValue != "") labels.push(schemaValue.full_folio);
      if(schemaField.type == "enum" && schemaValue != "") labels.push(schemaValue);
      if(schemaField.type == "object" && schemaValue != "") labels.push(JSON.stringify(schemaValue));
    })

    return { value, label: labels.slice(0, 2).join(" - ") };

  });

  return { ...field, options: (field.options??[]).concat(options) };

}

export {
  parseCollectionOptions
}