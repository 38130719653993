import React, { useState, useEffect } from "react";

const ClickPoint = ({ currentIndex, clickCoords = {}, capture, image, size = 45 }) => {

  const [point, setPoint] = useState(null);

  useEffect(() => {

    if(capture?.event?.includes("CLICK") && !capture?.event?.includes("DRAG")) {

      const img = new Image();
      img.src = image;
  
      img.onload = () => {

        const x = (capture?.abs_xi??clickCoords?.abs_x) * 100 / capture?.screen_width;
        const y = (capture?.abs_yi??clickCoords?.abs_y) * 100 / capture?.screen_height;

        setPoint(
          <div
            className={"screen-click" + (currentIndex%2 == 1 ? "-i" : "-j")}
            style={{
              position: "relative",
              top: y + "%",
              left: x + "%",
              marginTop: (-size/2 - 2) + "px",
              marginLeft: (-size/2 + 2) + "px",
              width: size + "px",
              height: size + "px",
              borderRadius: "50%",
              backgroundColor: "#50c5c0d0",
              border: "4px solid #50c5c0",
              transform: "translate(-50%, -50%)",
            }}
          />
        );
  
      }
  
    }
    else {
      setPoint(null);
    }

  }, [capture, image]);

  return point;

}

export default ClickPoint;