import React from "react";
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/misc/settings/Panel.view";

function Panel() {

  const userId = sessionStorage.getItem("id");

  const reqUser = useDetail(`{
    user {
      firstName
      lastName
      email
      license { name }
    }
  }`, userId);

  const onClickLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace("/logout");
  }

  if (reqUser.loading) return <EmptyView />;
  if (reqUser.error) return "Error";

  const { user = {} } = reqUser.data;

  return <View user={user} onClickLogout={onClickLogout} />;
}

const EmptyView = () =>
  <div class="card">
    <div class="card-body" style={{ padding: "10rem 0" }}>
      <Loading />
    </div>
  </div>

export default Panel;