import React from "react";
import { Formik, Field, Form } from "formik"

const FormFlowView = ({ initialValues = {}, folders, isEditing = false, onSubmit, onClickDelete = () => {} }) => (
  <div className="card">

    <div class="card-header">
      <h3 class="card-header-title">
        {isEditing ? "Editar proceso" : "Crear proceso"}
      </h3>
    </div>

    <div className="card-body px-4">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {() =>
          <Form>

            {/* Name */}
            <div class="form-group mb-4">
              <label class="input-label" for="name">Nombre del proceso</label>
              <Field type="text" name="name" id="name" autocomplete="off"
                class="form-control form-control" required autoFocus={true} />
            </div>

            <div class="form-group mb-4">
              <label class="input-label" for="folder">Carpeta</label>
              <Field as="select" name="folder" id="folder"
                class="form-control form-control" required>
                <option value="0">Sin carpeta</option>
                {folders.map((folder) => (
                  <option key={folder.id} value={folder.id}>{folder.name}</option>
                ))}
              </Field>
            </div>
          
            
            <div className="row">
              <div className={`col-md-${(isEditing) ? "9 pr-0" : "12"}`}>
                <button type="submit" class="btn btn-block btn-primary">
                  {isEditing ? "Editar proceso" : "Crear proceso"}
                </button>
              </div>

              {(isEditing) && (
                <div className="col-md-3">
                  <button type="button" class="btn btn-block btn-outline-danger" onClick={onClickDelete}>
                    Eliminar
                  </button>
                </div>
              )}
            </div>


          </Form>}
      </Formik>
    </div>
  </div>
);

export default FormFlowView;