import React, { useState, useEffect } from "react";
import { usePost } from "seed/api";
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/presentation/Template.view";

function Template({ flowId, reset, onResetPresentation }) {

  const [template, setTemplate] = useState("");
  const [callUpdateTemplate, reqUpdateTemplate] = usePost("/flows/save_presentation", {
    onCompleted: () => {
      onResetPresentation();
    }
  })

  const reqFlow = useDetail(`{
    flow {
      presentationSettings
    }
  }`, flowId, {
    onCompleted: (data) => {
      const flow = data.flow;
      setTemplate(flow.presentationSettings?.navigation ?? "tabs")
    }
  });

  useEffect(() => {
    reqFlow.refetch();
  }, [reset])

  if (reqFlow.loading) return <Loading />;

  const onChangeSidenav = () => {
    setTemplate("sidenav")
    callUpdateTemplate({
      flow_id: flowId,
      presentation_settings: {
        navigation: "sidenav"
      }
    })
  }

  const onChangeTopnav = () => {
    setTemplate("tabs")
    callUpdateTemplate({
      flow_id: flowId,
      presentation_settings: {
        navigation: "tabs"
      }
    })
  }



  return <View template={template} onChangeSidenav={onChangeSidenav} onChangeTopnav={onChangeTopnav} />;
}

export default Template;