import React, { useEffect, useRef } from 'react';
import { useWS } from 'seed/ws';
import { usePost } from 'seed/api';
import { addListener, postMessage } from 'components/util/win';

const Command = () => {

  const userId = sessionStorage.getItem("id");
  const dataRef = useRef({});
  const [callSendCommand] = usePost("/apps/rpa/send_command");
  const [_] = useWS("EXECUTE_COMMAND_" + userId,
    {
      onMessageReceived: (message) => {
        postMessage({ 
          "type": "execute_command",
          "action": message.data.action,
          "sample": message.data.sample,
          "params": message.data.params,
          "element": message.data.element,
        });
        dataRef.current = message.data;
      },
    });

  useEffect(() => {

    addListener((message) => {

      if(message.type === "execute_command") {

        let result = {};
        try{
          result = JSON.parse(message.result);
        } catch (e) {
          result = message.result;
        }

        callSendCommand({ 
          status: message.status,
          result: result,
          node_id: dataRef.current.node_id,
          execution_id: dataRef.current.execution_id
        });

      }
        
    });
    
  }, []);

  return <></>
}

export default Command;