import React from 'react';
import swal from 'sweetalert';
import { useQuery } from 'seed/gql';
import { usePost } from 'seed/api';
import { Loading } from "seed/helpers";
import View from 'components/flow/toolbox/apps/AppList.view';

const AppList = ({ search, flowId, activeTab, onUpdateAppsQuery }) => {

	const isDeveloper = sessionStorage.getItem("developer_mode") == "true";

	const qApps = useQuery(`{
		apps {
			name
			version
			description
			requestOnly
			underDev
			uiSettings
		}
  }`, !isDeveloper ? "developer_only=false AND under_dev=false" : "", { orderBy: "relevance" });

	const qAppSettings = useQuery(`{
		appSettingses {                
			enabled
			requested
			flow { }
			app { name }
		}
  }`, `flow.id=${flowId}`);

	const [callSetRequest, qSetRequest] = usePost('/apps/set_status_requested', {
		onCompleted: (data) => {
			if (data.status == true) {
				swal("¡Listo!", "Solicitud enviada.", "success").then(() => {
					qApps.refetch();
					qAppSettings.refetch();
					onUpdateAppsQuery();
				});
			}
		},
		onError: (error) => {
			console.log(error);
		},
	});

	const [callSetEnabled, qSetEnabled] = usePost('/apps/set_status_enabled', {
		onCompleted: (data) => {
			if (data.status == true) {
				swal("Acciones instaladas", "Módulo agregado correctamente", "success").then(() => {
					qApps.refetch();
					qAppSettings.refetch();
					onUpdateAppsQuery();
				});
			}
		},
		onError: (error) => {
			console.log(error);
		},
	});

	const [callSetDisabled, qSetDisabled] = usePost('/apps/set_status_disabled', {
		onCompleted: (data) => {
			if (data.status == true) {
				swal("Acciones desinstaladas", "Módulo deshabilitado correctamente", "success").then(() => {
					qApps.refetch();
					qAppSettings.refetch();
					onUpdateAppsQuery();
				});
			}
		},
		onError: (error) => {
			console.log(error);
		},
	});


	const onRequestAccess = (id) => {
		callSetRequest({ app_id: id, flow_id: flowId });
	}

	const onEnableApp = (id, settingsId) => {
		callSetEnabled({ app_id: id, flow_id: flowId, settings_id: settingsId });
	}

	const onDisableApp = (id) => {
		callSetDisabled({ settings_id: id });
	}


	if (qApps.loading || qAppSettings.loading) return <Loading />
	let apps = [...qApps.data.apps]
	let userAppSettings = [...qAppSettings.data.appSettingses]

	apps = apps
		.filter(app => {
			const uiSettings = (app.uiSettings);
			const appName = uiSettings?.label ?? app.name
			return search == "" ||
				appName.toLowerCase().includes(search.toLowerCase()) ||
				(uiSettings.keywords ?? []).toString().toLowerCase().includes(search.toLowerCase())
		})
		.map((app, idx) => {
			const userAppSetting = userAppSettings
				.find(setting => setting?.flow?.id === parseInt(flowId) && setting?.app?.id == app.id);
			const enabled = userAppSetting?.enabled ?? false
			const requested = userAppSetting?.requested ?? false
			const sortValue =
				(enabled ? (-10000000 - idx) : requested ? (10000000 - idx) : !app.requestOnly ? (5000000 - idx) : idx)
			return {
				...app, sortValue: sortValue, userAppSetting: userAppSetting,
				enabled: enabled, requested: requested
			}
		})

	apps = apps.sort((app1, app2) => app2.sortValue - app1.sortValue)

	if (activeTab == 'installed')
		apps = apps.filter(
			app => userAppSettings.some(setting =>
				setting?.app?.id == app.id &&
				setting?.flow?.id === parseInt(flowId) &&
				setting?.enabled)
		);
	else
		apps = apps.filter(
			app => !userAppSettings.some(setting =>
				setting?.app?.id == app.id &&
				setting?.flow?.id === parseInt(flowId) &&
				setting?.enabled)
		);

	return (
		<View
			apps={apps}
			onEnableApp={onEnableApp}
			onDisableApp={onDisableApp}
			onRequestAccess={onRequestAccess}
		/>
	);
}

export default AppList;