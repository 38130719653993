import React from "react";
import BaseField from "components/schema_form/fields/custom_fields/BaseField";
import Editor from "components/schema_form/fields/rich_text/Editor";
import "styles/css/RichText.css"


const RichTextView = ({
  nodes,
  collections,
  datatypes,
  name,
  label,
  required,
  caption,
  helpMessage,
  placeholder,
  singleLine,
  rows,
  showStyles,
  values,
  initialValue,
  setFieldValue,
  onChangeValue
}) => (
  <BaseField name={name} label={label} required={required} caption={caption} helpMessage={helpMessage}
    enableComments={false} values={values} setFieldValue={setFieldValue}>
    <Editor
      nodes={nodes}
      collections={collections}
      datatypes={datatypes}
      singleLine={singleLine}
      rows={rows}
      placeholder={placeholder}
      showStyles={showStyles}
      initialValue={initialValue}
      onChangeValue={onChangeValue} />
  </BaseField>
)

export default RichTextView;