import React, { useState, useRef } from "react";
import View from "components/misc/apps/rpa/generator/Generator.view";
import { useEffect } from "react";
import { usePost } from "seed/api";

function Generator({ location }) {

  const formikRef = useRef();
  const [flowCaptures, setFlowCaptures] = useState([]);
  const [flowResult, setFlowResult] = useState([]);
  const [saved, setSaved] = useState(false);
  const [showLastMessage, setShowLastMessage] = useState(false);
  const [autoCompleteAppPaths, setAutoCompleteAppPaths] = useState([]);
  const [autoCompleteApps, setAutoCompleteApps] = useState(["Excel", "Word", "Outlook"]);

  const [currentFormCompleted, setCurrentFormCompleted] = useState(false);
  const [currentDefinition, setCurrentDefinition] = useState(null);
  const [currentHiddenRegions, setCurrentHiddenRegions] = useState({ absolute: [], relative: [] });
  const [currentParameters, setCurrentParameters] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentKey, setCurrentKey] = useState(0);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentRuleAdded, setCurrentRuleAdded] = useState(false);
  const [currentRuleIndex, setCurrentRuleIndex] = useState(-1);
  const [nextImage, setNextImage] = useState(null);

  const [initialRegions, setInitialRegions] = useState({ absolute: [], relative: [] });
  const [showRegionPicker, setShowRegionPicker] = useState(false);
  const [regionPickerMode, setRegionPickerMode] = useState("HIDE");
  const [onRegionSubmit, setOnRegionSubmit] = useState(null);

  const [response, setResponse] = useState(null);
  const [responseStatus, setResponseStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sample, setSample] = useState(null);

  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const id = params.get("user_id");
  const rawCaptureId = params.get("raw_capture_id");

  const [callGetRawCapture, reqGetRawCapture] = usePost("/raw_captures/get_raw_capture", { 
    onCompleted: (data) => {

      setFlowCaptures(data?.raw_capture?.captures ?? []);

      const prevFlowResult = data?.raw_capture?.captures?.map(capture => ({
        id: capture.id,
        definition: capture?.metadata?.definition,
        answers: capture?.metadata?.answers??{},
        hiddenRegions: capture?.metadata?.hiddenRegions??{ absolute: [], relative: [] },
        parameters: capture?.metadata?.parameters,
        depth: capture?.metadata?.depth ?? 0,
        parent: capture?.metadata?.parent ?? -1,
        skip: capture?.metadata?.skip ?? false,
        ruleIndex: capture?.metadata?.rule_index ?? -1,
        hasRule: capture?.metadata?.hasRule ?? false,
        show: (Object.keys(capture?.metadata?.answers??{}).length != 0 || capture?.metadata?.skip) ?? false
      })) ?? [];

      const shouldSet = prevFlowResult.reduce((acc, curr) => acc && curr.show, true);
      if(shouldSet) setFlowResult(prevFlowResult);

    }
  });

  const [callUpdateRawCapture, reqUpdateRawCapture] = usePost("/raw_captures/update_raw_capture", {
    onCompleted: (data) => {
      alert("Flow saved successfully!");
    }
  });

  if(token && id) {
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("id", id);
  }

  if(localStorage.getItem("id") != null) {
    sessionStorage.setItem("token", localStorage.getItem("token"));
    sessionStorage.setItem("id", localStorage.getItem("id"));
  }

  const handleChangeCurrentValues = (values, index, isEditing = false) => {

    const skip = (values?.skip) ?? false;
    delete values.skip;
    delete values.definition;

    if(isEditing && (Object.keys(values).length == 0)) { /* Ignore */  }
    else if(Object.keys(values).length != 0 || skip || currentDefinition) {

      const currentFlowResult = [...flowResult];
      currentFlowResult[currentIndex] = {
        ...currentFlowResult[currentIndex],
        id: flowCaptures[currentIndex]?.id,
        definition: currentDefinition,
        answers: { ...values, definition: currentDefinition },
        hiddenRegions: currentHiddenRegions,
        parameters: currentParameters,
        ruleIndex: currentRuleIndex,
        hasRule: currentRuleAdded,
        depth: currentFlowResult[currentIndex]?.depth ?? currentFlowResult[currentIndex - 1]?.depth ?? 0,
        parent: currentIndex - 1,
        show: true,
        skip
      };

      setFlowResult(currentFlowResult);

    }

    if(index > flowCaptures.length - 1) {
      setSaved(true);
      setShowLastMessage(true);
    } 
    else {
      setShowLastMessage(false);
    }

    if(index < 0 || index > flowCaptures.length) return;

    setCurrentHiddenRegions((flowResult[index]?.hiddenRegions) ?? { absolute: [], relative: [] });
    setCurrentParameters((flowResult[index]?.parameters) ?? {});
    setCurrentRuleAdded((flowResult[index]?.hasRule) ?? false);
    setResponse(null);
    setCurrentDefinition(null);
    setCurrentRuleIndex(-1);
    setCurrentIndex(index);

  }

  const handleSkip = () =>
    handleChangeCurrentValues({ skip: true }, currentIndex + 1);

  const handleRule = () => {
    setCurrentRuleAdded(!currentRuleAdded);
    if(!currentRuleAdded) handleAddDepth(currentIndex);
    else handleBackDepth(currentIndex);
  }
  
  const handleEdit = (values, index) =>
    handleChangeCurrentValues(values, index, true);

  const handleNext = (values) =>
    handleChangeCurrentValues(values, currentIndex + 1);

  const handleAddDepth = (index) => {

    const values = formikRef.current.values;
    const skip = (values?.skip) ?? false;
    delete values.skip;
    delete values.definition;

    const currentFlowResult = [...flowResult];
    let newDepth = 1; 
    
    if(currentFlowResult[index])
      newDepth = currentFlowResult[index]?.depth + 1;
    else if(currentFlowResult[index - 1])
      newDepth = currentFlowResult[index - 1]?.depth + 1;

    currentFlowResult[index] = {
      ...currentFlowResult[index],
      depth: newDepth,
    };

    if(index == currentIndex) {
      currentFlowResult[index] = {
        ...currentFlowResult[index],
        definition: currentDefinition,
        answers: { ...values, definition: currentDefinition },
        hiddenRegions: currentHiddenRegions,
        parameters: currentParameters,
        ruleIndex: currentRuleIndex,
        hasRule: currentRuleAdded,
        parent: currentIndex - 1,
        skip
      };
    }
  
    for(let i = index - 1; i >= 0; i--) {
      if(currentFlowResult[i]?.depth == newDepth) {
        currentFlowResult[index].parent = i;
        break;
      }
    }

    const updateChildren = (index) => {
      for(let i = index + 1; i < currentFlowResult.length; i++) {
        if(currentFlowResult[i]?.parent == index) {
          currentFlowResult[i].depth++;
          if(currentFlowResult[i]?.hasRule)
            updateChildren(i);
        }
      }
    }

    updateChildren(index);
    setFlowResult(currentFlowResult);

  }

  const handleBackDepth = (index) => {

    const values = formikRef.current.values;
    const skip = (values?.skip) ?? false;
    delete values.skip;
    delete values.definition;

    const currentFlowResult = [...flowResult];
    let newDepth = 0;

    if(currentFlowResult[index])
      newDepth = currentFlowResult[index]?.depth - 1;
    else if(currentFlowResult[index - 1])
      newDepth = currentFlowResult[index - 1]?.depth - 1;

    currentFlowResult[index] = {
      ...currentFlowResult[index],
      depth: newDepth,
    };

    if(index == currentIndex) {
      currentFlowResult[index] = {
        ...currentFlowResult[index],
        definition: currentDefinition,
        answers: { ...values, definition: currentDefinition },
        hiddenRegions: currentHiddenRegions,
        parameters: currentParameters,
        ruleIndex: currentRuleIndex,
        hasRule: currentRuleAdded,
        parent: currentIndex - 1,
        skip
      };
    }

    if(currentFlowResult[index]?.hasRule) {
      if(newDepth == 0) {
        currentFlowResult[index].hasRule = false;
        currentFlowResult[index].ruleIndex = -1;
      }
    }

    for(let i = index - 1; i >= 0; i--) {
      if(currentFlowResult[i]?.depth == newDepth) {
        currentFlowResult[index].parent = i;
        break;
      }
    }

    const updateChildren = (index) => {
      for(let i = index + 1; i < currentFlowResult.length; i++) {
        if(currentFlowResult[i]?.parent == index)
          currentFlowResult[i].depth--;        
      }
    }

    updateChildren(index);
    setFlowResult(currentFlowResult);

  }

  const handleFinish = () => {

    const values = formikRef.current.values;

    const skip = (values?.skip) ?? false;
    delete values.skip;

    const currentFlowResult = [...flowResult];

    if(!showLastMessage && (Object.keys(values).length != 0 || skip)) {
      currentFlowResult[currentIndex] = {
        ...currentFlowResult[currentIndex],
        answers: values,
        hiddenRegions: currentHiddenRegions,
        parameters: currentParameters,
        depth: currentFlowResult[currentIndex]?.depth ?? 0,
        parent: currentIndex - 1,
        skip
      };
    }

    if(!rawCaptureId) {
      window?.chrome?.webview?.postMessage(JSON.stringify({ type: "finish", data: currentFlowResult }));
    }
    else {
      callUpdateRawCapture({ raw_capture_id: rawCaptureId, captures: currentFlowResult });
    }

  }

  const handleCloseRegionPicker = () => {
    setShowRegionPicker(false);
    setLoading(false);
  }

  const callParamGenerator = (regions) => {

    const relativeRegions = regions.relative
    const absoluteRegions = regions.absolute

    setInitialRegions({ absolute: absoluteRegions, relative: relativeRegions });
    setLoading(true);

    setResponse(null);
    setCurrentParameters({});
    setResponseStatus(null);

    // Only one region is returned by Picker
    const sampleRegion = absoluteRegions[0];
    const data = {
      sampleX: sampleRegion.x,
      sampleY: sampleRegion.y,
      sampleWidth: sampleRegion.width,
      sampleHeight: sampleRegion.height,
    };

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = currentImage;
    img.crossOrigin = "Anonymous";

    img.onload = () => {

      // Crop image by sample region (given in percentage, so we calculate the absolute values)
      const width = img.width * (sampleRegion.width / 100);
      const height = img.height * (sampleRegion.height / 100);
      const x = img.width * (sampleRegion.x / 100);
      const y = img.height * (sampleRegion.y / 100);

      canvas.width = width;
      canvas.height = height;

      // Draw sample on canvas
      ctx.drawImage(img, x, y, width, height, 0, 0, width, height);

      const base64 = canvas.toDataURL("image/png");
      setSample(base64);

      setTimeout(() => {

        setResponse({
          regions: { absolute: absoluteRegions, relative: relativeRegions },
          sample: { url: base64, },
          params: {}
        });
  
        setCurrentParameters({
          regions: { absolute: absoluteRegions, relative: relativeRegions },
          sample: { url: base64, },
          params: {}
        });
  
        setResponseStatus("OK");
        setLoading(false);
  
      }, 500); // TODO DUMMY TIMEOUT

      // window.chrome.webview.postMessage(JSON.stringify({ type: "generator", ...data }));

    }

  }

  window?.chrome?.webview?.addEventListener('message', (e) => {

    const data = JSON.parse(e.data);

    if(data.type == "load_image") {
      setCurrentImage("data:image/png;base64," + data.image);
      setNextImage("data:image/png;base64," + data.next_image);
    }
    else if(data.type == "load_captures") {
      setFlowCaptures(data.captures);
    }
    else if(data.type == "load_apps") {
      let apps = data.apps ?? [];
      setAutoCompleteAppPaths(apps.map(app => app.path));
      setAutoCompleteApps(apps.map(app => app.name));
    }

  });

  useEffect(() => {
    if(!rawCaptureId) {
      window?.chrome?.webview?.postMessage(JSON.stringify({ type: "load_captures" }));
      window?.chrome?.webview?.postMessage(JSON.stringify({ type: "load_apps" }));
    }
    else {
      callGetRawCapture({ raw_capture_id: rawCaptureId });
    }
  }, []);

  useEffect(() => {
    if(flowCaptures && flowCaptures[currentIndex]) {
      if(!rawCaptureId) {
        window.chrome.webview.postMessage(JSON.stringify(
          {
            type: "load_image",
            id: flowCaptures[currentIndex].ref_idx_i,
            next_id: flowCaptures[currentIndex].ref_idx_f
          })
        );
      }
      else {
        setCurrentImage(flowCaptures[currentIndex].screenshot_i.url);
        setNextImage(flowCaptures[currentIndex].screenshot_f.url);
      }
    }
  }, [currentIndex, flowCaptures]);

  useEffect(() => {
    setCurrentKey(Math.random());
    formikRef.current.setValues({ ...(flowResult[currentIndex]?.answers) ?? {} });
    setCurrentRuleIndex(flowResult[currentIndex]?.hasRule ? flowResult[currentIndex]?.answers?.ruleIndex : -1);
  }, [currentIndex, flowResult]);

  const currentImageHeight = currentIndex < flowCaptures.length ?
    727 * (flowCaptures[currentIndex].screen_height / flowCaptures[currentIndex].screen_width) : 0

  const currentImageWidth = currentIndex < flowCaptures.length ?
    currentImageHeight * (flowCaptures[currentIndex].screen_width / flowCaptures[currentIndex].screen_height) : 0

  return (
    <View

      formikRef={formikRef}
      flowCaptures={flowCaptures}
      flowResult={flowResult}
      saved={saved}
      showLastMessage={showLastMessage}
      autoCompleteApps={autoCompleteApps}

      currentFormCompleted={currentFormCompleted}
      currentDefinition={currentDefinition}
      currentHiddenRegions={currentHiddenRegions}
      currentParameters={currentParameters}
      currentIndex={currentIndex}
      currentKey={currentKey}
      currentImage={currentImage}
      currentImageHeight={currentImageHeight}
      currentImageWidth={currentImageWidth}
      currentRuleAdded={currentRuleAdded}
      currentRuleIndex={currentRuleIndex}
      nextImage={nextImage}

      initialRegions={initialRegions}
      showRegionPicker={showRegionPicker}
      regionPickerMode={regionPickerMode}
      onRegionSubmit={onRegionSubmit}

      response={response}
      responseStatus={responseStatus}
      loading={loading}

      handleFinish={handleFinish}
      handleSkip={handleSkip}
      handleRule={handleRule}
      handleNext={handleNext}
      handleEdit={handleEdit}
      handleAddDepth={handleAddDepth}
      handleBackDepth={handleBackDepth}
      handleCloseRegionPicker={handleCloseRegionPicker}
      callParamGenerator={callParamGenerator}

      setCurrentFormCompleted={setCurrentFormCompleted}
      setCurrentDefinition={setCurrentDefinition}
      setCurrentIndex={setCurrentIndex}
      setCurrentHiddenRegions={setCurrentHiddenRegions}
      setCurrentRuleIndex={setCurrentRuleIndex}
      setInitialRegions={setInitialRegions}
      setShowRegionPicker={setShowRegionPicker}
      setRegionPickerMode={setRegionPickerMode}
      setOnRegionSubmit={setOnRegionSubmit}

    />
  );

}

export default Generator;