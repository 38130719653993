import React from "react";
import { Link } from "react-router-dom";
import { getFlowName } from "components/util/flow";


const UseCaseItem = ({ idx, flow, profile }) => (
  <div className="col-11 col-md-6 col-lg-3 px-0">
    <div
      class={`card animate__animated animate__fadeInUp animate__delay-1s`}
      style={{
        marginLeft: "0.27rem",
        marginRight: "0.27rem",
        border: "1px solid #d2dae0",
        "--animate-delay": (idx * 0.22) + "s"
      }}
    >

      <div class="card-body text-center mt-5 mb-2">

        <Link to={`/use_case/${flow.templateName}?title=${getFlowName(profile, flow)}`}
          class="avatar avatar-xl avatar-border-lg mb-2">
          <img style={{ width: "4rem" }} src="/resources/images/icon-45.svg" alt="Flow" />
        </Link>

        <h3 class={"mt-1 mb-0"}>
          <Link to={`/use_case/${flow.templateName}?title=${getFlowName(profile, flow)}`}
            class="text-dark h4 text-truncate w-100 d-inline-block mb-0">
            {getFlowName(profile, flow)}
          </Link>
        </h3>

        <div class="w-100" style={{ display: "inline-block" }}>
          {function () {
            const templateCategories = flow?.templateCategories ?? [];
            if (templateCategories.length > 0) {
              const templateCategory = templateCategories[0];
              return (
                <a className="text-dark text-truncate w-100 d-inline-block" href="#" key={templateCategory.id}>
                  <i className={`${templateCategory?.uiSettings?.display?.icon} mr-1`} style={{ color: "#888" }}></i>
                  <span style={{ color: "#777" }}>{templateCategory?.uiSettings?.display?.title}</span>
                </a>
              );
            }
            return <></>;
          }()}
        </div>

      </div>

      <div class="card-footer">
        <div class="py-1 w-100 px-3">
          <Link to={`/use_case/${flow.templateName}?title=${getFlowName(profile, flow)}`}
            class="btn btn-primary w-100">
            <i class="fas fa-play mr-2"></i>
            Probar caso de uso
          </Link>
        </div>
      </div>

    </div>
  </div>
);

export default UseCaseItem;