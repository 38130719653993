import React, { useEffect } from "react";
import queryString from 'query-string';
import { Loading } from "seed/helpers"
import { usePost } from "seed/api";
import { APP_URL } from "settings";


const AuthHandler = (props) => {

    const [callAuth, reqAuth] = usePost("/integration_accounts/auth", {
        onCompleted: (data) =>
            window.location.href = data.redirect_url ?? `${APP_URL}/app`,
        onError: () => {
            alert("Ha ocurrido un error, prueba de nuevo")
            window.location.href = `${APP_URL}/app`
        }
    });

    useEffect(() => {
        let token = null
        let params = queryString.parse(props.location.search)
        let hashParams = queryString.parse(window.location.hash);
        
        const keys = ['token', 'id_token', 'access_token']
        keys.forEach(key => {
            if(params[key] || hashParams[key])
                token = params[key] ?? hashParams[key]
        })

        let state = sessionStorage.getItem("auth_state")
        sessionStorage.removeItem("auth_state")

        callAuth({
            code: params.code ?? hashParams.code,
            token: token,
            state: state,
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (reqAuth.loading) return <EmptyView />

    return <div />;
}

const EmptyView = () =>

    <div class="container p-7">
        <div class="card">
            <div class="card-body" style={{ padding: "7rem 0" }}>
                <Loading />
            </div>
        </div>
    </div>

export default AuthHandler;