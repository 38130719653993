import React, { useState, useEffect } from "react";
import { StyleTag } from "seed/helpers";

const DragPoint = ({ currentIndex, dragCoords, capture, image, size = 32 }) => {

  const [point, setPoint] = useState(null);

  useEffect(() => {

    if(capture?.event?.includes("DRAG")) {
      const img = new Image();
      img.src = image;
  
      img.onload = () => {
       
        // const xI = capture.abs_xi * 100 / capture.screen_width;
        // const yI = capture.abs_yi * 100 / capture.screen_height;
        // const xF = capture.abs_xf * 100 / capture.screen_width;
        // const yF = capture.abs_yf * 100 / capture.screen_height;

        const xi = (capture?.abs_xi??dragCoords?.abs_xi) * 100 / capture?.screen_width;
        const yi = (capture?.abs_yi??dragCoords?.abs_yi) * 100 / capture?.screen_height;
        const xf = (capture?.abs_xf??dragCoords?.abs_xf) * 100 / capture?.screen_width;
        const yf = (capture?.abs_yf??dragCoords?.abs_yf) * 100 / capture?.screen_height;

        setPoint(
          <div
            className={"screen-drag" + (currentIndex%2 == 1 ? "-i" : "-j")}
            style={{
              position: "relative",
              top: yi + "%",
              left: xi + "%",
              marginTop: (-size/2 - 2) + "px",
              marginLeft: (-size/2 + 2) + "px",
              width: size + "px",
              height: size + "px",
              borderRadius: "50%",
              backgroundColor: "#50c5c0d0",
              border: "4px solid #50c5c0",
              transform: "translate(-50%, -50%)",
            }}
          >
            <StyleTag content={`
              @keyframes anim_drag${(currentIndex%2 == 1 ? "_i" : "_j")} {
                0%   { transform: scale(0, 0); opacity: 0; }
                10%  { transform: scale(0, 0); opacity: 0; top: ${yi}%; left: ${xi}%; }
                30%  { transform: scale(1, 1); opacity: 1; top: ${yi}%; left: ${xi}%; }
                60%  { transform: scale(1, 1); opacity: 1; }
                80%  { transform: scale(0, 0); opacity: 0; top: ${yf}%; left: ${xf}%; }
                100% { transform: scale(0, 0); opacity: 0; top: ${yf}%; left: ${xf}%; }
              }              
            `}/>
          </div>
        );
  
      }
  
    }
    else {
      setPoint(null);
    }

  }, [capture, image]);

  return point;

}

export default DragPoint;