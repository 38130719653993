import React from "react";
import { APP_URL } from "settings";
import { BrowserRouter } from "react-router-dom";
import { Modal } from "seed/helpers";
import FormSave from "components/presentation/FormSave";
import Template from "components/presentation/Template";
import Collaboration from "components/flow/forms/collaboration/Collaboration";


const PresentationModeView = ({
  flow,
  reset,
  isEditModalShown,
  isTemplatesShown,
  isShareShown,
  isTutorialFinished,
  onCloseEditModal,
  onCloseTemplatesModal,
  onCloseShareModal,
  onResetPresentation
}) => (
  <BrowserRouter basename={`/p/${flow.token}`}>
    <div className="d-flex justify-content-center">
      <div className="card mt-3 p-0" id="presentation-container"
      style={{
        width: "90%", height: "88vh",
        border: "1px solid #ddd",
        overflowY: "auto",
        borderRadius: "0.75rem"
      }}>
        <div className="w-100">
          <iframe
            key={reset}
            className="w-100"
            style={{ height: "85vh", border: "0px" }}
            title="Presentation mode"
            referrerpolicy="same-origin"
            id="presentation-iframe"
            src={`${APP_URL}/presentation/${flow.token}?tabs=true`}>
          </iframe>

          {!isTutorialFinished ?
          <div id="presentation-ctas-frame"
            style={{position: "absolute", top: "0.65rem", right: "9.4rem", height: "2.25rem", width: "19.55rem"}}
          ></div> : null}
          
        </div>
      </div>
    </div>

    {isEditModalShown && (
      <Modal
        width="700"
        height={window.innerHeight - 150}
        component={() => <FormSave flowId={flow.id} reset={reset} onResetPresentation={onResetPresentation} />}
        onClose={onCloseEditModal}
      />
    )}

    {isTemplatesShown && (
      <Modal
        width="700"
        height={window.innerHeight - 150}
        component={() => <Template flowId={flow.id} reset={reset} onResetPresentation={onResetPresentation} />}
        onClose={onCloseTemplatesModal}
      />
    )}

  {isShareShown && (
      <Modal
        width="650"
        height={window.innerHeight - 150}
        component={() => <Collaboration flowId={flow.id} showLink={true} />}
        onClose={onCloseShareModal}
      />
    )}

  </BrowserRouter>
)

export default PresentationModeView;