/* eslint-disable max-len */
/* eslint-disable no-case-declarations */

const checkRoot = (id, nodes, edges) => {

	const roots = [];
	const adjacency = {}, visited = new Set();

	for (const node of nodes) {
		adjacency[node.id] = [];
	}

	for (const edge of edges) {
		adjacency[edge.source].push({
			"node": edge.target,
			"edge": edge
		})
	}

	for (const node of nodes) {
		const neighbours = adjacency[node.id];
		for (const neighbour of neighbours) {
			visited.add(neighbour["node"]);
		}
	}

	for (const node of nodes) {
		if (!visited.has(node.id)) {
			roots.push(node.id)
		}
	}

	return roots.includes(id);
}

const getBody = (settings, input, nodeValues) => {

	const showLabel = settings?.show_label ?? true;
	const limit = settings.limit;
	const separator = settings?.separator ?? " | ";

	let value = nodeValues.find((item) => item.name == input.name)?.value;
	if (!value) return "";

	switch (input.type) {			

		case "SELECT":
			let options = input.options;
			let newValue = value;
			if (showLabel) {
				const selectedOption = options.find((option) => option.value == value);
				if (!selectedOption) return "";
				newValue = selectedOption.label;
			}
			if (limit) return newValue.substring(0, limit);
			return newValue;

		case "MULTI_SELECT":
			options = input.options;
			let newValues = value;

			if (showLabel) {
				newValues = newValues.map((item) => {
					const selectedOption = options.find((option) => option.value == item);
					if (!selectedOption) return "";
					return selectedOption.label;
				})
			}

			if (limit) newValues = newValues.slice(0, limit);
			return newValues.join(separator);

		default:
			if (limit) return String(value).substring(0, limit);
			return value;
	}

}

const bodyContentMapper = (optionsDisplay, nodeValues, attribute, formSettings) => {
	const values = nodeValues;
	if (!Array.isArray(optionsDisplay))
		optionsDisplay = [optionsDisplay]

	if (optionsDisplay.length == 0) return "";

	let parsedDisplay = "";
	let idxSelectedOption = 0;
	// Evaluate conditions
	for (let idx = 0; idx < optionsDisplay.length; idx++) {
		let show = false;
		const item = optionsDisplay[idx];
		const showConditions = item?.show_if ?? [];
		for (const conditional of showConditions) {
			const variable = conditional?.variable ?? "";
			const value = conditional?.value ?? "";
			const condition = conditional?.condition ?? "EQUAL";
			const variableValue = values.find(val => val.name == variable)?.value

			if (condition == "EQUAL")
				if (variableValue == value) show = true;
			if (condition == "NOT_EQUAL")
				if (variableValue != value) show = true;
			if (condition == "GREATER_THAN")
				if (variableValue > value) show = true;
			if (condition == "LOWER_THAN")
				if (variableValue < value) show = true;
			if (condition == "GREATER_THAN_OR_EQUAL")
				if (variableValue >= value) show = true;
			if (condition == "LOWER_THAN_OR_EQUAL")
				if (variableValue <= value) show = true;
			if (condition == "CONTAINS")
				if (variableValue.includes(value)) show = true;
			if (show) idxSelectedOption = idx;
		}
	}
	if (optionsDisplay[idxSelectedOption][attribute]) 
		parsedDisplay = optionsDisplay[idxSelectedOption][attribute];
	else parsedDisplay = "";	
	

	let regex = /{([^}]+)}/g;
	let matches = parsedDisplay.match(regex);

	if (matches) {
		for (const match of matches) {
			let name = match.substring(1, match.length - 1);
			let defValue = nodeValues.find((item) => item.name == name)?.value ?? "";

			if (!formSettings.fields) {
				parsedDisplay = parsedDisplay.replace(match, defValue);
				continue;
			}

			const inputs = formSettings.fields;
			const input = inputs.find((item) => item.name == name);

			if (!input) {
				parsedDisplay = parsedDisplay.replace(match, defValue);
				continue;
			}

			let variableDisplaySettings = {};
			const replacement = getBody(variableDisplaySettings, input, values);
			parsedDisplay = parsedDisplay.replace(match, replacement);
		}
	}

	return parsedDisplay;
}

const warningMapper = (warning, actionUiSettings) => {

	const inputs = actionUiSettings.form.fields;
	const type = warning.type;

	switch (type) {

		case "NULL_VALUE":
			const input = inputs.find((input) => input.name == warning.details?.name ?? "");
			return `Completa el campo <i>${input.label}</i>`;
		default:
			return "";
	}
}

const errorMapper = (error, actionUiSettings) => {

	const inputs = actionUiSettings.form.fields;
	const type = error.type;
	const inputLabel = inputs.find((input) => input.name == error.details?.name ?? "");

	switch (type) {

		case "WRONG_TYPE":
			return `Revisar el campo <i>${inputLabel.label}</i>, el tipo de dato esperado es <i>${datatypeMapper(error.details?.expected_type ?? "")}</i>`;

		case "NOT_REFERENCE":
			return `Revisar el campo <i>${inputLabel.label}</i>, cuenta con una referencia que no existe, o bien no es accesible`;

		default:
			return "";
	}
}

const datatypeMapper = (type) => {
	if (type == "int") return "Numérico";
	if (type == "string") return "Texto";
	if (type == "date") return "Fecha";
	if (type == "boolean") return "Booleano";
	if (type == "float") return "Numérico con decimales";
	if (type == "text") return "Texto";
	if (type == "file") return "Archivo";
	if (type == "image") return "Imagen";
	if (type == "object") return "Objeto";
	if ((type ?? "").startsWith("array")) return "Lista";
	return "";
}

export { checkRoot, bodyContentMapper, warningMapper, errorMapper } 