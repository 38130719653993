/* eslint-disable max-len */
import React from "react";
import { ReactFlowProvider } from "reactflow";
import { BrowserRouter } from "react-router-dom";
import Tour from "components/misc/tour/Tour"
import Forms from "components/misc/apps/forms/Forms";
import RPA from "components/misc/apps/rpa/RPA";
import Controller from "components/flow/Controller";

const FlowView = ({
  flow,
  nodes,
  edges,
  annotations,
  actions,
  activePage,
  tutorialSteps,
  currentTutorialStep,
  history,
  historyPosition,
  isAnonymous,
  isTutorialShown,
  setActivePage,
  setHistoryPosition,
  reloadFlow,
  onUpdateHistory
}) =>
  <div className="content container-fluid px-1 px-md-3 py-3 py-md-4">
    <div className="d-flex justify-content-center">
      <ReactFlowProvider>
        <BrowserRouter basename={`/app/flow/${flow.id}`}>
          <Tour
            steps={tutorialSteps}
            startAt={currentTutorialStep}
            show={isTutorialShown}
            tutorialName={"flow"}
          >
            <Controller
              flow={flow}
              activePage={activePage}
              initialNodes={[...nodes, ...annotations]}
              initialEdges={edges}
              actions={actions}
              history={history}
              historyPosition={historyPosition}
              isAnonymous={isAnonymous}
              setActivePage={setActivePage}
              setHistoryPosition={setHistoryPosition}
              reloadFlow={reloadFlow}
              onUpdateHistory={onUpdateHistory}
            />
          </Tour>
        </BrowserRouter>
      </ReactFlowProvider>
      <Forms flow={flow} />
      <RPA />
    </div>
  </div>;

export default FlowView;