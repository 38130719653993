import React from "react";

const EventDescription = ({ capture }) => {

  if(!(capture?.event?.includes("TEXT") || capture?.event?.includes("KEY") || capture?.event?.includes("SHORTCUT") ))
    return null

  return <div className="w-100">
    <label style={{
      fontSize: "14px",
      fontStyle: "italic",
      background: "#f5f5f5",
      width: "100%",
      padding: "5px 10px",
      display: "block",
      textAlign: "center"
    }}>
      <b>
        {
          function () {
            if(capture?.event?.includes("TEXT")) return "Texto: "
            if(capture?.event?.includes("KEY")) return "Tecla: "
            if(capture?.event?.includes("SHORTCUT")) return "Tecla: "
            return ""
          }()
        }
      </b>
      {
        function () {

          if(capture?.combination.length == 0)
            return capture.value;

          let textIndex = -1;
          let keyIndex = -1;
          let shortcutIndex = -1;

          for(let i = 0; i < capture?.combination?.length; i++) {
            if(capture?.combination[i]?.event == "TEXT") textIndex = i;
            if(capture?.combination[i]?.event == "KEY") keyIndex = i;
            if(capture?.combination[i]?.event == "SHORTCUT") shortcutIndex = i;
          }
          
          if(textIndex != -1) return capture?.combination[textIndex]?.value??""
          if(keyIndex != -1) return capture?.combination[keyIndex]?.value??""
          if(shortcutIndex != -1) return capture?.combination[shortcutIndex]?.value??""

          return ""

        }()
      }
    </label>
  </div>;
}

export default EventDescription;