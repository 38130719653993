import React from 'react';
import View from "components/misc/settings/Flows.view";

function Flows() {

  return (
    <View />
  );
}

export default Flows;