import React, { useEffect } from "react";
import { useTour } from "@reactour/tour";
import View from "components/misc/tour/Main.view";

function Main({ children, show }) {
  const { setIsOpen } = useTour();

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(show);
    }, 750);
  }, [])

  return <View children={children} />;
}

export default Main;