import React, { useState } from "react";
import View from "components/start/Start.view";

function Start({ uuid }) {

  const isDeveloperMode = sessionStorage.getItem("developer_mode") == "true";
  sessionStorage.setItem("uuid", uuid);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isExecutionsShown, setIsExecutionsShown] = useState(false);

  return (
    <View
      selectedFolder={selectedFolder}
      isExecutionsShown={isExecutionsShown}
      isDeveloperMode={isDeveloperMode}
      setSelectedFolder={setSelectedFolder}
      setIsExecutionsShown={setIsExecutionsShown}
    />
  );
}

export default Start;