import { useState } from 'react';
import { useNodesState, useEdges } from 'reactflow';

const useNodeCustomState = (initialNodes, customOnNodeChange) => {
    const edges = useEdges();
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [prevChange, setPrevChange] = useState({});

    const newOnNodeChange = (changes) => {
        onNodesChange(changes)
        for (const change of changes) {
            const type = change.type;
            if (type == "position" && change.dragging == false) {
                if (prevChange?.type == "position" && prevChange?.dragging == true) {
                    setNodes((nodes) => {
                        customOnNodeChange(nodes);
                        return nodes;
                    })
                }
            } else if (type == "dimensions") {
                if (change.resizing == false) {
                    setNodes((nodes) => {
                        customOnNodeChange(nodes);
                        return nodes;
                    })
                }
            }
        }
        setPrevChange(changes.length > 0 ? changes[0] : {})
    }

    return [nodes, setNodes, newOnNodeChange]
}

export { useNodeCustomState };