import { API_URL } from "settings";
import $ from "jquery";

const downloadFile = (url, blob = null, name = '') => {

  if (!blob && !url) throw new Error("Either url or blob must be provided");

  const link = document.createElement('a');
  link.href = url || URL.createObjectURL(blob);
  link.setAttribute('download', name);

  document.body.appendChild(link);

  link.click();

}

const uploadFile = (callback) => {

  const onUploadFile = (formData) => {
    $.ajax({
      url: `${API_URL}/files/`,
      type: "POST",
      headers: {
        "Authorization": `Token ${sessionStorage.getItem("token")}`
      },
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      xhr: () => $.ajaxSettings.xhr(),
      success: (res) => callback(res),
      error: (error) => console.log(error)
    });
  };

  const input = document.createElement("input");
  input.type = "file";
  input.style.display = "none";
  input.onchange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    onUploadFile(formData);
  };
  document.body.appendChild(input);
  input.click();

}

export { downloadFile, uploadFile }