import { toCamelCase } from "components/tables/util/format";

const ColumnCallbaks = ({
  _columns,
  _rows,
  isReadOnly,
  setColumns,
  setRows,
  setSelectedCell,
  onAddColumn,
  onChangeColumn,
  onChangeColumnOptions,
  onDeleteColumn
}) => {

  const _onAddColumn = (type) => {

    if(isReadOnly) return;

    const column = { type, name: `column${_columns.length}`, label: `Column ${_columns.length}` };
    const newColumns = [..._columns, column];

    setColumns(newColumns);
    onAddColumn(newColumns, column);
    setSelectedCell({ row: -1, column: _columns.length });

  }

  const _onChangeColumn = (index, column) => {

    if (isReadOnly) return;
    if (!_columns[index] || !column) return;

    const prevKey = _columns[index].name;
    let newColumns = [..._columns];
    newColumns = newColumns.map(col => Object.assign({}, col));
    newColumns[index] = column;
    newColumns[index].name = toCamelCase(column.label);
    
    if (prevKey != newColumns[index].name) {

        let newRows = [..._rows];
        newRows = newRows.map(row => Object.assign({}, row));
        newRows.forEach(row => {
          row.data = { ...row.data };
          row.data[newColumns[index].name] = row.data[prevKey];
          delete row.data[prevKey];
        });
    
        
        setRows(newRows);

    }

    setColumns(newColumns);
    onChangeColumn(newColumns, prevKey, newColumns[index].name);

  }

  const _onChangeColumnOptions = (index, newColumn) => {
    const newColumns = [..._columns];
    newColumns[index] = newColumn;
    setColumns(newColumns);
    onChangeColumnOptions(newColumns);
  }

  const _onDeleteColumn = (index) => {

    if (isReadOnly) return;

    const column = _columns[index];

    let newColumns = [..._columns];
    newColumns = newColumns.map(col => Object.assign({}, col));
    newColumns.splice(index, 1);

    let newRows = [..._rows];
    newRows = newRows.map(row => Object.assign({}, row));
    newRows.forEach(row => {
      row.data = { ...row.data };
      delete row.data[column.name];
    });

    setColumns(newColumns);
    setRows(newRows);
    onDeleteColumn(newColumns, column.name);

  }

  return {
    _onAddColumn,
    _onChangeColumn,
    _onChangeColumnOptions,
    _onDeleteColumn
  }

}

export default ColumnCallbaks;