import React from "react";
import { useDetail, useSet } from "seed/gql";
import { SET_USER, USER } from "seed/gql/queries";
import { Loading } from "seed/helpers";
import View from "components/misc/settings/Account.view";


function Account() {

	const userId = sessionStorage.getItem('id');

	const [callSet, setCallSet] = useSet(SET_USER, {
		onCompleted: () => {
			window.location.reload();
		},
		onError: () => {
			alert("Ha ocurrido un error");
		}
	});

	const reqUser = useDetail(USER, userId);

	const onSubmit = (values) => {
		values.firstName = values.first_name;
		values.lastName = values.last_name;
		callSet({
			...values,
			id: userId
		});
	}

	if (reqUser.loading) return <Loading />;
	if (reqUser.error) return "Error";

	const { user = {} } = reqUser.data;

	return <View
		user={user}
		onSubmit={onSubmit}
	/>;
}

export default Account;