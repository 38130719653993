import React from "react";
import { StyleTag } from "seed/helpers";

const TableControls = ({
  search,
  filters,
  showSearch,
  showImport,
  showExport,
  showForm,
  showFilters,
  isReadOnly,
  isSearchShown,
  setIsImportModalShown,
  setIsExportModalShown,
  setIsFormModalShown,
  setIsSearchShown,
  setIsFilterModalShown,
  setSelectedCell,
  onChangeSearch
}) => {

  return <div className="d-flex align-items-center justify-content-end w-100 my-1">

    {
      showSearch &&
        <>
          {
            isSearchShown 
              ? <div className="animate__animated animate__fadeIn animate__faster">
                  <div className="bg-white px-2 py-1 rounded mr-2"
                    style={{ border: "1px solid #dadde0" }}>
                    <i className="fas fa-search mr-3" />
                    <input
                      type="text"
                      className="border-0"
                      placeholder="Buscar"
                      style={{ width: "10rem" }}
                      onFocus={() => setSelectedCell(null)}
                      onBlur={() => search.length === 0 && setIsSearchShown(false)}
                      value={search}
                      onChange={onChangeSearch}
                    />
                    <i 
                      className="fas fa-times ml-3"
                      onClick={() => {
                        onChangeSearch({ target: { value: "" } });
                        setIsSearchShown(false);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              : <button
                  style={{ width: "2.2rem" }}
                  className="btn btn-sm btn-lighter-white mr-2 text-center"
                  onClick={() => setIsSearchShown(true)}
                >
                  <i className="fas fa-search"></i>
                </button>
          }
        </>
    }

    <StyleTag content={`

      .btn-export, .btn-import {
        background: white;
        transition: 0.3s;
      }
    `} />

    <div className="d-flex align-items-center">

      {
        showFilters &&
          <button
            className={`btn btn-sm mr-2 text-center ${filters.length > 0 ? "btn-primary text-white" : "btn-lighter-white"}`}
            style={{ minWidth: "2.2rem" }}
            onClick={() => setIsFilterModalShown(true)}
          >
            <i className={`fas fa-filter`}></i>
            {
              filters.length > 0 && 
                <span className="ml-1"> 
                  Filtro habilitado 
                </span>
            }
          </button>
      }

      {
        (showImport || showExport) && <>
            <button
              className="btn btn-sm btn-lighter-white text-center"
              style={{ width: "2.2rem" }}
              type="button"
              id="dropdown-options"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-ellipsis-v"></i>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdown-options">
              {
                showImport &&
                  <button
                    className="dropdown-item"
                    onClick={() => setIsImportModalShown(true)}
                  >
                    <i className="fas fa-upload mr-2"></i>
                    Carga masiva de Excel
                  </button>
              }
              {
                showExport &&
                  <button
                    className="dropdown-item"
                    onClick={() => setIsExportModalShown(true)}
                  >
                    <i className="fas fa-file-excel mr-2"></i>
                    Exportar Excel
                  </button>
              }
            </div>
          </>
      }

      {
        (showForm && !isReadOnly) &&
          <button
            className="btn btn-sm btn-lighter-white mr-2"
            onClick={() => setIsFormModalShown(true)}
          >
            <i className="fas fa-plus mr-2"></i>
            Agregar dato
          </button>
      }

    </div>

  </div>

}

export default TableControls;