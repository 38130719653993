import React from "react";
import { useGet } from "seed/api";
import * as chartOptions from "components/start/usage/Usage.lib.chartOptions";
import * as lib from "components/start/executions/Executions.lib.format";
import View from "components/start/executions/Executions.view";


const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function Executions({ flow = {} }) {

  const userId = sessionStorage.getItem("id");
  const now = new Date();
  const date15DaysAgo = new Date();
  date15DaysAgo.setDate(now.getDate() - 15);
  const endDate = formatDate(now);
  const startDate = formatDate(date15DaysAgo);

  const reqExecutionsUsage = useGet("/executions/get_executions_usage",
    { "user_id": userId, "start_date": startDate, "end_date": endDate })
  const reqExecutionsByApp = useGet("/executions/get_executions_by_app",
    { "user_id": userId, "start_date": startDate, "end_date": endDate })

  if (reqExecutionsUsage.loading || reqExecutionsByApp.loading) return <div style={{ padding: "0.72rem 0rem" }} />

  const executionsPerDay = reqExecutionsUsage.data?.executions ?? {};
  const executionsByApp = reqExecutionsByApp.data?.executions ?? [];
  const topExecutionsByApp = executionsByApp.slice(0, 5);

  const lineChartExecutions = {
    "data": [{
      "name": "Ejecuciones",
      "data": Object.keys(executionsPerDay).map(key => executionsPerDay[key])
    }],
    "options": chartOptions.lineChartOptions
  }
  lineChartExecutions["options"]["title"] = {
    text: "Ejecuciones por día de los últimos 15 dias",
    style: { fontFamily: "sans-serif", fontWeight: 700 }
  };
  lineChartExecutions["options"]["xaxis"] = {
    "categories": Object.keys(executionsPerDay).map(key => {
      const date = key;
      const day = parseInt(date.split("-")[2]);
      const month = parseInt(date.split("-")[1]);
      return `${day} ${lib.getMonthName(month)}`
    })
  }

  const barChartExecutions = {
    "data": [{
      "name": "Ejecuciones",
      "data": topExecutionsByApp.map(record => record.executions)
    }],
    "options": chartOptions.barChartOptions
  }
  barChartExecutions["options"]["title"] = {
    text: "Ejecuciones por app de los últimos 15 dias",
    style: { fontFamily: "sans-serif", fontWeight: 700 }
  };
  barChartExecutions["options"]["xaxis"] = {
    "categories": topExecutionsByApp.map(record => record["app"]["label"])
  }

  return (
    <View
      flow={flow}
      lineChartExecutions={lineChartExecutions}
      barChartExecutions={barChartExecutions}
    />
  );
}

export default Executions;