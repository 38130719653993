const IS_PROD = process.env.NODE_ENV === "production" || process.env.REACT_APP_IS_PROD== "true"

// Default (Development)

let APP_URL = "http://localhost:3003";
let SERVER_URL = "http://localhost:8008";
let LANDING_URL = "http://localhost:5005";
let GA_KEY = "G-0000000000";
let SENTRY_DSN = "";
let SENTRY_SAMPLE_RATE = 0.25;
let MICROSOFT_CLIENT_ID = "f74335a6-8303-414f-8c51-17bc937578ab";
let GOOGLE_CLIENT_ID = "403772658120-2pjhgj9tofptfanl0aeggie1fnpkl4se.apps.googleusercontent.com"

// Production

if (IS_PROD) {
  APP_URL = "https://app.workix.mx";
  SERVER_URL = "https://app.workix.mx";
  LANDING_URL = "https://workix.mx"
  GA_KEY = "G-0000000000";
  SENTRY_DSN = "https://5280cf8161a2811bda1d656334a8dca9@o4505944161779712.ingest.sentry.io/4505944207261696";
  SENTRY_SAMPLE_RATE = 0.25;
  MICROSOFT_CLIENT_ID = "f74335a6-8303-414f-8c51-17bc937578ab";
  GOOGLE_CLIENT_ID = "403772658120-2pjhgj9tofptfanl0aeggie1fnpkl4se.apps.googleusercontent.com"
}

let GRAPH_URL = SERVER_URL + "/graphql";
let API_URL = SERVER_URL + "/api";
let WS_URL = (new URL(SERVER_URL).protocol == "https:" ? "wss:" : "ws:") + "//" +  new URL(SERVER_URL).host + "/ws";
export { APP_URL, SERVER_URL, LANDING_URL, GRAPH_URL, API_URL, WS_URL, GA_KEY, SENTRY_DSN, SENTRY_SAMPLE_RATE, MICROSOFT_CLIENT_ID, GOOGLE_CLIENT_ID, IS_PROD };