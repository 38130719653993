import React from "react";
import { useHistory } from 'react-router-dom';
import { usePost } from "seed/api";


function EventInit({ onDeleteNode }) {
  const historyRouter = useHistory();

  const [callGetAction] = usePost("/actions/get_action_data", {
    onCompleted: (data) => historyRouter.replace(`/flow/create/${data.id}`)
  })

  // iframe-viewer
  let iFrameViewers = document.getElementsByClassName("cta-iframe-viewer")
  for (let item of iFrameViewers)
    item.onclick = (e) => {
      let url = e.target.dataset.url
      historyRouter.replace(`/flow/website/${encodeURIComponent(url)}`);
    }

  // create-node
  let createNodes = document.getElementsByClassName("cta-create-node")
  for (let item of createNodes)
    item.onclick = (e) => {
      let actionName = e.target.dataset.action
      callGetAction({ action_name: actionName })
    }

  // delete-annotation
  let deleteNodes = document.getElementsByClassName("cta-delete-node")
  for (let item of deleteNodes)
    item.onclick = (e) => {
      let internalId = e.target.dataset.internal_id
      onDeleteNode(internalId)
    }

  return <div />
}

export default EventInit;