import React from "react";

const dataTutorialSteps = [
	{
		selector: "#tables",
		content: () => <label><b>
			Este es el módulo de datos<br/> En este podrás guardar toda la información que necesite tu proceso (ej. Datos de empleados, clientes, solicitudes)
		</b></label>
	},
	{
		selector: "#create-table-dropdown, #no-table-ctas",
		content: () => <label><b>Prueba importar un excel o crear tablas de datos manualmente</b></label>
	}
];

export { dataTutorialSteps }