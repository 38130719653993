import { isSingleEvent, isMultiEvent } from "components/misc/apps/rpa/generator/util/events";

const FLOW_QUESTIONS = {
  "text_00": {
    "name": "IS_SINGLE",
    "next": [
      {
        "id": "text_01",
        "condition": (answers, captures, index) =>
          isSingleEvent(captures[index]),
      },
      {
        "id": "text_multiple_00",
        "condition": (answers, captures, index) =>
          isMultiEvent(captures[index]),
      }
    ]
  },
  "text_01": {
    "name": "MANUAL_INPUT",
    "label": (answers, captures, index) => 
      `¿Necesitas teclear el texto "${captures[index]?.value.substring(0,20)}" manualmente?`,
    "component": "CHECKBOX",
    "options": {
      "true_label": "Sí",
      "false_label": "No, el texto viene de otro lado",
    },
    "next": [
      {
        "id": "text_02",
        "condition": (answers, captures, index) => 
          answers["MANUAL_INPUT"]
      },
      {
        "id": "text_02", // TODO: Check this reference_00
        "condition": (answers, captures, index) => 
          answers["MANUAL_INPUT"] == false
      }
    ],
    "definition": (answers, captures, index) =>
      "TEXT"
  },
  "text_02": {
    "definition": (answers, captures, index) => 
      "PROMPT"
  },
};

export default FLOW_QUESTIONS;