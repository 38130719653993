import React from "react";
import { Formik, Form } from "formik";
import { StyleTag } from "seed/helpers";
import SchemaHandler from "components/schema_form/SchemaForm.sub.SchemaHandler.view";
import "styles/css/Form.css";


const SchemaFormView = ({
  schema,
  settings,
  appId,
  actionId,
  flowId,
  nodeId,
  initialValues,
  submitText,
  submitComponent,
  ignoreSamplePicker,
  isDeveloperMode,
  isReadOnly,
  isCommentEnabled,
  onSubmit
}) => (
  <div>
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}>
      {({ values, setValues, setFieldValue }) => (
        <Form className="schema-form">
          {console.log(values)}
          <SchemaHandler schema={schema} appId={appId} actionId={actionId} flowId={flowId}
            nodeId={nodeId} values={values} setFieldValue={setFieldValue} setValues={setValues}
            ignoreSamplePicker={ignoreSamplePicker} isDeveloperMode={isDeveloperMode}
            isReadOnly={isReadOnly} isCommentEnabled={isCommentEnabled}
          />
          {function () {

            let showSubmitButton = true;
            if (!schema) return <></>;

            // Determine which inputs are required
            const requiredInputs = [];

            for (const input of schema?.fields ?? []) {

              let showInput = true;
              const showConditions = input.show_if;

              if (!showConditions) {
                requiredInputs.push(input)
                continue;
              }

              for (const conditional of showConditions) {
                const variable = conditional?.variable ?? "";
                const value = conditional?.value ?? "";
                const condition = conditional?.condition ?? "EQUAL";

                switch (condition) {
                  case "EQUAL":
                    if (!(values[variable] == value)) showInput = false;
                    break;

                  case "NOT_EQUAL":
                    if (!(values[variable] != value)) showInput = false;
                    break;

                  case "GRATER_THAN":
                    if (!(values[variable] > value)) showInput = false;
                    break;

                  case "LOWER_THAN":
                    if (!(values[variable] < value)) showInput = false;
                    break;

                  case "GRATER_THAN_OR_EQUAL":
                    if (!(values[variable] >= value)) showInput = false;
                    break;

                  case "LOWER_THAN_OR_EQUAL":
                    if (!(values[variable] <= value)) showInput = false;
                    break;

                  case "CONTAINS":
                    if (!(values[variable].includes(value))) showInput = false;
                    break;

                  default:
                    break;
                }
              }

              if (showInput) {
                requiredInputs.push(input)
              }

            }

            for (const input of requiredInputs) {
              const type = input.type;
              let required = input?.required ?? true;
              if (input?.hidden == true) required = false;
              const name = input?.name ?? "";

              if (type == "SAMPLE_PICKER" && !ignoreSamplePicker) {
                if (values["element"] == null || values["values"] == "") {
                  showSubmitButton = false;
                  break;
                }
              }

              if (!required) continue;
              if (values[name] == null || values[name] === "") {
                showSubmitButton = false;
                break;
              }
            }

            if (showSubmitButton) {
              return (
                <div className="px-3 mt-1 mb-1">
                  {
                    submitComponent ? React.createElement(submitComponent, {
                      values: values,
                      setFieldValue: setFieldValue,
                    }) :
                      <button type="submit" class="btn btn-block btn-primary">{submitText}</button>
                  }

                </div>
              )
            }

            return <></>

          }()}

        </Form>
      )}
    </Formik>

    <StyleTag content={settings?.css ?? ""} />
  </div>
)
export default SchemaFormView;