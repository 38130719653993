import React from "react";
import { Modal } from "seed/helpers";
import Filters from "components/tables/helpers/Filters";
import ImportFile from "components/tables/helpers/Import";
import ExportFile from "components/tables/helpers/Export";
import Options from "components/tables/helpers/Options";
import Form from "components/tables/helpers/Form";
import TableControls from "components/tables/Table.sub.controls.view";
import TableDatagrid from "components/tables/Table.sub.datagrid.view";

const TableView = ({
  height = "auto",
  columns = [],
  rows = [],
  filteredData = [],
  filteredColumns = [],
	collections = [],
	collectionColumns = [],
  isReadOnly = false,
	isLoading = false,
  isFilePreviewShown = false,
  isFilterModalShown = false,
  isOptionModalShown = false,
  isImportModalShown = false,
  isExportModalShown = false,
  isFormModalShown = false,
  isSearchShown = false,
	showFilters = true,
	showOptions = true,
	showImport = true,
	showExport = true,
	showSearch = true,
  showForm = false,
  tableRef,
  pageSize,
  pageNum,
  totalPages,
  query,
  filters, 
  search,
  formValue,
  selectedCell,
  selectedFile,
  setSelectedCell,
  setSelectedFile,
  setFormValue,
  setIsFilePreviewShown,
  setIsFilterModalShown,
  setIsOptionModalShown,
  setIsImportModalShown,
  setIsExportModalShown,
  setIsFormModalShown,
  setIsSearchShown,
	onAddColumn = () => { },
	onChangeColumn = () => { },
	onChangeColumnOptions = () => { },
	onDeleteColumn = () => { },
	onAddRow = () => { },
	onDeleteRow = () => { },
	onInsertRow = () => { },
	onChangeRow = () => { },
	onImport = () => { },
	onExport = () => { },
	onDownloadZip = () => { },
	onPageChange = () => { },
	onChangeSearch = () => { },
	onChangeQuery = () => { },
  onChangeFilters = () => { },
	onClickCell = () => { },
  onSubmitForm = () => { },
	folioComponent
}) => {
  return (
    <div className="h-100">

      <TableControls
        search={search}
        filters={filters}
        showSearch={showSearch}
        showImport={showImport}
        showExport={showExport}
        showForm={showForm}
        showFilters={showFilters}
        isReadOnly={isReadOnly}
        isSearchShown={isSearchShown}
        setIsImportModalShown={setIsImportModalShown}
        setIsExportModalShown={setIsExportModalShown}
        setIsFormModalShown={setIsFormModalShown}
        setIsSearchShown={setIsSearchShown}
        setIsFilterModalShown={setIsFilterModalShown}
        setSelectedCell={setSelectedCell}
        onChangeSearch={onChangeSearch}
      />

      <TableDatagrid
        height={height}
        columns={columns}
        rows={filteredData}
        filteredData={filteredData}
        filteredColumns={filteredColumns}
        collections={collections}
        collectionColumns={collectionColumns}
        isReadOnly={isReadOnly}
        isLoading={isLoading}
        isFilePreviewShown={isFilePreviewShown}
        showOptions={showOptions}
        tableRef={tableRef}
        pageSize={pageSize}
        pageNum={pageNum}
        filters={filters}
        selectedCell={selectedCell}
        selectedFile={selectedFile}
        setSelectedCell={setSelectedCell}
        setSelectedFile={setSelectedFile}
        setFormValue={setFormValue}
        setIsFilePreviewShown={setIsFilePreviewShown}
        setIsOptionModalShown={setIsOptionModalShown}
        setIsFormModalShown={setIsFormModalShown}
        onAddColumn={onAddColumn}
        onChangeColumn={onChangeColumn}
        onDeleteColumn={onDeleteColumn}
        onAddRow={onAddRow}
        onDeleteRow={onDeleteRow}
        onInsertRow={onInsertRow}
        onChangeRow={onChangeRow}
        onDownloadZip={onDownloadZip}
        onClickCell={onClickCell}
        folioComponent={folioComponent}
      />

      <div className="w-100">
        <nav class="mt-3 scrollbar-narrow" style={{ overflowX: "auto" }}>
          <ul class="pagination d-flex align-items-center mb-1">

            <li
              onClick={() => onPageChange(pageNum - 1)}
              class="page-item"
              style={{ display: pageNum > 1 ? "block" : "none" }}
            >
              <a class="page-link" aria-label="Prev">
                <span aria-hidden="true">«</span><span class="sr-only">Prev</span>
              </a>
            </li>

            {
              totalPages > 1 ?
              Array(totalPages).fill(0).map((_, idx) =>
                <li
                  key={idx}
                  onClick={() => onPageChange(idx + 1)}
                  class={"page-item " + (idx == pageNum - 1 ? "page-active" : "")}
                >
                  <a
                    class={`btn btn-sm ${idx == pageNum - 1 ? "btn-primary" : "btn-soft-secondary"} px-3`}
                    role="button"
                  >
                    {idx + 1}
                  </a>
                </li>
              ) : null
            }

            <li
              onClick={() => onPageChange(pageNum + 1)}
              class="page-item"
              style={{ display: pageNum <= totalPages - 1 ? "block" : "none" }}
            >
              <a class="page-link" aria-label="Sig">
                <span aria-hidden="true">»</span><span class="sr-only">Sig</span>
              </a>
            </li>

          </ul>
        </nav>
      </div>

      {
        isFilterModalShown && <Modal
          width="800"
          height={window.innerHeight - 150}
          columns={columns}
          query={query}
          onClose={() => setIsFilterModalShown(false)}
          component={() => 
            <div className="card card-body w-100">
              <Filters 
                columns={columns} 
                query={query}
                filters={filters}
                onChangeQuery={onChangeQuery}
                onChangeFilters={onChangeFilters}
                onClose={() => setIsFilterModalShown(false)}
              />
            </div>
          }
        />
      }

      {
        isOptionModalShown && <Modal
          width="500"
          height={window.innerHeight - 150}
          columns={columns}
          onChangeColumnOptions={onChangeColumnOptions}
          onClose={() => setIsOptionModalShown(false)}
          component={Options}
        />
      }

      {
        isExportModalShown && <Modal
          width="325"
          height="400"
          rows={rows}
          columns={columns}
          onExport={onExport}
          onClose={() => setIsExportModalShown(false)}
          component={ExportFile}
        />
      }

      {
        isImportModalShown && <Modal
          width="600"
          height={window.innerHeight - 150}
          rows={rows}
          columns={columns}
          onImport={onImport}
          onClose={() => setIsImportModalShown(false)}
          component={ImportFile}
        />
      }

      {
        isFormModalShown && <Modal
          width="600"
          height={window.innerHeight - 150}
          title={formValue ? "Editar dato" : "Agregar dato"}
          structure={columns}
          initialRow={formValue}
          onSubmit={onSubmitForm}
          onClose={() => {
            setIsFormModalShown(false);
            setFormValue(null);
          }}
          component={Form}
        />
      
      }

    </div>
  )
}

export default TableView;