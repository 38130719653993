import React from "react";
import { Link } from "react-router-dom";

const SidenavView = ({ flow, showTabs, onOpenEdit, onOpenTemplates, onOpenShare }) => (
  <>

    <header id="header" className="navbar navbar-expand-lg navbar-fixed navbar-height navbar-flush navbar-container navbar-bordered">
      <div className="navbar-nav-wrap">
        <div className="navbar-brand-wrapper">
        </div>

        <div className="navbar-nav-wrap-content-left">
          <button type="button" className="js-navbar-vertical-aside-toggle-invoker close mr-3">
            <i class="tio-first-page navbar-vertical-aside-toggle-short-align" data-toggle="tooltip"
              data-placement="right" title="" data-original-title="Collapse"></i>
            <i class="tio-last-page navbar-vertical-aside-toggle-full-align"
              // eslint-disable-next-line max-len
              data-template="<div class=&quot;tooltip d-none d-sm-block&quot; role=&quot;tooltip&quot;><div class=&quot;arrow&quot;></div><div class=&quot;tooltip-inner&quot;></div></div>"
              data-toggle="tooltip" data-placement="right" title="" data-original-title="Expand"></i>
          </button>

          {showTabs && (
            <li className="nav-item d-none d-sm-inline-block mr-3">
              <button className="btn btn-sm btn-lighter"
                onClick={() => window.parent.location.href = `/app/flow/${flow.id}/flow`}>
                <i class="fas fa-reply mr-2"></i> Ir a proceso
              </button>
            </li>
          )}

        </div>

        <div className="navbar-nav-wrap-content-right">
          <ul className="navbar-nav align-item-center flex-row" id="presentation-ctas">

            <li className="nav-item d-none d-sm-inline-block">
              <button id="edit_presentation_button" className="btn btn-sm btn-lighter" onClick={onOpenTemplates}>
                <i class="fas fa-th-large mr-2"></i> Ajustar plantilla
              </button>
            </li>

            <li className="nav-item d-none d-sm-inline-block mr-3">
              <button id="edit_presentation_button" className="btn btn-sm btn-lighter" onClick={onOpenEdit}>
                <i class="fas fa-pencil-ruler mr-2"></i> Personalizar diseño
              </button>
            </li>

            <li className="nav-item d-none d-sm-inline-block">
              <button id="edit_presentation_button" className="btn btn-sm text-white" 
                style={{ background: "#f0b000", paddingRight: "0.85rem", paddingLeft: "0.85rem" }} onClick={(onOpenShare)}>
                Compartir
              </button>
            </li>

            <li class="nav-item" style={{ visibility: "hidden" }}>
              <button type="button" class="navbar-toggler btn btn-ghost-secondary rounded-circle" aria-label="Toggle navigation"
                aria-expanded="false" aria-controls="navbarNavMenu" data-toggle="collapse" data-target="#navbarNavMenu">
                <i class="tio-menu-hamburger"></i>
              </button>
            </li>

          </ul>
        </div>
      </div>
    </header>

    <aside className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-dark 
      navbar-expand-xl navbar-bordered navbar-vertical-aside-initialized">
      <div className="navbar-vertical-container">
        <div className="navbar-vertical-footer-offset">

          <div className="navbar-brand-wrapper justify-content-between">
            <a href="#" className="navbar-brand">
              <img className="navbar-brand-logo" alt="Logo" src="/theme/svg/logos/logo-white.svg" />
              <img className="navbar-brand-logo-mini" alt="Logo" src="/theme/svg/logos/logo-short-white.svg" />
            </a>

            <button type="button" class="js-navbar-vertical-aside-toggle-invoker navbar-vertical-aside-toggle btn btn-icon btn-xs btn-ghost-dark">
              <i class="tio-clear tio-lg"></i>
            </button>
          </div>

          <div className="navbar-vertical-content">
            <ul className="navbar-nav navbar-nav-lg nav-tabs">

              <li className="nav-item">
                <small class="nav-subtitle" title="Secciones">Secciones</small>
                <small class="tio-more-horizontal nav-subtitle-replacer"></small>
              </li>

              {(flow.presentationSettings?.sections ?? []).map((section, idx) => (
                <li className="nav-item" key={`section_${idx}`}>
                  <Link to={`/section/${idx}`} className="js-nav-tooltip-link nav-link">
                    <i class="fa-solid fa-minus nav-icon"></i>
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                      {section.title}
                    </span>
                  </Link>
                </li>
              ))}

            </ul>
          </div>

          <div className="navbar-vertical-footer">
            <div className="navbar-vertical-footer-list">

            </div>
          </div>

        </div>
      </div>
    </aside>

    <div class="js-navbar-vertical-aside-toggle-invoker navbar-vertical-aside-mobile-overlay"></div>

  </>
);

export default SidenavView;