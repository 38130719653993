import React from "react";
import RegionSelect from "react-region-select";
import ProcessCapture from "components/misc/apps/rpa/generator/ProcessCapture";

const RegionPickerView = ({

  formikRef,
  type,
  capture,
  cropImage,
  edit,
  regionPickerMode,

  regions,

  parameters,
  response,
  responseStatus,
  loading,

  handleAddZoom,
  handleSubZoom,
  handleChangeRegion,
  handleSubmit,
  callParamGenerator,
  getTransformedRegions,

}) => (
  <div>
    <div style={{ overflowY: "auto", height: "100%" }}>

      <div className="d-flex my-2">
        <button type="button" className="btn btn-outline-danger" onClick={handleSubZoom}>
          <i className="fas fa-search-minus"></i>
        </button>
        <button type="button" className="btn btn-outline-success ml-2" onClick={handleAddZoom}>
          <i className="fas fa-search-plus"></i>
        </button>
      </div>

      <RegionSelect
        maxRegions={regionPickerMode == "REGION" ? 1 : 50}
        regions={regions}
        regionStyle={{ border: "5px dashed #5dc1b9" }}
        constraint
        style={{ width: "100%" }}
        onChange={handleChangeRegion}
      >
        <img
          src={cropImage}
          className="img-fluid w-100"
          alt="Captura"
          style={{
            border: "1px solid #ccc",
            cursor: regions.length > 0 && regions[regions.length - 1].isChanging ? "nwse-resize" : "pointer"
          }}
        />
      </RegionSelect>

      {
        console.log(regions)
      }

      {
        regionPickerMode == "REGION"
          ? <div>
              <ProcessCapture
                initialData={parameters}
                onSubmit={async () => callParamGenerator(await getTransformedRegions(regions))}
                response={response}
                formikRef={formikRef}
                isEditing={false}
                regions={regions}
                loading={loading}
              />
            </div>
          : null
      }

      {
        responseStatus == "ERROR" && (
          <div className="alert alert-danger mt-3" role="alert">
            No ha sido posible identificar la región seleccionada. Por favor, intente nuevamente.
          </div>
        )
      }

      {
        (
          (
            regionPickerMode == "REGION" &&
            responseStatus == "OK" &&
            !edit &&
            !loading
          ) || regionPickerMode == "HIDE"
        ) && (
          <button type="button" className="btn btn-sm btn-primary mt-2 w-100
          " onClick={() => handleSubmit(regions)}>
            Continuar
          </button>
        )
        }

    </div>
  </div>
);

export default RegionPickerView;