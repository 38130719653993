import React, { useEffect, useState } from "react";
import { useQuery } from "seed/gql";
import View from "components/misc/templates/Flows.view";


function Flows({
  Flow,
  manualFlows,
  flowsQuery = "",
  style = {},
  arrowsStyle = {},
  arrowContainerStyle = {},
  scrollAmount = 500
}) {

  const [activeFlow, setActiveFlow] = useState(null);

  const reqFlows = useQuery(`{
    flows {
      name
      createdAt
      description
      isTemplate
      templateName
      templateSettings
      templateCategories {
        name
        description
        uiSettings
      }
    }
  }`, flowsQuery)

  useEffect(() => {
    reqFlows.refetch();
  }, [flowsQuery])

  const onSelectFlow = (flowId) => {
    setActiveFlow(flowId)
  }

  const isTemplateFlowsLoading = reqFlows.loading;
  const flows = manualFlows || reqFlows.data?.flows;

  return (
    <View
      Flow={Flow}
      flows={flows}
      activeFlow={activeFlow}
      scrollAmount={scrollAmount}
      style={style}
      arrowsStyle={arrowsStyle}
      arrowContainerStyle={arrowContainerStyle}
      isTemplateFlowsLoading={isTemplateFlowsLoading}
      onSelectFlow={onSelectFlow}
    />
  );
}

Flows.propTypes = {};

export default Flows;