import React from "react";
import ExecutionDetails from "components/start/executions/ExecutionDetails";


const ExecutionsListView = ({
  executions,
  totalPages,
  pageNum,
  placeholder,
  setPageNum,
  onResumeExecution,
  onCancelExecution
}) => (
  <div>
    {executions.map((execution, i) => (
      <div className="bg-white" key={i} >
        <ExecutionDetails
          idx={i}
          execution={execution}
          onResumeExecution={onResumeExecution}
          onCancelExecution={onCancelExecution}
        />
      </div>
    ))
    }

    {totalPages > 1 && (
      <nav class="mt-3 float-right">
        <ul class="pagination d-flex align-items-center">

          <li
            onClick={() => setPageNum(pageNum - 1)}
            class="page-item"
            style={{ display: pageNum > 1 ? "block" : "none" }}
          >
            <a class="page-link" aria-label="Prev">
              <span aria-hidden="true">«</span><span class="sr-only">Prev</span>
            </a>
          </li>

          {
            Array(totalPages).fill(0).map((ignore, idx) =>
              <li
                key={idx}
                onClick={() => setPageNum(idx + 1)}
                class={"page-item " + (idx == pageNum - 1 ? "page-active" : "")}
              >
                <a
                  class={`btn ${idx == pageNum - 1 ? "btn-primary" : "btn-soft-secondary"} px-3 py-2`}
                  role="button"
                >
                  {idx + 1}
                </a>
              </li>
            )
          }

          <li
            onClick={() => setPageNum(pageNum + 1)}
            class="page-item"
            style={{ display: pageNum <= totalPages - 1 ? "block" : "none" }}
          >
            <a class="page-link" aria-label="Sig">
              <span aria-hidden="true">»</span><span class="sr-only">Sig</span>
            </a>
          </li>

        </ul>
      </nav>
    )}

    {executions.length === 0 && (
      <div className="h-100">
        <div className="d-flex justify-content-start align-items-center h-100 ml-1">
          <span style={{ color: "#b0b2b2", fontSize: "0.95em" }}>{placeholder}</span>
        </div>
      </div>
    )}
  </div>
);

ExecutionsListView.propTypes = {};

export default ExecutionsListView;