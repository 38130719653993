const FLOW_QUESTIONS = {
  "initial_00": {
    "next": [
      {
        "id": "events_00", /* TODO TEMP ->  ORIGINAL excel_00 */
        "condition": (answers, captures, index) =>
          (captures[index]?.window_name ?? "").toLowerCase() == "excel",
      },
      {
        "id": "explorer_00",
        "condition": (answers, captures, index) => {
          let windoWidth = captures[index]?.window_xf - captures[index]?.window_xi
          let windowHeight = captures[index]?.window_yf - captures[index]?.window_yi
          return (
            (captures[index]?.window_name ?? "").toLowerCase() == "explorer" || 
            (captures[index]?.window_name ?? "").toLowerCase() == "#32770" 
          ) && (windoWidth < captures[index]?.screen_width || windowHeight < captures[index]?.screen_height);
        },
      },
      {
        "id": "events_00",
        "condition": (answers, captures, index) => true
      }
    ]
  }
};

export default FLOW_QUESTIONS;