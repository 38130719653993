import React from "react";
import { Formik, Field, Form } from "formik";
import RegionSelect from "react-region-select";
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const phases = [
  "crop_by_region",
  "match_by_sample",
  "filter_by_text",
  "expand_matches_by_text",
  "filter_by_image_similarity",
  "segment_by_canny",
  "filter_by_embedding_similarity"
]

const apps = ["excel", "word", "outlook", "other"]

const getPhaseName = (phase) => {
  if (phase == "crop_by_region") return "Crop by region";
  if (phase == "match_by_sample") return "Match by sample";
  if (phase == "filter_by_text") return "Filter by text";
  if (phase == "expand_matches_by_text") return "Expand matches by text";
  if (phase == "filter_by_image_similarity") return "Filter by image similarity";
  if (phase == "segment_by_canny") return "Segment by canny";
  if (phase == "filter_by_embedding_similarity") return "Filter by embedding similarity";
  return "";
}

const getAppName = (app) => {
  if (app == "excel") return "Microsoft Excel";
  if (app == "word") return "Microsoft  Word";
  if (app == "outlook") return "Outlook";
  if (app == "other") return "Otra";
  return "";
}

const helpMessages = [
	{
		name: "sample_url",
		message: "Ruta de la imagen de muestra, esta puede ser una imagen en línea o la ruta de manera local"
	},
	{
		name: "text",
		message: "Texto a encontrar en la imagen"
	},
	{
		name: "region",
		message: "Región rectangular para encontrar en la imagen, referente a la ubicación aproximada donde puede estar el objeto a encontrar"
	},
	{
		name: "phases",
		message: (
			<div>
				Fases que emplea el algoritmo para encontrar la muestra en la imagen, dichas fases se ejecutan de forma consecutiva
				<ul>
					<li><b>Crop by region: </b>Recorta la imagen de acuerdo con los parámetros de región</li>
					<li><b>Match by sample: </b>Obtiene los elementos que se asemejen a la muestra</li>
					<li><b>Filter by text: </b>Filtra las coincidencias obtenidas según el parámetro de texto a encontrar</li>
					<li><b>Expand matches by text: </b>Aumenta la región de coincidencia para incluir posible texto cortado</li>
					<li><b>Filter by image similarity: </b>Filtra las coincidencias según su parecido con la muestra</li>
				</ul>
			</div>

		)
	},
	{
		name: "process_different_aspects",
		message: "Ejecutar el algoritmo usando diferentes aspectos de la muestra"
	},
	{
		name: "match_by_sample_threshold",
		message: "Umbral para la conincidencia con la muestra, mientras mas alto sea el valor se bucará mayor coincidencia"
	},
	{
		name: "filter_by_text_threshold",
		message: "Umbral para la conincidencia con el texto, mientras mas alto sea el valor se bucará mayor coincidencia"
	},
	{
		name: "expand_matches_by_text_delta",
		message: (<div>Valor en píxeles para configurar la fase <b>Expand matches by text</b></div>)
	},
	{
		name: "wait_after",
		message: "Tiempo de espera en segundos depues de ejecutar la acción"
	},
	{
		name: "offset_x",
		message: "Desplazamiento horizontal en píxeles de la acción desde el centro del elemento"
	},
	{
		name: "offset_y",
		message: "Desplazamiento vertical en píxeles de la acción desde el centro del elemento"
	},
	{
		name: "captures",
		message: "Capturas asociadas a esta acción con el próposito de identificar lo que se automatizará"
	},
	{
		name: "input",
		message: "Valor que se introducirá al campo, puede ser texto junto con numeros"
	},
	{
		name: "program_name",
		message: "Path del programa que se abrirá, se debe introducir el path del archivo exe de su computadora"
	},
	{
		name: "url_website",
		message: "Url del sitio web que se abrirá"
	},
	{
		name: "prompt_title",
		message: "Título de la ventana emergente del mensaje"
	},
	{
		name: "prompt_description",
		message: "Descripción del mensaje en la ventana emergente"
	},
	{
		name: "button_text",
		message: "Texto que aparecerá en el botón que ejecuta la acción"
	},
	{
		name: "type",
		message: (
			<div>
				Tipo de entrada a solicitar, las posibles opciones son:
				<ul>
					<li>Texto</li>
					<li>Lista desplegable</li>
					<li>Fecha</li>
				</ul>
			</div>
		)
	},
	{
		name: "options",
		message: "Cadenas de texo con la lista de las opciones a mostrar si el tipo es lista desplegable, " +
			"un ejemplo es ['One', 'Two', 'Three', ...]"
	},
	{
		name: "value_a",
		message: "Primer valor que aparecerá en la condición (lado izquierdo)"
	},
	{
		name: "value_b",
		message: "Segundo valor que aparecerá en la condición (lado derecho)"
	},
	{
		name: "condition",
		message: (
			<div>
				Operador de condición que evaluará la expresión <b>&apos;Valor A&apos; &apos;Condición&apos; &apos;Valor B&apos;</b>
			</div>
		)
	},
	{
		name: "raw_condition",
		message: "Condición en forma de texto, sirve para casos donde se desea crear condiciones mas complejas o con más flexibilidad"
	},
	{
		name: "collection",
		message: "Colección de objetos a iterar, debe estar en texto de la siguiente manera ['One', 'Two', 'Three', ...]"
	},
	{
		name: "inner_variable",
		message: "Nombre de la variable que se encontrará en el ciclo y con la cual podemos obtener el valor en cada repetición"
	},
	{
		name: "variable_name",
		message: "Nombre de la variable que almacenará el valor"
	},
	{
		name: "value",
		message: "Valor que será asignado a la variable"
	},
	{
		name: "seconds",
		message: "Tiempo de espera en segundos que pasará el programa detenido"
	},
	{
		name: "code",
		message: "Código para ser insertado en el script, debe respetar la sintaxis de robotframework"
	},
	{
		name: "scroll",
		message: "Realizar scroll al componente en caso de ser necesario"
	},
	{
		name: "scroll_direction",
		message: "Dirección del scroll a aplicar al componente, solo válido si scroll está activado"
	},
	{
		name: "apps",
		message: "La aplicación a la que corresponde la captura que se está procesando"
	},
	{
		name: "selection_type",
		message: "Tipo de input seleccionado en la imagen"
	},
	{
		name: "scroll_height",
		message: "Tmaño del scroll a realizar en pixeles"
	},
	{
		name: "stop_on_first",
		message: "Detener el scroll cuando encuentre una coincidencia"
	},
	{
		name: "command",
		message: "Comando a ingresar en el elemento seleccionado"
	},
	{
		name: "click_type",
		message: "Tipo de click que se ejecutará"
	},
	{
		name: "drag_type",
		message: "Tipo de drag que se ejecutará"
	},
	{
		name: "input_type",
		message: "Tipo de entrada que se va a ingresar en el elemento"
	},
];

const getHelpComponent = (message) => {
	return (
		<OverlayTrigger key="bottom" placement="bottom"
			overlay={
				<Popover id="popover-positioned-right">
					<Popover.Body className="p-3"
						style={{ backgroundColor: "#ddd", color: "black", borderRadius: "10px" }}>
						{message}
					</Popover.Body>
				</Popover>
			}
		>
			<i class="fas fa-info-circle ml-2"></i>
		</OverlayTrigger>
	)
}

const getHelpMessage = (name) => {
	return helpMessages.find(item => item.name == name)?.message ?? "";
}

const ProcessCaptureView = ({
  data,
  elements,
  formikRef,
  selectedImage,
  developerMode,
  regions,
  loading,
  onChangeAdvancedMode,
  onSubmit,
  onChangeValue
}) => (
  <div>

    <Formik
      initialValues={{}}
      innerRef={formikRef}
      onSubmit={onSubmit}>
      {({ values, setFieldValue }) => (
        <Form>

          {developerMode && (
            <>
              <div className="my-4">

                <a data-toggle="collapse" href="#advancedMode" role="button" aria-expanded="true"
                  style={{ fontSize: "1rem" }} onClick={onChangeAdvancedMode} aria-controls="advancedMode">
                  Opciones avanzadas
                </a>

                <div className="collapse mt-3 show" id="advancedMode">

                  <div className="mb-3">
                    <div class="form-group">
                      <div class="d-flex">
                        <label class="input-label" for="text">
                          Texto a encontrar {getHelpComponent(getHelpMessage("text"))}
                        </label>
                      </div>
                      <Field type="text" name="text" id="text" class="form-control"
                        value={data?.text} onChange={(event) => onChangeValue(event, "text")}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <div class="form-group">
                      <div class="d-flex">
                        <label class="input-label" for="region">
                          Region en la imagen en porcentaje [x1, x2, y1, y2]
                          {getHelpComponent(getHelpMessage("region"))}
                        </label>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <Field type="number" name="region.x1" class="form-control" placeholder="x1"
                            value={data?.region?.x1} onChange={(event) => onChangeValue(event, "region.x1")} />
                        </div>
                        <div className="col-md-3">
                          <Field type="number" name="region.x2" class="form-control" placeholder="x2"
                            value={data?.region?.x2} onChange={(event) => onChangeValue(event, "region.x2")} />
                        </div>
                        <div className="col-md-3">
                          <Field type="number" name="region.y1" class="form-control" placeholder="y1"
                            value={data?.region?.y1} onChange={(event) => onChangeValue(event, "region.y1")} />
                        </div>
                        <div className="col-md-3">
                          <Field type="number" name="region.y2" class="form-control" placeholder="y2"
                            value={data?.region?.y2} onChange={(event) => onChangeValue(event, "region.y2")} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div class="form-group">

                      <div class="d-flex">
                        <label class="input-label" for="phases">
                          Fases del algotirmo
                          {getHelpComponent(getHelpMessage("phases"))}
                        </label>
                      </div>

                      <Autocomplete
                        multiple
                        id="phases"
                        options={phases}
                        disableCloseOnSelect
                        defaultValue={data?.phases}
                        getOptionLabel={(option) => getPhaseName(option)}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {getPhaseName(option)}
                          </React.Fragment>
                        )}
                        onChange={(event, items) => onChangeValue(event, "phases", items)}
                        style={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} variant="outlined" label="" placeholder="" />}
                      />

                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="row form-group">

                      <div className={elements && elements?.length > 0 ? "col-md-6" : "col-md-12"}>

                        <div className="mb-3">
                          <div className="row form-group">

                            <div className="col-md-6">
                              <label class="input-label">
                                Umbral de coincidencia por sample
                                {getHelpComponent(getHelpMessage("match_by_sample_threshold"))}
                              </label>

                              <div className="row">
                                <div className="col-md-9">
                                  <Slider
                                    value={
                                      typeof data?.match_by_sample_threshold == 'number'
                                        ? data?.match_by_sample_threshold * 100
                                        : 0
                                    }
                                    onChange={(event, newValue) =>
                                      onChangeValue({ target: { value: newValue / 100 } }, "match_by_sample_threshold")
                                    }
                                    aria-labelledby="input-slider"
                                  />
                                </div>
                                <div className="col-md-3">
                                  <Input
                                    value={data?.match_by_sample_threshold}
                                    margin="dense"
                                    onChange={(event) => {
                                      onChangeValue(event, "match_by_sample_threshold");
                                    }}
                                    inputProps={{
                                      step: 0.1,
                                      min: 0,
                                      max: 1,
                                      type: 'number',
                                      'aria-labelledby': 'input-slider',
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">

                              <label class="input-label">
                                Umbral de coincidencia por texto
                                {getHelpComponent(getHelpMessage("filter_by_text_threshold"))}
                              </label>

                              <div className="row">
                                <div className="col-md-9">
                                  <Slider
                                    value={
                                      typeof data?.filter_by_text_threshold == 'number'
                                        ? data?.filter_by_text_threshold * 100
                                        : 0
                                    }
                                    onChange={(event, newValue) =>
                                      onChangeValue({ target: { value: newValue / 100 } }, "filter_by_text_threshold")
                                    }
                                    aria-labelledby="input-slider"
                                  />
                                </div>
                                <div className="col-md-3">
                                  <Input
                                    value={data?.filter_by_text_threshold}
                                    margin="dense"
                                    onChange={(event) => {
                                      onChangeValue(event, "filter_by_text_threshold");
                                    }}
                                    inputProps={{
                                      step: 0.1,
                                      min: 0,
                                      max: 1,
                                      type: 'number',
                                      'aria-labelledby': 'input-slider',
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>

                        <div className="mb-3">
                          <div className="row form-group">

                            <div className="col-md-6">
                              <div class="d-flex">
                                <label class="input-label" for="selection_type">
                                  Tipo de selección
                                  {getHelpComponent(getHelpMessage("selection_type"))}
                                </label>
                              </div>
                              <Field as="select" name="selection_type" id="selection_type"
                                class="form-control" value={data?.selection_type}
                                onChange={(event) => onChangeValue(event, "selection_type")}>
                                <option value="button_icon">Botón con ícono</option>
                                <option value="button_text">Botón con texto</option>
                                <option value="button_text_icon">Botón con ícono y texto</option>
                                <option value="input_checkbox">Checkbox</option>
                                <option value="input_file">Archivo</option>
                                <option value="input_radio">Radio button</option>
                                <option value="input_select">Select</option>
                                <option value="input_text">Text</option>
                                <option value="list_dynamic">Listas dinámicas</option>
                                <option value="list_static">Listas estáticas</option>
                              </Field>
                            </div>

                            <div className="col-md-6">
                              <div class="d-flex">
                                <label class="input-label" for="apps">
                                  Aplicación
                                  {getHelpComponent(getHelpMessage("apps"))}
                                </label>
                              </div>

                              <Autocomplete
                                id="apps"
                                options={apps}
                                disableCloseOnSelect
                                defaultValue={data?.app}
                                getOptionLabel={(option) => getAppName(option)}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {getAppName(option)}
                                  </React.Fragment>
                                )}
                                onChange={(event, items) => onChangeValue(event, "app", items)}
                                style={{ width: "100%" }}
                                renderInput={(params) =>
                                  <TextField {...params} variant="outlined" label="" placeholder="" />}
                              />
                            </div>

                          </div>
                        </div>

                        <div className="mb-3">
                          <div class="row form-group">

                            <div className="col-md-4">
                              <div class="d-flex">
                                <label class="input-label" for="expand_matches_by_text_delta">
                                  Expandir por delta de texto
                                  {getHelpComponent(getHelpMessage("expand_matches_by_text_delta"))}
                                </label>
                              </div>
                              <Field type="number" name="expand_matches_by_text_delta" id="expand_matches_by_text_delta"
                                class="form-control" value={data?.expand_matches_by_text_delta}
                                onChange={(event) => onChangeValue(event, "expand_matches_by_text_delta")} />
                            </div>

                            <div className="col-md-4">
                              <div class="d-flex">
                                <label class="input-label" for="scroll_direction">
                                  Dirección del scroll
                                  {getHelpComponent(getHelpMessage("scroll_direction"))}
                                </label>
                              </div>
                              <Field as="select" name="scroll_direction" id="scroll_direction"
                                class="form-control" value={data?.scroll_direction}
                                onChange={(event) => onChangeValue(event, "scroll_direction")}>
                                <option value="DOWN">Abajo</option>
                                <option value="UP">Arriba</option>
                              </Field>
                            </div>

                            <div className="col-md-4">
                              <div class="d-flex">
                                <label class="input-label" for="scroll_height">
                                  Tamaño para hacer scroll
                                  {getHelpComponent(getHelpMessage("scroll_height"))}
                                </label>
                              </div>
                              <Field type="number" name="scroll_height" id="scroll_height"
                                class="form-control" value={data?.scroll_height}
                                onChange={(event) => onChangeValue(event, "scroll_height")} />
                            </div>

                          </div>
                        </div>

                        <div className="mb-3">
                          <div class="row form-group">

                            <div className="col-md-5">
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="process_different_aspects"
                                  checked={data?.process_different_aspects} onChange={(event) => {
                                    onChangeValue(event, "process_different_aspects");
                                  }} />
                                <label class="input-label custom-control-label" for="process_different_aspects">
                                  Procesar diferentes aspectos
                                  {getHelpComponent(getHelpMessage("process_different_aspects"))}
                                </label>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="scroll"
                                  checked={data?.scroll} onChange={(event) => {
                                    onChangeValue(event, "scroll");
                                  }} />
                                <label class="input-label custom-control-label" for="scroll">
                                  Scroll {getHelpComponent(getHelpMessage("scroll"))}
                                </label>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="stop_on_first"
                                  checked={data?.stop_on_first} onChange={(event) => {
                                    onChangeValue(event, "stop_on_first");
                                  }} />
                                <label class="input-label custom-control-label" for="stop_on_first">
                                  Detener scroll{getHelpComponent(getHelpMessage("stop_on_first"))}
                                </label>
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>

                      {elements && elements?.length > 0 && (
                        <div className="col-md-6">
                          <RegionSelect
                            regions={elements}
                            regionStyle={{ border: "4px solid #00ff00" }}
                            onChange={() => { }}
                          >
                            <img src={selectedImage}
                              style={{
                                cursor: elements?.length > 0 && elements[regions?.length - 1].isChanging ?
                                  "nwse-resize" : "pointer"
                              }}
                              width="100%" alt="Capture" />
                          </RegionSelect>

                          {elements?.length == 1 ?
                            (<span className="text-muted">{elements?.length} elemento identificado</span>) :
                            (<span className="text-muted">{elements?.length} elementos identificados</span>)}

                          <div className="d-flex justify-content-center mt-3">
                            <div style={{ width: "100%" }}>
                              <button type="button" className="btn btn-sm btn-block btn-success"
                                onClick={onSubmit} disabled={loading}>
                                {loading ? (
                                  <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                ) : (
                                  developerMode ? "Validar →" : "Guardar →"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                    </div>
                  </div>

                </div>
              </div>

            </>
          )}

          {regions?.length > 0 && (
            <div className="d-flex justify-content-center mt-1">
              <div style={{ width: "100%" }}>
                <button type="button" className="btn btn-sm btn-block btn-success"
                  onClick={onSubmit} disabled={loading}>
                  {loading ? (
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    developerMode ? "Validar →" : "Guardar →"
                  )}
                </button>
              </div>
            </div>
          )}

        </Form>
      )}
    </Formik>

  </div>
);

export default ProcessCaptureView;