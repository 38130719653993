import React, { useState } from "react";
import { useEdges, useNodes, useReactFlow } from 'reactflow';
import { useHistory } from 'react-router-dom';
import { useDetail, useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import { bodyContentMapper } from "components/flow/board/nodes/Node.lib";
import * as lib from "components/flow/board/nodes/Node.lib";
import * as executionLib from "components/flow/board/nodes/Node.lib.execution";
import View from "components/flow/board/nodes/Node.view";


function Node({ id, data }) {

  const nodes = useNodes();
  const edges = useEdges();
  const remoteId = data?.remoteId;
  const nodeInput = data.input_value;
  const nodeOutput = data.output_value;
  const actionId = data.actionId;
  const flowId = data?.flowId;
  const isIterable = data?.is_iterable ?? false;
  const isSelected = data?.is_selected ?? false;
  const onExecute = data.onExecute;
  const warnings = data?.warnings ?? [];
  const errors = data?.errors ?? [];
  const isRoot = lib.checkRoot(id, nodes, edges);
  const historyRouter = useHistory();
  const { setNodes } = useReactFlow();
  const [executionMessage, setExecutionMessage] = useState(null);
  const [executionPlaceholder, setExecutionPlaceholder] = useState(null);
  const [canNodeBeExecuted, setCanNodeBeExecuted] = useState(true);

  const reqAction = useDetail(`{
    action { 
      name
      type
      inputStructure
      outputStructure
      uiSettings
      app {
        uiSettings
      }
    }
  }`, actionId, {
    onCompleted: (data) => {
      const { action = {} } = data;
      const displayNodeOptions = action.uiSettings?.node ?? [];
      const formSettings = action.uiSettings.form;
      const placeholder = bodyContentMapper(displayNodeOptions, nodeInput, "execution_message", formSettings)
      if (placeholder == "" && action.type == "TRIGGER") {
        setExecutionPlaceholder("Evento")
        setExecutionMessage("Evento")
        return;
      }
      setExecutionPlaceholder(placeholder)
      setExecutionMessage(placeholder)
    }
  });

  const reqActions = useQuery(`{
    actions {
      name
      type
      inputStructure
      outputStructure
      uiSettings
      app { name }
    }
  }`)

  if (reqAction.loading || reqActions.loading) return <Loading size={27} />;

  const { action = {} } = reqAction.data;
  const { actions = [] } = reqActions.data;
  const app = action?.app ?? {};

  const onClickExecute = (e) => {
    const [canExecute, message] = executionLib.canExecuteNode(nodes, edges, actions, remoteId, flowId)
    setCanNodeBeExecuted(canExecute)
    if (message == "") setExecutionMessage(executionPlaceholder)
    else setExecutionMessage(message)

    if (canExecute && action?.type != "TRIGGER") {
      e.stopPropagation();
      onExecute();
    }
  }

  const onChangeStatusTrigger = () => {
    let actualNode = nodes.find((nd) => nd.id == id);
    actualNode.data.is_enabled = !actualNode.data.is_enabled;
    setNodes(nodes);
  }

  const onSelectNode = () => {
    setNodes((nodes) => {
      let actualNode = nodes.find((nd) => nd.id == id);
      if (actualNode.data.is_selected == null) {
        actualNode.data.is_selected = true;
      } else {
        actualNode.data.is_selected = !actualNode.data.is_selected;
      }
      return nodes;
    });
    data.onSelectNode()
  }

  const onEdit = () => {
    const node = nodes.find((nd) => nd.id == id);
    if (node) {
      const url = `/flow/edit/${node.remoteId}`
      historyRouter.replace(url);
    }
  }

  return (
    <View
      app={app}
      action={action}
      nodeId={id}
      nodeInput={nodeInput}
      nodeOutput={nodeOutput}
      nodeData={data}
      errors={errors}
      warnings={warnings}
      executionMessage={executionMessage}
      canNodeBeExecuted={canNodeBeExecuted}
      isRoot={isRoot}
      isIterable={isIterable}
      isSelected={isSelected}
      onClickExecute={onClickExecute}
      onEdit={onEdit}
      onDeleteNode={data.onDeleteNode}
      onSelectNode={onSelectNode}
      onChangeStatusTrigger={onChangeStatusTrigger}
    />
  );
}

export default Node;