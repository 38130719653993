import INITIAL from "components/misc/apps/rpa/generator/questions/000_initial";
import EVENTS from "components/misc/apps/rpa/generator/questions/010_events";
import MOUSE from "components/misc/apps/rpa/generator/questions/020_mouse";
import MOUSE_MULTIPLE from "components/misc/apps/rpa/generator/questions/021_mouse_multiple";
import TEXT from "components/misc/apps/rpa/generator/questions/030_text";
import TEXT_MULTIPLE from "components/misc/apps/rpa/generator/questions/031_text_multiple";
import KEY from "components/misc/apps/rpa/generator/questions/040_key";
import EXCEL from "components/misc/apps/rpa/generator/questions/100_excel";
import EXPLORER from "components/misc/apps/rpa/generator/questions/200_explorer";

const DEFINITION_TYPES = {
    OPEN_PROGRAM: "Abrir programa",
    DRAG_RCLICK: "Arrastrar y click derecho",
    LCLICK_TEXT: "Click izquierdo y texto",
    LCLICK_KEY: "Click izquierdo y tecla",
    LCLICK_LCLICK_KEY: "Doble click izquierdo",
    WHEEL: "Scroll",
    ITERATE_DRAG: "Iteración con selección",
    ITERATE_CLICK: "Iteración con click",
    ITERATE_IF_DRAG: "Iteración con selección y condición",
    ITERATE_IF_CLICK: "Iteración con click y condición",
    CLICK: "Click",
    DRAG: "Arrastrar",
    PROMPT: "Insertar texto manualmente",
    TEXT: "Insertar texto",
    KEY: "Presionar tecla",
    READ_TEXT: "Leer texto",
    SELECT_FILE: "Seleccionar archivo",
    CREATE_FILE: "Crear archivo",
    COPY_FILE: "Copiar archivo",
    DELETE_FILE: "Eliminar archivo",
    MOVE_FILE: "Mover archivo",
    OTHER_FILE: "Otra acción con archivo",
};

const FLOW_QUESTIONS = {
  ...INITIAL,
  ...EVENTS,
  ...MOUSE,
  ...MOUSE_MULTIPLE,
  ...TEXT,
  ...TEXT_MULTIPLE,
  ...KEY,
  ...EXCEL,
  ...EXPLORER,
};

export { FLOW_QUESTIONS, DEFINITION_TYPES };