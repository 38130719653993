import React from "react";
import { useDetail, useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/start/Panel.view";

function Panel({ selectedFolder, setSelectedFolder }) {

  const userId = sessionStorage.getItem("id");

  const reqUser = useDetail(`{
    user {
      firstName
      lastName
      email
      license {
        name
      }
    }
  }`, userId);

  const reqFolders = useQuery(`{
    folders {
      name
    }
  }`, `user.id=${userId}`, { orderBy: "name", cacheQuery: true });

  const onClickLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace("/logout");
  }

  if (reqUser.loading || reqFolders.loading || reqFolders.loading) return <EmptyView />;
  if (reqUser.error || reqFolders.error) return "Error";

  const user = reqUser?.data?.user ?? {};
  const folders = reqFolders?.data?.folders ? [...reqFolders.data.folders] : [];

  folders.sort((a, b) => {
    if (a.name.toLowerCase() == "default") return -1;
    else if (b.name.toLowerCase() == "default") return 1;
    return a.name.localeCompare(b.name);
  });

  return (
    <View
      user={user}
      folders={folders}
      selectedFolder={selectedFolder}
      setSelectedFolder={setSelectedFolder}
      onClickLogout={onClickLogout}
    />
  );
}

const EmptyView = () =>
  <div>
    <div class="card">
      <div class="card-body" style={{ padding: "7.24rem 0" }}>
        <Loading />
      </div>
    </div>
    <div className="card mt-3 d-none d-md-block">
      <div class="card-body" style={{ padding: "3.03rem 0" }}></div>
    </div>
  </div>

export default Panel;