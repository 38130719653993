const getFlowRule = (answers, captures, index) => {

  let flowRules = [
    {
      "name": "ITERATE_ALL",
      "label": (answers, captures, index) => 
        "¿Desea iterar todos los elementos?",
      "component": "CHECKBOX",
    },
  ];

  if(answers?.ITERATE_ALL == false) {
    flowRules.push(
      {
        "name": "RULE",
        "label": (answers, captures, index) => 
          "",
        "component": "RULE",
        "options": [
          {
            "label": "Incluya",
            "value": "IN"
          },
          {
            "label": "Sea mayor que",
            "value": "GT"
          },
          {
            "label": "Sea menor que",
            "value": "LT"
          },
          {
            "label": "Sea diferente a",
            "value": "NEQ"
          },
        ]
      }
    );
  }

  return flowRules;

}

export {
  getFlowRule
}