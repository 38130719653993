import * as parser from "components/flow/Controller.lib.parser";
import * as board from "components/flow/Controller.lib.board";


const onSaveBoard = (nodes, edges, flowId, activePage, callAutoSave) => {

	const onlyNodes = nodes.filter((item) => item.isAnnotation == false);
	const onlyAnnotations = nodes.filter((item) => item.isAnnotation == true);

	const parsedNodes = parser.parseNodesUiToDatabase(onlyNodes);
	const parsedEdges = parser.parseEdgesUiToDatabase(edges);
	const parsedAnnotations = parser.parseAnnotationsUiToDatabase(onlyAnnotations);

	callAutoSave({
		nodes: parsedNodes,
		edges: parsedEdges,
		annotations: parsedAnnotations,
		flow_id: flowId,
		page_id: activePage
	});
}

const onSaveUiBoard = (nodes, callSaveUi) => {

	const onlyNodes = nodes.filter((item) => item.isAnnotation == false);
	const onlyAnnotations = nodes.filter((item) => item.isAnnotation == true);

	const parsedNodes = parser.parseNodesUiToDatabase(onlyNodes);
	const parsedAnnotations = parser.parseAnnotationsUiToDatabase(onlyAnnotations);

	callSaveUi({
		nodes: parsedNodes,
		annotations: parsedAnnotations
	});
}

const onAutoSave = (data, selectedNodes, setNodes, setEdges, onExecuteNode, onChangeTriggerStatus, onSelectNode, onDeleteNode) => {

	const dbNodes = data?.nodes ?? [];
	const dbEdges = data?.edges ?? [];
	const dbAnnotations = data?.annotations ?? [];

	const nodes = parser.parseNodesDatabaseToUi(dbNodes);
	const newEdges = parser.parseEdgesDatabaseToUi(dbEdges);
	const annotations = parser.parseAnnotationsDatabaseToUi(dbAnnotations);

	const newNodes = [], newAnnotations = [];
	for (const node of nodes) {
		const newNode = board.setNodeFunctions(node, onExecuteNode, onChangeTriggerStatus, onSelectNode, onDeleteNode)
		newNode.data.is_selected = selectedNodes.includes(newNode.id);
		newNodes.push(newNode);
	}

	for (const annotation of annotations) {
		const newAnnotation = board.setAnnotationFunctions(annotation, onSelectNode, onDeleteNode)
		newAnnotation.data.is_selected = selectedNodes.includes(annotation.id);
		newAnnotations.push(newAnnotation);
	}

	setNodes([...newNodes, ...newAnnotations]);
	setEdges(newEdges)
}

const onUpdateErrorWarnings = (nodes, data, setNodes) => {
	let newNodes = [...nodes];
	for (const item of data) {
		const id = item["id"];
		const warnings = item["warnings"];
		const errors = item["errors"];

		const index = newNodes.findIndex((nd) => nd.remoteId == id);
		if (index != -1) {
			newNodes[index].data.warnings = warnings;
			newNodes[index].data.errors = errors;
		}
	}
	setNodes(newNodes);
}


export { onSaveBoard, onSaveUiBoard, onAutoSave, onUpdateErrorWarnings } 