import React from "react";
import { bodyContentMapper } from "components/flow/board/nodes/Node.lib";

function DropdownDetails({ isIterable = true, action, nodeInput, onEdit, onDelete }) {

    const displayDetailOptions = action.uiSettings?.detail ?? [];
    const displayNodeOptions = action.uiSettings?.node ?? [];
    const formSettings = action.uiSettings.form;
    const fields = formSettings?.fields ?? [];

    const nodeValues = nodeInput;
    const title = bodyContentMapper(displayNodeOptions, nodeValues, "title", formSettings);
    const body = bodyContentMapper(displayDetailOptions, nodeValues, "body", formSettings);


    return (
        <div class="dropdown-menu dropdown-card">
            <div className="card-body px-2 pt-3 pb-2 text-center">
                <h6 className="px-2 mb-0" style={{ borderBottom: "0.1rem" }}>
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                </h6>
                <p className="px-2 mb-0 mt-2" style={{ fontSize: "0.7rem" }}>
                    <div dangerouslySetInnerHTML={{ __html: body }} />
                </p>
                {isIterable && (
                    <span class="badge badge-pill badge-light text-center" style={{ border: "1px solid #c0c0c0" }}>
                        Iterable
                    </span>
                )}
                <div class="dropdown-divider"></div>
                <div className="d-flex">
                    {fields.length > 0 && (
                        <a class="dropdown-item" style={{ fontSize: "0.7rem", cursor: "pointer" }} onClick={onEdit}>
                            <i class="fas fa-pen mr-2"></i>Editar
                        </a>
                    )}
                    <a class="dropdown-item" style={{ fontSize: "0.7rem", cursor: "pointer" }} onClick={onDelete}>
                        <i class="fas fa-trash mr-2"></i>Eliminar
                    </a>
                </div>
            </div>
        </div>
    );

}

export default DropdownDetails;
