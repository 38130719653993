import React, { useEffect, useState, useRef } from "react";
import { useQuery, useDetail } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import { useWS } from "seed/ws";
import View from "components/flow/toolbox/Toolbox.view";


function Toolbox({ flowId, nodes, isAnonymous, onClickNode }) {

  const userId = sessionStorage.getItem('id');
  const prevNodesLength = useRef(nodes.length);
  const [suggestions, setSuggestions] = useState([]);
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false);
  const [actionSearch, setActionSearch] = useState("")
  const [actionFilters, setActionFilters] = useState([])
  const isDeveloper = sessionStorage.getItem("developer_mode") == "true";

  const reqUser = useDetail(`{
    user {
      firstName
      lastName
      email
      license {
        name
      }
    }
  }`, userId);

  const queryApps = `{
    apps {
      name
      uiSettings
      setupSettings
      desktopOnly
      actions {
        name
        type
        inputStructure
        outputStructure
        uiSettings
        relevance
        isHidden
        underDev
        developerOnly
      }
    }
  }`

  const reqFlow = useDetail(`{ flow { name } }`, flowId)

  const reqApps = useQuery(queryApps,
    `appSettingses.enabled = true AND appSettingses.flow.id = ${flowId} ${!isDeveloper ? " AND developer_only=false AND under_dev=false" : ""}`, {
    orderBy: "relevance"
  });



  const [callSuggestions, reqSuggestions] = usePost('/flows/get_action_suggestions', {
    onCompleted: (data) => {
      setIsSuggestionsLoading(false);
      setSuggestions(data);
    },
    onError: (error) => {
      console.error(error);
    }
  })

  useWS(`FLOW_STATUS_${flowId}`, {
    onMessageReceived: (message) => callSuggestions({ flow_id: flowId })
  });

  useEffect(() => {
    if (nodes.length != prevNodesLength.current) {
      setIsSuggestionsLoading(true);
      prevNodesLength.current = nodes.length;
    }
  }, [nodes])

  useEffect(() => {
    setIsSuggestionsLoading(true);
    callSuggestions({ flow_id: flowId });
  }, [])

  const onDragStart = (event, type, actionId) => {
    const data = {
      "type": type,
      "actionId": actionId
    };
    event.dataTransfer.setData('application/reactflow', JSON.stringify(data));
    event.dataTransfer.effectAllowed = 'move';
  };

  const onClickAction = (category) => {
    onClickNode(category)
  };

  const onSearchActions = (search) => {
    setActionSearch(search)
    setActionFilters([])
  }

  const onClickSearchRemove = () => {
    setActionSearch("")
    setActionFilters([])
    document.querySelector(".search-input").focus()
  }

  const onClickFilterTab = (value) => {
    const filterIndex = actionFilters.indexOf(value)
    if (filterIndex == -1)
      setActionFilters([value])
    else onClickFilterRemove(value)
    setActionSearch("")
  }

  const onClickFilterRemove = (value) => {
    const filterIndex = actionFilters.indexOf(value)
    if (filterIndex != -1) {
      let tempVal = [...actionFilters]
      tempVal.splice(filterIndex, 1)
      setActionFilters(tempVal)
    }
  }

  if (reqApps.loading || reqFlow.loading || reqUser.loading) return <EmptyView />;
  if (reqApps.error || reqFlow.error || reqUser.error) return "Error";

  let apps = reqApps.data.apps;
  let flow = reqFlow.data.flow
  let user = reqUser.data.user;

  const filterTabs = [
    { name: "initials", label: "Iniciales" },
    { name: "events", label: "Eventos" },
    { name: "updates", label: "Actualizaciones" },
    { name: "notifications", label: "Notificaciones" },
    { name: "ai", label: "IA" },    
  ]

  return (
    <View
      apps={apps}
      flow={flow}
      user={user}
      filterTabs={filterTabs}
      suggestions={suggestions}
      actionSearch={actionSearch}
      actionFilters={actionFilters}
      isAnonymous={isAnonymous}
      isSuggestionsLoading={isSuggestionsLoading}
      reqApps={reqApps}
      onSearchActions={onSearchActions}
      onClickSearchRemove={onClickSearchRemove}
      onDragStart={onDragStart}
      onClickAction={onClickAction}
      onClickFilterTab={onClickFilterTab}
      onClickFilterRemove={onClickFilterRemove}
    />
  );

}

function EmptyView() {

  return (
    <div style={{ width: "21.2em" }}>
      <Loading />
    </div>
  );
}

export default Toolbox;