import React, { useEffect } from "react";
import swal from "sweetalert";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import View from "components/misc/auth/VerifyEmail.view";

function VerifyEmail(props) {
  const { token } = props.match.params;
  if (!token) window.location.replace("/login")

  const [callVerify, reqVerify] = usePost("/users/registry_verify", {
    includeAuth: false,
  });

  const [callGenerate, reqGenerate] = usePost("/users/registry_generate", {
    onCompleted: () => {
      swal("Verificación enviada",
        "Hemos enviado a tu correo el proceso para verificar tu cuenta, asegurate de verificarlo en los próximos 20 minutos",
        "success")
    },
    includeAuth: false,
  });

  useEffect(() => {
    callVerify({ token: token });
  }, []);

  if (reqVerify.loading) return <Loading />;
  const verifyError = reqVerify.error;
  const onClickGenerate = () => callGenerate({ token: token });

  return <View verifyError={verifyError} onClickGenerate={onClickGenerate} />;
}

export default VerifyEmail;