import React from "react";
import swal from "sweetalert";
import { usePost } from "seed/api";
import View from "components/flow/forms/contact_us/Contact.view";

function Contact({closeModal = () => {}}) {

  const userId = sessionStorage.getItem("id");

  const [callContact, reqContact] = usePost('/flows/contact', {
    onCompleted: (data) => {
      swal("¡Gracias por contactarnos!", "En unos momentos más nos pondremos en contacto contigo", "success")
      .then((response) => {
        if (response) closeModal()
    })
    },
    onError: () => {
      swal("Ha ocurrido un error", "Por favor intenta de nuevo más tarde", "error")
    }
  })

  const onSubmit = (values) => {
    callContact({
      user_id: userId,
      ...values
    })
  }

  return <View onSubmit={onSubmit} />;
}

export default Contact;