import React from "react";
import { useHistory } from 'react-router-dom';
import { useNodes } from 'reactflow';
import View from "components/flow/board/nodes/Pipe.view";

function Pipe({ id, data }) {

  const nodes = useNodes();
  const input = data?.input_value ?? [];
  const title = input.find((inp) => inp.name == "title")?.value ?? "";
  const orientation = input.find((inp) => inp.name == "orientation")?.value ?? "";
  const historyRouter = useHistory();

  const onEditAnnotation = () => {
    const annotation = nodes.find((nd) => nd.id == id);
    if (annotation) {
      const url = `/flow/edit_annotation/${annotation.remoteId}`
      historyRouter.replace(url);
    }
    return nodes;
  }

  return (
    <View
      title={title}
      orientation={orientation}
      onEditAnnotation={onEditAnnotation}
    />
  );
}

export default Pipe;