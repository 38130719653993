import React from "react";
import { Field } from "formik";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const Picker = ({
  flowQuestion,
  values,
  flowCaptures,
  questionIndex,
  currentIndex,
  showRegionPicker,
  handleShowPicker
}) => (
  <div key={questionIndex} className="my-3">
    <div class="d-flex align-items-center">
      <label class="input-label" for="routine_name">
        {flowQuestion.label(values, flowCaptures, currentIndex)}
      </label>

      <OverlayTrigger 
        key="bottom" 
        placement="bottom"
        overlay={
          <Popover id="popover-positioned-right">
            <Popover.Body className="p-3"
              style={{ 
                backgroundColor: "#fff", 
                color: "black", 
                borderRadius: "10px", 
                width: "750px", 
                boxShadow: "-4px 4px 10px rgba(0,0,0,0.4)" 
              }}
            >
              <div className="py-2"><i>Arrastra la imagen y selecciona una región</i></div>
              Ejemplos:
              <div className="d-flex row mt-2">
                <div className="col-4">
                  <label style={{fontWeight: "bold"}}>Botón</label>
                  <img style={{width: "220px"}} src="/resources/images/picker_button.png" alt="Botón" />
                </div>
                <div className="col-4">
                  <label style={{fontWeight: "bold"}}>Campo de texto</label>
                  <img style={{width: "220px"}} src="/resources/images/picker_input.png" alt="Campo de texto" />
                </div>
                <div className="col-4">
                  <label style={{fontWeight: "bold"}}>Item de lista</label>
                  <img style={{width: "220px"}} src="/resources/images/picker_item.png" alt="Item de lista" />
                </div>
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <i className="fas fa-info-circle ml-2 mr-3" style={{fontSize: "1.25em", cursor: "pointer"}}></i>
      </OverlayTrigger>
  
      { 
        !showRegionPicker ?
          <button
            type="button"
            className={`btn btn-sm py-1 px-3 btn-lighter ${values[flowQuestion.name] ? "selected" : ""}`}
            onClick={() => handleShowPicker(flowQuestion.name)}
          >
            {values[flowQuestion.name] ? 
            <span>Elemento seleccionado <small class="fas fa-pen ml-1" style={{ fontSize: "0.7rem" }}></small></span> : 
            <span>Seleccionar →</span>}
            
          </button> 
        : null
      }

      <Field
          type="text"
          style={{
            width: "0px",
            height: "0px",
            opacity: "0",
            position: "absolute",
            right: "0px"
          }}
          required={!values.skip}
          name={flowQuestion.name}
        />
        
    </div>
  </div>
);

export default Picker;