import React, { useEffect, useState } from "react";
import View from "components/misc/apps/rpa/designer/SamplePicker.view";
import { addListener, postMessage, topMostWindow, showBlackScreen, closeWindow, setResponse, restoreWindow } from "components/util/win";
import { cropSample } from "components/util/helpers/image";

const processScreenshot = async (screenshot, region) => {

  return new Promise((resolve, reject) => {

    if (!region) return screenshot;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = screenshot;

    img.onload = () => {

      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      // Draw outer region black opacity

      ctx.fillStyle = "rgba(0, 0, 0, 0.7)";

      let x1 = parseFloat(region.x1) * img.width / 100;
      let x2 = parseFloat(region.x2) * img.width / 100;
      let y1 = parseFloat(region.y1) * img.height / 100;
      let y2 = parseFloat(region.y2) * img.height / 100;

      if (!x1) x1 = 0;
      if (!x2) x2 = img.width;
      if (!y1) y1 = 0;
      if (!y2) y2 = img.height;

      // Draw top region
      ctx.fillRect(0, 0, canvas.width, y1);

      // Draw bottom region
      ctx.fillRect(0, y2, canvas.width, canvas.height - y2);

      // Draw left region
      ctx.fillRect(0, y1, x1, y2 - y1);

      // Draw right region
      ctx.fillRect(x2, y1, canvas.width - x2, y2 - y1);

      resolve(canvas.toDataURL("image/png"));

    }

  });


}
function SamplePicker() {

  const winParameters = JSON.parse(sessionStorage.getItem("parameters") || "{}");
  const params = winParameters?.parameters;
  const initialScreenshot = winParameters?.screenshot;
  const initialInput = winParameters?.input;
  const category = winParameters?.category;

  const [screenshot, setScreenshot] = useState(initialScreenshot.url);
  const [resultScreenshot, setResultScreenshot] = useState(null);
  const [regions, setRegions] = useState([]);
  const [parameters, setParameters] = useState(params);
  const [input, setInput] = useState(initialInput ?? {});
  const [loading, setLoading] = useState(false);
  const [pickerResponse, setPickerResponse] = useState(null);
  const [showResponseModal, setShowResponseModal] = useState(false);
  
  const onChangeValue = (event, path, items = []) => {

    const numericValues = ["match_by_sample_threshold", "filter_by_text_threshold",
      "expand_matches_by_text_delta", "scroll_height"];

    const booleanValues = ["process_different_aspects", "scroll", "stop_on_first"];

    let value = "";

    if (path == "text" || path == "scroll_direction" || path == "selection_type") {
      value = event.target.value;
    }
    else if (path.includes("region") || numericValues.includes(path)) {
      value = parseFloat(event.target.value);
    }
    else if (booleanValues.includes(path)) {
      const prevValue = parameters[path];
      parameters[path] = !prevValue;
      setParameters({ ...parameters });
      return;
    }
    else if (path == "phases") {
      parameters[path] = items;
      setParameters({ ...parameters });
      return;
    }
    else if (path == "app") {
      value = items;
    }

    let keys = path.split('.');
    let lastKey = keys.pop();
    let lastObj = keys.reduce((acc, key) => acc[key] = acc[key] || {}, parameters);
    lastObj[lastKey] = value;

    setParameters({ ...parameters });

  }

  const onSelect = async () => {

    setLoading(true);

    processScreenshot(initialScreenshot, parameters.region).then((screenshot) => {
      setScreenshot(screenshot);
    });

    // crop sample 20% of the image
    const sample = await cropSample(
      screenshot,
      {
        x: 20,
        y: 20,
        width: 60,
        height: 60
      }
    );

    postMessage({
      "type": "execute_finder",
      "action": "GET_ELEMENTS",
      "image": initialScreenshot?.url?.replace("data:image/png;base64,", ""),
      "sample": sample?.replace("data:image/png;base64,", ""),
      "params": params
    });

  }
  
  const onResetZoom = () => {
    setScreenshot(initialScreenshot.url);
    setRegions([]);
  }

  const onFinish = () => {
    setResponse({ input, parameters, sample: screenshot });
    closeWindow();
  }

  useEffect(() => {

    let auxInput = Object.assign({}, input);

    if (category == "CLICK") {
      if (!input.click_type) auxInput = { click_type: "LEFT", ...auxInput,  };
    }

    if (category == "DOUBLE_CLICK") {
      if (!input.click_type) auxInput = { click_type: "LEFT", ...auxInput, };
    }

    if (category == "DRAG") {
      if (!input.drag_type) auxInput = { drag_type: "LEFT", ...auxInput, };
    }

    if (category == "INPUT") {
      if (!input.input_type) auxInput = { input_type: "TEXT", ...auxInput, };
    }

    setInput(auxInput);

  }, [category]);

  useEffect(() => {

    addListener((data) => {

      if(data?.type == "execute_finder") {

        let minX = 100000;
        let minY = 100000;
        let maxX = 0;
        let maxY = 0;

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.src = screenshot;
        img.crossOrigin = "Anonymous";

        img.onload = () => {

          canvas.width = img.width;
          canvas.height = img.height;

          ctx.drawImage(img, 0, 0);

          let result = [];
          try{ 
            result = JSON.parse(data.result); 
          } catch(e) { 
            result = data.result; 
          }

          if(result.length > 0) result = [result[0]];

          result.forEach(element => {

            let x1 = element.real_rect.x1;
            let x2 = element.real_rect.x2;
            let y1 = element.real_rect.y1;
            let y2 = element.real_rect.y2;

            ctx.strokeStyle = "#5dc1b9";
            ctx.lineWidth = 3;
            ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);

            if (category == "CLICK" || category == "DOUBLE_CLICK" || category == "INPUT") {
              ctx.beginPath();
              ctx.arc(x1 + 10, y1 + 10, 5, 0, 2 * Math.PI);
              ctx.fillStyle = "#5dc1b9";
              ctx.fill();
            }

            if (x1 < minX) minX = x1;
            if (y1 < minY) minY = y1;
            if (x2 > maxX) maxX = x2;
            if (y2 > maxY) maxY = y2;

          });

          const canvasAux = document.createElement("canvas");
          const ctxAux = canvasAux.getContext("2d");
          const imgAux = new Image();
          imgAux.src = canvas.toDataURL("image/png");

          imgAux.onload = () => {

            // crop image
            canvasAux.width = maxX - minX + 20;
            canvasAux.height = maxY - minY + 20;

            ctxAux.drawImage(imgAux, minX - 10, minY - 10, maxX - minX + 20, maxY - minY + 20,
              0, 0, maxX - minX + 20, maxY - minY + 20);

            setLoading(false);
            setPickerResponse({
              result: result,
              screenshot: canvasAux.toDataURL("image/png"),
              status: data.status
            });

            if(result.length > 0) setResultScreenshot(canvasAux.toDataURL("image/png"));
            else setResultScreenshot(canvas.toDataURL("image/png"));

            setShowResponseModal(true);

          }

        }

      }

    });

  }, []);

  useEffect(() => {
    showBlackScreen();
    setTimeout(() => {
      restoreWindow();
      topMostWindow();
    }, 300);
  }, []);

  useEffect(() => {
    if(!regions || regions.length == 0) return;
    if(regions[0].isChanging) return;
    cropSample(
      screenshot, 
      {
        x: Math.max(regions[0].x - 10, 0),
        y: Math.max(regions[0].y - 10, 0),
        width: Math.min(regions[0].width + 20, 100),
        height: Math.min(regions[0].height + 20, 100)
      }
    ).then((sample) => {
      setScreenshot("data:image/png;base64," + sample);
      setRegions([{
        ...regions[0],
        x: 20,
        y: 20,
        width: 60,
        height: 60,
        isChanging: true
      }]);
    });
  }, [regions]);

  return <View
    input={input}
    category={category}
    parameters={parameters}
    screenshot={screenshot}
    regions={regions}
    loading={loading}
    pickerResponse={pickerResponse}
    resultScreenshot={resultScreenshot}
    setRegions={setRegions}
    showResponseModal={showResponseModal}
    setShowResponseModal={setShowResponseModal}
    onChangeValue={onChangeValue}
    onSelect={onSelect}
    onResetZoom={onResetZoom}
    onFinish={onFinish}
    setInput={setInput}
  />;

}

export default SamplePicker;