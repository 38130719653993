import Table from '@tiptap/extension-table';

const TableBorder = Table.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      border: {
        default: 'table-border',
        renderHTML: attributes => {
          return { class: ` ${attributes.border} w-100 ` };
        },
      },
    };
  },
  addCommands(a) {
    return {
      ...this.parent?.(),
      setTableBorder: border => ({ commands }) => {
        return commands.updateAttributes('table', { border });
      }
    };
  }
});

export default TableBorder;