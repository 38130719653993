import React, { useState } from "react";
import { usePost } from "seed/api";
import View from "components/misc/tour/Tour.view";

function Tour({
  children,
  steps = [],
  startAt = 0,
  show = true,
  tutorialName = "",
  onCompleted = () => { },
  onError = () => { }
}) {

  const userId = sessionStorage.getItem("id");

  const [currentStep, setCurrentStep] = useState(startAt);

  const [callEndpoint, reqEndpoint] = usePost("/users/save_tutorial", {
    onCompleted: (data) => onCompleted(data),
    onError: (error) => onError(error)
  });

  const onClickMask = ({ setCurrentStep, currentStep, steps, setIsOpen }) => {
    if (steps) {
      if (currentStep == steps.length - 1) {
        setIsOpen(false)
      }
      setCurrentStep((s) => (s == steps.length - 1 ? 0 : s + 1))
    }
  }

  const onClickClose = ({ setCurrentStep, currentStep, steps, setIsOpen }) => {
    if (steps) {
      if (currentStep == steps.length - 1) {
        setIsOpen(false)
      }
      setCurrentStep((s) => (s == steps.length - 1 ? 0 : s + 1))
    }
  }

  const onClickHighlighted = (e) => {
    e.stopPropagation()
    console.log('No interaction')
  }

  const onChangeCurrentStep = () => {
    let newStep = 0;
    if (currentStep == steps.length - 1) {
      newStep = 0;
    } else {
      newStep = currentStep + 1;
    }
    setCurrentStep(newStep)

    callEndpoint({
      user_id: userId,
      tutorial_name: tutorialName,
      current_step: newStep,
      total_steps: steps.length,
    })
  }

  return (
    <View
      steps={steps}
      show={show}
      children={children}
      startAt={startAt}
      currentStep={currentStep}
      onClickMask={onClickMask}
      onClickClose={onClickClose}
      onClickHighlighted={onClickHighlighted}
      onChangeCurrentStep={onChangeCurrentStep}
    />
  );
}

export default Tour;