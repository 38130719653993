

const getWeekDayNum = (weekday) => {
    weekday = weekday.toLowerCase();
    if (weekday == "monday") return 0;
    if (weekday == "tuesday") return 1;
    if (weekday == "wednesday") return 2;
    if (weekday == "thursday") return 3;
    if (weekday == "friday") return 4;
    if (weekday == "saturday") return 5;
    if (weekday == "sunday") return 6;
    return -1;
}

const getWeekNameByNumber = (weekday) => {
    if (weekday == 0) return "Lun";
    if (weekday == 1) return "Mar";
    if (weekday == 2) return "Mie";
    if (weekday == 3) return "Jue";
    if (weekday == 4) return "Vie";
    if (weekday == 5) return "Sab";
    if (weekday == 6) return "Dom";
    return "";
}


export { getWeekDayNum, getWeekNameByNumber }