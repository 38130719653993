import React from "react";
import lodash from "lodash";
import { Field } from "formik";
import $ from 'jquery';
import VariableSelector from "components/schema_form/fields/variable_selector/VariableSelector";

const snakeCase = string => {
  return string.replace(/\W+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_');
};

const ValueView = ({ values, flowId, activeKey, defaultFieldValue, placeholder,
  setFieldValue, setValues, parentKey = "", setActiveKey }) => {

  const fields = (parentKey == "" ? values : lodash.get(values, parentKey))
  return <div className="w-100">
    {
      fields.map((fieldDat, idx) => {
        const key = `${parentKey}[${idx}]`
        const isActive = activeKey == key;
        const keyValue = lodash.get(values, key) ?? {}
        const hiddenId = "schema-builder-hidden-" + key.replaceAll("[", "").replaceAll("]", "").replaceAll(".", "-")

        return <li key={idx} className={"step-item " +
          (parentKey != "" ? "sub " : " ") + (isActive != "" ? "mb-3 " : "mb-2 ")}
          style={{ paddingRight: "0rem", paddingLeft: "0rem" }}>
          <div className="step-content-wrapper"
            onClick={keyValue.type != "SECTION" ? () => setActiveKey(key) : null}>
            <span className="step-icon step-icon-pseudo step-icon-soft-dark ml-1"></span>
            <div className="step-content ml-2 pt-0 pb-0">

              <div class="d-flex align-items-end justify-content-between">
                <div style={{ width: "48%" }}>
                  <Field type="text" required autocomplete="off"
                    className="form-control"
                    placeholder={isActive ? placeholder : "Campo sin nombre"}
                    name={`${key}.label`}
                    onClick={keyValue.type == "SECTION" ? () => setActiveKey(key) : null}
                    onChange={(value) => {
                      setFieldValue(`${key}.label`, value.target.value)
                      setFieldValue(`${key}.name`, snakeCase(value.target.value))
                    }} />

                  <Field type="hidden"
                    name={`${key}.name`} />

                </div>
                <div style={{ width: "30%" }}>
                  <Field as="select" name={`${key}.type`}
                    className="form-control" required onChange={(value) => {
                      $("#" + hiddenId).removeClass('show');
                      setFieldValue(`${key}.type`, value.target.value);
                      if (value.target.value == "SECTION")
                        setFieldValue(`${key}.fields`, [defaultFieldValue]);

                    }}>
                    <option value="TEXT">Texto</option>
                    <option value="TEXT_AREA">Párrafo</option>
                    <option value="FILE">Archivo</option>
                    <option value="DATE">Fecha</option>
                    <option value="DATE_TIME">Fecha-hora</option>
                    <option value="SELECT">Opción múltiple</option>
                    <option value="SECTION">Sección (Grupo)</option>
                  </Field>
                </div>

                <div class="text-right" style={{ width: "19%" }}>

                  <button type="button" class="btn btn-sm btn-outline-secondary"
                    style={!(idx > 0) ? { color: "#b1c6dd" } : {}}
                    disabled={!(idx > 0)} onClick={() => {
                      let tempVals = parentKey == "" ? [...values] : lodash.get(values, parentKey)
                      let tempVal = tempVals[idx]
                      tempVals[idx] = tempVals[idx - 1]
                      tempVals[idx - 1] = tempVal
                      if (parentKey == "") setValues(tempVals);
                      else setValues(lodash.set(values, parentKey, tempVals))
                    }}>
                    <i class="fas fa-arrow-up" aria-hidden="true"></i>
                  </button>

                  <button type="button" class="btn btn-sm btn-outline-secondary"
                    style={!(idx < fields.length - 1) ? { color: "#b1c6dd" } : {}}
                    disabled={!(idx < fields.length - 1)} onClick={() => {
                      let tempVals = parentKey == "" ? [...values] : lodash.get(values, parentKey)
                      let tempVal = tempVals[idx]
                      tempVals[idx] = tempVals[idx + 1]
                      tempVals[idx + 1] = tempVal
                      if (parentKey == "") setValues(tempVals);
                      else setValues(lodash.set(values, parentKey, tempVals))
                    }}>
                    <i class="fas fa-arrow-down" aria-hidden="true"></i>
                  </button>

                  <button type="button" class="btn btn-sm btn-outline-secondary ml-2"
                    onClick={() => {
                      let tempVals = parentKey == "" ? [...values] : lodash.get(values, parentKey)
                      tempVals.splice(idx, 1)
                      if (parentKey == "") setValues(tempVals);
                      else setValues(lodash.set(values, parentKey, tempVals))
                    }}>
                    <i class="fas fa-trash" aria-hidden="true"></i>
                  </button>

                </div>
              </div>


              <div class="mt-3" style={{ width: keyValue.type == "SECTION" ? "100%" : "58%" }}>


                {
                  keyValue.type == "SECTION" ?
                    <div className="w-100 mb-3">

                      {/*** SECTION OPTIONS ***/}

                      {isActive ?
                        <div className="animate__animated animate__fadeIn animate__faster">

                          <div class="mt-0 w-100 mb-2 ml-3" style={{ width: "58%", color: "#777" }}>

                            {
                              <div className="d-flex w-100 mb-2" style={{marginLeft: "0.15rem"}}>

                                <div className="d-flex">
                                  <label class="input-label mr-2">Mútiples secciones</label>
                                  <Field type="checkbox"
                                    name={`${key}.multiple`}
                                    class="mt-0"></Field>
                                </div>

                              </div>
                            }

                            <div className="mt-n1 ml-n1">
                              <HiddenOptions
                                hiddenId={hiddenId} key={key} keyValue={keyValue}
                                values={values} fields={fields} setFieldValue={setFieldValue} />
                            </div>


                          </div>
                        </div>
                        : null
                      }

                      <span class="dropdown-header pt-2 pb-3"><span style={{ color: "#777" }}>Subcampos</span></span>
                      {
                        (() => {
                          return <ValueView
                            values={values}
                            flowId={flowId}
                            activeKey={activeKey}
                            defaultFieldValue={defaultFieldValue}
                            placeholder={placeholder}
                            setFieldValue={setFieldValue}
                            setValues={setValues}
                            parentKey={`${key}.fields`}
                            setActiveKey={setActiveKey}
                          />
                        })()
                      }
                    </div>
                    : null
                }

              </div>




              {/*** FIELDS OPTIONS ***/}
              {keyValue.type != "SECTION" && isActive ?
                <div className="animate__animated animate__fadeIn animate__faster">

                  <div class="mt-0 w-100 mb-2" style={{ marginLeft: "1.25%", width: "58%", color: "#777" }}>



                    {/*** TEXT OPTIONS ***/}
                    {
                      keyValue.type == "TEXT" || keyValue.type == "TEXT_AREA" ?
                        <div className="d-flex w-100 mb-2">

                          <div className="d-flex mr-3">
                            <label class="input-label mr-2">Requerido</label>
                            <Field type="checkbox"
                              name={`${key}.required`}
                              class="mt-0"></Field>
                          </div>

                          <div className="d-flex">
                            <label class="input-label mr-2">Mútiples campos</label>
                            <Field type="checkbox"
                              name={`${key}.multiple`}
                              class="mt-0"></Field>
                          </div>

                        </div>
                        : null
                    }

                    {/*** DATE OPTIONS ***/}
                    {
                      keyValue.type == "DATE" ?
                        <div className="d-flex w-100 mb-2">

                          <div className="d-flex mr-3">
                            <label class="input-label mr-2">Requerido</label>
                            <Field type="checkbox"
                              name={`${key}.required`}
                              class="mt-0"></Field>
                          </div>

                        </div>
                        : null
                    }

                    {/*** DATE_TIME OPTIONS ***/}
                    {
                      keyValue.type == "DATE_TIME" ?
                        <div className="d-flex w-100 mb-2">

                          <div className="d-flex mr-3">
                            <label class="input-label mr-2">Requerido</label>
                            <Field type="checkbox"
                              name={`${key}.required`}
                              class="mt-0"></Field>
                          </div>

                        </div>
                        : null
                    }

                    {/*** FILE OPTIONS ***/}
                    {
                      keyValue.type == "FILE" ?
                        <div className="w-100">
                          <div className="mb-3" style={{ width: "58%" }}>
                            <label class="input-label mb-0">Archivos aceptados</label>

                            <Field as="select" name={`${key}.accept_files_select`}
                              className="form-control" required onChange={(value) => {
                                setFieldValue(`${key}.accept_files_select`, value.target.value);
                                if (value.target.value != "custom")
                                  setFieldValue(`${key}.accept_files`, value.target.value);
                                else setFieldValue(`${key}.accept_files`, "");
                              }}>
                              <option value="/*">Todos los archivos</option>
                              <option value=".pdf">PDFs</option>
                              <option value=".doc,.docx">Documentos Word</option>
                              <option value=".xlsx, xls,.csv">Hojas de cálculo</option>
                              <option value="image/*">Imágenes</option>
                              <option value="custom">Personalizado</option>
                            </Field>

                            {lodash.get(values, `${key}.accept_files_select`) == "custom" ?
                              <Field type="text" required autocomplete="off"
                                className="form-control"
                                placeholder={"Ej. Para aceptar PDFs y Word = .pdf, .docx"}
                                name={`${key}.accept_files`}
                                onChange={(value) => setFieldValue(`${key}.accept_files`, value.target.value)} />
                              : null}
                          </div>

                          <div className="d-flex w-100 mb-2">

                            <div className="d-flex mr-3">
                              <label class="input-label mr-2">Requerido</label>
                              <Field type="checkbox"
                                name={`${key}.required`}
                                class="mt-0"></Field>
                            </div>

                            <div className="d-flex">
                              <label class="input-label mr-2">Mútiples archivos</label>
                              <div className="d-flex">
                                <Field type="checkbox"
                                  name={`${key}.multiple_files`}
                                  class="mt-0"></Field>
                              </div>
                            </div>



                          </div>
                        </div>
                        : null
                    }

                    {/*** SELECT OPTIONS ***/}
                    {
                      keyValue.type == "SELECT" ?
                        <div className="w-100 mb-2">

                          <div className="mb-3" style={{ width: "58%" }}>
                            <label class="input-label mb-0">Opciones</label>
                            {
                              (keyValue.options ?? [{ value: "", label: "" }]).map((option, idxOpts) =>
                                <div key={idxOpts} class="d-flex align-items-end justify-content-between mb-1 mt-n1">
                                  <div style={{ width: "90%" }}>
                                    <Field type="text" required autocomplete="off"
                                      name={`${key}.options[${idxOpts}].label`}
                                      onChange={(value) => {
                                        setFieldValue(`${key}.options[${idxOpts}].label`, value.target.value)
                                        setFieldValue(`${key}.options[${idxOpts}].value`, snakeCase(value.target.value))
                                      }}
                                      class="form-control mt-2"></Field>
                                    <Field type="hidden" required
                                      name={`${key}.options[${idxOpts}].value`}
                                      class="form-control mt-2"></Field>
                                  </div>
                                  <div class="text-right" style={{ width: "10%" }}>
                                    <button type="button" class="btn btn-link px-1"
                                      onClick={() => {
                                        let tempVals = parentKey == "" ? [...values] : lodash.get(values, parentKey)
                                        let tempOptions = tempVals[idx].options ?? [{ value: "", label: "" }]
                                        tempOptions.splice(idxOpts, 1)
                                        tempVals[idx].options = tempOptions
                                        if (parentKey == "") setValues(tempVals);
                                        else setValues(lodash.set(values, parentKey, tempVals));
                                      }}
                                      style={{ color: "#c0c5d5", fontSize: "1.15em" }}>
                                      <i class="fas fa-minus-circle"></i>
                                    </button>
                                  </div>

                                </div>
                              )
                            }
                            {
                              keyValue.collection_options?.map((collection_options, idxOpts) =>
                                <div key={idxOpts} class="d-flex align-items-end justify-content-between mb-1 mt-n1">
                                  <div style={{ width: "90%" }}>
                                    <VariableSelector
                                      name={`${key}.collection_options[${idxOpts}].query`}
                                      placeholder="Referencia a tabla"
                                      required
                                      flowId={flowId}
                                      value={collection_options?.query}
                                      values={values}
                                      setFieldValue={setFieldValue}
                                      selectorFilters={[{
                                        type: "SOURCE",
                                        value: "variable"
                                      }]}
                                    />
                                  </div>
                                  <button
                                    type="button"
                                    class="btn btn-link px-1"
                                    onClick={() => {
                                      setFieldValue(key, {
                                        ...keyValue,
                                        collection_options: keyValue.collection_options.filter((_, index) => index !== idxOpts)
                                      })
                                    }}
                                    style={{ color: "#c0c5d5", fontSize: "1.15em" }}
                                  >
                                    <i class="fas fa-minus-circle"></i>
                                  </button>
                                </div>
                              )
                            }
                            <button type="button" class="btn btn-link pl-0"
                              onClick={() => {
                                let tempVals = parentKey == "" ? [...values] : lodash.get(values, parentKey)
                                tempVals[idx].options = [...(tempVals[idx].options ?? []), ...[{}]]
                                if (parentKey == "") setValues(tempVals);
                                else setValues(lodash.set(values, parentKey, tempVals));
                              }}
                              style={{ color: "#666", fontWeight: "normal" }}>
                              <i class="fas fa-plus"></i>&nbsp;Agregar
                            </button>

                            <button type="button" class="btn btn-link pl-0"
                              onClick={() => {
                                let tempVals = parentKey == "" ? [...values] : lodash.get(values, parentKey)
                                tempVals[idx].collection_options = [...(tempVals[idx].collection_options ?? []), ...[{}]]
                                if ((tempVals[idx].options ?? []).length < 2)
                                  tempVals[idx].options = []
                                if (parentKey == "") setValues(tempVals);
                                else setValues(lodash.set(values, parentKey, tempVals));
                              }}
                              style={{ color: "#666", fontWeight: "normal" }}>
                              <i class="fas fa-plus"></i>&nbsp;Agregar opciones desde tabla
                            </button>

                          </div>

                          <div className="d-flex mr-3">
                            <label class="input-label mr-2">Requerido</label>
                            <Field type="checkbox"
                              name={`${key}.required`}
                              class="mt-0"></Field>
                          </div>
                        </div>
                        : null
                    }



                  </div>
                </div>
                : null
              }

              {/*** HIDDEN OPTIONS ***/}
              {
                keyValue.type != "SECTION" && isActive ?
                  <HiddenOptions
                    hiddenId={hiddenId} key={key} keyValue={keyValue}
                    values={values} fields={fields} setFieldValue={setFieldValue} /> : null
              }


            </div>
          </div>
        </li>



      }
      )
    }
    <button type="button" class="btn btn-sm btn-secondary px-3 ml-n2"
      onClick={() => {
        if (parentKey == "") {
          setValues([...values, ...[defaultFieldValue]]);
        } else {
          const newValues = [...(lodash.get(values, parentKey) ?? []), ...[defaultFieldValue]]
          setValues(lodash.set(values, parentKey, newValues))
        }
        setActiveKey(`${parentKey}[${fields.length}]`)
      }}>
      <i class="fas fa-plus mr-1"></i>&nbsp;Agregar campo
    </button>
  </div>
}


const HiddenOptions = ({ hiddenId, key, keyValue, values, fields, setFieldValue }) =>
  <div>
    <a
      class="btn btn-sm btn-lighter py-2 px-2 mt-0 ml-0 text-left" data-toggle="collapse"
      style={{ border: "none", color: "#959595" }}
      href={"#" + hiddenId} role="button" aria-expanded="false" aria-controls="schema-builder-hidden">
      Opciones avanzadas
      <i style={{ color: "#aaa" }} class="ml-1 fas fa-chevron-down"></i>
    </a>


    <div className={"collapse mt-0 pt-2 pb-2 " +
      ((keyValue?.show_if ?? []).length > 0 ||
        (lodash.get(values, `${key}.caption`) != null && lodash.get(values, `${key}.caption`) != "") ? "show" : "")}
      style={{ marginLeft: "1.25%", width: "87%", color: "#777" }}
      id={hiddenId}>


      {
        function () {

          let conditions = keyValue?.show_if ?? [];

          return <>
            <span class="d-flex justify-content-between dropdown-header pt-2 pb-1 px-1">
              <span style={{ color: '#777' }}>Mostrar si</span>
            </span>
            {
              conditions?.map((condition, idx) =>
                <div key="idx" className="d-flex align-items-center justify-content-between mr-3 my-1"
                  style={{ width: "95%" }}>
                  <div className="d-flex align-items-center" style={{ width: "35%" }}>
                    {idx > 0 ? <label className="input-label mr-2 text-right">y</label> : null}
                    <Field
                      as="select"
                      name={`${key}.show_if[${idx}].variable`}
                      className="form-control"
                    >
                      <option value="">Selecciona un campo</option>
                      {
                        fields.filter(field => field.type != "FILE").map((field, idx) =>
                          <option key={idx} value={field.name}>{field.label}</option>
                        )
                      }
                    </Field>
                  </div>
                  <label class="input-label mx-2">es</label>
                  {
                    function () {

                      let inputType = "text";
                      let operators = {
                        "EQUAL": "Igual a",
                        "NOT_EQUAL": "Diferente a",
                        "GREATER_THAN": "Mayor a",
                        "LOWER_THAN": "Menor a",
                        "GREATER_THAN_OR_EQUAL": "Mayor o igual a",
                        "LOWER_THAN_OR_EQUAL": "Menor o igual a",
                        "CONTAINS": "Contiene",
                      };

                      const field = fields.find(f => f.name == condition?.variable);
                      if (field?.type == "DATE") {
                        inputType = "date";
                        delete operators["CONTAINS"];
                      }
                      if (field?.type == "DATE_TIME") {
                        inputType = "datetime-local";
                        delete operators["CONTAINS"];
                      }

                      return <div className="d-flex" style={{ width: "60%" }}>
                        <Field
                          as="select"
                          style={{ width: "35%" }}
                          name={`${key}.show_if[${idx}].condition`}
                          class="form-control mx-1"
                        >
                          <option value="">Selecciona una condición</option>
                          {
                            Object.keys(operators).map((operator, idx) =>
                              <option key={idx} value={operator}>{operators[operator]}</option>
                            )
                          }
                        </Field>
                        <Field
                          type={inputType}
                          style={{ width: "60%" }}
                          name={`${key}.show_if[${idx}].value`}
                          class="form-control mx-1"
                          placeholder="Indica un valor"
                          required
                        />

                      </div>;

                    }()
                  }
                  <button
                    type="button"
                    class="btn btn-link px-1"
                    onClick={() => {
                      setFieldValue(key, {
                        ...keyValue,
                        show_if: keyValue.show_if.filter((_, index) => index !== idx)
                      })
                    }}
                    style={{ color: "#c0c5d5", fontSize: "1.15em" }}
                  >
                    <i class="fas fa-minus-circle"></i>
                  </button>
                </div>
              )
            }
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary mt-1"
              onClick={() => {
                setFieldValue(`${key}.show_if[${conditions.length}]`, { variable: "", condition: "EQUAL" })
              }}
            >
              <i class="fas fa-lock mr-1"></i>&nbsp;Agregar condición
            </button>
          </>

        }()
      }

      <div className="mb-3 mt-3" style={{ width: "58%" }}>
        <span class="d-flex justify-content-between dropdown-header pt-2 pb-1 px-1">
          <span style={{ color: '#777' }}>Información adicional</span>
        </span>
        <Field type="text" autocomplete="off"
          className="form-control"
          placeholder={"Ej. Instrucciones de uso"}
          name={`${key}.caption`}
          onChange={(value) => setFieldValue(`${key}.caption`, value.target.value)} />
      </div>

    </div>
  </div>

export default ValueView;