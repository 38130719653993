import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ScriptTag, StyleTag } from "seed/helpers";
import SideTemplate from "components/presentation/templates/side_template/SideTemplate";
import TopTemplate from "components/presentation/templates/top_template/TopTemplate";


const MainView = ({ flow, showTabs, onOpenEdit, onOpenTemplates, onOpenShare }) => (
  <BrowserRouter basename={`/presentation/${flow.token}?tabs=${showTabs ? "true" : "false"}`}>

    {function () {
      const navigation = flow.presentationSettings?.navigation ?? "tabs";
      if (navigation == "sidenav")
        return <SideTemplate
          flow={flow}
          showTabs={showTabs}
          onOpenEdit={onOpenEdit}
          onOpenTemplates={onOpenTemplates}
          onOpenShare={onOpenShare} />;
      else if (navigation == "tabs")
        return <TopTemplate
          flow={flow}
          showTabs={showTabs}
          onOpenEdit={onOpenEdit}
          onOpenTemplates={onOpenTemplates}
          onOpenShare={onOpenShare} />;


    }()}

    <ScriptTag content={`
         // Builder toggle invoker
        $('.js-navbar-vertical-aside-toggle-invoker').click(function () {
          $('.js-navbar-vertical-aside-toggle-invoker i').tooltip('hide');
        });

        // Initialization of navbar vertical navigation
        var sidebar = $('.js-navbar-vertical-aside').hsSideNav();

        // Initialization of tooltip in navbar vertical menu
        $('.js-nav-tooltip-link').tooltip({ boundary: 'window' })

        $(".js-nav-tooltip-link").on("show.bs.tooltip", function(e) {
          if (!$("body").hasClass("navbar-vertical-aside-mini-mode")) {
            return false;
          }
        });

        // Initialization of unfold
        $('.js-hs-unfold-invoker').each(function () {
          var unfold = new HSUnfold($(this)).init();
        });

        // Initialization of form search
        $('.js-form-search').each(function () {
          new HSFormSearch($(this)).init()
        });
    `} />

    <StyleTag content={`
      #background-hero {
        display: none !important;
      }

      #app {
        background-color: white !important;
      }

      #no-collection-card {
        width: 50% !important;
      }

      @media only screen and (max-width: 1000px) {
        #no-collection-card {
          width: 70% !important;
        }
      }

      @media only screen and (max-width: 600px) {
        #no-collection-card {
          width: 100% !important;
        }
      }

    `} />

  </BrowserRouter>
);

export default MainView;