import React from "react";

const ResultsListType = {
  GENERATED: "GENERATED",
  SAVED: "SAVED",
  HISTORY: "HISTORY",
};

const ResultsList = ({
  type,
  suggestions,
  onChangeSuggestion = () => { },
  onSelectSuggestion = () => { },
}) => {

  if (suggestions.length === 0) return null;
  console.log("suggestions", suggestions);

  return (
    <div className={`${type == ResultsListType.GENERATED ? "animated animate__fadeIn animate__faster" : ""}`}>

      {
        function () {
          return <>
            <span class="text-decoration-none text-dark p-0 m-0 w-100 d-flex justify-content-between">
              <h6 className="text-muted mt-1">
                {
                  function () {
                    switch (type) {
                      case ResultsListType.GENERATED:
                        return "Ideas";
                      case ResultsListType.SAVED:
                        return "Ideas guardadas";
                      case ResultsListType.HISTORY:
                        return "Historial de ideas";
                      default:
                        return "";
                    }
                  }()
                }
              </h6>
            </span>
          </>
        }()
      }

      <div>
        {
          suggestions.map((s, index) => (
            <div 
              key={index} 
              className="border border-radius py-2 px-3 mb-2 rounded"
              style={{ cursor: "pointer" }}
              onClick={() => onSelectSuggestion(s)}
            >
              {
                s.type == "SUGGESTION" &&
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column pr-2">
                      <div>
                        {/* <i className="fas fa-lightbulb mr-2 text-warning" /> */}
                        {s.response.title}
                      </div>
                      {
                        type == ResultsListType.HISTORY &&
                        <div className="text-muted">
                          <small>
                            {s.description}
                          </small>
                        </div>
                      }
                    </div>
                    <div className="d-flex align-items-center">

                      <button
                        className="btn btn-sm btn-outline-primary mr-2"
                        onClick={() => onSelectSuggestion(s)}
                      >
                        Ver
                      </button>

                      {
                        (type === ResultsListType.GENERATED ||
                          type === ResultsListType.HISTORY
                        ) &&
                        <span>
                          <i
                            className="far fa-star p-1"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => { e.stopPropagation(); onChangeSuggestion(s); }}
                          />
                        </span>
                      }

                      {
                        type === ResultsListType.SAVED &&
                          <span>
                            <i
                              className="fas fa-star text-warning p-1"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => { e.stopPropagation(); onChangeSuggestion(s); }}
                            />
                          </span>
                      }

                      {
                        type === ResultsListType.HISTORY &&
                          <span>
                            <i
                              className="fas fa-trash-alt text-danger ml-2 p-1"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => { e.stopPropagation(); onChangeSuggestion(s); }}
                            />
                          </span>
                      }

                    </div>
                  </div>
              }
            </div>
          ))
        }
      </div>

    </div>
  );

}

export {
  ResultsList,
  ResultsListType
}