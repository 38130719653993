import React from "react";

const flowTutorialSteps = [
    {
        selector: "#workspace",
        content: () => <label><b>Bienvenido a Workix.<br/>  Este es tu tablero de procesos, en este podrás diseñar tus flujos automáticos.</b></label>
    },
    {
        selector: "#toolbox-actions",
        content: <label><b>Estas son las acciones que puedes agregar a tu proceso. Solo da click en alguna o arrástrala al tablero.</b></label>
    },
    {
        selector: ".node-execute",
        content: <label><b>Una vez que quede listo, presiona &quot;Ejecutar&quot; para probar tu proceso.</b></label>
    },
];


export { flowTutorialSteps };