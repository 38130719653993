import React, { useState } from "react";
import { useGet } from "seed/api";
import * as lib from "components/start/usage/Usage.lib.format";
import * as chartOptions from "components/start/usage/Usage.lib.chartOptions";
import { Loading } from "seed/helpers";
import View from "components/start/usage/Usage.view";

function Usage() {

  const userId = sessionStorage.getItem("id");
  const [isModalPlansShown, setIsModalPlansShown] = useState(false);
  
  const reqHeatmapData = useGet("/executions/get_executions_heatmap", { "user_id": userId });

  if (reqHeatmapData.loading) return <Loading />;
  const executions = reqHeatmapData.data?.executions ?? {};
  const formattedExecutions = [];
  Object.keys(executions).forEach((key) => {
    formattedExecutions.push({
      "name": lib.getWeekNameByNumber(lib.getWeekDayNum(key)),
      "weekday": lib.getWeekDayNum(key),
      "data": executions[key]
    })
  })
  formattedExecutions.sort((a, b) => b["weekday"] - a["weekday"]);
  const heatMapExecutions = {
    "data": formattedExecutions,
    "options": chartOptions.heatmapChartOptions
  }

  return (
    <View
      userId={userId}
      heatMapExecutions={heatMapExecutions}
      isModalPlansShown={isModalPlansShown}
      setIsModalPlansShown={setIsModalPlansShown}
    />
  );
}

export default Usage;