import React from "react";
import { Field } from "formik";

const Text = ({
  flowQuestion,
  values,
  flowCaptures,
  questionIndex,
  currentIndex,
  setFieldValue
}) => (
  <div key={questionIndex} className="my-3">
    <div class="d-flex align-items-center">
      <label class="input-label mr-4" for="routine_name">
        {flowQuestion.label(values, flowCaptures, currentIndex)}
      </label>
      <Field
        type="text"
        className="form-control w-auto h-auto"
        required={!values.skip}
        name={flowQuestion.name}
        autocomplete="off"
        style={{ borderColor: "#bbb", color: "#555", padding: "5px 12px", minWidth: "250px" }}
        onChange={(e) => setFieldValue(flowQuestion.name, e.target.value)}
        autofocus={true}
      />
    </div>
  </div>
);

export default Text;