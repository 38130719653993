import React from "react";

const Select = ({
  flowQuestion,
  values,
  flowCaptures,
  questionIndex,
  currentIndex,
  setFieldValue
}) => (
  <div className="my-3" key={questionIndex}>
    <div class="d-flex align-items-top">
      <label class="input-label mr-4" for="routine_name">
        {flowQuestion.label(values, flowCaptures, currentIndex)}
        <input 
          type="text"
          style={{ width: "1px", height: "1px", border: "none", margin: "0", padding: "0" }}
          required={
            !values.skip &&
            (values[flowQuestion.name] == undefined || values[flowQuestion.name] == null)
          }
        />
      </label>
      <div className={flowQuestion.component == "SELECT_HORIZONTAL" ? "d-flex" : ""}>
        {
          flowQuestion.options.map((option, index) =>
            <div key={index} className={"d-block " +
              (flowQuestion.component == "SELECT_HORIZONTAL" ? "mr-2" : "mb-2")}>
              <input 
                type="button" 
                className={
                  `btn btn-sm py-1 px-3 btn-lighter ${values[flowQuestion.name] == option.value ? "selected" : ""}`
                }
                onClick={() => setFieldValue(flowQuestion.name, option.value)}
                value={option.label} />
            </div>
          )
        }
      </div>
    </div>
  </div>
);

export default Select;