import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import View from "components/schema_form/fields/rich_text/OptionList.view";


const OptionList = forwardRef(function OptionList(props, ref) {

  const [selectedItem, setSelectedItem] = useState(props.items.length > 0 ? props.items[0].id : null);

  const onSelectItem = (id) => {
    const item = props.items.find((item) => item.id == id)

    if (item) {
      props.command(item)
    }
  }

  const onUpHandler = () => {
    const selectedIndex = props.items.findIndex((item) => item.id == selectedItem);
    const newIndex = ((selectedIndex + props.items.length) - 1) % props.items.length;
    setSelectedItem(props.items[newIndex].id);
  }

  const onDownHandler = () => {
    const selectedIndex = props.items.findIndex((item) => item.id == selectedItem);
    const newIndex = (selectedIndex + 1) % props.items.length;
    setSelectedItem(props.items[newIndex].id)
  }

  const onEnterHandler = () => {
    onSelectItem(selectedItem)
  }

  useEffect(() => {
    setSelectedItem(props.items.length > 0 ? props.items[0].id : null);
  }, [props.items])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        onUpHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        onDownHandler()
        return true
      }

      if (event.key === 'Enter') {
        onEnterHandler()
        return true
      }

      return false
    },
  }))


  return (
    <View
      items={props.items}
      selectedItem={selectedItem}
      isVariable={props.isVariable}
      onSelectItem={onSelectItem}
    />
  );
})

export default OptionList;