
const presentationSchema = [
	{
		"name": "sections",
		"type": "SECTION",
		"label": "Secciones",
		"multiple": true,
		"fields": [
			{
				"name": "title",
				"type": "TEXT",
				"label": "Titulo de la sección",
				"required": true
			},
			{
				"name": "actions",
				"type": "SECTION",
				"label": "Acciones",
				"enable_collapse": true,
				"multiple": true,
				"fields": [
					{
						"name": "name",
						"type": "TEXT",
						"label": "Nombre de botón",
						"required": true,
						"help_message": "Nombre del botón que permitirá ejecutar una acción"
					},
					{
						"name": "nodes",
						"type": "VARIABLE",
						"label": "Acción a ejecutar",
						"required": true,
						"help_message": "Acción del flujo para ejecutar a partir del botón de esta sección",
						"selector_filters": [
							{
								"type": "SOURCE",
								"value": "variable"
							}
						]
					}
				]
			},
			{
				"name": "data",
				"type": "SECTION",
				"label": "Datos",
				"enable_collapse": true,
				"fields": [
					{
						"name": "collection",
						"type": "VARIABLE",
						"label": "Tabla",
						"required": false,
						"accept_types": ["collection"]
					},
					{
						"name": "visualization",
						"type": "SELECT",
						"label": "Visualización",
						"required": false,
						"options": [
							{
								"label": "Lista",
								"value": "list"
							},
							{
								"label": "Tabla",
								"value": "table"
							}
						]
					}
				],
			}
		]
	}
];

export { presentationSchema };