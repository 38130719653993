import React, { useEffect, useState } from "react";
import swal from 'sweetalert';
import { usePost } from "seed/api";
import { useGet } from "seed/api";
import { Loading } from "seed/helpers";
import View from "components/misc/use_cases/UseCases.view";

function UseCases({ match }) {

  const userProfile = match?.params?.user_profile;
  const [selectedProfile, setSelectedProfile] = useState(null);

  const [callSaveInfo, reqSaveInfo] = usePost('/users/create_anonymous', {
    onCompleted: (data) => {
      sessionStorage.setItem("token", data.token);
      sessionStorage.setItem("id", data.user);
      sessionStorage.setItem("developer_mode", false);
      localStorage.setItem("token", data.token);
      localStorage.setItem("id", data.user);
      localStorage.setItem("developer_mode", false);
    }
  });

  const flowTemplateFilters = {}
  if (selectedProfile) flowTemplateFilters["template_categories"] = [selectedProfile.id];

  const reqFlowTemplates = useGet("/flows/get_flows", flowTemplateFilters);
  const reqTemplateProfiles = useGet("/template_categories/get_template_category", {}, {
    onCompleted: (data) => {
      if (userProfile != null && userProfile != "" && selectedProfile == null) {
        const categories = data;
        const userProfileCategory = categories.find((category) => category.urlSlug == userProfile);
        if (userProfileCategory) {
          setSelectedProfile(userProfileCategory);
          reqFlowTemplates.refetch();
        }
      }
    }
  });

  useEffect(() => {
    localStorage.setItem("userProfile", userProfile);
    if (window.innerWidth < 540 && !sessionStorage.getItem("mobileWarningShown"))
      swal("Módulo parcialmente habilitado para moviles",
        "Para una mejor experiencia, te recomendamos acceder a este sitio desde un ordenador de escritorio o portátil.",
        "warning").then(() => {
          sessionStorage.setItem("mobileWarningShown", true)
        });
  }, []);


  const onChangeProfile = (newProfile) => {
    setSelectedProfile(newProfile);
    reqFlowTemplates.refetch();
  }

  if (reqTemplateProfiles.loading || reqFlowTemplates.loading) return <Loading />;
  if (reqTemplateProfiles.error || reqFlowTemplates.error) return "Error";

  const isTemplateProfilesLoading = reqTemplateProfiles.loading;
  const isFlowTemplatesLoading = reqFlowTemplates.loading;
  const templateCategories = reqTemplateProfiles.data ?? [];
  const flows = reqFlowTemplates.data ?? [];

  return (
    <View
      flows={flows}
      selectedProfile={selectedProfile}
      templateCategories={templateCategories}
      isTemplateProfilesLoading={isTemplateProfilesLoading}
      isFlowTemplatesLoading={isFlowTemplatesLoading}
      onChangeProfile={onChangeProfile}
    />
  );
}

export default UseCases;