const events = {
  DRAG_EVENT: "DRAG",
  WHEEL_EVENT: "WHEEL",
  RIGHT_CLICK_EVENT: "RCLICK",
  LEFT_CLICK_EVENT: "LCLICK",
  TEXT_EVENT: "TEXT",
  KEY_EVENT: "KEY",
  COMPOUND_EVENT: "COMPOUND",
}

const eqMultiEvents = (capture, ...events) => {

  if(capture?.event?.split("-").length != events.length) return false;

  const captureEvents = capture?.event?.split("-")??[];

  for(let i = 0; i < captureEvents.length; i++)
    if(captureEvents[i] != events[i]) 
      return false;

  return true;

}

const startsWithEvent = (capture, event) => {
  return capture?.event?.split("-")[0] == event;
}

const isSingleEvent = (capture) => {
  return !capture?.multi;
}

const isMultiEvent = (capture) => {
  return capture?.multi;
  // return capture?.event.split("-").length > 1;
}

const findValue = (capture, value) => {
  return (capture?.combination??[]).find((v) => v?.value == value);
}

export {
  events,
  eqMultiEvents,
  startsWithEvent,
  isSingleEvent,
  isMultiEvent,
  findValue
}