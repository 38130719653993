import React, { useEffect, useState } from "react";
import { FLOW_QUESTIONS } from "components/misc/apps/rpa/generator/FlowQuestions";
import { getFlowRule } from "components/misc/apps/rpa/generator/questions/rules";

import Select from "components/misc/apps/rpa/generator/components/Select";
import Text from "components/misc/apps/rpa/generator/components/Text";
import Autocomplete from "components/misc/apps/rpa/generator/components/Autocomplete";
import Picker from "components/misc/apps/rpa/generator/components/Picker";
import Checkbox from "components/misc/apps/rpa/generator/components/Checkbox";
import Label from "components/misc/apps/rpa/generator/components/Label";
import Rule from "components/misc/apps/rpa/generator/components/Rule";

const GeneratorForm = ({
  values,
  flowCaptures,
  currentKey,
  currentIndex,
  currentRuleAdded,
  currentRuleIndex,
  autoCompleteApps,
  handleShowPicker,
  showRegionPicker,
  setCurrentDefinition,
  setFieldValue,
  setFormCompleted,
  setCurrentRuleIndex
}) => {

  const [flowQuestions, setFlowQuestions] = useState([]);

  useEffect(() => {

    let auxFlowQuestions = [];

    const getFlowQuestions = (nextFlowId = "initial_00") => {

      const flowQuestion = FLOW_QUESTIONS[nextFlowId];
      auxFlowQuestions.push(flowQuestion);

      for(let flow of flowQuestion?.next ?? []) {
        if(flow?.condition(values, flowCaptures, currentIndex)) {
          if(flowQuestion.next) {
            getFlowQuestions(flow.id);
          }
          break;
        }
      }

    }

    getFlowQuestions();

    if(currentRuleAdded) {
      if(currentRuleIndex == -1) {
        setCurrentRuleIndex(auxFlowQuestions.length);  
        auxFlowQuestions = [
          ...auxFlowQuestions,
          ...getFlowRule(values, flowCaptures, currentIndex)
        ]
      }
      else {
        auxFlowQuestions = [
          ...auxFlowQuestions.slice(0, currentRuleIndex),
          ...getFlowRule(values, flowCaptures, currentIndex),
          ...auxFlowQuestions.slice(currentRuleIndex)
        ]
      }
    }

    let names = auxFlowQuestions.map(flowQuestion => flowQuestion?.name);
    names = names.filter(name => name);

    let filteredValues = Object.keys(values).filter(name => !names.includes(name));
    filteredValues.forEach(name => setFieldValue(name, undefined));

    let definitions = auxFlowQuestions.map(flowQuestion =>
      flowQuestion && flowQuestion.definition ? flowQuestion.definition(values, flowCaptures, currentIndex) : null);
      
    definitions = definitions.filter(definition => definition);

    setCurrentDefinition(definitions.length > 0 ? definitions[definitions.length - 1] : null);
    setFlowQuestions(auxFlowQuestions);

    let lastQuestion = auxFlowQuestions[auxFlowQuestions.length - 1];
    
    let showHidePicker = lastQuestion && lastQuestion.component == "PICKER" && !values[lastQuestion.name]
    handleShowPicker(lastQuestion?.name, showHidePicker);

    if(lastQuestion && lastQuestion.name)
      setFormCompleted(lastQuestion.name === undefined);

  }, [values, flowCaptures, currentIndex, setFieldValue, setCurrentDefinition, currentRuleAdded]);

  const autocompletes = { "APPS": autoCompleteApps };

  return flowQuestions.map((flowQuestion, idx) =>
    getQuestionField(idx, flowQuestion, values, setFieldValue, handleShowPicker, 
      flowCaptures, currentIndex, autocompletes, showRegionPicker));

};

const getQuestionField = (
  questionIndex,
  flowQuestion,
  values,
  setFieldValue,
  handleShowPicker,
  flowCaptures,
  currentIndex,
  autocompletes,
  showRegionPicker
) => {

  if(!flowQuestion || !flowQuestion.component) return null;

  {/* SELECT */}
  if(flowQuestion.component == "SELECT" || flowQuestion.component == "SELECT_HORIZONTAL")
    return <Select 
      flowQuestion={flowQuestion}
      values={values}
      flowCaptures={flowCaptures}
      questionIndex={questionIndex}
      currentIndex={currentIndex}
      setFieldValue={setFieldValue}
    />;

  {/* TEXT */}
  if(flowQuestion.component == "TEXT")
    return <Text 
      flowQuestion={flowQuestion}
      values={values}
      flowCaptures={flowCaptures}
      questionIndex={questionIndex}
      currentIndex={currentIndex}
      setFieldValue={setFieldValue}
    />;

  {/* AUTOCOMPLETE */}
  if(flowQuestion.component == "AUTOCOMPLETE")
    return <Autocomplete 
      flowQuestion={flowQuestion}
      values={values}
      flowCaptures={flowCaptures}
      questionIndex={questionIndex}
      currentIndex={currentIndex}
      setFieldValue={setFieldValue}
      autocompletes={autocompletes}
    />;

  {/* PICKER */}
  if(flowQuestion.component == "PICKER")
    return <Picker 
    flowQuestion={flowQuestion}
    values={values}
    flowCaptures={flowCaptures}
    questionIndex={questionIndex}
    currentIndex={currentIndex}
    showRegionPicker={showRegionPicker}
    handleShowPicker={handleShowPicker}
  />;

  {/* CHECKBOX */}
  if(flowQuestion.component == "CHECKBOX")
    return <Checkbox 
      flowQuestion={flowQuestion}
      values={values}
      flowCaptures={flowCaptures}
      questionIndex={questionIndex}
      currentIndex={currentIndex}
      setFieldValue={setFieldValue}
    />;

  {/* LABEL */}
  if(flowQuestion.component == "LABEL")
    return <Label 
      flowQuestion={flowQuestion}
      values={values}
      flowCaptures={flowCaptures}
      questionIndex={questionIndex}
      currentIndex={currentIndex}
    />;

  {/* RULE */}
  if(flowQuestion.component == "RULE")
    return <Rule 
      flowQuestion={flowQuestion}
      values={values}
      flowCaptures={flowCaptures}
      questionIndex={questionIndex}
      currentIndex={currentIndex}
      setFieldValue={setFieldValue}
    />;

};

export default GeneratorForm;