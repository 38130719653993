/* eslint-disable max-len */
import React from "react";
import { NavLink, Link } from "react-router-dom";
import { ScriptTag, StyleTag } from "seed/helpers";


const PanelView = ({ user, onClickLogout }) => (
  <>

    <div id="start-settings" class="card">
      <div class="card-body p-0">
        <aside class="navbar navbar-vertical-aside navbar-vertical navbar-bordered navbar-vertical-aside-initialized" style={{ borderRadius: "10px" }}>
          <div class="navbar-vertical-container">
            <div class="navbar-vertical-footer-offset">

              <div class="d-flex align-items-center pl-2 py-1" style={{ borderBottom: ".12rem solid #e7eaf3", background: "#f7faff", borderRadius: "10px 10px 0px 0px" }}>

                <Link to="/" className="d-flex align-items-center justify-content-center"
                  style={{ paddingRight: "0.7rem", paddingLeft: "0.7rem" }}>
                  <i class="fas fa-arrow-left d-block"></i>
                </Link>

                <div class="rounded w-auto">
                  <Link to="/" className="back-button-flow mt-n1"></Link>
                </div>

                <StyleTag content={`
                  #start-settings .back-button-flow {
                    display: block;
                    width: 2.15rem;
                    height: 2.15rem;
                    background-image: url(/theme/svg/logos/logo-short.svg);
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 2.15rem;
                    cursor: default;
                  }

                `} />

                <div class="ml-1">

                  <a class="js-hs-unfold-invoker navbar-dropdown-account-wrapper rounded pl-2 pr-2"
                    style={{ cursor: "pointer" }}
                    data-hs-unfold-options='{
                      "target": "#accountNavbarDropdown",
                      "type": "css-animation"
                    }'>
                    <div class="ml-2">
                      <span class="d-block h5 text-hover-primary mb-0">
                        {user?.firstName ?? ""} {user?.lastName ?? ""} <small class="ml-1"><i class="fas fa-chevron-down"></i></small>
                      </span>
                      <span class="d-block font-size-sm text-body">
                        Plan {(user?.license?.name ?? "básico").toLowerCase()}
                      </span>
                    </div>
                  </a>

                  <div id="accountNavbarDropdown" style={{ width: "16rem", top: "4rem" }}
                    class="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account"
                  >

                    <Link to="/app" class="dropdown-item" style={{ cursor: "pointer" }}>
                      <span class="text-truncate pr-2" title="Profile &amp; account">Principal</span>
                    </Link>


                    <div class="dropdown-item">
                      <span
                        class="text-truncate pr-2"
                        title="Settings"
                        onClick={onClickLogout}
                        style={{ cursor: "pointer" }}
                      >
                        Cerrar sesión
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="navbar-vertical-content mt-3 mb-5 overflow-hidden">
                <ul class="navbar-nav navbar-nav-lg nav-tabs">


                  <li class="nav-item">
                    <NavLink to="/settings/account" className={`js-nav-tooltip-link nav-link`} >
                      <i class="fas fa-user ml-1 mr-3"></i>
                      <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Cuenta</span>
                    </NavLink>
                  </li>

                  <li class="nav-item">
                    <NavLink to="/settings/billing" className={`js-nav-tooltip-link nav-link`}>
                      <i class="fa-solid fa-money-bill ml-1 mr-3"></i>
                      <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Plan</span>
                    </NavLink>
                  </li>

                  <li class="nav-item">
                    <NavLink to="/settings/flows" className={`js-nav-tooltip-link nav-link`}>
                      <i class="fas fa-project-diagram ml-1 mr-3"></i>
                      <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Procesos</span>
                    </NavLink>
                  </li>                

                  <li class="nav-item">
                    <NavLink to="/settings/external_apps" className={`js-nav-tooltip-link nav-link`}>
                      <i class="fas fa-shapes ml-1 mr-3"></i>
                      <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Aplicaciones externas</span>
                    </NavLink>
                  </li>


                </ul>
              </div>

            </div>
          </div>
        </aside>
      </div>
    </div>

    <ScriptTag content={`
      // Initialization of unfold
      $('.js-hs-unfold-invoker').each(function () {
        var unfold = new HSUnfold($(this)).init();
      });
    `} />

  </>
);

export default PanelView;