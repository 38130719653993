import React from "react";
import SchemaForm from "components/schema_form/SchemaForm";

const parseStructure = (structure) => {

  const parser = (type) => {
    switch(type) {
      case "string": return "TEXT";
      case "enum": return "SELECT";
      case "date": return "DATE";
      case "file": return "FILE";
      case "boolean": return "CHECKBOX";
      case "float": return "TEXT";
      default: return "string";
    }
  }

  return structure.map((field) => {
    return {
      ...field,
      type: parser(field.type)
    }
  });

}

const Form = ({ structure, initialRow = {}, title, onSubmit }) => {

  const parsedStructure = parseStructure(structure);
  console.log(initialRow?.data ?? {})

  return <div className="card card-body">
    {title && <h4>{title}</h4>}
    <SchemaForm
      schema={parsedStructure}
      initialValues={initialRow?.data ?? {}}
      onSubmit={onSubmit}
    />
  </div>

}

export default Form;