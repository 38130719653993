import ExcelJS from "exceljs";
import { ColumnTypes, parseExportData } from "components/tables/util/datatypes";
import { downloadFile } from "components/util/file_util";

const TableCallbacks = ({
  _rows,
  _columns,
  isReadOnly,
  useCustomImport,
  useCustomExport,
  formValue,
  setIsFormModalShown,
  setRows,
  setSearch,
  setQuery,
	setFilters,
  setSelectedCell,
  onImport,
  onExport,
  onChangeSearch,
  onChangeQuery,
	onChangeFilters,
  onClickCell,
  _onAddRow,
  _onUpdateRow,
}) => {

  const _onImport = (importedRows, isOverwrite) => {
		if (useCustomImport) {
			onImport(importedRows, isOverwrite);
		}
		else {
			importedRows = importedRows.map(row => ({ data: row }));
			setRows(isOverwrite ? importedRows : [..._rows, ...importedRows]);
		}
	}

	const _onExport = (extension) => {
		if (useCustomExport) {
			onExport(extension);
		}
		else {

			if(extension === "csv") {

				const csv = _rows.map((row) => _columns.map((c) =>
					parseExportData(c, row.data[c.name]))
					.join(";")).join("\n");
	
				const blob = new Blob([csv], { type: "text/csv" });
				downloadFile(URL.createObjectURL(blob), null, "export.csv");
	
			}
			else {
	
				const sheetName = "Sheet1";
				const workbook = new ExcelJS.Workbook();
				const sheet = workbook.addWorksheet(sheetName);
	
				const excelColumns = [
					..._columns.map((c) => c.label),
					"ID Sistema",
					"Folio Sistema"
				];
	
				const excelRows = _rows.map((row) => [
					..._columns.map((c) => parseExportData(c, row.data[c.name])),
					row.id,
					row._folio
				]);
	
				sheet.addRow(excelColumns);
	
				const lastRow = sheet.lastRow;
				for(let i = 0; i < _columns.length + 2; i++) {
					if(i < _columns.length)
						lastRow.getCell(i + 1).font = { bold: true, color: { argb: "176dc7" } };
					else
						lastRow.getCell(i + 1).font = { bold: true, color: { argb: "AABBCC" } };
				}
	
				excelRows.forEach((row) => sheet.addRow(row));
	
				workbook.xlsx.writeBuffer().then((buffer) => {
					const blob = new Blob([buffer], { type: "application/octet-stream" });
					downloadFile(URL.createObjectURL(blob), null, "export.xlsx");
				});
	
			}

		}
	
	}

	const _onChangeSearch = (e) => {
		setSearch(e.target.value);
		onChangeSearch(e.target.value);
	}

	const _onChangeQuery = (query) => {
		setQuery(query);
		onChangeQuery(query);
	}

	const _onChangeFilters = (filters) => {
		setFilters(filters);
		onChangeFilters(filters);
	}

	const _onClickCell = (rowData, row, column) => {
		if(isReadOnly || _columns[column].type == ColumnTypes.OBJECT) return;
		onClickCell(rowData, row, column);
		setSelectedCell({ row, column });
	}

	const _onSubmitForm = (values) => {

		if(formValue) {
			const index = _rows.findIndex(row => row.id === formValue.id);
			_onUpdateRow(index, values);
		}
		else {
			_onAddRow(Object.assign({}, values));
		}

		setIsFormModalShown(false);

	}

  return {
    _onImport,
    _onExport,
    _onChangeSearch,
    _onChangeQuery,
		_onChangeFilters,
    _onClickCell,
    _onSubmitForm
  }

}

export default TableCallbacks;