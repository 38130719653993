import React from "react";
import { Link } from "react-router-dom";
import { StyleTag } from "seed/helpers";


const TopnavView = ({ flow, showTabs, pathname, onOpenEdit, onOpenTemplates, onOpenShare }) => (
  <div>
    <header id="header" class="navbar navbar-expand-xl navbar-fixed navbar-height navbar-flush navbar-container navbar-bordered ml-0"
      style={{ background: "#1A5080" }}
    >
      <div class="js-mega-menu navbar-nav-wrap hs-menu-initialized hs-menu-horizontal">

        <div class="navbar-brand-wrapper" style={{ display: "block" }}>
          <a class="navbar-brand" aria-label="Front">
            <img class="navbar-brand-logo" src="/theme/svg/logos/logo-white.svg" alt="Logo" />
          </a>
          {showTabs && (
             <li className="nav-item d-none d-sm-inline-block ml-4">
             <button className="btn btn-sm" 
              style={{color: "#fff", borderColor: "#ffffff90"}}
              onClick={() => window.parent.location.href = `/app/flow/${flow.id}/flow`}>
             <i class="fas fa-reply mr-2"></i> Ir a proceso
             </button>
           </li>
          )}

        </div>

        <div class="navbar-nav-wrap-content-right">
          <ul class="navbar-nav align-items-center flex-row">

         

            <li className="nav-item d-none d-sm-inline-block">
              <button id="edit_presentation_button" className="btn btn-sm btn-light" onClick={onOpenTemplates}>
              <i class="fas fa-th-large mr-2"></i> Ajustar plantilla
              </button>
            </li>

            <li className="nav-item d-none d-sm-inline-block mr-3">
              <button id="edit_presentation_button" className="btn btn-sm btn-light" onClick={onOpenEdit}>
              <i class="fas fa-pencil-ruler mr-2"></i> Personalizar diseño
              </button>
            </li>

            <li className="nav-item d-none d-sm-inline-block">
              <button id="edit_presentation_button" className="btn btn-sm text-white" 
              style={{background: "#f0b000", paddingRight: "0.85rem", paddingLeft: "0.85rem"}} onClick={onOpenShare}>
              Compartir
              </button>
            </li>

            <li class="nav-item" style={{visibility: "hidden"}}>
              <button type="button" class="navbar-toggler btn btn-ghost-secondary rounded-circle" aria-label="Toggle navigation"
                aria-expanded="false" aria-controls="navbarNavMenu" data-toggle="collapse" data-target="#navbarNavMenu">
                <i class="tio-menu-hamburger"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>

    <div className="row justify-content-center" style={{ marginTop: "5rem" }}>
      {(flow.presentationSettings?.sections ?? []).length > 1 && (
        flow.presentationSettings?.sections ?? []).map((section, idx) => (
          <Link to={`/section/${idx}`} key={idx}>
            <div className={`col-auto nav-badge ${pathname.replaceAll("section", "").replaceAll("/", "") == idx ? "active" : ""}`}>
              <span class="badge badge-pill badge-light py-2 px-3">
                <i className="fa-solid fa-minus mr-3"></i>
                {section.title}
              </span>
            </div>
          </Link>
        )
        )}
    </div>

    <StyleTag content={`

      .nav-badge {
        font-size: 1.6rem;
      }

      .nav-badge span {
        background-color: white;
        color: #132144;
        border: 1px solid #132144;
        font-weight: 100 !important;
      }

      .nav-badge.active span {
        background-color: #132144;
        color: white;
      }

      .nav-badge:hover span {
        background-color: #132144;
        color: white;
      }

      `} />

  </div>
);

export default TopnavView;