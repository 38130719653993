/* eslint-disable max-len */
import React from "react";
import { Link } from "react-router-dom";
import { ScriptTag, StyleTag } from "seed/helpers";
import FolderItem from "components/start/folders/FolderItem"
import ExecutionsList from "components/start/executions/ExecutionsList";


const PanelView = ({
  user,
  folders,
  selectedFolder,
  setSelectedFolder,
  onClickLogout,
}) => (
  <>

    <div id="start" class="card"
      style={{ border: "1px solid #d2dae0" }}>
      <div class="card-body p-0">
        <aside class="navbar navbar-vertical-aside navbar-vertical navbar-bordered navbar-vertical-aside-initialized" style={{ borderRadius: "10px" }}>
          <div class="navbar-vertical-container">
            <div class="navbar-vertical-footer-offset">

              <div class="d-flex align-items-center pl-2 py-1" style={{ borderBottom: ".12rem solid #e7eaf3", background: "#f7faff", borderRadius: "10px 10px 0px 0px" }}>

                <div class="rounded w-auto" style={{paddingLeft: "1.25rem"}}>
                  <a href="/app" className="back-button-flow mt-n1"></a>
                </div>

                <StyleTag content={`
                  #start .back-button-flow {
                    display: block;
                    width: 2.15rem;
                    height: 2.15rem;
                    background-image: url(/theme/svg/logos/logo-short.svg);
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 2.15rem;
                    cursor: default;
                  }
                `} />

                <div class="">

                  <a class="js-hs-unfold-invoker navbar-dropdown-account-wrapper rounded pl-2 pr-2"
                    style={{ cursor: "pointer" }}
                    data-hs-unfold-options='{
                      "target": "#accountNavbarDropdown",
                      "type": "css-animation"
                    }'>
                    <div class="ml-2">
                      <span class="d-block h5 text-hover-primary mb-0">
                        {user?.firstName ?? ""} {user?.lastName ?? ""} <small class="ml-1"><i class="fas fa-chevron-down"></i></small>
                      </span>
                      <span class="d-block font-size-sm text-body">
                        Plan {(user?.license?.name ?? "básico").toLowerCase()}
                      </span>
                    </div>
                  </a>

                  <div id="accountNavbarDropdown" style={{ width: "16rem", top: "4rem" }}
                    class="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account"
                  >

                    <Link to="/settings/account" class="dropdown-item" style={{ cursor: "pointer" }}>
                      <span class="text-truncate pr-2" title="Profile &amp; account">Perfil</span>
                    </Link>

                    {window.innerWidth < 540 ?
                      <Link to="/settings/account" class="dropdown-item" style={{ cursor: "pointer" }}>
                        <span class="text-truncate pr-2" title="Profile &amp; account">Ajustes</span>
                      </Link>
                      : null}

                    <div class="dropdown-item">
                      <span
                        class="text-truncate pr-2"
                        title="Settings"
                        onClick={onClickLogout}
                        style={{ cursor: "pointer" }}
                      >
                        Cerrar sesión
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="navbar-vertical-content mt-3 mb-4 overflow-hidden d-none d-md-block">

                <StyleTag content={`
                      #start .navbar-vertical-content .nav-link {
                        color: #6a7080 !important;
                      }
                      #start .navbar-vertical-content .nav-link.active,
                      #start .navbar-vertical-content .nav-link:hover  {
                        color: #2080e5 !important;
                      }
                    `} />

                <ul class="navbar-nav navbar-nav-lg nav-tabs">
                  <li class="navbar-vertical-aside-has-menu">
                    <a class={`js-nav-tooltip-link nav-link ${!selectedFolder && "active"}`} title="Principal"
                      style={{ cursor: "pointer" }} onClick={() => setSelectedFolder(null)}>
                      <i class="fas fa-home mr-2"></i>
                      <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Principal</span>
                    </a>

                    <ul class="js-navbar-vertical-aside-submenu nav nav-sub" style={{ display: "block" }}>
                      {folders.map((folder) => (
                        <FolderItem folder={folder} key={folder.id} selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} />
                      ))}
                      <li class="nav-item mt-1">
                        <Link className="nav-link" to="/create_folder" style={{ cursor: "pointer", fontSize: "0.97em" }}>
                          <i class="fas fa-plus mr-2"></i>
                          <span class="text-truncate">Crear carpeta</span>
                        </Link>
                      </li>
                    </ul>

                  </li>

                  <li class="nav-item py-2">
                    <div class="nav-divider"></div>
                  </li>

                  <li class="nav-item">
                    <Link to="settings/account" class="js-nav-tooltip-link nav-link">
                      <i class="fas fa-cog mr-2"></i>
                      <span class="text-truncate">Ajustes</span>
                    </Link>
                  </li>

                </ul>

              </div>

            </div>
          </div>
        </aside>
      </div>
    </div>


    <div className="card mt-3 d-none d-md-block"
      style={{ border: "1px solid #d2dae0" }}>
      <div className="card-body">
        <small class="nav-subtitle mb-3" title="Pages">Acciones pendientes</small>
        <div className="scrollbar-narrow" style={{ maxHeight: "9.8rem", minHeight: "1.29rem", overflowY: "auto" }}>
          <ExecutionsList
            status={"AWAITING"}
            placeholder="No hay acciones pendientes"
            user_id={user.id}
          />
        </div>
      </div>
    </div>

    <ScriptTag content={`
      // Initialization of unfold
      $('.js-hs-unfold-invoker').each(function () {
        var unfold = new HSUnfold($(this)).init();
      });
    `} />

  </>
);

export default PanelView;