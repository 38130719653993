import React from "react";
import View from "components/presentation/templates/side_template/SideTemplate.view";

function SideTemplate({ flow, showTabs, onOpenEdit, onOpenTemplates, onOpenShare }) {

  return <View
    flow={flow}
    showTabs={showTabs}
    onOpenEdit={onOpenEdit}
    onOpenTemplates={onOpenTemplates}
    onOpenShare={onOpenShare} />;
}

export default SideTemplate;