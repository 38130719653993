import React, { useEffect } from "react";
import swal from "sweetalert";
import { useDetail } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import * as util from "components/presentation/util/presentation";
import View from "components/presentation/FormSave.view";

function FormSave({ flowId, reset, onResetPresentation }) {

  const [callSave, reqSave] = usePost("/flows/save_presentation", {
    onCompleted: () => {
      onResetPresentation();
    },
    onError: (error) => {
      swal("Error al crear su presentación", "Por favor intente de nuevo mas tarde", "error")
    }
  })

  const reqFlow = useDetail(`{
    flow {
      name
      presentationSettings
    }
  }`, flowId)

  useEffect(() => {
    reqFlow.refetch();
  }, [reset])

  const onSubmit = (values) => {
    callSave({
      flow_id: flowId,
      presentation_settings: values
    })
  }

  if (reqFlow.loading) return <Loading />;
  if (reqFlow.error) return "Error";

  const { flow = {} } = reqFlow.data
  const initialValues = flow?.presentationSettings ?? {};

  return (
    <View
      schema={util.presentationSchema}
      flowId={flowId}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
}

export default FormSave;