import React from "react";
import SchemaForm from "components/schema_form/SchemaForm";


const FormSaveView = ({ schema, flowId, initialValues, onSubmit }) => (
  <div className="card">
    <div className="card-header">
      <h2>Personalizar diseño</h2>
    </div>

    <div className="card-body">

      <SchemaForm
        initialValues={initialValues}
        schema={schema}
        flowId={flowId}
        onSubmit={onSubmit}
      />

    </div>
  </div>
)

export default FormSaveView;