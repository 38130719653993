import React from "react";
import { Link } from "react-router-dom"

const FolderItemView = ({
  folder,
  selectedFolder,
  isHovered,
  setSelectedFolder,
  onMouseEnter,
  onMouseLeave
}) => (
  <li class="nav-item" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
    <a class={`nav-link ${selectedFolder == folder.id ? "active" : ""}`}
      title={folder.name} style={{ cursor: "pointer" }}
      onClick={() => setSelectedFolder(folder.id)}>
      <i class="fas fa-folder mr-2"></i>
      <span class="text-truncate">{folder.name}</span>
      {isHovered && (
        <div className="ml-3">
          <Link to={`/${folder.id}/edit_folder`}>
            <i class="fas fa-pen"></i>
          </Link>
        </div>
      )}
    </a>
  </li>
)

export default FolderItemView;