import React from "react";

const presentationTutorialSteps = [
	{
		selector: "#presentation-container",
		content: () => <label><b>En la sección de presentación, podrás convertir tu proceso en un sitio web para compartir.</b></label>
	},
	{
		selector: "#presentation-ctas-frame",
		content: () => <label><b>Personaliza tu sitio web con diferentes secciones y acciones.</b></label>
	}	
];

export { presentationTutorialSteps }