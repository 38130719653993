import React, {useState} from "react";
import { useEditor } from '@tiptap/react';
import Placeholder from '@tiptap/extension-placeholder';
import Paragraph from '@tiptap/extension-paragraph';
import Mention from '@tiptap/extension-mention';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import History from '@tiptap/extension-history';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import { PluginKey } from "prosemirror-state";
import ImageResize from "components/schema_form/fields/rich_text/Editor.lib.image";
import TableBorder from "components/schema_form/fields/rich_text/Editor.lib.table";
import OptionList from "components/schema_form/fields/rich_text/OptionList";
import * as lib from "components/schema_form/fields/rich_text/Editor.lib";
import View from "components/schema_form/fields/rich_text/Editor.view";

const CustomDocument = Document.extend({
  content: 'text*',
});

function Editor({
  nodes,
  collections,
  datatypes,
  initialValue,
  singleLine,
  rows,
  placeholder,
  showStyles,
  onChangeValue
}) {

  const [wrapperId, setWrapperId] = useState(Math.floor(Math.random() * 100000000))

  const extensions = [
    CustomDocument,
    Paragraph,
    Text,
    History,
    ImageResize.configure({
      allowBase64: true,
      handleSize: 8,
    }),
    TableBorder.configure({
      // resizable: true,
    }),
    TableCell,
    TableHeader,
    TableRow,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    Mention.configure({
      HTMLAttributes: {
        class: 'variable',
      },
      renderHTML: lib.renderHtml,
      suggestion: {
        char: "#",
        pluginKey: new PluginKey("referenceSuggestions"),
        items: ({ query }) => {
          const options = lib.getReferenceOptions(nodes, collections);
          return options.filter((item) => item.label.toLowerCase().startsWith(query.toLowerCase()))
        },
        render: () => lib.render(OptionList, { isVariable: true }),
      },
    }),
    Mention.configure({
      HTMLAttributes: {
        class: 'variable',
      },
      renderHTML: lib.renderHtml,
      suggestion: {
        char: ".",
        pluginKey: new PluginKey("atributeSuggestions"),
        items: ({ editor, query }) => {
          const contentJson = editor.getJSON();
          let content = contentJson.content.length > 0 ? contentJson.content[0] : {};
          content = content.content;
          console.log("content", content)

          const options = lib.getAtributeOptions(content, nodes, collections, datatypes);
          console.log("options", options)
          return options.filter((item) => item.label.toLowerCase().startsWith(query.toLowerCase()))
        },
        render: () => lib.render(OptionList, { isVariable: false })
      },
    }),
    Placeholder.configure({
      placeholder: placeholder
    }),
  ]

  if (showStyles)
    extensions.push(
      StarterKit.configure({
        history: false,
        heading: { levels: [1, 2] },
      })
    )

  const editor = useEditor({
    extensions: extensions,
    content: initialValue.includes("<") ? initialValue : lib.getInitialContent(nodes, collections, datatypes, initialValue),
    editorProps: {
      handleDOMEvents: {
        keydown: (_, event) => {
          if (event.key === "Enter" && singleLine) {
            event.preventDefault();
            return false;
          }
        }
      }
    },
    onUpdate: ({ editor }) => {

      const html = editor.getHTML();
      const contentJson = editor.getJSON();

      let content = contentJson?.content ?? [];
      let containsStyles = false;
      for (const item of content) {
        if (item?.type != "paragraph" && item?.type != "text" && item?.type != "mention") {
          containsStyles = true;
          break;
        }
      }

      let finalContent = "";
      for (const item of content) {
        for (const subItem of item?.content ?? []) {
          const type = subItem?.type ?? "";
          if (type == "text") {
            finalContent += subItem?.text ?? "";
          } else if (type == "mention") {
            finalContent += subItem?.attrs?.id ?? "";
          }
        }
      }

      // Join variable and dots (attributes)
      finalContent = finalContent?.replace(/(@|#)((\w|( \.))+)/g, (match) => {
        let newMatch = match.replaceAll(" ", "")
        return newMatch
      })

      onChangeValue(containsStyles ? html : finalContent);

    }
  })

  return (
    <View
      wrapperId={wrapperId}
      singleLine={singleLine}
      rows={rows}
      showStyles={showStyles}
      editor={editor}
    />
  );
}

export default Editor;