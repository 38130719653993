import React from "react";
import Plan from "components/misc/plans/Plan";

const PlansView = ({ professionalFeatures, premiumFeatures, enterpriseFeatures }) => (
  <div className="card">
    <div className="card-body p-5">
      <div class="row">
        <div class="col-md-4 mb-3 mb-sm-5">
          <Plan
            name={"Profesional"}
            price={"20"}
            totalCredits={100}
            usedCredits={50}
            features={professionalFeatures}
          />
        </div>

        <div class="col-md-4 col-lg-4 mb-3 mb-sm-5">
          <Plan
            name={"Premium"}
            price={"50"}
            totalCredits={200}
            features={premiumFeatures}
          />
        </div>

        <div class="col-md-4 col-lg-4 mb-3 mb-sm-5">
          <Plan
            name={"Enterprise"}
            price={"100"}
            totalCredits={10000}
            features={enterpriseFeatures}
          />
        </div>
      </div>
    </div>
  </div>
);

export default PlansView;