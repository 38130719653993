import lodash from "lodash";

const getShowInput = (parentKey, showIf, values) => {

  for (const conditional of showIf) {

    const variable = parentKey == "" ? conditional.variable : `${parentKey}.${conditional.variable}`
    const variableValue = lodash.get(values, variable)
    const value = conditional.value;
    const condition = conditional?.condition ?? "EQUAL";

    if (condition == "EQUAL" && !(variableValue == value)) return false
    if (condition == "NOT_EQUAL" && !(variableValue != value)) return false
    if (condition == "GREATER_THAN" && !(variableValue > value)) return false
    if (condition == "LOWER_THAN" && !(variableValue < value)) return false
    if (condition == "GRATER_THAN_OR_EQUAL" && !(variableValue >= value)) return false
    if (condition == "LOWER_THAN_OR_EQUAL" && !(variableValue <= value)) return false
    if (condition == "CONTAINS" && !(variableValue.includes(value))) return false
  }
  return true;
}

export { getShowInput }