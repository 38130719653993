const TableListeners = ({
  _rows,
  _columns,
  trackKeybindings,
  shiftPressedRef,
  selectedCell,
  setSelectedCell,
}) => {

  const onKeyDown = (e) => {

    if (selectedCell && selectedCell.row != -1 && trackKeybindings) {

      let row = selectedCell.row;
      let column = selectedCell.column;

      if (e.key === "Shift") shiftPressedRef.current = true;
      if (shiftPressedRef.current) return;

      if (e.key === "ArrowUp") row--;
      if (e.key === "ArrowDown") row++;
      if (e.key === "ArrowLeft") column--;
      if (e.key === "ArrowRight") column++;

      if (row < 0) row = 0;
      if (row >= _rows.length) row = _rows.length - 1;
      if (column < 0) column = 0;
      if (column >= _columns.length) column = _columns.length - 1;

      setSelectedCell({ row, column });

    }

  }

  const onKeyUp = (e) => {
    if (e.key === "Shift")
      shiftPressedRef.current = false;
  }

  return {
    onKeyDown,
    onKeyUp
  }

}

export default TableListeners;