/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
import React, { useState, useRef, useCallback, useEffect } from "react";
import { useEdgesState, useOnSelectionChange } from 'reactflow';
import { toast, Flip } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { usePost, useGet } from "seed/api";
import { useSet } from "seed/gql";
import { SET_EXECUTION } from "seed/gql/queries";
import { Loading } from "seed/helpers";
import { useWS } from "seed/ws";
import { SERVER_URL, APP_URL } from "settings";
import { useNodeCustomState } from "components/flow/Controller.lib.state";
import { downloadFile, uploadFile } from "components/util/file_util";
import * as board from "components/flow/Controller.lib.board";
import * as callback from "components/flow/Controller.lib.callback";
import View from "components/flow/Controller.view";
import { addListener } from "components/util/win";


function Controller({
  initialNodes = [],
  initialEdges = [],
  isRPADesigner = false,
  actions,
  flow,
  history,
  historyPosition,
  activePage,
  isAnonymous,
  setActivePage,
  setHistoryPosition,
  reloadFlow,
  onUpdateHistory
}) {

  const urlParams = new URLSearchParams(window.location.search);
  const resume = urlParams.get('resume');

  const onSaveBoard = (nodes, edges) => {
    onUpdateHistory(nodes, edges);
    callback.onSaveBoard(nodes, edges, flow.id, activePage, callAutoSave);
  }

  const onSaveUi = (nodes) => {
    callback.onSaveUiBoard(nodes, callSaveUi);
  }

  const userId = sessionStorage.getItem("id");
  const isDeveloper = sessionStorage.getItem("developer_mode") == "true"
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodeCustomState(initialNodes, onSaveUi);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [isDesignerShow, setIsDesignerShow] = useState(false);
  const [isContactUsShown, setIsContactUsShown] = useState(false);
  const [isExecutionDetailsShown, setIsExecutionDetailsShown] = useState(false);
  const [actualExecutionId, setActualExecutionId] = useState(null);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const historyRouter = useHistory();

  const onExecuteNode = (remoteId) => {
    setNodes((nodes) => {
      board.onExecuteNode(nodes, edges, actions, remoteId, callExecute);
      return nodes
    })
  }

  const [callExport, reqExport] = usePost("/flows/export_flow", {
    onCompleted: (data) => downloadFile(SERVER_URL + data.url)
  });

  const [callImport, reqImport] = usePost("/flows/import_flow", {
    onCompleted: (data) => {
      window.location.reload();
    }
  });

  const [callExecute, reqExecute] = usePost("/flows/execute_node")

  const reqLatestExecutions = useGet('/executions/get_latest_executions', { flow_id: flow.id });

  const [callUpdateExecutionRead] = usePost('/executions/update_executions_read', {
    onCompleted: () => reqLatestExecutions.refetch()
  });

  const [callResume, reqResume] = usePost("/executions/resume_execution");

  const [callSetRead, reqSetRead] = useSet(SET_EXECUTION);

  useEffect(() => {
    addListener((data) => {
      if (data.type == "refresh_flow")
        window.location.reload();
    })
    // iFrame listener
    window.addEventListener('message', ({ message, data, origin }) => {
      if (origin == APP_URL && data?.action == "close_modal")
        historyRouter.replace("/flow")
    });
  }, [])

  useWS(`EXECUTION_STATUS_${userId}`, {
    onMessageReceived: (message) => {
      callSetRead({ id: message.data.execution_id, isRead: true });
      showToast(message.data.status, message.data.execution_id, message.data.message)
      reqLatestExecutions.refetch()
    }
  });

  useEffect(() => {
    const [newNodes, newEdges, annotations] = board.onInitBoard(nodes, edges, onExecuteNode, onChangeTriggerStatus, onSaveBoard, onAttachNode, onSelectNode, onDeleteNode);
    setEdges(newEdges);
    setNodes([...newNodes, ...annotations]);
    callValidate({ flow_id: flow.id })
  }, []);

  useEffect(() => {
    if (resume && reqResume.loading == false && reqResume.data == null)
      setTimeout(() => callResume({ execution_id: resume }), 2000);
  }, [resume, reqResume])

  const refetchExecutions = () => reqLatestExecutions.refetch();

  const onClickAlertBell = () => callUpdateExecutionRead({ flow_id: flow.id })
  const onClickAlertBanner = () => callUpdateExecutionRead({ flow_id: flow.id })

  const onClickExport = () => callExport({ flow_id: flow.id });
  const onClickImport = () => uploadFile((file) => callImport({ flow_id: flow.id, file }));


  const showToast = (status, executionId, message) => {
    let attrs = {
      position: "top-right",
      autoClose: status == "RUNNING" ? false : 2250,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      toastId: executionId,
      transition: Flip,
      onClick: () => {
        setIsExecutionDetailsShown(true);
        setActualExecutionId(executionId);
      }
    }
    let text = ""
    let subtitle = ""
    if (status == "RUNNING") {
      text = 'Proceso en ejecución ...'
      attrs.icon = ({ theme, type }) => <Loading size={18} />
      attrs.type = "info"
    }
    if (status == "OK") {
      text = 'Proceso completado'
      attrs.icon = null
      attrs.type = "success"
    }
    if (status == "FAILED") {
      text = 'Ha ocurrido un error'
      subtitle = message?.type == "flow_limit" ? "Revisa los límites de tu plan" : ""
      attrs.icon = null
      attrs.type = "error"
    }
    if (status == "AWAITING") {
      toast.dismiss(executionId);
      return
    }

    if (isDeveloper) text += ` (exec_id=${executionId})`

    let result = (
      <div style={{ cursor: "pointer !important" }}>
        <label>{text}</label>
        {subtitle ? <label><span style={{ fontSize: "0.9em" }}>{subtitle}</span></label> : null}
      </div>
    )

    if (!toast.isActive(executionId)) toast(result, attrs);
    else toast.update(executionId, { ...attrs, render: result });
  }

  /*----------------------------------------------------------------*/
  /* Edges                                                          */
  /*----------------------------------------------------------------*/

  // Function when a edge is connected to node
  const onConnectEdge = useCallback(({ source, target, sourceHandle, targetHandle }) => {
    board.onConnectEdges(source, target, sourceHandle, targetHandle, setNodes, setEdges, onSaveBoard, onAttachNode);
  }, [edges, setEdges])

  // Function when a already connected edge is modified between two nodes
  const onUpdateEdge = useCallback((oldEdge, connection) => {
    board.onUpdateEdge(oldEdge, connection, edges, setNodes, setEdges, onSaveBoard, onAttachNode);
  }, [edges, setEdges])


  /*----------------------------------------------------------------*/
  /* Nodes                                                          */
  /*----------------------------------------------------------------*/

  // Function when a node is dragged over the board
  const onDragNode = useCallback((event) => {
    board.onDragNode(event);
  }, []);

  // Function when a node is dragged over the board
  const onDropNode = (event) => {
    board.onDropNode(event, reactFlowWrapper, reactFlowInstance, onClickNode, onClickAnnotation);
  }

  // Function to delete a single node
  const onDeleteNode = (id) => {

    let newEdges = [];
    setEdges((eds) => {
      newEdges = eds.filter((ed) => ed.source != id && ed.target != id);
      return newEdges;
    })

    setNodes((nds) => {
      const newNodes = nds.filter((nd) => nd.id != id);
      onSaveBoard(newNodes, newEdges);
      return newNodes;
    });

  }

  // Function when node is clicked on the toolbox panel
  const onClickNode = (actionId, initialPosition = null) => {

    const action = actions.find((action) => action.id == actionId);
    if (!action) return;

    const uiSettings = action.uiSettings;
    const fields = uiSettings.form?.fields ?? [];

    if (fields.length == 0) {
      onCreateNode({}, actionId, initialPosition);
      return;
    }
    let isAnnotation = action.app.name == "annotations";
    let url = !isAnnotation ? `/flow/create/${actionId}` : `/flow/create_annotation/${actionId}`;
    if (initialPosition) url += `?x=${initialPosition.x}&y=${initialPosition.y}`;
    historyRouter.replace(url);
  }

  // Callback function to create a node
  const onCreateNode = (values, actionId, position = null, extraAttributes = {}) => {

    const keys = Object.keys(values);
    const input_value = keys.map((key) => {
      return { name: key, value: values[key] };
    });

    const newNode = {
      type: "node",
      isAnnotation: false,
      data: {
        actionId: actionId,
        input_value: input_value,
        ...extraAttributes
      }
    }
    if (position)
      newNode.position = position;

    onSaveBoard([...nodes, newNode], [...edges])
    historyRouter.replace(`/flow`);

  }

  // Callback function to edit a node
  const onEditNode = (values, remoteId, isDeletingNode = false) => {
    if (isDeletingNode) {
      const node = nodes.find((node) => node.remoteId == remoteId && node.type == "node");
      if (!node) return;
      onDeleteNode(node.id);
    } else {
      board.onEditNode(remoteId, values, edges, setNodes, onSaveBoard);
    }
    historyRouter.replace(`/flow`);
  }

  // Function to close the modal of create/edit a node
  const onCloseModalRoute = () => {
    historyRouter.replace(`/flow`);
  }

  // Fucntion to select a node on the board
  const onSelectNode = (id) => {
    setSelectedNodes((prevSelectedNodes) => {
      if (prevSelectedNodes.includes(id)) {
        return prevSelectedNodes.filter((node) => node != id)
      } else {
        return prevSelectedNodes.concat(id)
      }
    })
  }

  // Function to delete selected nodes on the board
  const onDeleteSelectedNodes = () => {

    let newEdges = [];
    setEdges((eds) => {
      let edgesToDelete = new Set();
      for (const node of selectedNodes) {
        for (const edge of eds) {
          if (edge.source == node || edge.target == node) {
            edgesToDelete.add(edge.remoteId)
          }
        }
      }
      edgesToDelete = Array.from(edgesToDelete);
      newEdges = eds.filter((ed) => {
        if (edgesToDelete.includes(ed.remoteId)) return false;
        return true;
      });
      return eds;
    })

    setNodes((nds) => {
      const newNodes = nds.filter((nd) => {
        if (selectedNodes.includes(nd.id)) return false;
        return true;
      });
      onSaveBoard(newNodes, newEdges);
      return nds;
    });

    setSelectedNodes([]);

  }

  // Fucntion when a trigger changes its status
  const onChangeTriggerStatus = (id) => {

    setNodes((nodes) => {
      let actualNode = nodes.find((nd) => nd.id == id);
      actualNode.data.is_enabled = !actualNode.data.is_enabled;
      onSaveBoard(nodes, edges)
      return nodes;
    });
  }

  const onChangeSelectedNodes = useCallback(({ nodes, edges }) => {
    // setSelectedNodes((prevSelectedNodes) => {
    //   const prevSetSelectedNodes = new Set(prevSelectedNodes)
    //   for (const node of nodes) {
    //     prevSetSelectedNodes.add(node.id);
    //   }
    //   return Array.from(prevSetSelectedNodes)
    // })
    // setNodes((prevNodes) => {
    //   const newNodes = [...prevNodes];
    //   for (const node of nodes) {
    //     const selectedNode = newNodes.find((nd) => nd.id == node.id);
    //     if (!selectedNode) continue;
    //     selectedNode.data.is_selected = true;
    //   }
    //   return newNodes;
    // })
  }, [])

  useOnSelectionChange({
    onChange: onChangeSelectedNodes
  });

  /*----------------------------------------------------------------*/
  /* Annotations                                                    */
  /*----------------------------------------------------------------*/

  // Function when an annotation is clicked to put it
  const onClickAnnotation = (actionId, initialPosition = null) => {

    const action = actions.find((action) => action.id == actionId);
    if (!action) return;

    let url = `/flow/create_annotation/${actionId}`;
    if (initialPosition) url += `?x=${initialPosition.x}&y=${initialPosition.y}`;
    historyRouter.replace(url);

  }

  // Callback function to create an annotation
  const onCreateAnnotation = (values, actionId, position = null) => {

    const keys = Object.keys(values);
    const input_value = keys.map((key) => {
      return { name: key, value: values[key] };
    });

    const action = actions.find((action) => action.id == actionId);
    if (!action) return;

    const newNote = {
      type: action.name,
      isAnnotation: true,
      data: {
        actionId: actionId,
        input_value: input_value
      }
    }

    if (position)
      newNote.position = position;

    onSaveBoard([...nodes, newNote], [...edges])
    historyRouter.replace(`/flow`);

  }

  // Callback function to edit an annotation
  const onEditAnnotation = (values, remoteId, isDeletingNode = false) => {
    if (isDeletingNode) {
      const annotation = nodes.find((node) => node.remoteId == remoteId && node.isAnnotation == true);
      if (!annotation) return;
      onDeleteNode(annotation.id);
    } else {
      board.onEditAnnotation(remoteId, values, edges, setNodes, onSaveBoard);
    }
    historyRouter.replace(`/flow`);

  }


  /*----------------------------------------------------------------*/
  /* General                                                        */
  /*----------------------------------------------------------------*/

  const onClickUndo = () => {
    if (historyPosition > 0) {
      setHistoryPosition((prev) => prev - 1);
      const lastState = history[historyPosition - 1];
      const nodes = [...lastState.nodes, ...lastState.annotations];
      setNodes(nodes);
      setEdges([...lastState.edges]);
      onSaveBoard(nodes, [...lastState.edges]);
    }
  }

  const onClickRedo = () => {
    if (historyPosition < history.length - 1) {
      setHistoryPosition((prev) => prev + 1);
      const nextState = history[historyPosition + 1];
      const nodes = [...nextState.nodes, ...nextState.annotations];
      setNodes(nodes);
      setEdges([...nextState.edges]);
      onSaveBoard(nodes, [...nextState.edges]);
    }
  }

  // Function to insert a node between two nodes already connected
  const onAttachNode = (edgeId, actionName) => {
    board.onAttachNode(edgeId, actionName, setEdges);
  }

  const [callAutoSave, reqAutoSave] = usePost('/flows/save', {
    onCompleted: (data) => {
      callback.onAutoSave(
        data, selectedNodes, setNodes, setEdges, onExecuteNode, onChangeTriggerStatus, onSelectNode, onDeleteNode);
    }
  });

  const [callSaveUi, reqSaveUi] = usePost('/flows/save_ui');

  const [callValidate, reqValidate] = usePost('/flows/validate', {
    onCompleted: (data) => {
      callback.onUpdateErrorWarnings(nodes, data, setNodes)
    }
  });

  let alertStatus = {};
  const isFlowBeingSaved = reqAutoSave.loading;
  const latestExecutions = reqLatestExecutions.data ?? []
  const isPendingActivity = latestExecutions.filter((execution) => 
    execution.status != "RUNNING").length > 0;

  for (let execution of latestExecutions) {
    if (execution.status == "AWAITING")
      alertStatus["AWAITING"] = true
    if (execution.status == "RUNNING")
      alertStatus["RUNNING"] = true
  }

  return (
    <View
      flow={flow}
      nodes={nodes}
      edges={edges}
      userId={userId}
      selectedNodes={selectedNodes}
      activePage={activePage}
      alertStatus={alertStatus}
      reactFlowWrapper={reactFlowWrapper}
      actualExecutionId={actualExecutionId}
      isFlowBeingSaved={isFlowBeingSaved}
      isDeveloper={isDeveloper}
      isAnonymous={isAnonymous}
      isContactUsShown={isContactUsShown}
      isDesignerShow={isDesignerShow}
      isRPADesigner={isRPADesigner}
      isExecutionDetailsShown={isExecutionDetailsShown}
      isPendingActivity={isPendingActivity}
      setIsContactUsShown={setIsContactUsShown}
      setReactFlowInstance={setReactFlowInstance}
      setActivePage={setActivePage}
      setIsDesignerShow={setIsDesignerShow}
      setIsExecutionDetailsShown={setIsExecutionDetailsShown}
      onDropNode={onDropNode}
      onDragNode={onDragNode}
      onConnectEdge={onConnectEdge}
      onUpdateEdge={onUpdateEdge}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onClickNode={onClickNode}
      onCreateNode={onCreateNode}
      onEditNode={onEditNode}
      onCreateAnnotation={onCreateAnnotation}
      onEditAnnotation={onEditAnnotation}
      onCloseModalRoute={onCloseModalRoute}
      onClickAlertBanner={onClickAlertBanner}
      onClickAlertBell={onClickAlertBell}
      onClickExport={onClickExport}
      onClickImport={onClickImport}
      onClickAnnotation={onClickAnnotation}
      onClickUndo={onClickUndo}
      onClickRedo={onClickRedo}
      onDeleteSelectedNodes={onDeleteSelectedNodes}
      reloadFlow={reloadFlow}
      refetchExecutions={refetchExecutions}
    />
  );

}

export default Controller;