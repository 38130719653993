/*
__Seed builder__
  AUTO_GENERATED (Read only)
  Modify via builder
*/

export const ACTION = `
{
  action {
    id
    name
    description
    type
    isHidden
    isResumable
    isReferenceable
    developerOnly
    desktopOnly
    underDev
    asyncExecution
    awaitingAlert
    filterCall
    relevance
    uiSettings
    inputStructure
    outputStructure
    baseUsageLimits
    src
    suggestions {
      id
    }
    app {
      id
    }
  }
}
`;

export const SET_ACTION = `
mutation Set(
  $id: Int!,
  $name: String,
  $description: String,
  $type: String,
  $isHidden: Boolean,
  $isResumable: Boolean,
  $isReferenceable: Boolean,
  $developerOnly: Boolean,
  $desktopOnly: Boolean,
  $underDev: Boolean,
  $asyncExecution: Boolean,
  $awaitingAlert: Boolean,
  $filterCall: String,
  $relevance: Float,
  $uiSettings: GenericScalar,
  $inputStructure: GenericScalar,
  $outputStructure: GenericScalar,
  $baseUsageLimits: GenericScalar,
  $src: GenericScalar,
  $suggestions: [Int],
  $app: Int,
)
{
  setAction(
    id: $id,
    name: $name,
    description: $description,
    type: $type,
    isHidden: $isHidden,
    isResumable: $isResumable,
    isReferenceable: $isReferenceable,
    developerOnly: $developerOnly,
    desktopOnly: $desktopOnly,
    underDev: $underDev,
    asyncExecution: $asyncExecution,
    awaitingAlert: $awaitingAlert,
    filterCall: $filterCall,
    relevance: $relevance,
    uiSettings: $uiSettings,
    inputStructure: $inputStructure,
    outputStructure: $outputStructure,
    baseUsageLimits: $baseUsageLimits,
    src: $src,
    suggestions: $suggestions,
    app: $app,
  ) {
    action {
      id
      name
      description
      type
      isHidden
      isResumable
      isReferenceable
      developerOnly
      desktopOnly
      underDev
      asyncExecution
      awaitingAlert
      filterCall
      relevance
      uiSettings
      inputStructure
      outputStructure
      baseUsageLimits
      src
      suggestions {
        id
      }
      app {
        id
      }
    }
  }
}
`;

export const SAVE_ACTION = `
mutation Save(
  $name: String!,
  $description: String!,
  $type: String!,
  $isHidden: Boolean!,
  $isResumable: Boolean!,
  $isReferenceable: Boolean!,
  $developerOnly: Boolean!,
  $desktopOnly: Boolean!,
  $underDev: Boolean!,
  $asyncExecution: Boolean!,
  $awaitingAlert: Boolean!,
  $filterCall: String!,
  $relevance: Float!,
  $uiSettings: GenericScalar!,
  $inputStructure: GenericScalar!,
  $outputStructure: GenericScalar!,
  $baseUsageLimits: GenericScalar!,
  $src: GenericScalar!,
  $suggestions: [Int],
  $app: Int!,
)
{
  saveAction(
    name: $name,
    description: $description,
    type: $type,
    isHidden: $isHidden,
    isResumable: $isResumable,
    isReferenceable: $isReferenceable,
    developerOnly: $developerOnly,
    desktopOnly: $desktopOnly,
    underDev: $underDev,
    asyncExecution: $asyncExecution,
    awaitingAlert: $awaitingAlert,
    filterCall: $filterCall,
    relevance: $relevance,
    uiSettings: $uiSettings,
    inputStructure: $inputStructure,
    outputStructure: $outputStructure,
    baseUsageLimits: $baseUsageLimits,
    src: $src,
    suggestions: $suggestions,
    app: $app,
  ) {
    action {
      id
    }
  }
}
`;

export const DELETE_ACTION = `
mutation Delete($id: Int!)
{
  deleteAction(id: $id)
  {
    id
  }
}
`;

export const APP = `
{
  app {
    id
    name
    description
    version
    status
    developerOnly
    desktopOnly
    requestOnly
    underDev
    isPremium
    relevance
    uiSettings
    setupSettings
    datatypesSettings
    revenueSettings
    src
    basePath
    codebase {
      id
      url
    }
  }
}
`;

export const SET_APP = `
mutation Set(
  $id: Int!,
  $name: String,
  $description: String,
  $version: String,
  $status: String,
  $developerOnly: Boolean,
  $desktopOnly: Boolean,
  $requestOnly: Boolean,
  $underDev: Boolean,
  $isPremium: Boolean,
  $relevance: Float,
  $uiSettings: GenericScalar,
  $setupSettings: GenericScalar,
  $datatypesSettings: GenericScalar,
  $revenueSettings: GenericScalar,
  $src: GenericScalar,
  $basePath: String,
  $codebase: Int,
)
{
  setApp(
    id: $id,
    name: $name,
    description: $description,
    version: $version,
    status: $status,
    developerOnly: $developerOnly,
    desktopOnly: $desktopOnly,
    requestOnly: $requestOnly,
    underDev: $underDev,
    isPremium: $isPremium,
    relevance: $relevance,
    uiSettings: $uiSettings,
    setupSettings: $setupSettings,
    datatypesSettings: $datatypesSettings,
    revenueSettings: $revenueSettings,
    src: $src,
    basePath: $basePath,
    codebase: $codebase,
  ) {
    app {
      id
      name
      description
      version
      status
      developerOnly
      desktopOnly
      requestOnly
      underDev
      isPremium
      relevance
      uiSettings
      setupSettings
      datatypesSettings
      revenueSettings
      src
      basePath
      codebase {
        id
      }
    }
  }
}
`;

export const SAVE_APP = `
mutation Save(
  $name: String!,
  $description: String!,
  $version: String!,
  $status: String!,
  $developerOnly: Boolean!,
  $desktopOnly: Boolean!,
  $requestOnly: Boolean!,
  $underDev: Boolean!,
  $isPremium: Boolean!,
  $relevance: Float!,
  $uiSettings: GenericScalar!,
  $setupSettings: GenericScalar!,
  $datatypesSettings: GenericScalar!,
  $revenueSettings: GenericScalar!,
  $src: GenericScalar!,
  $basePath: String!,
  $codebase: Int,
)
{
  saveApp(
    name: $name,
    description: $description,
    version: $version,
    status: $status,
    developerOnly: $developerOnly,
    desktopOnly: $desktopOnly,
    requestOnly: $requestOnly,
    underDev: $underDev,
    isPremium: $isPremium,
    relevance: $relevance,
    uiSettings: $uiSettings,
    setupSettings: $setupSettings,
    datatypesSettings: $datatypesSettings,
    revenueSettings: $revenueSettings,
    src: $src,
    basePath: $basePath,
    codebase: $codebase,
  ) {
    app {
      id
    }
  }
}
`;

export const DELETE_APP = `
mutation Delete($id: Int!)
{
  deleteApp(id: $id)
  {
    id
  }
}
`;

export const APP_DATATYPE = `
{
  appDatatype {
    id
    name
    structure
    app {
      id
    }
  }
}
`;

export const SET_APP_DATATYPE = `
mutation Set(
  $id: Int!,
  $name: String,
  $structure: GenericScalar,
  $app: Int,
)
{
  setAppDatatype(
    id: $id,
    name: $name,
    structure: $structure,
    app: $app,
  ) {
    appDatatype {
      id
      name
      structure
      app {
        id
      }
    }
  }
}
`;

export const SAVE_APP_DATATYPE = `
mutation Save(
  $name: String!,
  $structure: GenericScalar!,
  $app: Int!,
)
{
  saveAppDatatype(
    name: $name,
    structure: $structure,
    app: $app,
  ) {
    appDatatype {
      id
    }
  }
}
`;

export const DELETE_APP_DATATYPE = `
mutation Delete($id: Int!)
{
  deleteAppDatatype(id: $id)
  {
    id
  }
}
`;

export const APP_SETTINGS = `
{
  appSettings {
    id
    settings
    enabled
    requested
    app {
      id
    }
    flow {
      id
    }
  }
}
`;

export const SET_APP_SETTINGS = `
mutation Set(
  $id: Int!,
  $settings: GenericScalar,
  $enabled: Boolean,
  $requested: Boolean,
  $app: Int,
  $flow: Int,
)
{
  setAppSettings(
    id: $id,
    settings: $settings,
    enabled: $enabled,
    requested: $requested,
    app: $app,
    flow: $flow,
  ) {
    appSettings {
      id
      settings
      enabled
      requested
      app {
        id
      }
      flow {
        id
      }
    }
  }
}
`;

export const SAVE_APP_SETTINGS = `
mutation Save(
  $settings: GenericScalar!,
  $enabled: Boolean!,
  $requested: Boolean!,
  $app: Int!,
  $flow: Int,
)
{
  saveAppSettings(
    settings: $settings,
    enabled: $enabled,
    requested: $requested,
    app: $app,
    flow: $flow,
  ) {
    appSettings {
      id
    }
  }
}
`;

export const DELETE_APP_SETTINGS = `
mutation Delete($id: Int!)
{
  deleteAppSettings(id: $id)
  {
    id
  }
}
`;

export const COLLECTION = `
{
  collection {
    id
    name
    initials
    schema
    folios
    user {
      id
    }
    flow {
      id
    }
  }
}
`;

export const SET_COLLECTION = `
mutation Set(
  $id: Int!,
  $name: String,
  $initials: String,
  $schema: GenericScalar,
  $folios: GenericScalar,
  $user: Int,
  $flow: Int,
)
{
  setCollection(
    id: $id,
    name: $name,
    initials: $initials,
    schema: $schema,
    folios: $folios,
    user: $user,
    flow: $flow,
  ) {
    collection {
      id
      name
      initials
      schema
      folios
      user {
        id
      }
      flow {
        id
      }
    }
  }
}
`;

export const SAVE_COLLECTION = `
mutation Save(
  $name: String!,
  $initials: String!,
  $schema: GenericScalar!,
  $folios: GenericScalar!,
  $user: Int,
  $flow: Int!,
)
{
  saveCollection(
    name: $name,
    initials: $initials,
    schema: $schema,
    folios: $folios,
    user: $user,
    flow: $flow,
  ) {
    collection {
      id
    }
  }
}
`;

export const DELETE_COLLECTION = `
mutation Delete($id: Int!)
{
  deleteCollection(id: $id)
  {
    id
  }
}
`;

export const COLLECTION_DATA = `
{
  collectionData {
    id
    data
    collection {
      id
    }
  }
}
`;

export const SET_COLLECTION_DATA = `
mutation Set(
  $id: Int!,
  $data: GenericScalar,
  $collection: Int,
)
{
  setCollectionData(
    id: $id,
    data: $data,
    collection: $collection,
  ) {
    collectionData {
      id
      data
      collection {
        id
      }
    }
  }
}
`;

export const SAVE_COLLECTION_DATA = `
mutation Save(
  $data: GenericScalar!,
  $collection: Int!,
)
{
  saveCollectionData(
    data: $data,
    collection: $collection,
  ) {
    collectionData {
      id
    }
  }
}
`;

export const DELETE_COLLECTION_DATA = `
mutation Delete($id: Int!)
{
  deleteCollectionData(id: $id)
  {
    id
  }
}
`;

export const EXECUTION = `
{
  execution {
    id
    status
    startDate
    endDate
    isRead
    exception
    settings
    flow {
      id
    }
    user {
      id
    }
  }
}
`;

export const SET_EXECUTION = `
mutation Set(
  $id: Int!,
  $status: String,
  $startDate: DateTime,
  $endDate: DateTime,
  $isRead: Boolean,
  $exception: GenericScalar,
  $settings: GenericScalar,
  $flow: Int,
  $user: Int,
)
{
  setExecution(
    id: $id,
    status: $status,
    startDate: $startDate,
    endDate: $endDate,
    isRead: $isRead,
    exception: $exception,
    settings: $settings,
    flow: $flow,
    user: $user,
  ) {
    execution {
      id
      status
      startDate
      endDate
      isRead
      exception
      settings
      flow {
        id
      }
      user {
        id
      }
    }
  }
}
`;

export const SAVE_EXECUTION = `
mutation Save(
  $status: String!,
  $startDate: DateTime!,
  $endDate: DateTime!,
  $isRead: Boolean!,
  $exception: GenericScalar,
  $settings: GenericScalar!,
  $flow: Int,
  $user: Int,
)
{
  saveExecution(
    status: $status,
    startDate: $startDate,
    endDate: $endDate,
    isRead: $isRead,
    exception: $exception,
    settings: $settings,
    flow: $flow,
    user: $user,
  ) {
    execution {
      id
    }
  }
}
`;

export const DELETE_EXECUTION = `
mutation Delete($id: Int!)
{
  deleteExecution(id: $id)
  {
    id
  }
}
`;

export const EXECUTION_STEP = `
{
  executionStep {
    id
    status
    step
    time
    startDate
    endDate
    snapshot
    result
    flowNode {
      id
    }
    execution {
      id
    }
  }
}
`;

export const SET_EXECUTION_STEP = `
mutation Set(
  $id: Int!,
  $status: String,
  $step: Int,
  $time: Float,
  $startDate: DateTime,
  $endDate: DateTime,
  $snapshot: GenericScalar,
  $result: GenericScalar,
  $flowNode: Int,
  $execution: Int,
)
{
  setExecutionStep(
    id: $id,
    status: $status,
    step: $step,
    time: $time,
    startDate: $startDate,
    endDate: $endDate,
    snapshot: $snapshot,
    result: $result,
    flowNode: $flowNode,
    execution: $execution,
  ) {
    executionStep {
      id
      status
      step
      time
      startDate
      endDate
      snapshot
      result
      flowNode {
        id
      }
      execution {
        id
      }
    }
  }
}
`;

export const SAVE_EXECUTION_STEP = `
mutation Save(
  $status: String!,
  $step: Int!,
  $time: Float!,
  $startDate: DateTime!,
  $endDate: DateTime!,
  $snapshot: GenericScalar!,
  $result: GenericScalar!,
  $flowNode: Int!,
  $execution: Int!,
)
{
  saveExecutionStep(
    status: $status,
    step: $step,
    time: $time,
    startDate: $startDate,
    endDate: $endDate,
    snapshot: $snapshot,
    result: $result,
    flowNode: $flowNode,
    execution: $execution,
  ) {
    executionStep {
      id
    }
  }
}
`;

export const DELETE_EXECUTION_STEP = `
mutation Delete($id: Int!)
{
  deleteExecutionStep(id: $id)
  {
    id
  }
}
`;

export const FLOW = `
{
  flow {
    id
    name
    description
    token
    isTemplate
    templateName
    settings
    presentationSettings
    templateSettings
    usageStats
    user {
      id
    }
    folder {
      id
    }
    templateCategories {
      id
    }
  }
}
`;

export const SET_FLOW = `
mutation Set(
  $id: Int!,
  $name: String,
  $description: String,
  $token: String,
  $isTemplate: Boolean,
  $templateName: String,
  $settings: GenericScalar,
  $presentationSettings: GenericScalar,
  $templateSettings: GenericScalar,
  $usageStats: GenericScalar,
  $user: Int,
  $folder: Int,
  $templateCategories: [Int],
)
{
  setFlow(
    id: $id,
    name: $name,
    description: $description,
    token: $token,
    isTemplate: $isTemplate,
    templateName: $templateName,
    settings: $settings,
    presentationSettings: $presentationSettings,
    templateSettings: $templateSettings,
    usageStats: $usageStats,
    user: $user,
    folder: $folder,
    templateCategories: $templateCategories,
  ) {
    flow {
      id
      name
      description
      token
      isTemplate
      templateName
      settings
      presentationSettings
      templateSettings
      usageStats
      user {
        id
      }
      folder {
        id
      }
      templateCategories {
        id
      }
    }
  }
}
`;

export const SAVE_FLOW = `
mutation Save(
  $name: String!,
  $description: String,
  $token: String,
  $isTemplate: Boolean!,
  $templateName: String,
  $settings: GenericScalar!,
  $presentationSettings: GenericScalar,
  $templateSettings: GenericScalar,
  $usageStats: GenericScalar!,
  $user: Int,
  $folder: Int,
  $templateCategories: [Int],
)
{
  saveFlow(
    name: $name,
    description: $description,
    token: $token,
    isTemplate: $isTemplate,
    templateName: $templateName,
    settings: $settings,
    presentationSettings: $presentationSettings,
    templateSettings: $templateSettings,
    usageStats: $usageStats,
    user: $user,
    folder: $folder,
    templateCategories: $templateCategories,
  ) {
    flow {
      id
    }
  }
}
`;

export const DELETE_FLOW = `
mutation Delete($id: Int!)
{
  deleteFlow(id: $id)
  {
    id
  }
}
`;

export const FLOW_ANNOTATION = `
{
  flowAnnotation {
    id
    idx
    uiSettings
    inputValue
    flow {
      id
    }
    action {
      id
    }
    page {
      id
    }
  }
}
`;

export const SET_FLOW_ANNOTATION = `
mutation Set(
  $id: Int!,
  $idx: Int,
  $uiSettings: GenericScalar,
  $inputValue: GenericScalar,
  $flow: Int,
  $action: Int,
  $page: Int,
)
{
  setFlowAnnotation(
    id: $id,
    idx: $idx,
    uiSettings: $uiSettings,
    inputValue: $inputValue,
    flow: $flow,
    action: $action,
    page: $page,
  ) {
    flowAnnotation {
      id
      idx
      uiSettings
      inputValue
      flow {
        id
      }
      action {
        id
      }
      page {
        id
      }
    }
  }
}
`;

export const SAVE_FLOW_ANNOTATION = `
mutation Save(
  $idx: Int!,
  $uiSettings: GenericScalar!,
  $inputValue: GenericScalar!,
  $flow: Int!,
  $action: Int!,
  $page: Int,
)
{
  saveFlowAnnotation(
    idx: $idx,
    uiSettings: $uiSettings,
    inputValue: $inputValue,
    flow: $flow,
    action: $action,
    page: $page,
  ) {
    flowAnnotation {
      id
    }
  }
}
`;

export const DELETE_FLOW_ANNOTATION = `
mutation Delete($id: Int!)
{
  deleteFlowAnnotation(id: $id)
  {
    id
  }
}
`;

export const FLOW_EDGE = `
{
  flowEdge {
    id
    type
    sourceName
    targetName
    source {
      id
    }
    target {
      id
    }
    flow {
      id
    }
    page {
      id
    }
  }
}
`;

export const SET_FLOW_EDGE = `
mutation Set(
  $id: Int!,
  $type: String,
  $sourceName: String,
  $targetName: String,
  $source: Int,
  $target: Int,
  $flow: Int,
  $page: Int,
)
{
  setFlowEdge(
    id: $id,
    type: $type,
    source: $source,
    target: $target,
    flow: $flow,
    page: $page,
    sourceName: $sourceName,
    targetName: $targetName,
  ) {
    flowEdge {
      id
      type
      sourceName
      targetName
      source {
        id
      }
      target {
        id
      }
      flow {
        id
      }
      page {
        id
      }
    }
  }
}
`;

export const SAVE_FLOW_EDGE = `
mutation Save(
  $type: String!,
  $sourceName: String!,
  $targetName: String!,
  $source: Int!,
  $target: Int!,
  $flow: Int!,
  $page: Int,
)
{
  saveFlowEdge(
    type: $type,
    source: $source,
    target: $target,
    flow: $flow,
    page: $page,
    sourceName: $sourceName,
    targetName: $targetName,
  ) {
    flowEdge {
      id
    }
  }
}
`;

export const DELETE_FLOW_EDGE = `
mutation Delete($id: Int!)
{
  deleteFlowEdge(id: $id)
  {
    id
  }
}
`;

export const FLOW_NODE = `
{
  flowNode {
    id
    idx
    isEnabled
    isVerified
    isIterable
    iterableField
    uiSettings
    inputValue
    outputValue
    flow {
      id
    }
    action {
      id
    }
    page {
      id
    }
  }
}
`;

export const SET_FLOW_NODE = `
mutation Set(
  $id: Int!,
  $idx: Int,
  $isEnabled: Boolean,
  $isVerified: Boolean,
  $isIterable: Boolean,
  $iterableField: String,
  $uiSettings: GenericScalar,
  $inputValue: GenericScalar,
  $outputValue: GenericScalar,
  $flow: Int,
  $action: Int,
  $page: Int,
)
{
  setFlowNode(
    id: $id,
    idx: $idx,
    isEnabled: $isEnabled,
    isVerified: $isVerified,
    isIterable: $isIterable,
    iterableField: $iterableField,
    uiSettings: $uiSettings,
    inputValue: $inputValue,
    outputValue: $outputValue,
    flow: $flow,
    action: $action,
    page: $page,
  ) {
    flowNode {
      id
      idx
      isEnabled
      isVerified
      isIterable
      iterableField
      uiSettings
      inputValue
      outputValue
      flow {
        id
      }
      action {
        id
      }
      page {
        id
      }
    }
  }
}
`;

export const SAVE_FLOW_NODE = `
mutation Save(
  $idx: Int!,
  $isEnabled: Boolean!,
  $isVerified: Boolean!,
  $isIterable: Boolean!,
  $iterableField: String!,
  $uiSettings: GenericScalar!,
  $inputValue: GenericScalar!,
  $outputValue: GenericScalar!,
  $flow: Int!,
  $action: Int!,
  $page: Int,
)
{
  saveFlowNode(
    idx: $idx,
    isEnabled: $isEnabled,
    isVerified: $isVerified,
    isIterable: $isIterable,
    iterableField: $iterableField,
    uiSettings: $uiSettings,
    inputValue: $inputValue,
    outputValue: $outputValue,
    flow: $flow,
    action: $action,
    page: $page,
  ) {
    flowNode {
      id
    }
  }
}
`;

export const DELETE_FLOW_NODE = `
mutation Delete($id: Int!)
{
  deleteFlowNode(id: $id)
  {
    id
  }
}
`;

export const FLOW_PAGE = `
{
  flowPage {
    id
    name
    isDefault
    flow {
      id
    }
  }
}
`;

export const SET_FLOW_PAGE = `
mutation Set(
  $id: Int!,
  $name: String,
  $isDefault: Boolean,
  $flow: Int,
)
{
  setFlowPage(
    id: $id,
    name: $name,
    isDefault: $isDefault,
    flow: $flow,
  ) {
    flowPage {
      id
      name
      isDefault
      flow {
        id
      }
    }
  }
}
`;

export const SAVE_FLOW_PAGE = `
mutation Save(
  $name: String!,
  $isDefault: Boolean!,
  $flow: Int!,
)
{
  saveFlowPage(
    name: $name,
    isDefault: $isDefault,
    flow: $flow,
  ) {
    flowPage {
      id
    }
  }
}
`;

export const DELETE_FLOW_PAGE = `
mutation Delete($id: Int!)
{
  deleteFlowPage(id: $id)
  {
    id
  }
}
`;

export const FOLDER = `
{
  folder {
    id
    name
    user {
      id
    }
  }
}
`;

export const SET_FOLDER = `
mutation Set(
  $id: Int!,
  $name: String,
  $user: Int,
)
{
  setFolder(
    id: $id,
    name: $name,
    user: $user,
  ) {
    folder {
      id
      name
      user {
        id
      }
    }
  }
}
`;

export const SAVE_FOLDER = `
mutation Save(
  $name: String!,
  $user: Int!,
)
{
  saveFolder(
    name: $name,
    user: $user,
  ) {
    folder {
      id
    }
  }
}
`;

export const DELETE_FOLDER = `
mutation Delete($id: Int!)
{
  deleteFolder(id: $id)
  {
    id
  }
}
`;

export const INTEGRATION = `
{
  integration {
    id
    name
    uiSettings
    authSettings
    secretKeys
    app {
      id
    }
  }
}
`;

export const SET_INTEGRATION = `
mutation Set(
  $id: Int!,
  $name: String,
  $uiSettings: GenericScalar,
  $authSettings: GenericScalar,
  $secretKeys: String,
  $app: Int,
)
{
  setIntegration(
    id: $id,
    name: $name,
    uiSettings: $uiSettings,
    authSettings: $authSettings,
    secretKeys: $secretKeys,
    app: $app,
  ) {
    integration {
      id
      name
      uiSettings
      authSettings
      secretKeys
      app {
        id
      }
    }
  }
}
`;

export const SAVE_INTEGRATION = `
mutation Save(
  $name: String!,
  $uiSettings: GenericScalar!,
  $authSettings: GenericScalar!,
  $secretKeys: String!,
  $app: Int!,
)
{
  saveIntegration(
    name: $name,
    uiSettings: $uiSettings,
    authSettings: $authSettings,
    secretKeys: $secretKeys,
    app: $app,
  ) {
    integration {
      id
    }
  }
}
`;

export const DELETE_INTEGRATION = `
mutation Delete($id: Int!)
{
  deleteIntegration(id: $id)
  {
    id
  }
}
`;

export const INTEGRATION_ACCOUNT = `
{
  integrationAccount {
    id
    username
    remoteId
    status
    secretKeys
    user {
      id
    }
    integration {
      id
    }
  }
}
`;

export const SET_INTEGRATION_ACCOUNT = `
mutation Set(
  $id: Int!,
  $username: String,
  $remoteId: String,
  $status: String,
  $secretKeys: String,
  $user: Int,
  $integration: Int,
)
{
  setIntegrationAccount(
    id: $id,
    username: $username,
    remoteId: $remoteId,
    status: $status,
    secretKeys: $secretKeys,
    user: $user,
    integration: $integration,
  ) {
    integrationAccount {
      id
      username
      remoteId
      status
      secretKeys
      user {
        id
      }
      integration {
        id
      }
    }
  }
}
`;

export const SAVE_INTEGRATION_ACCOUNT = `
mutation Save(
  $username: String!,
  $remoteId: String!,
  $status: String!,
  $secretKeys: String!,
  $user: Int!,
  $integration: Int!,
)
{
  saveIntegrationAccount(
    username: $username,
    remoteId: $remoteId,
    status: $status,
    secretKeys: $secretKeys,
    user: $user,
    integration: $integration,
  ) {
    integrationAccount {
      id
    }
  }
}
`;

export const DELETE_INTEGRATION_ACCOUNT = `
mutation Delete($id: Int!)
{
  deleteIntegrationAccount(id: $id)
  {
    id
  }
}
`;

export const LICENSE = `
{
  license {
    id
    name
    usageLimits
  }
}
`;

export const SET_LICENSE = `
mutation Set(
  $id: Int!,
  $name: String,
  $usageLimits: GenericScalar,
)
{
  setLicense(
    id: $id,
    name: $name,
    usageLimits: $usageLimits,
  ) {
    license {
      id
      name
      usageLimits
    }
  }
}
`;

export const SAVE_LICENSE = `
mutation Save(
  $name: String!,
  $usageLimits: GenericScalar!,
)
{
  saveLicense(
    name: $name,
    usageLimits: $usageLimits,
  ) {
    license {
      id
    }
  }
}
`;

export const DELETE_LICENSE = `
mutation Delete($id: Int!)
{
  deleteLicense(id: $id)
  {
    id
  }
}
`;

export const LOCAL_STORAGE = `
{
  localStorage {
    id
    key
    value
    flow {
      id
    }
    action {
      id
    }
  }
}
`;

export const SET_LOCAL_STORAGE = `
mutation Set(
  $id: Int!,
  $key: String,
  $value: GenericScalar,
  $flow: Int,
  $action: Int,
)
{
  setLocalStorage(
    id: $id,
    key: $key,
    value: $value,
    flow: $flow,
    action: $action,
  ) {
    localStorage {
      id
      key
      value
      flow {
        id
      }
      action {
        id
      }
    }
  }
}
`;

export const SAVE_LOCAL_STORAGE = `
mutation Save(
  $key: String!,
  $value: GenericScalar!,
  $flow: Int!,
  $action: Int,
)
{
  saveLocalStorage(
    key: $key,
    value: $value,
    flow: $flow,
    action: $action,
  ) {
    localStorage {
      id
    }
  }
}
`;

export const DELETE_LOCAL_STORAGE = `
mutation Delete($id: Int!)
{
  deleteLocalStorage(id: $id)
  {
    id
  }
}
`;

export const PARTICIPANT = `
{
  participant {
    id
    type
    isAccepted
    token
    email
    user {
      id
    }
    flow {
      id
    }
  }
}
`;

export const SET_PARTICIPANT = `
mutation Set(
  $id: Int!,
  $type: String,
  $isAccepted: Boolean,
  $token: String,
  $email: String,
  $user: Int,
  $flow: Int,
)
{
  setParticipant(
    id: $id,
    type: $type,
    isAccepted: $isAccepted,
    token: $token,
    email: $email,
    user: $user,
    flow: $flow,
  ) {
    participant {
      id
      type
      isAccepted
      token
      email
      user {
        id
      }
      flow {
        id
      }
    }
  }
}
`;

export const SAVE_PARTICIPANT = `
mutation Save(
  $type: String!,
  $isAccepted: Boolean!,
  $token: String!,
  $email: String,
  $user: Int,
  $flow: Int!,
)
{
  saveParticipant(
    type: $type,
    isAccepted: $isAccepted,
    token: $token,
    email: $email,
    user: $user,
    flow: $flow,
  ) {
    participant {
      id
    }
  }
}
`;

export const DELETE_PARTICIPANT = `
mutation Delete($id: Int!)
{
  deleteParticipant(id: $id)
  {
    id
  }
}
`;

export const PAYMENT = `
{
  payment {
    id
    openpayId
    amount
    description
    orderId
    currency
    iva
    status
    checkoutLink
    expirationDate
    user {
      id
    }
  }
}
`;

export const SET_PAYMENT = `
mutation Set(
  $id: Int!,
  $openpayId: String,
  $amount: Float,
  $description: String,
  $orderId: String,
  $currency: String,
  $iva: Float,
  $status: String,
  $checkoutLink: String,
  $expirationDate: DateTime,
  $user: Int,
)
{
  setPayment(
    id: $id,
    openpayId: $openpayId,
    amount: $amount,
    description: $description,
    orderId: $orderId,
    currency: $currency,
    iva: $iva,
    status: $status,
    checkoutLink: $checkoutLink,
    expirationDate: $expirationDate,
    user: $user,
  ) {
    payment {
      id
      openpayId
      amount
      description
      orderId
      currency
      iva
      status
      checkoutLink
      expirationDate
      user {
        id
      }
    }
  }
}
`;

export const SAVE_PAYMENT = `
mutation Save(
  $openpayId: String!,
  $amount: Float!,
  $description: String!,
  $orderId: String!,
  $currency: String!,
  $iva: Float,
  $status: String!,
  $checkoutLink: String!,
  $expirationDate: DateTime,
  $user: Int!,
)
{
  savePayment(
    openpayId: $openpayId,
    amount: $amount,
    description: $description,
    orderId: $orderId,
    currency: $currency,
    iva: $iva,
    status: $status,
    checkoutLink: $checkoutLink,
    expirationDate: $expirationDate,
    user: $user,
  ) {
    payment {
      id
    }
  }
}
`;

export const DELETE_PAYMENT = `
mutation Delete($id: Int!)
{
  deletePayment(id: $id)
  {
    id
  }
}
`;

export const SUGGESTION = `
{
  suggestion {
    id
    type
    description
    prompt
    isFavorite
    isShown
    response
    extraData
    flow {
      id
    }
  }
}
`;

export const SET_SUGGESTION = `
mutation Set(
  $id: Int!,
  $type: String,
  $description: String,
  $prompt: String,
  $isFavorite: Boolean,
  $isShown: Boolean,
  $response: GenericScalar,
  $extraData: GenericScalar,
  $flow: Int,
)
{
  setSuggestion(
    id: $id,
    type: $type,
    description: $description,
    prompt: $prompt,
    isFavorite: $isFavorite,
    isShown: $isShown,
    response: $response,
    extraData: $extraData,
    flow: $flow,
  ) {
    suggestion {
      id
      type
      description
      prompt
      isFavorite
      isShown
      response
      extraData
      flow {
        id
      }
    }
  }
}
`;

export const SAVE_SUGGESTION = `
mutation Save(
  $type: String!,
  $description: String!,
  $prompt: String!,
  $isFavorite: Boolean!,
  $isShown: Boolean!,
  $response: GenericScalar!,
  $extraData: GenericScalar!,
  $flow: Int,
)
{
  saveSuggestion(
    type: $type,
    description: $description,
    prompt: $prompt,
    isFavorite: $isFavorite,
    isShown: $isShown,
    response: $response,
    extraData: $extraData,
    flow: $flow,
  ) {
    suggestion {
      id
    }
  }
}
`;

export const DELETE_SUGGESTION = `
mutation Delete($id: Int!)
{
  deleteSuggestion(id: $id)
  {
    id
  }
}
`;

export const TEMPLATE_CATEGORY = `
{
  templateCategory {
    id
    name
    description
    uiSettings
    urlSlug
  }
}
`;

export const SET_TEMPLATE_CATEGORY = `
mutation Set(
  $id: Int!,
  $name: String,
  $description: String,
  $uiSettings: GenericScalar,
  $urlSlug: String,
)
{
  setTemplateCategory(
    id: $id,
    name: $name,
    description: $description,
    uiSettings: $uiSettings,
    urlSlug: $urlSlug,
  ) {
    templateCategory {
      id
      name
      description
      uiSettings
      urlSlug
    }
  }
}
`;

export const SAVE_TEMPLATE_CATEGORY = `
mutation Save(
  $name: String!,
  $description: String!,
  $uiSettings: GenericScalar!,
  $urlSlug: String,
)
{
  saveTemplateCategory(
    name: $name,
    description: $description,
    uiSettings: $uiSettings,
    urlSlug: $urlSlug,
  ) {
    templateCategory {
      id
    }
  }
}
`;

export const DELETE_TEMPLATE_CATEGORY = `
mutation Delete($id: Int!)
{
  deleteTemplateCategory(id: $id)
  {
    id
  }
}
`;

export const USER = `
{
  user {
    id
    username
    firstName
    lastName
    email
    isActive
    accountType
    accountSettings
    phone
    company
    developerMode
    isAnonymous
    verifyToken
    verifyTokenExpiration
    tokenVerified
    openpayId
    license {
      id
    }
  }
}
`;

export const SET_USER = `
mutation Set(
  $id: Int!,
  $username: String,
  $firstName: String,
  $lastName: String,
  $email: String,
  $isActive: Boolean,
  $password: String,
  $accountType: String,
  $accountSettings: GenericScalar,
  $phone: String,
  $company: String,
  $developerMode: Boolean,
  $isAnonymous: Boolean,
  $verifyToken: String,
  $verifyTokenExpiration: DateTime,
  $tokenVerified: Boolean,
  $openpayId: String,
  $license: Int,
)
{
  setUser(
    id: $id,
    username: $username,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    isActive: $isActive,
    password: $password,
    accountType: $accountType,
    accountSettings: $accountSettings,
    phone: $phone,
    company: $company,
    developerMode: $developerMode,
    isAnonymous: $isAnonymous,
    license: $license,
    verifyToken: $verifyToken,
    verifyTokenExpiration: $verifyTokenExpiration,
    tokenVerified: $tokenVerified,
    openpayId: $openpayId,
  ) {
    user {
      id
      username
      firstName
      lastName
      email
      isActive
      accountType
      accountSettings
      phone
      company
      developerMode
      isAnonymous
      verifyToken
      verifyTokenExpiration
      tokenVerified
      openpayId
      license {
        id
      }
    }
  }
}
`;

export const SAVE_USER = `
mutation Save(
  $username: String!,
  $firstName: String!,
  $lastName: String!,
  $email: String!,
  $isActive: Boolean!,
  $password: String!,
  $accountType: String!,
  $accountSettings: GenericScalar!,
  $phone: String,
  $company: String,
  $developerMode: Boolean!,
  $isAnonymous: Boolean!,
  $verifyToken: String,
  $verifyTokenExpiration: DateTime,
  $tokenVerified: Boolean!,
  $openpayId: String,
  $license: Int,
)
{
  saveUser(
    username: $username,
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    isActive: $isActive,
    password: $password,
    accountType: $accountType,
    accountSettings: $accountSettings,
    phone: $phone,
    company: $company,
    developerMode: $developerMode,
    isAnonymous: $isAnonymous,
    license: $license,
    verifyToken: $verifyToken,
    verifyTokenExpiration: $verifyTokenExpiration,
    tokenVerified: $tokenVerified,
    openpayId: $openpayId,
  ) {
    user {
      id
    }
  }
}
`;

export const DELETE_USER = `
mutation Delete($id: Int!)
{
  deleteUser(id: $id)
  {
    id
  }
}
`;