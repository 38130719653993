import React from "react";
import { Formik, Form, Field } from "formik";

const FormPageView = ({ page = {}, isEditing = false, onDelete = () => { }, onSubmit }) => (
  <div className="card">

    <div class="card-header">
      <h3 class="card-header-title">
        {isEditing ? "Editar página" : "Nueva página"}
      </h3>
    </div>

    <div className="card-body">
      <Formik
        initialValues={page}
        onSubmit={onSubmit}
      >
        {() =>
          <Form>

            {/* Name */}
            <div class="form-group mb-4">
              <label class="input-label pb-1" for="name">Nombre de la página</label>
              <Field type="text" name="name" id="name" autocomplete="off"
                class="form-control form-control" required autoFocus={true} />
            </div>

            <div className="row">
              <div className={`col-md-${(isEditing && !page.isDefault) ? "9 pr-0" : "12"}`}>
                <button type="submit" class="btn btn-block btn-primary">
                  {isEditing ? "Editar página" : "Crear página"}
                </button>
              </div>

              {(isEditing && !page.isDefault) && (
                <div className="col-md-3">
                  <button type="button" class="btn btn-block btn-outline-danger px-1" onClick={onDelete}>
                    Eliminar
                  </button>
                </div>
              )}
            </div>


          </Form>}
      </Formik>
    </div>
  </div>
);

export default FormPageView;