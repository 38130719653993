import tippy from 'tippy.js'
import { ReactRenderer } from '@tiptap/react'
import { mergeAttributes } from '@tiptap/react'
import { bodyContentMapper } from "components/flow/board/nodes/Node.lib";


const getReferenceOptions = (nodes = [], collections = []) => {   
    return [...getNodeOptions(nodes), ...getCollectionOptions(collections)];
}

const getNodeOptions = (nodes) => {
    let nodeOptions = [];
    for (const node of nodes) {
        if (node.outputValue.length == 0) continue
        const title = node.action.uiSettings?.node?.title ?? node.action.uiSettings?.general?.title;
        const subtitle = bodyContentMapper(node.action.uiSettings?.node ?? [], node.inputValue, "subtitle", [])
        for (const outputValue of node.outputValue){

            nodeOptions.push({
                id: `${outputValue.value}`,
                label: `${title} ${subtitle ? "(" + subtitle + ")" : ""} [${node.idx}]`
            })
        }       
    }
    return nodeOptions;
}

const getCollectionOptions = (collections) => {
    const collectionOptions = collections.map((collection) => {
        return {
            id: `@${collection.name}`,
            label: collection.name
        }
    });
    return collectionOptions;
}

const isComplexType = (type, datatypes) =>
	type == "variable" || type == "collection" || type == "object" || (type ?? "").startsWith("array") ||
	datatypes.find(opt => opt.name == type)

const getAtributeOptions = (content, nodes, collections, datatypes) => {

    if (content.length < 2) return [];
    const variableInput = content[content.length-2];
    const lastInput = content[content.length - 1];
    if (lastInput.type != 'text' || lastInput.text.trim() != "." || variableInput.type != "mention") return [];

    const variableId = variableInput.attrs.id;
    return getVariableAttributes(variableId, nodes, collections, datatypes)
}


const getVariableAttributes  = (variableId, nodes, collections, datatypes) => {
    let nextSuggestions = [];
    const isNode = variableId.startsWith("#");
    const isCollection = variableId.startsWith("@");

    if (isNode) {
        let nodeOutput = null
        for (const node of nodes)
            for (const outputValue of node.outputValue)
                if (variableId == outputValue.value)
                    nodeOutput = outputValue

        if (!nodeOutput || !isComplexType(nodeOutput.type, datatypes)) return;

        let datatype = datatypes.find(opt => opt.name == nodeOutput.type)
		let subfields = datatype == null ? nodeOutput.structure : datatype.structure

        nextSuggestions = subfields.map((item) => {
            return {
                id: "." + item.name,
                label: item.label ?? item.name
            };
        })


    } else if (isCollection) {

        const collection = collections.find((collection) => collection.name == variableId.replace("@", ""));
        if (!collection) return [];
        const structure = collection?.schema?.structure ?? [];
        nextSuggestions = structure.map((item) => {
            return {
                id: "." + item.name,
                label: item.label
            };
        })
    }
    return nextSuggestions;
}

const getInitialContent = (nodes, collections, datatypes, initialValue) => {

    const collectionsOptions = getCollectionOptions(collections);
    const nodesOptions = getNodeOptions(nodes)
    const options = [...collectionsOptions, ...nodesOptions];

    const content = [];
    const mentionRegex = /(@|#)((\w|(\.))+)/g;
    let lastIndex = 0;

    initialValue?.replace(mentionRegex, (match, p1, p2, offset) => {

        const text = p1 + p2;
        const parts = text.split(".")
        

        const item = options.find((option) => option.id == parts[0]);
        if (!item) return;

        if (offset > lastIndex) {
            content.push({
                type: 'text',
                text: initialValue.substring(lastIndex, offset),
            });
        }
        content.push({
            type: 'mention',
            attrs: {
                id: parts[0],
                label: item?.label ?? "",
            },
        });

        if (parts.length > 1){
            let subOptions =  getVariableAttributes(parts[0], nodes, collections, datatypes) 
            const subItem = subOptions.find((option) => option.id == "." + parts[1]);
            if (!subItem) return;
            content.push({
                type: 'mention',
                attrs: {
                    id: "." + parts[1],
                    label: subItem?.label ?? "",
                },
            });

        }

        lastIndex = offset + match.length;
    });



    if (lastIndex < initialValue?.length) {
        content.push({
            type: 'text',
            text: initialValue.substring(lastIndex),
        });
    }

    const initialContent = {
        type: 'doc',
        content: [
            {
                type: 'paragraph',
                content: content,
            },
        ],
    };

    return initialContent;

}

const renderHtml = ({ options, node }) => {

    const isVariable = node.attrs.id.includes("#");
    const isCollection = node.attrs.id.includes("@");
    const isAtribute = !isVariable && !isCollection;

    if (isVariable || isCollection) {
        return [
            "span",
            mergeAttributes({ type: isVariable ? "variable" : "collection" }, options.HTMLAttributes),
            `${node.attrs.label ?? node.attrs.id}`
        ]
    } else if (isAtribute) {
        return [
            "span",
            mergeAttributes({ type: "attribute" }, options.HTMLAttributes),
            `${node.attrs.label ?? node.attrs.id}`
        ]
    }
}

const render = (ListComponent, extraProps) => {

    let reactRenderer;
    let popup;

    return {

        onStart: (props) => {

            if (!props.clientRect) return

            reactRenderer = new ReactRenderer(ListComponent, {
                props: { ...props, ...extraProps },
                editor: props.editor,

            })

            popup = tippy('body', {
                getReferenceClientRect: props.clientRect,
                appendTo: () => document.body,
                content: reactRenderer.element,
                showOnCreate: true,
                interactive: true,
                trigger: 'manual',
                placement: 'bottom-start',
            })

        },

        onUpdate(props) {
            reactRenderer.updateProps(props)

            if (!props.clientRect) {
                return
            }

            popup[0].setProps({
                getReferenceClientRect: props.clientRect,
            })
        },

        onKeyDown(props) {
            if (props.event.key === 'Escape') {
                popup[0].hide()

                return true
            }

            return reactRenderer.ref?.onKeyDown(props)
        },

        onExit() {
            popup[0].destroy()
            reactRenderer.destroy()
        },

    }
}

const command = ({ editor, range, props }) => {
    editor
        .chain()
        .focus()
        .deleteRange(range)
        .insertContentAt(range.from, [
            {
                type: this.name,
                attrs: props,
            },
        ])
        .run();
}

export { getInitialContent, getReferenceOptions, getNodeOptions, getCollectionOptions, getAtributeOptions, renderHtml, render, command }