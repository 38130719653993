import React from "react";
import * as Factory from "components/flow/board/nodes/helpers/Factory";


const ControlNode = ({
  app,
  action,
  nodeId,
  nodeInput,
  nodeOutput,
  errors,
  warnings,
  isSelected,
  onEdit,
  onSelectNode
}) => (

  <div>
    <div className="text-center drag-handle-container" onClick={onEdit}>

      <div className="d-flex align-items-center"
        style={{
          backgroundColor: "white", transform: "rotate(45deg)", border: "1px solid #c0c0c0",
          padding: "15px 14px", width: "40px", height: "40px"
        }}>

        <div style={{ transform: `rotate(-45deg) translate(-7px, -7px)` }}>
          <Factory.NodeContent
            app={app}
            action={action}
            nodeInput={nodeInput}
            nodeOutput={nodeOutput}
            isControl={true}
            isSelected={isSelected}
            onSelectNode={onSelectNode}
          />
        </div>
      </div>

      <Factory.TargetPoints action={action} />
      <Factory.HelpPlaceholder nodeId={nodeId} action={action} />
    </div>

    {(warnings.length > 0 || errors.length > 0) && (
      <div className="dropright">
        <div className="text-center dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" data-offset="-50,-330">
          {errors.length > 0 ? (
            <div style={{ position: "absolute", top: "calc(100% - 45px)", right: "-20px", cursor: "pointer" }}>
              <i class="fa-solid fa-circle-xmark text-danger"></i>
            </div>
          ) : (
            <div style={{ position: "absolute", top: "calc(100% - 45px)", right: "-20px", cursor: "pointer" }}>
              <i class="fa-solid fa-triangle-exclamation text-warning"></i>
            </div>
          )}
        </div>

        <Factory.DropdownErrors
          isControl={true}
          warnings={warnings}
          errors={errors}
          actionUiSettings={action.uiSettings}
        />
      </div>
    )}

  </div>
);

export default ControlNode;
