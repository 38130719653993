
/* eslint-disable max-len */
import React from "react";
import { Link, NavLink, Switch, Redirect } from "react-router-dom";
import { Tab } from "components/flow/Controller.sub.view";
import { ModalRoute, Route } from "seed/helpers"
import Modal from "components/util/helpers/Modal";
import Board from "components/flow/board/Board";
import Tables from "components/tables/Tables";
import Toolbox from "components/flow/toolbox/Toolbox";
import CreateNodeForm from "components/flow/board/action_form/CreateNodeForm";
import EditNodeForm from "components/flow/board/action_form/EditNodeForm";
import EditFormAnnotation from "components/flow/board/action_form/EditFormAnnotation";
import FormSetFlow from "components/start/flows/FormSetFlow";
import RPADesigner from "components/misc/apps/rpa/designer/RPADesigner";
import Contact from "components/flow/forms/contact_us/Contact";
import FormSavePage from "components/flow/forms/pages/FormSavePage";
import FormSetPage from "components/flow/forms/pages/FormSetPage";
import Executions from "components/start/executions/Executions";
import SetupApp from "components/flow/toolbox/apps/setup/SetupApp";
import Collaboration from "components/flow/forms/collaboration/Collaboration";
import SuggestionsReport from "components/flow/suggestions/SuggestionsReport";
import Execution from "components/flow/forms/executions/Execution";
import { postMessage } from "components/util/win";
import "styles/css/Flow.css";


const ControllerView = ({
  flow,
  nodes,
  edges,
  userId,
  selectedNodes,
  activePage,
  alertStatus,
  reactFlowWrapper,
  actualExecutionId,
  isFlowBeingSaved,
  isDeveloper,
  isAnonymous,
  isContactUsShown,
  isDesignerShow,
  isRPADesigner,
  isExecutionDetailsShown,
  isPendingActivity,
  setIsContactUsShown,
  setReactFlowInstance,
  setActivePage,
  setIsDesignerShow,
  setIsExecutionDetailsShown,
  onDropNode,
  onDragNode,
  onConnectEdge,
  onUpdateEdge,
  onNodesChange,
  onEdgesChange,
  onClickNode,
  onCreateNode,
  onEditNode,
  onCreateAnnotation,
  onEditAnnotation,
  onCloseModalRoute,
  onClickAlertBanner,
  onClickAlertBell,
  onClickExport,
  onClickImport,
  onClickAnnotation,
  onClickUndo,
  onClickRedo,
  onDeleteSelectedNodes,
  reloadFlow,
  refetchExecutions
}) => (
  <div className="card mt-2 p-0" style={{
    width: "94%", height: "88vh", overflowY: "auto",
    borderRadius: "0.75rem",
    border: "1px solid #d2dae0"
  }}>
    <div class="container-fluid p-0 h-100">

      <div class="d-flex h-100">

        <Toolbox flowId={flow.id} nodes={nodes.filter((item) => item.type == "node")} isAnonymous={isAnonymous} onClickNode={onClickNode} />

        <div id="workspace" class={"w-100 mr-4 " + (!isRPADesigner ? "pl-5" : "pl-3")} style={{ minWidth: "40rem" }}>

          {!isRPADesigner && (
            <div className="d-flex justify-content-between"
              style={{ marginBottom: "1.25rem", marginTop: "1.2rem" }}>

              <div className="flow-tab-padding"></div>

              <div id="flow-tab">

                <Tab
                  flow={flow}
                  route={"/flow"}
                  title={"Proceso"}
                  caption={"Diseña tu proceso con las acciones que necesites"}
                  width={"7rem"}
                  onClick={refetchExecutions}
                />

                <Tab
                  flow={flow}
                  route={"/data"}
                  title={"Datos"}
                  caption={"Almacena información de tu proceso como datos de clientes, empleados o solicitudes"}
                  width={"7rem"}
                />

                <Tab
                  flow={flow}
                  route={"/activity"}
                  title={"Actividad"}
                  caption={"Monitorea todas las ejecuciones completas y en marcha de tu proceso"}
                  width={"7rem"}
                  isPending={isPendingActivity}
                  onClick={refetchExecutions}
                />


              </div>

              <div>

                <span className="mr-1">

                  {!isAnonymous &&
                    (<Link to="/flow/share" className="btn  btn-sm btn-lighter mr-1"
                      style={{ paddingRight: "0.85rem", paddingLeft: "0.85rem" }}>
                      Compartir
                    </Link>)}

                  {!isAnonymous &&
                    (<a className="btn btn-sm btn-lighter ml-1" aria-haspopup="true"
                      id="dropdownGeneralSettings" data-toggle="dropdown" aria-expanded="false">
                      <i class="fa-solid fa-ellipsis-vertical px-1"></i>
                    </a>)}

                  <div class="dropdown-menu" aria-labelledby="dropdownGeneralSettings">


                    <a href={`/p/${flow.token}`} className="dropdown-item" style={{ cursor: "pointer" }}>
                      <i class="fas fa-chalkboard-teacher dropdown-item-icon mr-1"></i> Modo presentación
                    </a>


                    {isDeveloper && <div>
                      <hr style={{ marginTop: "0.7rem", marginBottom: "0.7rem" }} />
                      <button
                        className="dropdown-item"
                        onClick={() => {
                          postMessage({ type: "open_capture", flow_id: flow.id, page_id: activePage });
                        }}> <i class="fa-solid fa-mouse-pointer dropdown-item-icon mr-1"></i> RPA Debug </button>

                      <a className="dropdown-item" onClick={onClickExport} style={{ cursor: "pointer" }}>
                        <i class="fa-solid fa-download dropdown-item-icon mr-1"></i> Exportar datos
                      </a>
                      <a className="dropdown-item" onClick={onClickImport} style={{ cursor: "pointer" }}>
                        <i class="fa-solid fa-upload dropdown-item-icon mr-1"></i> Importar datos
                      </a>
                    </div>}


                    <hr style={{ marginTop: "0.7rem", marginBottom: "0.7rem" }} />


                    <Link to="/flow/edit_flow" class="dropdown-item">
                      <i class="fas fa-pen dropdown-item-icon mr-1"></i>Editar proceso
                    </Link>

                    <a href={`/app/settings`} class="dropdown-item">
                      <i class="fas fa-cog dropdown-item-icon mr-1"></i>Ajustes
                    </a>

                    <hr style={{ marginTop: "0.7rem", marginBottom: "0.7rem" }} />

                    <Link to="/flow/activity" className="dropdown-item" style={{ cursor: "pointer" }}>
                      <i class="fa-solid fa-sign-out-alt dropdown-item-icon mr-1"></i> Cerrar sesión
                    </Link>

                  </div>
                </span>

                {isAnonymous && (
                  <button className="btn btn-primary btn-sm" onClick={() => setIsContactUsShown(true)}>
                    <i class="fa-solid fa-comment mr-1"></i> Contáctanos
                  </button>
                )}

              </div>
            </div>
          )}

          <Switch>

            <Route path="/flow" render={(props) =>
              <Board
                {...props}
                nodes={nodes}
                edges={edges}
                flowId={flow.id}
                activePage={activePage}
                selectedNodes={selectedNodes}
                isFlowBeingSaved={isFlowBeingSaved}
                isRPADesigner={isRPADesigner}
                isAnonymous={isAnonymous}
                reactFlowWrapper={reactFlowWrapper}
                setActivePage={setActivePage}
                setReactFlowInstance={setReactFlowInstance}
                onDropNode={onDropNode}
                onDragNode={onDragNode}
                onConnectEdge={onConnectEdge}
                onUpdateEdge={onUpdateEdge}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onClickNode={onClickNode}
                onClickAnnotation={onClickAnnotation}
                onDeleteSelectedNodes={onDeleteSelectedNodes}
                onClickUndo={onClickUndo}
                onClickRedo={onClickRedo}
              />
            } />

            <Route path="/data" render={() =>
              <Tables
                flowId={flow?.id}
              />
            } />

            <Route path="/activity" render={(props) =>
              <Executions
                {...props}
                flow={flow}
              />
            } />

            <Redirect to={sessionStorage.getItem("def_tab_" + flow.id) ?? "/flow"} />
          </Switch>
        </div>

      </div>
    </div>

    {isDesignerShow && (
      <Modal
        width={800}
        height={600}
        animation={""}
        onClose={() => setIsDesignerShow(false)}
        component={() =>
          <RPADesigner />
        }
      />
    )}

    {isContactUsShown && (
      <Modal
        width={500}
        height={500}
        animation={""}
        onClose={() => setIsContactUsShown(false)}
        component={Contact}
      />
    )}

    {isExecutionDetailsShown && (
      <Modal
        width={500}
        height={500}
        animation={""}
        onClose={() => setIsExecutionDetailsShown(false)}
        component={() => <Execution executionId={actualExecutionId} />}
      />
    )}

    <ModalRoute
      width="1200"
      height="800"
      path="/flow/suggestions/report"
      isDeveloper={isDeveloper}
      component={SuggestionsReport}
    />

    <ModalRoute
      width="500"
      height="450"
      path="/flow/edit_flow"
      component={() => <FormSetFlow match={{ params: { "flowId": flow.id } }} />}
    />

    <ModalRoute
      width="450"
      height="500"
      path="/flow/activity"
      component={() =>
        <div className="card">
          <div className="card-header" style={{ position: "relative" }}>
            <h3 className="card-title">Actividad</h3>
          </div>
          <div className="card-body">
            <Executions flow={flow} />
          </div>
        </div>
      }
    />

    <ModalRoute
      width="450"
      height="500"
      path="/flow/pending_activity"
      component={() =>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Acciones pendientes</h3>
          </div>
          <div className="card-body">
            <Executions flow={flow}
              status={"AWAITING"}
              placeholder="No hay acciones pendientes"
            />
          </div>
        </div>
      }
    />

    <ModalRoute
      width="400"
      height="400"
      path="/flow/create_page"
      onClose={onCloseModalRoute}
      component={() => <FormSavePage match={{ params: { "flowId": flow.id } }} />}
    />

    <ModalRoute
      width="420"
      height="400"
      path="/flow/edit_page/:pageId"
      onClose={onCloseModalRoute}
      component={(props) => <FormSetPage match={{ params: { ...props.match?.params, "flowId": flow.id } }} />}
    />

    <ModalRoute
      width={800}
      height={window.innerHeight - 150}
      animation={""}
      path="/flow/create/:actionId"
      onClose={onCloseModalRoute}
      component={(props) =>
        <CreateNodeForm
          match={props.match}
          flowId={flow.id}
          onSubmit={onCreateNode}
        />
      }
    />

    <ModalRoute
      width={800}
      height={window.innerHeight - 150}
      animation={""}
      path="/flow/edit/:nodeId"
      onClose={onCloseModalRoute}
      component={(props) =>
        <EditNodeForm
          match={props.match}
          flowId={flow.id}
          onSubmit={onEditNode}
        />
      }
    />

    <ModalRoute
      width={800}
      height={window.innerHeight - 150}
      animation={""}
      path="/flow/create_annotation/:actionId"
      onClose={onCloseModalRoute}
      component={(props) =>
        <CreateNodeForm
          match={props.match}
          flowId={flow.id}
          onSubmit={onCreateAnnotation}
        />
      }
    />

    <ModalRoute
      width={800}
      height={window.innerHeight - 150}
      animation={""}
      path="/flow/edit_annotation/:annotationId"
      onClose={onCloseModalRoute}
      component={(props) =>
        <EditFormAnnotation
          match={props.match}
          flowId={flow.id}
          onSubmit={onEditAnnotation}
        />
      }
    />

    <ModalRoute
      width={600}
      height={window.innerHeight - 150}
      animation={""}
      path="/flow/setup/:appId"
      component={(props) =>
        <SetupApp
          match={props.match}
          flowId={flow.id} />}
    />

    <ModalRoute
      width={650}
      height={window.innerHeight - 150}
      animation={""}
      path="/flow/share"
      component={() => <Collaboration flowId={flow.id} />}
    />

    <ModalRoute
      width={750}
      height={window.innerHeight - 150}
      animation={""}
      path="/flow/website/:url"
      component={({ match }) => <div className="card w-100 h-100">
        <div className="w-100 text-center py-2">Vista previa:&nbsp;
          <a href={decodeURIComponent(match.params?.url)} target="_blank" rel="noreferrer">{decodeURIComponent(match.params?.url)}</a>
        </div>
        <iframe className="w-100 h-100"
          src={decodeURIComponent(match.params?.url)}
          referrerpolicy="same-origin" title="website" />
      </div>}
    />

  </div>
);

export default ControllerView;