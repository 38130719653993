import React from "react";
import { Link } from "react-router-dom";
import ScrollArrow from "components/util/helpers/ScrollArrow";


const FlowsView = ({
  userId,
  folder,
  recentFlows,
  flowCount,
  setSelectedFolder,
  onDeleteFlow,
}) => (
  <>
    <div class="card"
      style={{ border: "1px solid #d2dae0" }}>

      <div class="card-body" style={{ padding: "1.5rem" }}>

        <div className="d-flex justify-content-between align-items-center ml-1"
          style={{ marginTop: "-0.4rem", marginBottom: "1rem" }}>

          <small class="nav-subtitle" title="processes"
            style={{ color: "#808590", fontSize: "0.95em" }}>
            <i class="fas fa-shapes mr-2"></i>
            Procesos recientes
            &nbsp;<span className="text-primary">{folder ? "(" + folder.name + ")" : ""}</span>
          </small>
          <div>
            <Link to={`${folder?.id ?? 0}/create_flow`} className="btn btn-sm btn-primary"
              style={{ visibility: recentFlows.length > 0 ? "visible" : "hidden" }}>
              <i class="fas fa-plus mr-2" style={{ fontSize: "1em" }}></i>Crear proceso
            </Link>
          </div>
        </div>

        {recentFlows.length > 0 ? (
          <div class="w-100">
            <div class="d-flex flex-wrap justify-content-between" style={{ marginTop: "0.75rem" }}>
              <ScrollArrow scrollAmount={600} itemsContainerStyle={{ width: "100%" }}>
                {recentFlows.map((flow) => (
                  <Link to={`/flow/${flow.id}/flow`} key={flow.id} className="col-11 col-md-6 px-0">
                    <div className="card card-hover-shadow pb-0 px-0 mr-2">
                    <div className="d-flex justify-content-between align-items-center mb-0">
                      <div>
                        <span class="badge bg-soft-success text-success p-2 ml-3">Activo</span>
                        {function () {
                          const participants = flow?.participants ?? [];
                          const participant = participants.find((participant) => participant?.user?.id == userId);
                          const isParticipant = participant != null && participant != undefined;

                          if (isParticipant) {
                            return (<span class="badge bg-soft-primary text-primary p-2 ml-2">Compartido</span>);
                          }

                        }()}
                      </div>

                      {flow.user.id == userId && (
                        <div class="btn-group dropup">
                          <button type="button" class="btn btn-lighter text-center dropdown-toggle py-3 px-4 m-2 rounded-circle"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            onClick={e => e.preventDefault()}
                            style={{ fontSize: "1.2em", border: "none" }}>
                            <i class="fas fa-ellipsis-v" style={{ color: "#909090" }}></i>
                          </button>
                          <div class="dropdown-menu pr-1">
                            <Link to={`/${flow.id}/share`} class="dropdown-item">
                              <i class="fa-solid fa-share-from-square dropdown-item-icon mr-1"></i> Compartir
                            </Link>
                            <Link to={`/${flow.id}/edit_flow`} class="dropdown-item">
                              <i class="fas fa-pen dropdown-item-icon mr-1"></i> Editar proceso
                            </Link>
                            <Link to={`/presentation/${flow.id}`} class="dropdown-item">
                              <i class="fa-solid fa-display dropdown-item-icon mr-1"></i> Modo presentación
                            </Link>

                            <hr style={{ marginTop: "0.7rem", marginBottom: "0.7rem" }} />

                            <a class="dropdown-item" style={{ cursor: "pointer" }}
                              onClick={e => { e.preventDefault(); onDeleteFlow(flow.id); }}>
                              <i class="tio-delete dropdown-item-icon mr-1"></i> Eliminar proceso
                            </a>


                          </div>
                        </div>
                      )}
                    </div>

                    <div to={`/flow/${flow.id}/flow`} class="card-body text-center"
                      style={{ paddingBottom: "1.45rem", marginTop: "-1.5rem" }}>
                      <div class="avatar avatar-xl avatar-border-lg mb-1">
                        <img style={{ width: "3.6rem" }} src="/resources/images/icon-45.svg" alt="Flow" />
                      </div>

                      <h3 className={"mt-0 mb-0"}>
                        <div class="text-dark h4 text-truncate w-100 d-inline-block mb-0">
                          {flow.name}
                        </div>
                      </h3>

                      <div class="mb-2 w-100">
                        <a className="text-dark text-truncate w-100 d-inline-block" href="#"
                          onClick={() => setSelectedFolder(flow.folder?.id)}>
                          <i class="far fa-folder mr-1" style={{ color: "#889095", display: flow.folder ? "inline-block" : "none" }}></i>
                          <span style={{ color: "#808590" }}>{flow.folder?.name ?? 'Principal'}</span>
                        </a>
                      </div>

                    </div>
                    </div>
                  </Link>
                ))}
              </ScrollArrow>
            </div>
            {flowCount > 4 ?
              <Link to="settings/flows" class="btn btn-sm btn-outline-secondary w-100 mt-2">Ver todos</Link>
              : null}

          </div>
        ) : (
          <div class="card w-100 shadow-none mt-2">
            <div class="card-body">
              <div className="d-flex flex-column justify-content-center py-10">
                <span className="text-center mb-3" style={{ color: "#708090" }}>No tienes procesos registrados</span>
                <div className="d-flex justify-content-center">
                  <Link to={`${folder?.id ?? 0}/create_flow`} className="btn btn-sm btn-primary w-25" href="#">
                    <i class="fas fa-plus mr-2" style={{ fontSize: "1em" }}></i>Crear proceso
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>

    </div>
  </>
)

export default FlowsView;