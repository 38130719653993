import React, { useState } from "react";
import View from "components/misc/apps/rpa/generator/ProcessCapture.view";

function ProcessCapture({ 
  data = {},
  selectedImage, 
  elements, 
  onSubmit, 
  onChangeValue, 
  loading, 
  regions, 
  formikRef 
}) {

  const [advancedMode, setAdvancedMode] = useState(false);
  const developerMode = sessionStorage.getItem("developer_mode") == "true";

  const onChangeAdvancedMode = () =>
    setAdvancedMode(!advancedMode);

  return <View
    data={data}
    elements={elements}
    selectedImage={selectedImage}
    formikRef={formikRef}
    advancedMode={advancedMode}
    developerMode={developerMode}
    onChangeAdvancedMode={onChangeAdvancedMode}
    onSubmit={onSubmit}
    onChangeValue={onChangeValue}
    loading={loading}
    regions={regions}
  />;

}

export default ProcessCapture;