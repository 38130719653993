/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { usePost } from "seed/api";
import SelectField from "components/schema_form/fields/custom_fields/SelectField"

const DynamicSelectField = (props) => {

  const userId = sessionStorage.getItem("id");
  const flowId = props.flowId;
  const dynamicOptions = props.dynamicOptions;
  const value = dynamicOptions.value ?? "";
  const actionId = props.actionId;
  const sectionIdx = props.sectionIdx;
  const [options, setOptions] = useState(null)
  const [callCall, reqCall] = usePost(`/call/${actionId}/${value}`, {
    onCompleted: (data) => setOptions(data)
  })

  const initOptions = () => {
    callCall({
      user_id: parseInt(userId),
      flow_id: parseInt(flowId),
      section_idx: sectionIdx,
      values: props.values,
    })
  }

  useEffect(() => initOptions()
    , []); // eslint-disable-line react-hooks/exhaustive-deps

  const onOpenSelect = () => initOptions()


  const loading = options == null && reqCall.loading;
  const error = reqCall.error;

  return (
    <SelectField
      {...props}
      onOpen={onOpenSelect}
      placeholder={
        error ? "Ha ocurrido un error" :
          loading ? "Cargando..." :
            "Selecciona una opción"}
      options={options ?? []}
    />
  )

}

export default DynamicSelectField;