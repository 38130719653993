import React from "react";
import SchemaForm from "components/schema_form/SchemaForm";


const SetupAppView = ({ app, initialValues, onSubmit }) => (
  <div className="card">

    <div className="card-header">
      <h3 class="mb-0">Configuración</h3>
    </div>

    <div className="card-body pt-5">
      <SchemaForm
        schema={app.setupSettings ?? []}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </div>

  </div>
)

export default SetupAppView;