import React, { useState, useEffect } from "react";
import { useQuery, useSet } from "seed/gql";
import { SET_SUGGESTION } from "seed/gql/queries";
import View from "components/flow/suggestions/Results.view";

function Results({ 
  flowId,
  result = {},
  onGenerateResponse = () => { },
  onClose = () => { },
}) {

  const { id } = result;
  const [selectedSuggestion, setSelectedSuggestion] = useState(JSON.parse(sessionStorage.getItem("selectedSuggestion" + id)) || {});
  const [pages, setPages] = useState(JSON.parse(sessionStorage.getItem("suggestionPages" + id)) || []);

  const reqFavoriteSuggestions = useQuery(`{
    suggestions {
      id
      type
      description
      response
      isFavorite
      isShown
      extraData
    }
  }`, `(flow.id=${flowId} AND is_favorite=true AND type=SUGGESTION)`);

  const reqHistorySuggestions = useQuery(`{
    suggestions {
      id
      type
      description
      response
      isFavorite
      isShown
      extraData
    }
  }`, `(flow.id=${flowId} AND is_favorite=false AND is_shown=true AND type=SUGGESTION)`);

  const [callSetSuggestions, reqSetSuggestions] = useSet(SET_SUGGESTION, {
    onCompleted: (data) => {
      reqFavoriteSuggestions.refetch();
      reqHistorySuggestions.refetch();
    }
  }); 

  const onRegenerateResponse = () => {
    onGenerateResponse(result?.prompt);
    onClose();
  }

  const onChangeSuggestion = (values) => 
    callSetSuggestions(values);

  const onSelectSuggestion = (suggestion) => {
    setSelectedSuggestion(suggestion);
    setPages([...pages, "details"]);
    sessionStorage.setItem("selectedSuggestion" + id, JSON.stringify(suggestion));
  }

  useEffect(() => {
    sessionStorage.setItem("suggestionPages" + id, JSON.stringify(pages));
  }, [id, pages]);

  const { suggestions: _favoriteSuggestions = [] } = reqFavoriteSuggestions.data || {};
  const { suggestions: _historySuggestions = [] } = reqHistorySuggestions.data || {};
  const { suggestions, response } = result;

  let favoriteSuggestions = _favoriteSuggestions.map(suggestion => Object.assign({}, suggestion));
  let historySuggestions = _historySuggestions.map(suggestion => Object.assign({}, suggestion));

  let generatedSuggestions = suggestions?.filter((suggestion) => 
    !favoriteSuggestions.some((savedSuggestion) => suggestion?.id === savedSuggestion?.id));

  historySuggestions = _historySuggestions?.filter(suggestion => 
    !generatedSuggestions.some((generatedSuggestion) => generatedSuggestion?.id === suggestion?.id))?.slice(-20);

  return (
    <View
      pages={pages}
      response={response}
      generatedSuggestions={generatedSuggestions}
      savedSuggestions={favoriteSuggestions}
      historySuggestions={historySuggestions}
      selectedSuggestion={selectedSuggestion}
      setPages={setPages}
      onChangeSuggestion={onChangeSuggestion}
      onSelectSuggestion={onSelectSuggestion}
      onRegenerateResponse={onRegenerateResponse}
      onClose={onClose}
    />
  );

}

export default Results;