import React from "react";
import { getFlowName } from "components/util/flow";


const TemplateFlow = ({ idx, flow, selectedProfile, isActive, onCloneTemplate }) => (
    <div className="card card-hover-shadow mb-0 pr-1 pl-0 py-0 mx-1" key={flow.id} onClick={() => onCloneTemplate(flow.id)}
        style={{
            cursor: "pointer"}}
    >
        <div className="card-body pt-3 pb-3">
            <div className="d-flex">
                <div class="mr-3">
                    <img style={{ width: "2.1rem" }} src="/resources/images/icon-45.svg" alt="Flow" />
                </div>
                <div className="">
                    <h5 className="mb-1">
                        {getFlowName(selectedProfile, flow)}
                    </h5>
                    <div>
                        {function () {
                            const templateCategories = flow?.templateCategories ?? [];
                            if (templateCategories.length > 0) {
                                const templateCategory = templateCategories[0];
                                return (
                                    <span class="badge bg-soft-primary text-primary" key={templateCategory.id} style={{ fontSize: "0.7rem" }}>
                                        {templateCategory?.uiSettings?.display?.title}
                                    </span>
                                );
                            }
                            return <></>;
                        }()}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default TemplateFlow;