import React from "react";
import { Formik, Form } from "formik";
import { ScriptTag } from "seed/helpers";


const InvitationView = ({ participant, onSubmit }) => (

  <main id="content" role="main" class="main pl-0">

    <div class="container py-5 py-sm-7">
      <a class="d-flex justify-content-center mb-6" href={"/"}>
        <img class="z-index-2"
          src="/theme/svg/logos/logo-white.svg" alt="Logo" style={{ height: "2.65rem" }} />
      </a>

      <div class="row justify-content-center">
        <div class="col-md-7 col-lg-5">
          <div class="card card-lg mb-5 pt-1 px-1 pb-2 animate__animated animate__fadeInUp animate__faster">
            <div class="card-body">
              <Formik
                initialValues={{}}
                onSubmit={onSubmit}>
                {() =>
                  <Form>
                    <div class="text-center">
                      <div class="mb-5">
                        <h1 class="display-4">Invitación a proceso</h1>
                      </div>
                    </div>

                    {participant.isAccepted ? (
                      <div class="form-group">
                        <h4>Usted ya se encuentra colaborando en el proceso {participant?.flow?.name}</h4>
                      </div>
                    ) : (
                      <div class="form-group">
                        <h4>El usuario lo ha invitado a colaborar en el proceso llamado {participant?.flow?.name}</h4>
                      </div>
                    )}

                    {participant.isAccepted ? (
                      <a class="btn btn-lg btn-block btn-primary" href={`/app/flow/${participant.flow.id}/flow`}>
                        Ir al proceso
                      </a>
                    ) : (
                      <button type="submit" class="btn btn-lg btn-block btn-primary">
                        Aceptar invitación
                      </button>
                    )}

                  </Form>}
              </Formik>
            </div>
          </div>

        </div>
      </div>

    </div>

    <ScriptTag content={`
    $('.js-toggle-password').each(function () {
      new HSTogglePassword(this).init()
    });
  `} />

  </main>
)

export default InvitationView;