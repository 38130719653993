import React from "react";
import MonacoEditor from 'react-monaco-editor';
import BaseField from "components/schema_form/fields/custom_fields/BaseField";

const SmartCodeView = ({
  name,
  label,
  values,
  required,
  caption,
  helpMessage,
  enableComments,
  isSmartCode,
  setFieldValue,
  onChangeCode,
  onEditorDidMount,
}) => (
  <BaseField name={name} label={label} required={required} caption={caption} helpMessage={helpMessage}
    enableComments={enableComments} values={values} setFieldValue={setFieldValue}>

    {isSmartCode ? (
      <div className="d-flex justify-content-center">
      </div>
    ) : (
      <div className="mt-3" style={{ width: "100%", border: "1px solid black" }}>
        <MonacoEditor
          height="400px"
          language="python"
          value={values[name] ? values[name] : "# Escribe tu codigo aqui"}
          editorDidMount={onEditorDidMount}
          onChange={onChangeCode}
        />
      </div>
    )}

  </BaseField>

);

export default SmartCodeView;