import React, { useState, useRef } from "react";
import { usePost } from "seed/api";
import View from "components/tables/forms/Form.view";
import { parseSuggestionType } from "components/tables/util/datatypes";

function CollectionFormSave({ 
  flowId, 
  suggestions = [], 
  onSave=() => {} 
}) {

  const userId = sessionStorage.getItem("id");
  const formRef = useRef(null);
  const [error, setError] = useState(null);
  const [isGenerated, setIsGenerated] = useState(false);

  const hasSuggestions = (suggestions?.length ?? 0) > 0
  let initialColumns = []
  if(hasSuggestions) initialColumns = suggestions

  const initialColumnsParsed = initialColumns.map(suggestion => ({
    ...suggestion,
    type: parseSuggestionType(suggestion.type)
  }));

  const [callSave, reqSave] = usePost("/collections/create_collection", {
    onCompleted: (data) => {
      onSave(data);
    },
    onError: (error) => {
      if(error.status == 409) setError("Ya existe una tabla con ese identificador");
      else setError("Ocurrió un error al crear la tabla");
    }
  });

  const [callGenerate, reqGenerate] = usePost("/collections/generate_collection", {
    onCompleted: (data) => {
      formRef?.current.setFieldValue("structure", data);
      formRef?.current.setFieldValue("show_suggestions", true);
      setIsGenerated(true);
    }
  });

  const onSubmit = (values) => {
    let structure = values.structure;
    callSave({
      ...values,
      flow_id: flowId,
      user_id: parseInt(userId),
      structure,
    });
  }

  const onGenerate = (name) => {
    callGenerate({
      flow_id: flowId,
      user_id: parseInt(userId),
      name
    });
  }

  return <View
    formRef={formRef}
    isGenerated={isGenerated}
    isLoading={reqSave.loading}
    isLoadingGenerate={reqGenerate.loading}
    error={error}
    hasSuggestions={hasSuggestions}
    initialColumns={initialColumnsParsed}
    onSubmit={onSubmit}
    onGenerate={onGenerate}
  />;

}

export default CollectionFormSave;