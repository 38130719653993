import React from "react";
import EventInit from "components/flow/board/nodes/helpers/EventInit";
import { NodeResizeControl } from 'reactflow';
import "styles/css/Note.css";



const NoteView = ({ id, content, readOnly, isSelected, onEditNote, onDeleteNode, onSelectAnnotation }) => (
  <div className={"note w-100 " + (readOnly ? "h-auto pb-1" : "h-100")}>

    <div className="note-content drag-handle-container w-100 h-100" style={{ whiteSpace: "pre-wrap" }}
      onClick={!readOnly ? onEditNote : null}>
      {readOnly ? <div dangerouslySetInnerHTML={{ __html: content }} /> : <span>{content}</span>}

      <div className={"badge-container " + (isSelected ? "selected " : "")}
        style={{ position: "absolute", top: "-24px", right: "calc(100% - 27px)", width: "2.8rem", height: "2.8rem" }}
        onClick={e => e.stopPropagation()}
      >

        <div class="badge badge-pill badge-checkbox"
          style={{ border: "1px solid #ddd", width: "1.9rem", height: "1.9rem", marginTop: "0.2rem", marginLeft: "0.2rem" }}
          onClick={onSelectAnnotation}
        >
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" id={`checkSelectAnnotation${id}`} class="custom-control-input"
                onClick={(e) => { e.stopPropagation(); }} checked={isSelected}
              />
              <label class="custom-control-label" for={`checkSelectAnnotation${id}`}></label>
            </div>
          </div>
        </div>

      </div>
      <EventInit onDeleteNode={onDeleteNode} />
    </div>

    {!readOnly ?
      <NodeResizeControl
        minWidth={10} minHeight={10}
        className="note-resizer">
        <i class="fa-solid fa-up-right-and-down-left-from-center"
          style={{
            transform: "scaleX(-1)", fontSize: "0.72em", color: "#a0a0a0",
            padding: "0.4em", background: "#f8fafe", borderRadius: "50%"
          }}></i>
      </NodeResizeControl>
      : null}

  </div>
);

export default NoteView;