import React from "react";
import { Modal, ModalRoute } from "seed/helpers";
import Panel from "components/start/Panel";
import Templates from "components/start/templates/Templates";
import Flows from "components/start/flows/Flows";
import FormSaveFlow from "components/start/flows/FormSaveFlow";
import FormSetFlow from "components/start/flows/FormSetFlow";
import FormSaveFolder from "components/start/folders/FormSaveFolder";
import FormSetFolder from "components/start/folders/FormSetFolder";
import Collaboration from "components/flow/forms/collaboration/Collaboration";
import FlowsUser from "components/misc/settings/Flows";
import Usage from "components/start/usage/Usage";


const StartView = ({
  selectedFolder,
  isExecutionsShown,
  isDeveloperMode,
  setSelectedFolder,
  setIsExecutionsShown
}) => (

  <div>

    <div style={{ height: "100vh", width: "100%", overflowY: "auto" }}>

      <div class="container px-2 px-md-7 py-4 py-md-7 py-xl-8">
        <div class="row m-0 w-100" style={{ maxHeight: "400px" }}>

          <div className="col-md-4">
            <Panel
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
            />
          </div>

          <div className="col-md-8 pl-md-0 mt-3 mt-md-0 mb-10">
            {isDeveloperMode ?
              <div className="mb-3">
                <Templates />
              </div> : null}

            <Flows
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
            />
            <Usage />
          </div>

        </div>
      </div>

    </div>

    <ModalRoute
      width="410"
      height="450"
      path="/create_folder"
      component={() => <FormSaveFolder setSelectedFolder={setSelectedFolder} />}
    />

    <ModalRoute
      width="410"
      height="450"
      path="/:folderId/edit_folder"
      component={FormSetFolder}
    />

    <ModalRoute
      width="470"
      height="450"
      path="/:folderId/create_flow"
      component={FormSaveFlow}
    />

    <ModalRoute
      width="500"
      height="450"
      path="/:flowId/edit_flow"
      component={FormSetFlow}
    />

    <ModalRoute
      width="800"
      height={window.innerHeight - 150}
      path="/:flowId/share"
      component={(props) =>
        <Collaboration flowId={props.match?.params?.flowId} />
      }
    />


    {isExecutionsShown && (
      <Modal
        width={500}
        height={500}
        onClose={() => setIsExecutionsShown(false)}
        component={FlowsUser}
      />
    )}

  </div>
);

export default StartView;