import React from "react";
import { Field } from "formik";

const Rule = ({
  flowQuestion,
  values,
  flowCaptures,
  questionIndex,
  currentIndex,
  setFieldValue
}) => (
  <div key={questionIndex} className="my-3">
    <div class="">
      <label class="input-label mr-4 mb-0" for="routine_name">
        {flowQuestion.label(values, flowCaptures, currentIndex)}
      </label>
      <div className="d-flex ml-4 mb-3 mt-1 align-items-center">

        <i className="mr-3 d-block">Realizar siempre que el texto </i>

        {/* 
          This selects store the value in the following format: condition__value 
          * Example: "equals__Hola"
          To get the data of the condition, use: values[flowQuestion.name].split("__")[0]
          To get the data of the value, use: values[flowQuestion.name].split("__")[1]
        */}  
        <div className="">
          <select
            required={!values.skip && (values[flowQuestion.name] == undefined || values[flowQuestion.name] == null)}
            className={`btn btn-sm text-center btn-lighter btn-lighter d-block`}
            style={{ borderColor: "#bbb", color: "#555", padding: "5px 7px" }}
            onChange={(e) =>
              setFieldValue(
                flowQuestion.name,
                e.target.value + "__" + (values[flowQuestion.name]?.split("__")[1] ?? "")
              )
            }
            value={(values[flowQuestion.name] ?? "").split("__")[0]}
          >
            {
              flowQuestion.options.map((option, index) =>
                <option key={index} value={option.value} className="">
                  {option.label}
                </option>
              )
            }
          </select>
        </div>

        <Field
          autofocus={true}
          type="text"
          className="form-control w-auto h-auto d-block ml-3"
          required={!values.skip}
          name={flowQuestion.name}
          style={{ borderColor: "#bbb", color: "#555", padding: "4px 12px", minWidth: "250px" }}
          value={(values[flowQuestion.name] ?? "").split("__")[1]}
          onChange={(e) =>
            setFieldValue(
              flowQuestion.name,
              (values[flowQuestion.name]?.split("__")[0] ?? flowQuestion?.options[0]?.value ?? "") 
              + "__" + e.target.value
            )
          }
        />

        {
          values[flowQuestion.name] && values[flowQuestion.name].split("__")[1] != "" 
            ? <i className="ml-2" style={{ color: "#f0b000" }}>✓</i> 
            : null
        }

      </div>

    </div>
  </div>
);

export default Rule;