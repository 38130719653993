import React from "react";
import BaseField from "components/schema_form/fields/custom_fields/BaseField";


const LabelField = ({
    name,
    label,
    values,
    required,
    caption,
    helpMessage,
    labelText,
    labelFontStyle,
    labelFontSize,
    setFieldValue
}) => {
    let fontSize = "1em"
    if (labelFontSize == "large") fontSize = "1.5em"
    if (labelFontSize == "small") fontSize = "0.5"

    return (
        <BaseField
            name={name}
            label={label}
            values={values}
            required={required}
            caption={caption}
            helpMessage={helpMessage}
            enableComments={false}
            setFieldValue={setFieldValue}
        >
            <label style={{
                fontStyle: labelFontStyle,
                fontSize: fontSize
            }
            }>{labelText}</label>
        </BaseField>
    )
}

export default LabelField;