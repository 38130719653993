import React from "react";

const Label = ({
  flowQuestion,
  values,
  flowCaptures,
  questionIndex,
  currentIndex,
}) => (
  <div key={questionIndex} className="my-3">
    <div class="d-flex align-items-center">
      <label class="input-label mr-4" for="routine_name">
        <i>
          {flowQuestion.label(values, flowCaptures, currentIndex)}
        </i>
      </label>
    </div>
  </div>
);

export default Label;