import { parseRowByColumn } from "components/tables/util/datatypes";

const RowCallbaks = ({
  _columns,
  _rows,
  _pageNum,
  _pageSize,
	_totalPages,
	selectedCell,
  isReadOnly,
  setRows,
  setSelectedCell,
  onAddRow,
  onChangeRow,
  onDeleteRow,
  onInsertRow,
  _onPageChange
}) => {

  const _onAddRow = (data = {}) => {

		if(isReadOnly) return;

		let newRow = { data };
		_columns.forEach(column => {
			if(!newRow.data[column.name]) 
				newRow.data[column.name] = "";
		});

		newRow.data = parseRowByColumn(newRow.data, _columns);
		setSelectedCell({ ...selectedCell, row: _rows.length });

		let newRows = [..._rows, newRow];
		if(newRows.length > _pageSize) {
			setSelectedCell({ ...selectedCell, row: 0 });
			_onPageChange(_totalPages);
		}

		setRows(newRows);
		onAddRow(newRow.data);

	}

	const _onDeleteRow = (index) => {

		if (isReadOnly) return;

		const newRows = [..._rows];
		newRows.splice(index, 1);

		setRows(newRows);
		onDeleteRow(index);

	}

	const _onInsertRow = (index) => {

		if (isReadOnly) return;

		const newRows = [..._rows];
		const newRow = { data: {} };
		_columns.forEach(column => newRow.data[column.name] = "");
		newRows.splice(index, 0, newRow);

		setRows(newRows);
		setSelectedCell(null);
		onInsertRow(_rows[index]);

	}

	const _onChangeRow = (index, name, value) => {

		if (isReadOnly) return;

		const newRows = [..._rows];
		newRows[index].data = { ...newRows[index].data, [name]: value };

		setRows(newRows);
		onChangeRow(index, newRows);

	}

	const _onUpdateRow = (index, data) => {
		
		if (isReadOnly) return;

		const newRows = [..._rows];
		newRows[index].data = data;

		setRows(newRows);
		onChangeRow(index, newRows);
	
	}

  return {
    _onAddRow,
    _onDeleteRow,
    _onInsertRow,
    _onChangeRow,
    _onUpdateRow
  }

}

export default RowCallbaks;