import React from "react";
import View from "components/misc/charts/LineChart.view";


const defaultOptions = {
  chart: {
    type: 'line'
  },
  dataLabels: {
    enabled: true,
  },
  stroke: {
    curve: 'smooth'
  },
  colors: ['#77B6EA'],
  grid: {
    borderColor: '#e7e7e7',
    row: {
      colors: ['#f3f3f3', 'transparent'],
      opacity: 0.5
    },
  },
  markers: {
    size: 1
  },
}

function LineChart({ data, options = defaultOptions, height = 350 }) {

  return (
    <View
      options={options}
      series={data}
      height={height}
    />
  );
}

export default LineChart;