import React from "react";
import UseCaseItem from "components/misc/use_cases/UseCases.sub.Item.view";
import UseCaseCategory from "components/misc/use_cases/UseCases.sub.Category.view";
import { Formik, Field, Form } from "formik";
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { LANDING_URL } from "settings"
import ClientForm from "./ClientForm";
import { Loading } from "seed/helpers";
import Categories from "components/misc/templates/Categories";
import Flows from "components/misc/templates/Flows";


const UseCasesView = ({
  flows,
  selectedProfile,
  templateCategories,
  isTemplateProfilesLoading,
  isFlowTemplatesLoading,
  onChangeProfile
}) => (
  <div style={{ height: "100vh", width: "100%", overflowY: "auto" }}>

    <div class="container w-100 h-100 p-3" style={{ position: "relative", top: "15%", marginTop: "-5rem" }}>

      <a class="d-flex justify-content-center" href={LANDING_URL}>
        <img class="z-index-2"
          src="/theme/svg/logos/logo-white.svg" alt="Logo" style={{ height: "2.5rem" }} />
      </a>


      <div className="d-flex flex-column align-items-center justify-content-center mt-5 animate__animated animate__fadeInUp w-100">

        <div class="mb-3 mb-lg-4 text-center">
          <h1 className="text-white">Soluciones</h1>
          <span className="text-wrap" style={{ fontSize: "1.15em", color: "#ffffffe0" }}>
            Conoce algunos ejemplos que Workix tiene para ti
          </span>
        </div>

        {/* Template Categories */}
        {isTemplateProfilesLoading ? <Loading /> : (
          <Categories
            Category={(props) => <UseCaseCategory {...props} onChangeProfile={onChangeProfile} />}
            manualCategories={templateCategories}
            initialActiveCategory={selectedProfile ? selectedProfile.id : null}
            style={{ width: (templateCategories.length * 7) + "%", marginBottom: "1.25rem" }}
            arrowsStyle={{
              color: "white",
              border: "2px solid white",
              fontSize: "1em"
            }}
            arrowContainerStyle={{ width: "100%" }}
            scrollAmount={400}
          />
        )}

        {/* Template Flows */}
        {isFlowTemplatesLoading ? <Loading /> : (
          <Flows
            Flow={(props) => <UseCaseItem {...props} profile={selectedProfile} />}
            manualFlows={flows}
            scrollAmount={500}
            style={{ width: "100%" }}
            arrowContainerStyle={{ width: "100%" }}
          />
        )}

      </div>


    </div>
  </div>
);

export default UseCasesView;