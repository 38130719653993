import React from "react";

const PlanView = ({ name, price, features, usage, usedCredits, totalCredits }) => (
  <div class="card custom-checkbox-card custom-checkbox-card-lg checked">
    <div class="card-header d-block text-center">
      <input type="radio" id={`plan-pricing-radio-${name}`} name="planPricingRadio" class="custom-checkbox-card-input" />
      <label class="custom-checkbox-card-label custom-checkbox-card-label-stretched" for={`plan-pricing-radio-${name}`}></label>

      <small class="card-subtitle">{name}</small>
      <div class="mb-3">
        <span class="card-title display-3">
          $<span id="pricingCount1">{price}</span>
          <small class="font-size-sm text-muted">/ mon</small>
        </span>

        {usage != null && usage != undefined && (
          <div className="mt-4">
            <p>Créditos usados: {usedCredits} / {totalCredits}</p>
            <div class="progress" style={{ height: "15px" }}>
              <div class="progress-bar" role="progressbar" style={{ width: `${usage}%` }}
                aria-valuenow={usage} aria-valuemin="0" aria-valuemax="100">
                {usage}%
              </div>
            </div>
          </div>
        )}
      </div>
    </div>

    <div class="card-body">
      <ul class="list-checked list-checked-primary list-unstyled-py-2">
        {features.map((feature, idx) => (
          <li class="list-checked-item" key={idx}>
            <div dangerouslySetInnerHTML={{ __html: feature }} />
          </li>
        ))}
      </ul>
    </div>

    <div class="card-body-stretched">
      <button type="button" class="btn btn-block btn-white custom-checkbox-card-btn">Seleccionar plan</button>
    </div>

  </div>
)

export default PlanView;