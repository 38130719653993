import React from "react";
import SuggestionPrompt from "components/flow/suggestions/Suggestions.view.lib.prompt";
import Results from "components/flow/suggestions/Results";

const sortExamples = (examples) => {
  const priority = {
    "suggestion": 1,
    "instruction": 2,
    "question": 3
  };
  return examples.sort((a, b) => priority[a?.type ?? a?.content?.response?.type] - priority[b?.type ?? b?.content?.response?.type]);
}

const SuggestionsView = ({
  flowId,
  promptRef,
  isLoading,
  isExamplesLoading,
  text,
  active,
  result = [],
  examples = [],
  setText,
  setActive,
  onGenerateResponse,
  onGenerateExamples,
  onCloseResult,
  onPromptRemove
}) => {
  return (
    <div id="suggestions" className="d-flex"
      style={{ "position": "absolute", top: "0", right: "0", width: "25.7rem", zIndex: 5, background: "#f8fafe" }}>

      <div className="mr-1 py-1 w-100">
        <form onSubmit={e => { e.preventDefault(); onGenerateResponse(text) }} >
          <div class="prompt-wrapper bg-white pl-3 rounded mr-2 ml-2 mt-1 d-flex justify-content-between" 
            style={{ border: "1px solid rgb(218, 221, 224)" }}>
            <input
              type="text"
              required={true}
              class="prompt-input border-0 pl-0 pr-5"
              placeholder="¿En qué te puedo ayudar?"
              ref={promptRef}
              onChange={(e) => { setText(e.target.value); setActive(true) }}
              onFocus={() => setActive(true)}
              onKeyDown={(e) => e.key === "Enter" && onGenerateResponse(text)}
              onClick={() => setActive(true)}
              value={text}
              style={{ width: isLoading ? "calc(100% - 120px)" : "calc(100% - 140px)"  }}
            />
            <button
              type="submit"
              className="btn btn-sm btn-accent font-weight-bold"
              style={{
                paddingLeft: "0.75rem",
                paddingRight: "0.85rem",
                paddingTop: "0.4rem",
                paddingBottom: "0.4rem"
              }}
            >
              {
                isLoading
                  ? <><i class="fas fa-spinner fa-spin mr-2"></i>Generando</>
                  : <><i class="tio-magic-wand mr-2"></i>Generar ideas</>
              }
            </button>
            {text != "" ?
              <i className="prompt-close fas fa-times px-2 py-1"
                onClick={onPromptRemove}
                style={{
                  position: "absolute", right: isLoading ? "8.45rem" : "9.75rem", 
                  top: "1rem", fontSize: "0.9rem", marginTop: "0rem", color: "#a0aab0"
                }}></i> : null}
          </div>
        </form>
      </div>


      {
        active && !isLoading && result?.length == 0
          ? <div
            className={"animate__animated animate__fadeIn w-100"}
            style={{
              position: "absolute",
              marginTop: "3rem",
              right: "0px",
              background: "#f8fafe",
              "--animate-duration": "0.6s"
            }}
          >
            <div className="ml-2 my-2 mr-4">
              <span class="dropdown-header pt-0 pb-2 px-0 ml-2">
                <span style={{ color: "#999" }}>Ejemplos</span>
              </span>
              {
                !isExamplesLoading && sortExamples(examples).map((e, i) =>
                  <SuggestionPrompt
                    key={i}
                    type={e?.response?.type}
                    text={e?.response?.text}
                    onClick={() => onGenerateResponse(e?.response?.text)}
                  />
                )
              }
              <button
                type="button"
                className="btn btn-sm"
                onClick={onGenerateExamples}
                ignore-blur="true"
              >
                {
                  isExamplesLoading
                    ? <><i class="fas fa-spinner fa-spin mr-2"></i>Generando ejemplos</>
                    : <><i class="fas fa-undo mr-2"></i>Generar ejemplos</>
                }
              </button>
            </div>
          </div>
          : null
      }

      <div
        className="mr-2 px-2 pb-2"
        style={{
          position: "absolute",
          marginTop: "50px",
          background: "#f8fafe",
          right: "0px",
        }}
      >
        <div className="scrollbar-narrow"
          style={{
            position: "relative",
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight: "70vh"
          }}
        >
          {
            result?.map((r, i) =>
              <Results
                key={i}
                flowId={flowId}
                result={r}
                onGenerateResponse={onGenerateResponse}
                onClose={() => onCloseResult(i, r.id)}
              />
            )
          }
        </div>
      </div>

    </div>
  );
}

export default SuggestionsView;