import React from "react";
import { useHistory } from 'react-router-dom';
import { usePost } from "seed/api";

const ResultDetails = ({
  suggestion = {},
}) => {

  const historyRouter = useHistory();

  const [callGetAction] = usePost("/actions/get_action_data", {
    onCompleted: (data) => historyRouter.replace(`/flow/create/${data.id}`)
  })

  const onClickAction = (actionName) =>
    callGetAction({action_name: actionName})

  return (
    <div className="animated animate__fadeIn animate__faster">
      {
        suggestion?.type == "SUGGESTION" &&
          <div className="px-2 mt-2">

            <h3>{suggestion.response.title}</h3>

            <div 
              className=""
              style={{
                overflowY: "auto"
              }}
            >
              <span class="d-flex justify-content-between dropdown-header px-0">Resumen</span>
              { suggestion.response.explanation }
              <div className="d-flex flex-column my-2">
                <span class="d-flex justify-content-between dropdown-header px-0">Pasos</span>
                <table className="table table-bordered table-hover table-sm m-0 p-0">
                  <tr>
                    <th>Paso</th>
                    <th>Descripción</th>
                    <th>Acción</th>
                  </tr>
               
                {
                  function () {
                    try {
                      return <>
                        {suggestion.extraData.actions.map((a, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{a.description}</td>
                            <td> <a 
                              className="text-primary ml-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => onClickAction(a.action)} 
                            >
                              {a.label}
                            </a></td>
                          </tr>
                        ))}
                      </>
                    }
                    catch (e) {
                      return <></>
                    }
                  }()
                }
                 </table>
              </div>
            </div>

            <div className="text-center text-muted h6">
              <div className="py-2 px-2" style={{ background: "#fafafa", border: "1px solid #e0e0e0" }}>
                El generador de ideas puede contener aproximaciones y/o errores, recomendamos utilizar el proceso como referencia
              </div>
            </div>
            
          </div>
      }
    </div>
  );
}

export default ResultDetails;