

const getMonthName = (monthNum) => {
    if (monthNum == 1) return "Ene";
    if (monthNum == 2) return "Feb";
    if (monthNum == 3) return "Mar";
    if (monthNum == 4) return "Abr";
    if (monthNum == 5) return "May";
    if (monthNum == 6) return "Jun";
    if (monthNum == 7) return "Jul";
    if (monthNum == 8) return "Ago";
    if (monthNum == 9) return "Sep";
    if (monthNum == 10) return "Oct";
    if (monthNum == 11) return "Nov";
    if (monthNum == 12) return "Dic";
    return "";
}

export { getMonthName }