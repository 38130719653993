import React from "react";
import { Formik, Field, Form } from "formik"

const FormFolderView = ({ folder = {}, isEditing = false, onSubmit, onDeleteFolder }) => (
  <div className="card">

    <div class="card-header">
      <h3 class="card-header-title">
        {isEditing ? "Editar carpeta" : "Crear carpeta"}
      </h3>
    </div>

    <div className="card-body px-4">
      <Formik
        initialValues={folder}
        onSubmit={onSubmit}
      >
        {() =>
          <Form>

            <div class="form-group mb-4">
              <label class="input-label" for="name">Nombre de la carpeta</label>
              <Field type="text" name="name" id="name" autocomplete="off"
                class="form-control form-control" tabindex="0"
                required autoFocus={true} />
            </div>

            {isEditing ? (
              <div className="row">
                <div className="col-md-8">
                  <button type="submit" class="btn btn-block btn-primary">
                    Editar carpeta
                  </button>
                </div>
                <div className="col-md-4 pl-0">
                  <button type="button" class="btn btn-block btn-outline-danger" onClick={onDeleteFolder}>
                    Eliminar
                  </button>
                </div>
              </div>
            ) : (
              <button type="submit" class="btn btn-block btn-primary">
                Crear carpeta
              </button>
            )}

          </Form>}
      </Formik>
    </div>
  </div>
);

export default FormFolderView;