import React, { useState, useRef } from "react";
import View from "components/misc/apps/rpa/generator/RegionPicker.view";
import { useEffect } from "react";

function RegionPicker({ 

  type,
  image,
  capture,
  initialRegions,
  hiddenRegions, 

  currentParameters,
  regionPickerMode = "HIDE", 

  response,
  responseStatus,
  loading,

  onClose,
  onSubmit,
  callParamGenerator,

}) {

  const formikRef = useRef();
  const [edit, setEdit] = useState(Object.keys(initialRegions).length == 0);
  const [regions, setRegions] = useState(initialRegions?.relative?.length > 0 ? initialRegions.relative : []);
  const [cropImage, setCropImage] = useState(null);
  const [relativeCoords, setRelativeCoords] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(10);
  
  const handleChangeRegion = (regions) => {
    
    if(loading) return;

    // Filter regions with area less than 10px or isChanging
    regions = regions.filter(region => region.width * region.height > 10 || region.isChanging);

    setRegions(regions);
    setEdit(true);
  }

  const LoadImage = async img => {
    return new Promise((resolve, reject) => {
        img.onload = async () => {
            resolve(true);
        };
    });
  }

  const getTransformedRegions = async (regions) => {

    const auxImage = new Image();
    auxImage.src = image;
    auxImage.crossOrigin = "Anonymous";
    await LoadImage(auxImage);

    const auxCropImage = new Image();
    auxCropImage.crossOrigin = "Anonymous";
    auxCropImage.src = cropImage;
    await LoadImage(auxCropImage);

    // Convert relative coords
    // Because of the region is relative to the crop image, we need to convert to the original image
    // That's why we need to convert from percent to pixels (crop) and then from pixels (crop) to percent (original)
    // Steps:
    // 1 - Convert from percent to pixels (crop)
    // 2 - Add relative (x1, y1) coords (translate region to the original image)
    // 3 - Convert from pixels (crop) to percent (original)
    const regionsAbs = regions.map(region => {
      return {
        x: ((region.x * auxCropImage.width / 100) + relativeCoords.x) * (100 / auxImage.width),
        y: ((region.y * auxCropImage.height / 100) + relativeCoords.y) * (100 / auxImage.height),
        width: (region.width * auxCropImage.width / 100) * (100 / auxImage.width),
        height: (region.height * auxCropImage.height / 100) * (100 / auxImage.height),
      }
    });

    return {
      relative: regions,
      absolute: regionsAbs,
    }
    
  }

  const callParamGeneratorWrapper = async (...args) => {
    const newRegions = await getTransformedRegions(regions);
    callParamGenerator(...args)
    onSubmit(newRegions);
    onClose();
  }

  const handleSubmit = async () => {
    const newRegions = await getTransformedRegions(regions);
    onSubmit(newRegions);
    onClose();
  }

  const handleAddZoom = () => {
    if(zoom >= 20) return;
    setZoom(zoom + 1);
  }

  const handleSubZoom = () => {
    if(zoom <= 1) return;
    setZoom(zoom - 1);
  }

  useEffect(() => {

    // If the region picker mode is HIDE or the user is not clicking,
    // we don't need to crop the image

    setRegions(initialRegions?.relative?.length > 0 ? initialRegions.relative : []);

    if(regionPickerMode == "HIDE" || ((!capture?.event?.includes("CLICK") && (!capture?.event?.includes("DRAG"))))) {
      setCropImage(image);
      return;
    }

    const auxImage = new Image();
    auxImage.crossOrigin = "Anonymous";
    auxImage.src = image;

    auxImage.onload = () => {

      // Crop image size
      const xSize = Math.max(auxImage.width / zoom, capture.abs_xf - capture.abs_xi + 40)
      const ySize = Math.max(auxImage.height / zoom, capture.abs_yf - capture.abs_yi + 40)

      const canvas = document.createElement("canvas");
      canvas.width = xSize;
      canvas.height = ySize;

      const ctx = canvas.getContext("2d");

      const x = capture.event.includes("DRAG") ? (capture.abs_xi + capture.abs_xf) / 2 : capture.abs_xf;
      const y = capture.event.includes("DRAG") ? (capture.abs_yi + capture.abs_yf) / 2 : capture.abs_yf;

      let clickX = 0;
      let clickY = 0;

      // We need to crop image near to the click point, but it depends in which region is located
      // If is in the middle, there is no problem, but if is in the corner or side, we need to move the crop image
      // to avoid the image be out of bounds
      // To do that, we need to calculate the relative coords of the click point traslated to the crop image coords

      // The relative coords is the top left corner of the crop image coords
      // Click coords is transformed capture.x & capture.y to the crop image coords

      // In middle crops like top, bottom, left and right, 
      // The relative click coord is in center (x or y = 100)

      // ¡Don't modify this code D:!

      // Top left corner
      if((x - (xSize / 2) < 0 && y - (ySize / 2) < 0)) {
        ctx.drawImage(auxImage, 0, 0, xSize, ySize, 0, 0, xSize, ySize);
        setRelativeCoords({ x: 0, y: 0 });
        clickX = x; clickY = y;
      }
      // Bottom right corner
      else if(x + (xSize / 2) > auxImage.width && y + (ySize / 2) > auxImage.height) {
        ctx.drawImage(auxImage, auxImage.width - xSize, auxImage.height - ySize, xSize, ySize, 0, 0, xSize, ySize);
        setRelativeCoords({ x: auxImage.width - xSize, y: auxImage.height - ySize });
        clickX = x - (auxImage.width - xSize); clickY = y - (auxImage.height - ySize);
      }
      // Bottom left corner
      else if(x - (xSize / 2) < 0 && y + (ySize / 2) > auxImage.height) {
        ctx.drawImage(auxImage, 0, auxImage.height - ySize, xSize, ySize, 0, 0, xSize, ySize);
        setRelativeCoords({ x: 0, y: auxImage.height - ySize });
        clickX = x; clickY = y - (auxImage.height - ySize);
      }
      // Top right corner
      else if(x + (xSize / 2) > auxImage.width && y - (ySize / 2) < 0) {
        ctx.drawImage(auxImage, auxImage.width - xSize, 0, xSize, ySize, 0, 0, xSize, ySize);
        setRelativeCoords({ x: auxImage.width - xSize, y: 0 });
        clickX = x - (auxImage.width - xSize); clickY = y;
      }
      // Left side
      else if(x - (xSize / 2) < 0) {
        ctx.drawImage(auxImage, 0, y - (ySize / 2), xSize, ySize, 0, 0, xSize, ySize);
        setRelativeCoords({ x: 0, y: y - (ySize / 2) });
        clickX = x; clickY = ySize / 2;
      }
      // Right side
      else if(x + (xSize / 2) > auxImage.width) {
        ctx.drawImage(auxImage, auxImage.width - xSize, y - (ySize / 2), xSize, ySize, 0, 0, xSize, ySize);
        setRelativeCoords({ x: auxImage.width - xSize, y: y - (ySize / 2) });
        clickX = x - (auxImage.width - xSize); clickY = ySize / 2;
      }
      // Top side
      else if(y - (ySize / 2) < 0) {
        ctx.drawImage(auxImage, x - (xSize / 2), 0, xSize, ySize, 0, 0, xSize, ySize);
        setRelativeCoords({ x: x - (xSize / 2), y: 0 });
        clickX = xSize / 2; clickY = y;
      }
      // Bottom side
      else if(y + (ySize / 2) > auxImage.height) {
        ctx.drawImage(auxImage, x - (xSize / 2), auxImage.height - ySize, xSize, ySize, 0, 0, xSize, ySize);
        setRelativeCoords({ x: x - (xSize / 2), y: auxImage.height - ySize });
        clickX = xSize / 2; clickY = y - (auxImage.height - ySize);
      }
      // Center
      else {
        ctx.drawImage(auxImage, x - (xSize / 2), y - (ySize / 2), xSize, ySize, 0, 0, xSize, ySize);
        setRelativeCoords({ x: x - (xSize / 2), y: y - (ySize / 2) });
        clickX = xSize / 2; clickY = ySize / 2;
      }

      // Draw click point
      if(capture?.event?.includes("CLICK")) {
        ctx.beginPath();
        ctx.arc(clickX, clickY, 5, 0, 2 * Math.PI);
        ctx.strokeStyle = "#5dc1b9";  
        ctx.fillStyle = '#5dc1b9e5';
        ctx.fill();
        ctx.stroke();
      }

      // Draw hidden regions
      if(regionPickerMode == "REGION") {
        hiddenRegions.absolute.forEach(region => {
          ctx.beginPath();
          ctx.rect(region.x - relativeCoords.x, region.y - relativeCoords.y, region.width, region.height);
          ctx.strokeStyle = "none";  
          ctx.fillStyle = 'rgba(0 ,0, 0, 1)';
          ctx.fill();
        });
      }

      const dataURL = canvas.toDataURL("image/png");
      setCropImage(dataURL);

    }

  }, [capture, image, cropImage, regionPickerMode, zoom]);

  return (
    <View

      formikRef={formikRef}
      type={type}
      capture={capture}
      cropImage={cropImage}
      edit={edit}
      regionPickerMode={regionPickerMode}
      regions={regions}

      parameters={currentParameters}
      response={response}
      responseStatus={responseStatus}
      loading={loading}
      
      handleAddZoom={handleAddZoom}
      handleSubZoom={handleSubZoom}
      handleChangeRegion={handleChangeRegion}
      handleSubmit={handleSubmit}
      callParamGenerator={callParamGeneratorWrapper}
      getTransformedRegions={getTransformedRegions}
      
    />
  );

}

export default RegionPicker;