const resizeWindow = (width, height) =>
  postMessage({ type: "resize", width, height });

const closeWindow = () => 
  postMessage({ type: "close" });

const minimizeWindow = () =>
  postMessage({ type: "minimize" });

const maximizeWindow = () =>
  postMessage({ type: "maximize" });

const restoreWindow = () =>
  postMessage({ type: "restore" });

const hideWindow = () =>
  postMessage({ type: "hide" });

const showWindow = () =>
  postMessage({ type: "show" });

const setResponse = (data = {}) =>
  postMessage({ type: "set_response", data });
  
const openDevTools = () =>
  postMessage({ type: "open_devtools" });

const locateWindow = (x, y) => 
  postMessage({ type: "locate", x, y });

const minimizeAllWindows = () =>
  postMessage({ type: "minimize_all_windows" });

const showBlackScreen = () =>
  postMessage({ type: "show_blackcreen" });

const hideBlackScreen = () =>
  postMessage({ type: "hide_blackscreen" });

const topMostWindow = () =>
  postMessage({ type: "top_most" });

const postMessage = (data, defFunction = () => {}) => {
  if(window?.chrome?.webview) 
    window?.chrome?.webview?.postMessage(JSON.stringify(data));
  else defFunction()
}

const addListener = (callback) => {
  if(window?.chrome?.webview)
    window?.chrome?.webview?.addEventListener("message", (e) => {
      try {
        callback(JSON.parse(e.data));
      }
      catch (err) {
        callback(e.data);
      }
    });
}

const removeListener = (callback) => {
  if(window?.chrome?.webview)
    window?.chrome?.webview?.removeEventListener("message", callback);

}

export { 
  postMessage, 
  addListener,
  removeListener,
  resizeWindow,
  closeWindow,
  minimizeWindow,
  maximizeWindow,
  restoreWindow,
  hideWindow,
  showWindow,
  locateWindow,
  setResponse,
  openDevTools,
  minimizeAllWindows,
  showBlackScreen,
  hideBlackScreen,
  topMostWindow
}