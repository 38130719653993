import React from "react";
import swal from "sweetalert";
import { useSave } from "seed/gql";
import { useHistory } from 'react-router-dom';
import { SAVE_FLOW_PAGE } from "seed/gql/queries";
import View from "components/flow/forms/pages/FormPage.view";

function FormSavePage({ match }) {

  const flowId = match?.params?.flowId;
  const historyRouter = useHistory();

  const [callSave, reqSave] = useSave(SAVE_FLOW_PAGE, {
    onCompleted: (data) => {
      sessionStorage.setItem("page_" + flowId, data.saveFlowPage.flowPage.id);
      window.location.replace(`/app/flow/${flowId}`);
    }, onError: (err) => {
      swal("Error al crear la capa", "Por favor cierre sesión y vuelva a entrar", "error")
    }
  })

  const onSubmit = (values) => {

    const newValues = {
      name: values.name,
      isDefault: false,
      flow: parseInt(flowId)
    }

    callSave(newValues);
  }

  return <View onSubmit={onSubmit} />;
}

export default FormSavePage;