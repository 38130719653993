import { events, startsWithEvent } from "components/misc/apps/rpa/generator/util/events";

const FLOW_QUESTIONS = {
  "events_00": {
    "next": [
      {
        "id": "mouse_00",
        "condition": (answers, captures, index) =>
          startsWithEvent(captures[index], events.RIGHT_CLICK_EVENT) ||
          startsWithEvent(captures[index], events.LEFT_CLICK_EVENT) ||
          startsWithEvent(captures[index], events.DRAG_EVENT),
      },
      {
        "id": "text_00",
        "condition": (answers, captures, index) =>
          startsWithEvent(captures[index], events.TEXT_EVENT),
      },
      {
        "id": "key_00",
        "condition": (answers, captures, index) =>
          startsWithEvent(captures[index], events.KEY_EVENT),
      },
      {
        "id": "events_01",
        "condition": (answers, captures, index) =>
          startsWithEvent(captures[index], events.WHEEL_EVENT),
      }
    ],
  },
  "events_01": {
    "name": "WHEEL",
    "definition": (answers, captures, index) =>
      "WHEEL",
  },
};

export default FLOW_QUESTIONS;