import { MarkerType } from "reactflow";

const parseNodesDatabaseToUi = (nodes) => {

    const parsedNodes = [];
    nodes.forEach((node) => {
        let ui = node.uiSettings;
        const newNode = {
            id: `${node.idx}`,
            remoteId: node.id,
            type: "node",
            isAnnotation: false,
            position: ui.position,
            dragHandle: ".drag-handle-container",
            data: {
                flowId: node?.flow?.id,
                remoteId: node.id,
                is_enabled: node.isEnabled,
                actionId: node.action.id,
                input_value: node.inputValue,
                output_value: node.outputValue,
                is_iterable: node.isIterable,
                iterable_field: node.iterableField,
                warnings: node?.warnings ?? [],
                errors: node?.errors ?? []
            }
        };
        if (ui.width) newNode["width"] = ui.width;
        if (ui.height) newNode["width"] = ui.height;

        parsedNodes.push(newNode);
    })

    return parsedNodes;
}

const parseNodesUiToDatabase = (nodes) => {

    const parsedNodes = nodes.map((node) => {

        const values = {};
        for (const input of node.data.input_value) {
            values[input.name] = input.value
        }

        const extraAttributes = {
            is_enabled: node.data?.is_enabled,
            is_iterable: node.data?.is_iterable,
            iterable_field: node.data?.iterable_field,
            parent_id: node.data?.parent_id
        };

        const newNode = {
            id: node?.remoteId,
            action: node.data.actionId,
            ui: { 
                position: node?.position,
                width: node?.width,
                height: node?.height
            },
            values: values,
            extra_attributes: extraAttributes
        };

        return newNode;

    })

    return parsedNodes;
}

const parseEdgesDatabaseToUi = (edges) => {

    const parsedEdges = [];
    edges.forEach((edge) => {
        const source = `${edge.source.idx}`;
        const target = `${edge.target.idx}`;

        const newEdge = {
            id: `edge-${edge.id}`,
            type: "edge",
            source: source,
            target: target,
            mode: edge.type,
            sourceHandle: edge.sourceName,
            targetHandle: edge.targetName,
            style: { strokeWidth: 0.4, stroke: "#111" },
            remoteId: edge.id,
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 65,
                height: 65,
                color: "#333"
            },
        };

        parsedEdges.push(newEdge);
    })

    return parsedEdges;
}

const parseEdgesUiToDatabase = (edges) => {

    const parsedEdges = [];
    edges.forEach((edge) => {
        const newEdge = {
            id: edge?.remoteId,
            mode: edge.mode,
            source_idx: edge.source,
            target_idx: edge.target,
            source_name: edge.sourceHandle,
            target_name: edge.targetHandle
        }
        parsedEdges.push(newEdge)
    })
    return parsedEdges;
}

const parseAnnotationsDatabaseToUi = (annotations) => {

    const parsedAnnotations = [];
    annotations.forEach((annotation) => {
        let ui = annotation.uiSettings;
        const newAnnotation = {
            id: `${annotation.idx}_annotation`,
            type: annotation.action.name,
            isAnnotation: true,
            position: ui.position,
            remoteId: annotation.id,
            dragHandle: ".drag-handle-container",
            data: {
                actionId: annotation.action.id,
                input_value: annotation.inputValue,
                width: ui.width,
                height: ui.height,
            }
        };

        if (ui.width && ui.height) {
            newAnnotation["width"] = ui.width;
            newAnnotation["height"] = ui.height;
            newAnnotation["style"] = {
                width: ui.width,
                height: ui.height
            }
        }

        parsedAnnotations.push(newAnnotation);
    })

    return parsedAnnotations;

}

const parseAnnotationsUiToDatabase = (annotations) => {

    const parsedAnnotations = [];
    annotations.forEach((annotation) => {

        const values = {};
        for (const input of annotation.data.input_value) {
            values[input.name] = input.value
        }

        const newAnnotation = {
            id: annotation?.remoteId,
            action: annotation.data.actionId,
            ui: {
                position: annotation?.position,
                width: annotation?.width,
                height: annotation?.height
            },
            values: values
        };

        parsedAnnotations.push(newAnnotation);

    })

    return parsedAnnotations;

}

export {
    parseNodesDatabaseToUi,
    parseEdgesDatabaseToUi,
    parseNodesUiToDatabase,
    parseEdgesUiToDatabase,
    parseAnnotationsDatabaseToUi,
    parseAnnotationsUiToDatabase
};