import React, { useState } from "react";
import View from "components/start/folders/FolderItem.view";

function FolderItem({ folder, selectedFolder, setSelectedFolder }) {

  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = () => {
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <View
      folder={folder}
      selectedFolder={selectedFolder}
      isHovered={isHovered}
      setSelectedFolder={setSelectedFolder}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
}

export default FolderItem;