/* eslint-disable max-len */
import React from "react";
import { bodyContentMapper } from "components/flow/board/nodes/Node.lib";

function Content({ nodeId, isControl = false, isIterable = false, app, action, nodeInput, isSelected, onSelectNode }) {

	const nodeValues = nodeInput;
	const displayOptions = action.uiSettings?.node ?? [];
	const formSettings = action.uiSettings.form;
	const isDummy = action.name == "dummy_node";

	const title = isDummy ? nodeInput.title : bodyContentMapper(displayOptions, nodeValues, "title", formSettings);
	const subtitle = isDummy ? nodeInput.subtitle : bodyContentMapper(displayOptions, nodeValues, "subtitle", formSettings);
	const offsetIconLeft = action.name == "conditional" ? "6px" : "6px";
	let icon = app.uiSettings?.icon;
	if (action.name == "conditional") icon = "fas fa-diagram-project";
	else if (action.name == "iterate") icon = "fa-solid fa-arrows-rotate";


	const appIconStyle = {
		position: "absolute",
		top: "-18px",
		right: "calc(100% - 24px)",
		width: "2.8rem",
		height: "2.8rem"
	}

	if(action.type == "TRIGGER") {
		appIconStyle.top = "-24px";
		appIconStyle.right = "calc(100% - 27px)"
	}

	if (isControl) {
		appIconStyle.top = "-40px";
		appIconStyle.right = "calc(100% - 25px)"
	}

	return (
		<div>

			{/* Node title */}
			{!isControl ? <h6 className="px-2 mb-0 text-center">
				<div dangerouslySetInnerHTML={{ __html: title }} />
			</h6> : null}

			{/* Node subtitle */}
			<p className="px-2 mb-0" style={{ fontSize: "0.7rem" }}>
				{!isControl && subtitle != "" && (
					<label style={{ fontSize: "0.9em", color: "#909090" }}><div dangerouslySetInnerHTML={{ __html: subtitle }} /> </label>
				)}

				{/* Main icon for control nodes */}
				{isControl && <i className={`${icon} text-dark`} style={{ fontSize: "0.8rem" }}></i>}
			</p>

			{/* Node subtitle and icon, only apply for control nodes */}
			{isControl && subtitle != "" && (
				<div style={{ position: "absolute", top: "-45px", left: `calc(100% - ${offsetIconLeft})` }}>
					<div className="badge badge-pill badge-light"
						style={{ border: "1px solid #c0c0c0", fontSize: "10px", color: "#808080", fontWeight: "normal", lineHeight: 1.15 }}>
						<div style={{ minWidth: "5.5rem", maxWidth: "7rem", whiteSpace: "normal", maxHeight: "2.4rem", overflow: "hidden" }} dangerouslySetInnerHTML={{ __html: subtitle }} />
					</div>
				</div>
			)}

			{/* Node icon, the icon in the left upper corner */}
			<div className={"badge-container " + (isSelected ? "selected " : "")}
				style={appIconStyle}
				onClick={e => e.stopPropagation()}
			>

				{/* Checkbox used to select the node when mouse is hover */}
				<div class="badge badge-pill badge-checkbox"
					style={{ border: "1px solid #ddd", width: "1.9rem", height: "1.9rem", marginTop: "0.2rem", marginLeft: "0.2rem" }}
					onClick={onSelectNode}
				>
					<div class="form-group">
						<div class="custom-control custom-checkbox">
							<input type="checkbox" id={`checkSelectNode${nodeId}`} class="custom-control-input"
								onClick={(e) => { e.stopPropagation(); }} checked={isSelected}
							/>
							<label class="custom-control-label" for={`checkSelectNode${nodeId}`}></label>
						</div>
					</div>
				</div>

				{/* Icon on the left upper corner */}
				{!isControl && (
					< span class="badge badge-pill badge-light text-center px-0 pb-0 badge-icon"
						style={{ border: "1px solid #c0c0c0", width: "1.25rem", height: "1.25rem", paddingTop: "0.22rem", marginTop: "0.6rem", marginLeft: "-0.2rem" }}
					>
						<i style={{ color: "#909090" }} className={icon}></i>
					</span>
				)}

			</div>

			{/* Legend that indicates the node is iterable */}
			{
				isIterable && (
					<div style={{ position: "absolute", bottom: action.type != "TRIGGER" ? "-12px" : "-15px", right: action.type != "TRIGGER" ? "calc(100% - 8px)" : "calc(100% - 10px)" }}>
						<span class="badge badge-pill badge-light text-center" style={{ border: "1px solid #c0c0c0" }}>
							Iterable
						</span>
					</div>
				)
			}

		</div >
	);

}

export default Content;
