import React from "react";


const TemplateView = ({ template, onChangeSidenav, onChangeTopnav }) => (
  <div className="card">
    <div className="card-header">
      <h2>Ajustar plantilla</h2>
    </div>

    <div className="card-body">

      <h3 className="text-center">Selecciona una plantilla</h3>

      <div className="row p-4">


        <div className="col-md-6">
          <div class="card custom-checkbox-card custom-checkbox-card-lg">
            <div class="card-header d-block text-center">
              <input type="radio" id="topnavTemplateRadio" name="template" class="custom-checkbox-card-input"
                onChange={onChangeTopnav} checked={template == "tabs"}
              />
              <label class="custom-checkbox-card-label custom-checkbox-card-label-stretched" for="topnavTemplateRadio"></label>

              <div class="mb-2">
                <span class="card-title h5" style={{ fontSize: "2rem" }}>Pestañas</span>
              </div>
              <span>Recomendado para menos de 4 secciones</span>
            </div>

            <div class="card-body">
              <img className="img-fluid" src="/theme/svg/layouts/header-default-container.svg" alt="Topnav layout" />
            </div>

          </div>
        </div>

        <div className="col-md-6">
          <div class="card custom-checkbox-card custom-checkbox-card-lg">
            <div class="card-header d-block text-center">
              <input type="radio" id="sidenavTemplateRadio" name="template" class="custom-checkbox-card-input"
                onChange={onChangeSidenav} checked={template == "sidenav"}
              />
              <label class="custom-checkbox-card-label custom-checkbox-card-label-stretched" for="sidenavTemplateRadio"></label>

              <div class="mb-2">
                <span class="card-title h5" style={{ fontSize: "2rem" }}>Menú lateral</span>
              </div>
              <span>Recomendado para más de 4 secciones</span>
            </div>

            <div class="card-body">
              <img className="img-fluid" src="/theme/svg/layouts/content-combinations-overlay.svg" alt="Sidenav layout" />
            </div>

          </div>
        </div>


      </div>
    </div>

  </div>
)

export default TemplateView;