import React, { useState } from "react";
import View from "components/misc/apps/rpa/designer/SampleParameters.view";

function SampleParameters({ 
  data = {},
  selectedImage, 
  onSubmit, 
  onChangeValue, 
  regions, 
}) {

  const [advancedMode, setAdvancedMode] = useState(false);
  const developerMode = sessionStorage.getItem("developer_mode") == "true";

  const onChangeAdvancedMode = () =>
    setAdvancedMode(!advancedMode);

  return <View
    data={data}
    selectedImage={selectedImage}
    advancedMode={advancedMode}
    developerMode={developerMode}
    onChangeAdvancedMode={onChangeAdvancedMode}
    onSubmit={onSubmit}
    onChangeValue={onChangeValue}
    regions={regions}
  />;

}

export default SampleParameters;