import React from "react";
import * as Factory from "components/flow/board/nodes/helpers/Factory";
import EventInit from "components/flow/board/nodes/helpers/EventInit";
import ControlNode from "components/flow/board/nodes/Node.sub.ControlNode.view";
import "styles/css/Node.css";


const NodeView = ({
  app,
  action,
  nodeId,
  nodeInput,
  nodeOutput,
  nodeData,
  errors,
  warnings,
  executionMessage,
  canNodeBeExecuted,
  isRoot,
  isIterable,
  isSelected,
  onClickExecute,
  onEdit,
  onDeleteNode,
  onSelectNode,
  onChangeStatusTrigger
}) => (
  <div>
    {action.name == "conditional" || action.name == "iterate" ? (
      <ControlNode
        app={app}
        action={action}
        nodeId={nodeId}
        nodeInput={nodeInput}
        nodeOutput={nodeOutput}
        errors={errors}
        warnings={warnings}
        isRoot={isRoot}
        isSelected={isSelected}
        onEdit={onEdit}
        onSelectNode={onSelectNode}
      />
    ) : (
      <div>
        <div className={ "dropright " + 
            (action.type == "TRIGGER" ? "trigger " : "node ") + 
            (isRoot ? "root " : "") + 
            (executionMessage != "" ? "has-execute-message " : "") +
            (warnings.length > 0 ? "has-warnings " : "") +
            (errors.length > 0 ? "has-errors " : "") }>

          <div style={{ position: "absolute", bottom: action.type != "TRIGGER" ? "-5px" : "-6px", right: "-5px" }}>
            <span style={{
              fontSize: "0.6rem", color: "#909090", padding: "0px 4px",
              background: "#f9fafc", border: "1px solid #c0c0c0", borderRadius: "2px"
            }}>{nodeId}</span>
          </div>

          <div
            className="text-center drag-handle-container" onClick={onEdit}
            style={{ padding: action.type != "TRIGGER" ? "8px 6px" : "5px 8px 2px 8px", position: "relative" }}
          >
            <Factory.NodeContent
              app={app}
              action={action}
              nodeId={nodeId}
              nodeInput={nodeInput}
              nodeOutput={nodeOutput}
              isIterable={isIterable}
              isSelected={isSelected}
              onSelectNode={onSelectNode}
            />
            {action.type == "TRIGGER" && (<hr className="mt-2 mb-1" style={{ borderTopColor: "#eee" }} />)}
          </div>

          {((isRoot && action.type != "TRIGGER") || (action.type == "TRIGGER" && nodeData.is_enabled)) && (
            <div style={{
              position: "absolute", cursor: "pointer",
              top: action.type != "TRIGGER" ? "-12.5px" : "-4px",
              left: action.type != "TRIGGER" ? "calc(100% - 15px)" : "calc(100% - 20px)"
            }}
              data-toggle="dropdown" className="dropdown-toggle" aria-expanded="true" data-display="static"
              onClick={onClickExecute}
            >
              <span class="badge badge-pill badge-success node-execute" 
                style={{ border: "1px solid #00b797", padding: "0.37rem 0.62rem 0.37rem 0.62rem", fontSize: "0.7rem" }}>
                <i className="fa-solid fa-play" style={{marginRight: "0.2rem"}}></i> Ejecutar
              </span>
            </div>
          )}

          {/* Execution Message */}
          {(executionMessage != "" && action.type != "TRIGGER" && canNodeBeExecuted) && (
            <div style={{
              position: "absolute", cursor: "pointer",
              top: !isRoot ? "-11px" : "-14px",
              left: !isRoot ? "calc(100% - 4px)" : "calc(100% + 43px)"
            }}
              data-toggle="dropdown" className="dropdown-toggle" aria-expanded="false" data-display="static">
              <i className="fas fa-question-circle"
                style={{ color: "#2080E5", cursor: "pointer", marginLeft: "0.5rem", fontSize: "1.05em" }}></i>
            </div>
          )}

          {executionMessage != "" && (
            <div className={`dropdown-menu execution-message ${action.type != "TRIGGER" ? "execution-node" : "execution-trigger"}`}
              style={{ minWidth: "200px" }} >
              <div dangerouslySetInnerHTML={{ __html: executionMessage }} />
              <EventInit onDeleteNode={onDeleteNode} />
            </div>
          )}

          {/* Switch to activate / deactivate the trigger */}
          {action.type == "TRIGGER" && (
            <div class="form-group mb-0 text-center">
              <div class="custom-control custom-switch d-flex mx-3 mt-0" onClick={e => e.stopPropagation()}>
                <input type="checkbox" class="trigger-radiobutton custom-control-input" id={`chechbox_status_${nodeId}`}
                  checked={nodeData.is_enabled} onChange={onChangeStatusTrigger} />
                <label class="custom-control-label" for={`chechbox_status_${nodeId}`} >
                  <span className="d-block" style={{ fontSize: "0.77em", marginTop: "0.12rem" }}>
                    {nodeData.is_enabled ? "Activo" : "Desactivado"}</span>
                </label>
              </div>
            </div>
          )}

          <Factory.TargetPoints action={action} />
        </div>

        {(warnings.length > 0 || errors.length > 0) && (
          <div className="dropright">

            <div className="text-center dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" data-offset="-50,-330">

              {errors.length > 0 ? (
                <div style={{ position: "absolute", top: "calc(50% - 27px)", right: "-21px", cursor: "pointer" }}>
                  <i class="fa-solid fa-circle-xmark text-danger"></i>
                </div>
              ) : (
                <div style={{ position: "absolute", top: "calc(50% - 27px)", right: "-21px", cursor: "pointer" }}>
                  <i class="fa-solid fa-triangle-exclamation text-warning"></i>
                </div>
              )}

            </div>

            <Factory.DropdownErrors
              warnings={warnings}
              errors={errors}
              actionUiSettings={action.uiSettings}
            />

          </div>
        )}

      </div>
    )}
  </div>
);

export default NodeView;