import React from "react";
import { components } from '@reactour/tour'

const PopoverContent = ({
  showPrevNextButtons = true,
  showCloseButton = true,
  showNavigation = true,
  showBadge = true,
  showDots = true,
  rtl,
  meta,
  steps,
  styles,
  badgeContent,
  accessibilityOptions,
  disabledActions,
  currentStep,
  transition,
  isHighlightingObserved,
  nextButton,
  prevButton,
  disableDotsNavigation,
  setMeta,
  setSteps,
  setIsOpen,
  setCurrentStep,
  onClickClose,
}) => {
  const step = steps[currentStep]

  const badge =
    badgeContent && typeof badgeContent === 'function'
      ? badgeContent({
        currentStep,
        totalSteps: steps.length,
        transition,
      })
      : currentStep + 1

  function closeClickHandler() {
    if (!disabledActions) {
      if (onClickClose && typeof onClickClose === 'function') {
        onClickClose({
          setCurrentStep,
          setIsOpen,
          currentStep,
          steps,
          meta,
          setMeta,
          setSteps,
        })
      } else {
        setIsOpen(false)
      }
    }
  }

  const isLastStep = currentStep === steps.length - 1

  return (
    <React.Fragment>
      {showBadge ? <components.Badge styles={styles}>{badge}</components.Badge> : null}
      {showCloseButton ? (
        <components.Close
          styles={styles}
          aria-label={accessibilityOptions?.closeButtonAriaLabel}
          disabled={disabledActions}
          onClick={closeClickHandler}
        />
      ) : null}
      <div className="d-flex flex-column align-items-end">
        <components.Content
          content={step?.content}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          transition={transition}
          isHighlightingObserved={isHighlightingObserved}
          setIsOpen={setIsOpen}
        />
        <button className={"btn btn-sm float-right " + (!isLastStep ? "mt-2 btn-link" : "btn-primary w-100 mt-3")}
          onClick={() => {
            if (isLastStep) {
              setIsOpen(false)
            } else {
              setCurrentStep((s) => s + 1)
            }
          }}
        >
          {isLastStep ? 'Iniciar' : 'Siguiente'}
        </button>
      </div>
      {showNavigation ? (
        <div className={!isLastStep ? "mt-n7" : "mt-n2"}>
          <components.Navigation
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            setIsOpen={setIsOpen}
            steps={steps}
            styles={styles}
            aria-hidden={!accessibilityOptions?.showNavigationScreenReaders}
            nextButton={nextButton}
            prevButton={prevButton}
            disableDots={disableDotsNavigation}
            hideButtons={!showPrevNextButtons}
            hideDots={!showDots}
            disableAll={disabledActions}
            rtl={rtl}
          />
        </div>
      ) : null}

    </React.Fragment>
  )
}

export default PopoverContent;