import React from "react";
import { useLocation } from 'react-router-dom';
import View from "components/presentation/templates/top_template/Topnav.view";

function Topnav({ flow, showTabs, onOpenEdit, onOpenTemplates, onOpenShare }) {

  const location = useLocation();
  const pathname = location.pathname

  return <View
    flow={flow}
    showTabs={showTabs}
    pathname={pathname}
    onOpenEdit={onOpenEdit}
    onOpenTemplates={onOpenTemplates}
    onOpenShare={onOpenShare} />;
}

export default Topnav;