import React from "react";
import { Modal } from "seed/helpers";
import Plans from "components/misc/plans/Plans";
import HeatmapChart from "components/misc/charts/HeatmapChart";
import ExecutionsList from "components/start/executions/ExecutionsList";


const UsageView = ({
  userId,
  heatMapExecutions,
  isModalPlansShown,
  setIsModalPlansShown
}) => (
  <>
    <div className="card mt-3"
      style={{ border: "1px solid #d2dae0" }}>
      <div class="card-body" style={{ padding: "1.5rem 0.7rem 0rem 0.7rem" }}>

        <small class="nav-subtitle mb-2" title="Pages" style={{ color: "#808590", fontSize: "0.95em", padding: "0rem 0.7rem" }}>
          <i class="fas fa-chart-bar mr-2"></i>
          Actividad
        </small>

        <div className="mt-n4">
          <HeatmapChart data={heatMapExecutions.data} options={heatMapExecutions.options} height={185} />
        </div>
      </div>

    </div>

    <div className="card mt-3"
      style={{ border: "1px solid #d2dae0" }}>

      <div class="card-body" style={{ padding: "1.5rem 1.5rem 1.2rem 1.5rem" }}>

        <small class="nav-subtitle" title="Pages"
          style={{ color: "#808590", fontSize: "0.95em", marginBottom: "0.7rem" }}>
          <i class="fas fa-chart-line mr-2"></i>
          Actividad reciente
        </small>

        <div className="my-3">
          <ExecutionsList
            user_id={userId}
            status={""}
            placeholder={"No hay ejecuciones"} />
        </div>

      </div>
    </div>

    {isModalPlansShown && (
      <Modal
        width={1000}
        height={600}
        animation={""}
        onClose={() => setIsModalPlansShown(false)}
        component={Plans}
      />
    )}

  </>
);

export default UsageView;