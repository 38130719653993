import React from "react";
import { Form, Field, Formik } from "formik";
import { StyleTag } from "seed/helpers";

const ContactView = ({ onSubmit }) => (
  <div className="card">

    <div class="card-header">
      <h3 class="card-header-title">
        Contáctanos
      </h3>
    </div>

    <div className="card-body">
      <Formik
        initialValues={{}}
        onSubmit={onSubmit}
      >
        {() =>
          <Form>

            <div class="row mx-n2">
              <div class="col-md-6 px-2 mb-3">
                <label class="sr-only">Nombre</label>
                <div class="js-form-message">
                  <div class="input-group">
                    <Field type="text" class="form-control" name="first_name" placeholder="Nombre *" required />
                  </div>
                </div>
              </div>

              <div class="col-md-6 px-2 mb-3">
                <label class="sr-only">Apellido</label>
                <div class="js-form-message">
                  <div class="input-group">
                    <Field type="text" class="form-control" name="last_name" placeholder="Apellido *" required />
                  </div>
                </div>
              </div>

              <div class="col-md-12 px-2 mb-3">
                <label class="sr-only">Correo electrónico</label>
                <div class="js-form-message">
                  <div class="input-group">
                    <Field type="email" class="form-control" name="email" placeholder="Correo electrónico *" required />
                  </div>
                </div>
              </div>

              <div class="col-md-6 px-2 mb-3">
                <label class="sr-only">Empresa</label>
                <div class="js-form-message">
                  <div class="input-group">
                    <Field type="text" class="form-control" name="company" placeholder="Empresa *" required />
                  </div>
                </div>
              </div>

              <div class="col-md-6 px-2 mb-3">
                <label class="sr-only">Puesto</label>
                <div class="js-form-message">
                  <div class="input-group">
                    <Field type="text" class="form-control" name="job_title" placeholder="Puesto *" required />
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-5">
              <label class="sr-only">Comentarios adicionales</label>

              <div class="js-form-message input-group">
                <Field as="textarea" class="form-control" rows="4" name="comments" placeholder="Comentarios adicionales" />
              </div>
            </div>

            <button type="submit" class="btn btn-pill btn-block text-white btn-contact-us transition-3d-hover mb-2">
              <strong>Enviar</strong>
            </button>

            <StyleTag content={`
              .btn-contact-us {
                background-color: #FFC000;
                border-color: #FFC000;
              }

              .transition-3d-hover {
                transition: all .2s ease-in-out;
              }
            `} />

          </Form>
        }</Formik>
    </div>

  </div>
);

export default ContactView;