/* eslint-disable max-len */
import React from "react";
import { Link } from "react-router-dom";
import Apps from "components/flow/toolbox/apps/Apps";
import { ActionElement, TabButton } from "components/flow/toolbox/Toolbox.sub.ActionElement.view"
import * as util from "components/flow/toolbox/util/toolbox";
import { StyleTag, ModalRoute } from "seed/helpers";
import ScrollArrow from "components/util/helpers/ScrollArrow";

const ToolboxView = ({
  apps,
  flow,
  user,
  filterTabs,
  suggestions,
  actionSearch,
  actionFilters,
  isAnonymous,
  isSuggestionsLoading,
  reqApps,
  onSearchActions,
  onClickSearchRemove,
  onDragStart,
  onClickAction,
  onClickFilterTab,
  onClickFilterRemove
}) => (
  <div id="toolbox" class="" style={{ background: "#ebf1f6", borderRight: ".12rem solid #d2dae0" }}>

    {
      <div class="d-flex align-items-center pl-2"
        style={{
          borderBottom: ".12rem solid #d2dae0", background: "#f7faff",
          paddingBottom: "0.3rem", paddingTop: "0.35rem"
        }}>

        <a href={!isAnonymous ? "/app" : "/use_cases"}
          className="back-button-flow d-flex align-items-center justify-content-center"
          style={{ paddingRight: "0.7rem", paddingLeft: "0.7rem" }}
          title="Regresar">
          <i class="fas fa-arrow-left d-block"></i>
        </a>

        <div class="w-auto" >
          <div className="logo"></div>
        </div>

        <StyleTag content={`
          #toolbox .logo {
            margin-top: -0.4rem;
            width: 2.5rem;
            height: 2.5rem;
            background-image: url(/theme/svg/logos/logo-short.svg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 2.15rem;
          }
        `} />

        <div class="ml-0">

          <div class="dropdown navbar-dropdown-account-wrapper rounded pr-2"
            style={{ cursor: "pointer", paddingLeft: "0.2rem", marginLeft: "-0.15rem" }}>
            <div class="dropdown-toggle ml-2" id="dropdownFlowSettings" data-toggle="dropdown" aria-expanded="false">
              <span class="d-flex align-items-center h5 text-hover-primary mb-0 text-truncate">
                <span className="d-inline-block text-truncate" style={{ maxWidth: "8.7rem" }}>{flow.name}</span>
                {!isAnonymous ? <small class="ml-1"><i class="fas fa-chevron-down"></i></small> : null}
              </span>
              <span class="d-block font-size-sm text-body">
                {!isAnonymous ? `Plan ${(user?.license?.name ?? "básico").toLowerCase()}` : "Demo"}
              </span>
            </div>

            <div class="dropdown-menu pr-1" aria-labelledby="dropdownFlowSettings">
              {!isAnonymous ? <>
                <Link to="/flow/edit_flow" class="dropdown-item">
                  <i class="fas fa-pen dropdown-item-icon mr-1"></i>Cambiar nombre
                </Link>
                <a href={`/app/settings/billing`} class="dropdown-item">
                  <i class="fas fa-money-bill dropdown-item-icon mr-1"></i>Ver plan
                </a>
              </> : null}
            </div>
          </div>

        </div>
      </div>
    }

    <div className="d-md-block" style={{ width: "285px", height: "calc(100% - 130px)" }} id="">

      <div style={{ height: "calc(100% - 130px)" }}>
        <div className={"search-wrapper"} style={{ marginTop: "1.4rem", marginBottom: "1.1rem", paddingLeft: "1.45rem", paddingRight: "1.45rem" }}>
          <input type="text" placeholder="Buscar acciones" class="py-1 pl-5 pr-5 rounded w-100 search-input"
            style={{ border: "1px solid #d5dae0", fontSize: "0.95em" }} value={actionSearch}
            onChange={(event) => onSearchActions(event.target.value)}
          ></input>
          <i className="fas fa-search d-block ml-2" style={{ fontSize: "0.9rem", marginTop: "-1.5rem" }}></i>
          {actionSearch != "" ?
            <i class="fas fa-times search-close py-0 px-2" style={{
              position: "relative", left: "100%", marginLeft: "-1.77rem",
              fontSize: "0.8rem", marginTop: "-0.75rem", color: "#a0aab0"
            }}
              onClick={onClickSearchRemove}
            ></i> : null}
        </div>

        <div id="toolbox-tabs" style={{ paddingLeft: "1.45rem", paddingRight: "1.1rem" }}>
          <ScrollArrow>
            {filterTabs.map(filter =>
              <TabButton
                key={filter.name}
                name={filter.name} label={filter.label}
                actionFilters={actionFilters}
                onClickFilterTab={onClickFilterTab}
                onClickFilterRemove={onClickFilterRemove}
              />
            )}
          </ScrollArrow>
        </div>

        <div id="toolbox-actions"
          className={"w-100 scrollbar-narrow d-flex px-3 " +
            (actionSearch != "" || actionFilters.length > 0 ? "flex-column" : "flex-wrap")}
          style={{ height: "calc(100% - 7px)", marginTop: "0.5rem", overflowY: "auto", scrollbarGutter: "stable both-edges" }}>

          {/*  SUGGESTIONS */}
          {!isSuggestionsLoading && suggestions.length > 0 ?
            <div class="card mx-3 my-2 pb-1" style={{ background: "#fffafa" }}>
              <span class="dropdown-header pt-3 pb-1">
                <span style={{ color: "#777" }}>
                  <i class="fa-solid fa-star mr-2" style={{ color: "#F0B000" }}></i>Sugerencias
                </span>
              </span>
              <ul className="list-group py-2 w-100"
                style={{ border: "none", paddingLeft: "1.2rem", paddingRight: "1.2rem" }}>
                {suggestions.map((suggestion, idx) => {
                  return (
                    <ActionElement
                      key={idx}
                      action={suggestion}
                      onClickAction={onClickAction}
                      onDragStart={onDragStart}
                      isSuggestion={true}
                    />
                  );
                })}
              </ul></div> : null}

          {actionSearch != "" || actionFilters.length > 0 ?
            <span class="d-flex justify-content-between dropdown-header pt-3 pb-2 px-2">
              <span style={{ color: "#555" }}>
                {actionSearch != "" ? "Búsqueda" : "Acciones - " + filterTabs.find(tab => tab.name == actionFilters[0])?.label}
              </span>
            </span> : null}

          {/* ACTIONS */}
          {function () {

            let appsToShow = apps;
            let hasFilters = actionFilters.length > 0;
            const searchCondition = (app, action) =>
              actionSearch == "" ||
              util.getActionName(action).toLowerCase().includes(actionSearch.toLowerCase()) ||
              util.getAppName(app).toLowerCase().includes(actionSearch.toLowerCase()) ||
              (action.uiSettings.general?.keywords ?? []).toString().toLowerCase().includes(actionSearch.toLowerCase()) ||
              (app.uiSettings.keywords ?? []).toString().toLowerCase().includes(actionSearch.toLowerCase())

            const filterCondition = (action) =>
              !hasFilters ||
              (action.uiSettings.general?.categories ?? []).find(category => actionFilters.includes(category))

            let groupIdx = 0
            return appsToShow.map((app, idx) => {
              if (!app) return <div key={idx}></div>;
              let isDeveloper = sessionStorage.getItem("developer_mode") == "true";
              const sectionId = "section-" + app.id
              const isPrevDifferentGroup = (idx > 0 && appsToShow[idx]?.uiSettings?.group_label != appsToShow[idx - 1]?.uiSettings?.group_label)
              const isNextDifferentGroup = (idx < appsToShow.length && appsToShow[idx]?.uiSettings?.group_label != appsToShow[idx + 1]?.uiSettings?.group_label)

              const isEvenSection = groupIdx % 2 == 1 || isNextDifferentGroup
              groupIdx = !isNextDifferentGroup ? groupIdx + 1 : 0


              const getActions = (app) => {
                return app.actions
                  .filter((action) =>
                    searchCondition(app, action) &&
                    filterCondition(action) &&
                    ((!action.developerOnly && !action.underDev) || isDeveloper))
                  .sort((a, b) => a.relevance - b.relevance)
              }

              const getMainActions = (app) => {
                let actions = getActions(app)
                return actions.filter(action => !(action.isHidden || action.developerOnly || action.underDev) || (actionSearch != "" || hasFilters))
              }

              const getHiddenActions = (app) => {
                let actions = getActions(app)
                return actions.filter(action => (action.isHidden || action.developerOnly || action.underDev) && (actionSearch == "" && !hasFilters))
              }

              const getCollapse = (app) => {
                const sectionId = "section-" + app.id
                const hiddenId = "hidden-" + app.id
                const mainActions = getMainActions(app)
                const hiddenActions = getHiddenActions(app)

                return <div id={sectionId} key={"collapse-" + idx}
                  className={"collapse w-100 " +
                    (actionSearch != "" || hasFilters ? "show " : "") +
                    (mainActions.length + hiddenActions.length == 0 ? "mt-0 mb-0 " :
                      (actionSearch != "" || hasFilters ? "mt-1 mb-1 pt-0 " : "mt-1 mb-2 pt-1 "))
                  }>
                  <ul className={"list-group pt-0 w-100 " + (actionSearch != "" || hasFilters ? "pb-0 " : "pb-2 ")}>
                    {mainActions.map((action, idx) => {
                      return (
                        <div key={idx}>
                          <ActionElement
                            action={action}
                            app={app}
                            onClickAction={onClickAction}
                            onDragStart={onDragStart}
                          />
                          {idx < mainActions.length - 1 &&
                            mainActions[idx].uiSettings?.general?.group_label != mainActions[idx + 1].uiSettings?.general?.group_label ?
                            <div style={{ marginBottom: "0.8rem", marginTop: "0.8rem" }}></div> : null
                          }
                        </div>
                      );
                    })}

                    {hiddenActions.length > 0 ?
                      <a
                        class="btn btn-sm py-2 px-3 mt-1 text-left" data-toggle="collapse"
                        style={{ border: "none", color: "#a0a0a0", fontSize: "0.9em", marginLeft: "-0.75rem" }}
                        href={"#" + hiddenId} role="button" aria-expanded="false" aria-controls="schema-builder-hidden">
                        Opciones avanzadas
                        <i style={{ color: "#b4b4b4", fontSize: "0.9em" }} class="ml-2 fas fa-chevron-down"></i>
                      </a> : null}

                    <div class="collapse mt-1 pt-2 mb-2" id={hiddenId}>
                      {hiddenActions.map((action, idx) => {
                        return (
                          <div key={idx}>
                            <ActionElement
                              action={action}
                              app={app}
                              onClickAction={onClickAction}
                              onDragStart={onDragStart}
                            />
                            {idx < mainActions.length - 1 &&
                              mainActions[idx].uiSettings?.general?.group_label != mainActions[idx + 1].uiSettings?.general?.group_label ?
                              <div style={{ marginBottom: "0.8rem", marginTop: "0.8rem" }}></div> : null
                            }
                          </div>
                        );
                      })}
                    </div>

                  </ul>
                </div>
              }


              const title =
                (idx == 0 || appsToShow[idx].uiSettings?.group_label != appsToShow[idx - 1].uiSettings?.group_label) && actionSearch == "" && !hasFilters ?
                  <div
                    key={"title-" + idx}
                    className={"px-1 w-100 " + (idx > 0 ? "mt-2" : "mt-2")} style={{ height: "1rem", marginBottom: "1.5rem" }}>
                    <hr className="w-100" style={{ borderBottom: "1px solid #d5dae0", marginTop: "1rem", marginBottom: "0.7rem" }} />
                    <label
                      style={{
                        width: "5.75rem",
                        position: "relative", top: "-1.5rem", background: "#ebf1f6", padding: "0.2rem 1.2rem",
                        fontSize: "0.75em", left: "4.15rem", color: "#90959a", textAlign: "center"
                      }}>{appsToShow[idx].uiSettings?.group_label}</label>
                  </div> : null


              const category = actionSearch == "" && !hasFilters ?
                <div key={"category-" + idx} className="btn-category-wrapper" style={{ width: "49%", marginRight: "1%", marginBottom: "0.2rem" }}>
                  <a
                    class="d-flex justify-content-between pt-3 px-1 w-100 btn-category" data-toggle="collapse"
                    style={{
                      border: "1px solid #ccc", color: "#a0a0a0", fontSize: "0.9em",
                      paddingRight: "1.25rem", paddingLeft: "1.25rem", paddingBottom: "0.7rem"
                    }}
                    href={"#" + sectionId} role="button" aria-expanded="false" aria-controls="schema-builder-hidden">
                    <div className="d-flex flex-column justify-content-center w-100" style={{ cursor: "pointer" }}>
                      <div style={{
                        color: "#757a80", fontSize: "1.75em", textAlign: "center", width: "100%"
                      }}
                        className={"ml-0 " + app.uiSettings?.icon}></div>
                      <span className="d-inline-block text-truncate"
                        style={{ color: "#454a50", fontSize: "0.9em", marginTop: "0.35rem", textAlign: "center" }}>
                        {util.getAppName(app)}
                      </span>
                    </div>
                  </a>
                </div> : null

              const collapse = isEvenSection ?
                <div className="w-100">
                  {!isPrevDifferentGroup ? getCollapse(appsToShow[idx - 1]) : null}
                  {getCollapse(app)}
                </div> : null

              return [title, category, collapse]
            })
          }()}

          {
            actionSearch != "" ?
              <div className="text-center mx-2 my-7"
                style={{ position: "absolute", fontSize: "0.9em", zIndex: "0" }}>No se encontraron resultados</div> : null
          }
        </div>

        <div className="px-3 my-4">
          <Link to="/flow/apps"
            class="btn btn-sm btn-lighter py-1 w-100" data-toggle="collapse"
            style={{ borderColor: "#d0d5da", color: "#70757a", fontSize: "0.9em" }}>
            <i class="fa-solid fa-plus mr-1"></i> Más acciones
          </Link>
        </div>

      </div>

      <ModalRoute
        height="600"
        path={"/flow/apps"}
        component={() =>
          <div className="card">
            <div class="card-header d-flex justify-content-between"><h3 class="mb-0">Acciones</h3></div>
            <Apps reqApps={reqApps} flowId={flow.id} />
          </div>}
      />


    </div>

  </div>
);

export default ToolboxView;
