import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { Route } from "seed/helpers";
import Topnav from "components/presentation/templates/top_template/Topnav";
import Section from "components/presentation/templates/side_template/Section";


const TopTemplateView = ({ flow, showTabs, onOpenEdit, onOpenTemplates, onOpenShare }) => (
  <>
    <Topnav flow={flow} showTabs={showTabs} onOpenEdit={onOpenEdit} onOpenTemplates={onOpenTemplates} onOpenShare={onOpenShare} />

    <main id="content" role="main" className="main pl-0">
      <div className="content container-fluid">
        <Switch>
          <Route path="/section/:sectionIdx" component={(props) => <Section {...props} flowId={flow.id} />} />
          <Redirect to="/section/0" />
        </Switch>
      </div>
    </main>

  </>
);

export default TopTemplateView;