import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Modal } from "seed/helpers";
import Tour from "components/misc/tour/Tour"
import { formatNumber } from "components/tables/util/format";
import Table from "components/tables/Table";
import FormSave from "components/tables/forms/FormSave";
import FormSet from "components/tables/forms/FormSet";
import FormImport from "components/tables/forms/FormImport";
import Suggestions from "components/flow/suggestions/Suggestions"
import "styles/css/Collection.css";

const TablesView = ({
  flowId,
  showTabs,
  columns,
  rows,
  pageNum,
  pageSize,
  totalPages,
  collections,
  collectionColumns,
  activeCollectionId,
  query,
  filters,
  search,
  height,
  loading,
  tutorialSteps,
  currentTutorialStep,
  isTutorialShown,
  isModalOpen,
  isEditModalOpen,
  isImportModalOpen,
  isImportModalShown,
  isExportModalShown,
  isFilterModalShown,
  isOptionModalShown,
  onClickTab,
  onClickDelete,
  setIsModalOpen,
  setIsEditModalOpen,
  setIsImportModalOpen,
  onAddColumn,
  onClickEdit,
  onChangeColumn,
  onChangeColumnOptions,
  onDeleteColumn,
  onAddRow,
  onDeleteRow,
  onInsertRow,
  onChangeRow,
  onImport,
  onExport,
  onDownloadZip,
  onChangeSearch,
  onPageChange,
  onChangeQuery,
  onChangeFilters,
  onClickCell,
  onSaveCollection,
  onEditCollection,
}) => (
  <div 
    id="tables"
    class="px-2 py-1" style={{
    width: "100%",
    height: "calc(100% - 110px)",
    background: "#f8fafe",
    border: "1px solid #E7EAF3",
    overflow: "hidden"
  }}>

    <BrowserRouter basename="/app/data">

      <Tour
        steps={tutorialSteps}
        startAt={currentTutorialStep}
        show={isTutorialShown}
        tutorialName={"collections"}
      >
        {
          showTabs &&
          <div className="w-100"
            style={{ position: "relative", marginRight: "2rem" }}>

            <div style={{ position: "absolute", top: "0.25rem", right: "-0.5rem" }}>
              <Suggestions flowId={flowId} />
            </div>

            <div style={{ paddingRight: "26rem" }}>
              <div id="collection-tabs" className="scrollbar-narrow d-flex w-100 align-items-baseline pt-3 px-3 pb-2"
                style={{ overflowX: "auto" }}>
                {(collections ?? []).map((collection, index) => (
                  <a
                    key={index}
                    style={{
                      maxWidth: "16rem",
                    }}
                    className={"btn collection-tab d-flex align-items-center mr-2 px-0 py-0 " +
                      (activeCollectionId == collection.id ? "active" : "")}

                  >
                    <span className="text-truncate py-2 pl-3" onClick={() => onClickTab(collection.id)}>{collection.name}</span>
                    <div class="dropright">
                      <a
                        class="dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="px-3 fas fa-ellipsis-v tab-icon"></i>
                      </a>
                      <div class="dropdown-menu py-1">
                        <div className="d-flex">
                          <a
                            href="#"
                            class="dropdown-item px-3"
                            style={{ borderRight: "1px solid #ddd" }}
                            onClick={() => onClickEdit(collection.id)}
                          >
                            <i class="tio-edit dropdown-item-icon" ></i> Editar
                          </a>
                          <a
                            class="dropdown-item px-3"
                            href="#"
                            onClick={() => onClickDelete(collection.id)}
                          >
                            <i class="tio-delete dropdown-item-icon"></i> Eliminar
                          </a>
                        </div>
                      </div>
                    </div>
                  </a>
                ))}

                <div style={{ position: "relative" }}>

                  <div class="dropdown">

                    <button
                      id="create-table-dropdown"
                      class="btn collection-tab d-flex align-items-center ml-2 dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span style={{ color: "#6a7075", width: "7.2rem" }}>
                        <i className="fas fa-plus mr-2 tab-icon"></i>
                        <span>Agregar tabla</span>
                      </span>
                    </button>

                    <div style={{ position: "fixed", zIndex: 4000 }}>
                      <div style={{ position: "absolute" }}>
                        <div class="dropdown-menu" aria-labelledby="create-table-dropdown">
                          <span class="dropdown-item" style={{ cursor: "pointer" }} onClick={() => setIsModalOpen(true)}>
                            <i class="tio-table dropdown-item-icon"></i>
                            <span>Crear tabla</span>
                          </span>
                          <span class="dropdown-item" style={{ cursor: "pointer" }} onClick={() => setIsImportModalOpen(true)}>
                            <i class="tio-file-add dropdown-item-icon"></i>
                            <span>Importar desde excel</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        }

        <div className="h-100">

          {
            !activeCollectionId && 
            <div className="w-100 h-100">
              <div className="d-flex flex-column justify-content-center align-items-center w-100 h-75">
                <h3 className="text-muted">
                  {
                    collections && collections.length > 0
                      ? "No se ha seleccionado ninguna tabla"
                      : "No hay tablas creadas"
                  }
                </h3>
                {
                  collections && collections.length == 0 && !loading &&
                  <div id="no-table-ctas" className="d-flex justify-content-center">
                    <div>
                      <button
                        type="button"
                        className="btn btn-sm btn-lighter-white mt-2"
                        onClick={() => setIsModalOpen(true)}
                      >
                        <i className="fas fa-plus mr-2"></i>
                        Crear tabla
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-sm btn-lighter-white mt-2 ml-2"
                        onClick={() => setIsImportModalOpen(true)}
                      >
                        <i className="fas fa-file-import mr-2"></i>
                        Importar excel
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
          }
          {
            activeCollectionId &&
            <Table
              trackKeybindings={(
                !isModalOpen && !isEditModalOpen && !isImportModalShown &&
                !isExportModalShown && !isFilterModalShown && !isOptionModalShown
              )}
              isReadOnly={!showTabs}
              useCustomPagination={true}
              useCustomSearch={true}
              useCustomFilters={true}
              useCustomImport={true}
              useCustomExport={true}
              pageNum={pageNum}
              pageSize={pageSize}
              totalPages={totalPages}
              values={rows}
              structure={columns}
              collections={collections}
              collectionColumns={collectionColumns}
              query={query}
              filters={filters}
              search={search}
              height={height}
              loading={loading}
              onAddColumn={onAddColumn}
              onChangeColumn={onChangeColumn}
              onChangeColumnOptions={onChangeColumnOptions}
              onDeleteColumn={onDeleteColumn}
              onAddRow={onAddRow}
              onDeleteRow={onDeleteRow}
              onInsertRow={onInsertRow}
              onChangeRow={onChangeRow}
              onImport={onImport}
              onExport={onExport}
              onDownloadZip={onDownloadZip}
              onPageChange={onPageChange}
              onChangeSearch={onChangeSearch}
              onChangeQuery={onChangeQuery}
              onChangeFilters={onChangeFilters}
              onClickCell={onClickCell}
              folioComponent={(data, _) => {
                const collection = collections?.find(c => c.id == activeCollectionId);
                if (!data.folio) return;
                if (!collection) return <label className="text-center" style={{ minWidth: "2.85rem" }}>{data.folio}</label>;
                return <label className="text-center" style={{ minWidth: "2.85rem" }}>{collection.initials}-{formatNumber(data.folio)}</label>;
              }}
            />
          }

        </div>
      </Tour>

      {
        isModalOpen && <Modal
          width="600"
          height="550"
          flowId={flowId}
          onSave={onSaveCollection}
          onClose={() => setIsModalOpen(false)}
          component={FormSave}
        />
      }

      {
        isEditModalOpen && <Modal
          width="600"
          height="550"
          flowId={flowId}
          onSave={onEditCollection}
          collectionId={activeCollectionId}
          onClose={() => setIsEditModalOpen(false)}
          component={FormSet}
        />
      }

      {
        isImportModalOpen && <Modal
          width="600"
          height="550"
          flowId={flowId}
          onSave={onEditCollection}
          collectionId={activeCollectionId}
          onClose={() => setIsImportModalOpen(false)}
          component={FormImport}
        />
      }

    </BrowserRouter>
  </div>
);

export default TablesView;