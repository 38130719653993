import React from 'react';

const FileViewer = ({ url }) => {
  return (<div className='card text-center py-5'>
    <div className='card-body'>
      <a className='btn btn-primary' href={url} target="_blank" rel="noreferrer" >Descargar</a>
    </div>
  </div>)

}

export default FileViewer;