import React from 'react';
import { Formik, Form, Field } from 'formik';

const AccountView = ({ user, onSubmit }) => (

  <div className="card">
    <div className="card-header">
      <h3 className="card-title">Cuenta</h3>
    </div>
    <div className="card-body">
      <Formik
        initialValues={{
          first_name: user.firstName,
          last_name: user.lastName,
          phone: user.phone,
          company: user.company,
        }}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="form-group">
              <div className="d-flex align-items-center">
                <span className="col-md-3">Nombre (s)</span>
                <Field
                  type="text"
                  id="first_name"
                  name="first_name"
                  className="form-control flex-grow-1"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="d-flex align-items-center">
                <span className="col-md-3">Apellidos</span>
                <Field
                  type="text"
                  id="last_name"
                  name="last_name"
                  className="form-control flex-grow-1"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="d-flex align-items-center">
                <span className="col-md-3">Correo</span>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className="form-control flex-grow-1"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="d-flex align-items-center">
                <span className="col-md-3">Teléfono</span>
                <Field
                  type="text"
                  name="phone"
                  className="form-control flex-grow-1"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="d-flex align-items-center">
                <span className="col-md-3">Organización</span>
                <Field
                  type="text"
                  name="company"
                  className="form-control flex-grow-1"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="d-flex align-items-center">
                <span className="col-md-3">Actualizar contraseña</span>
                <Field
                  type="password"
                  name="password"
                  className="form-control flex-grow-1"
                />
              </div>
            </div>
            <div className="text-right">
              <button type="submit" className="btn btn-primary">
                Guardar
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  </div>
);

export default AccountView;