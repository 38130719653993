import React, { useState, useEffect, useRef } from "react";
import { useDetail } from "seed/gql";
import { usePost } from "seed/api";
import ExcelJS from "exceljs";
import { ColumnTypes, parseSuggestionType, parseRowByColumn } from "components/tables/util/datatypes";
import View from "components/tables/forms/Form.view";

function CollectionFormSet({ 
  flowId, 
  collectionId, 
  onSave = () => {}, 
  closeModal = () => {} 
}) {

  const userId = sessionStorage.getItem("id");
  const formRef = useRef(null);
  const [error, setError] = useState(null);
  const [importFile, setImportFile] = useState(null);
  const [importedColumns, setImportedColumns] = useState(null);
  const [importedRows, setImportedRows] = useState(null);

  const reqPlayer = useDetail(`{
    collection {
      name
      initials
      schema
    }
  }`, collectionId);

  const [callSet, reqSet] = usePost("/collections/update_collection", {
    onCompleted: (data) => {
      onSave(data);
      closeModal();
    },
    onError: (error) => {
      if(error.status == 409) setError("Ya existe una tabla con ese identificador");
      else setError("Ocurrió un error al crear la tabla");
    }
  });

  useEffect(() => {

    (async () => {

      if(importFile) {
        
        let csv = null;
        let rawRows = null;

        if(importFile?.url?.endsWith(".csv")) {
          const response = await fetch(importFile.url);
          csv = await response.text();
          rawRows = csv.replace(/\r/g, "").trim().split("\n").map((row) => row.split(","));

        }
        else if(importFile?.url?.endsWith(".xls") || importFile?.url?.endsWith(".xlsx")) {
          const response = await fetch(importFile.url);
          const buffer = await response.arrayBuffer();
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(buffer);
          const sheet = workbook.getWorksheet(1);
          rawRows = sheet.getSheetValues().map(row => {
            row.shift();
            return row;
          });
        }

        rawRows = rawRows.filter((row) => row.length > 1);

        let columns = rawRows.shift().map((label, i) => ({
          name: label.toLocaleLowerCase().replace(/ /g, "_").replace(/[^a-z0-9_]/g, ""),
          type: ColumnTypes.STRING,
          label
        }));

        let rows = rawRows.map((row) => {
          const obj = {};
          row.forEach((value, i) => {
            if(columns[i]) 
              obj[columns[i].name] = value;
          });
          return obj
        });

        setImportedColumns(columns);
        setImportedRows(rows);

      }

    })();

  }, [importFile]);

  useEffect(() => {
    if(importedColumns)
        formRef.current.setFieldValue("structure", importedColumns);
  }, [importedColumns]);

  const onSubmit = (values) => {

    delete values.id;
    delete values.schema;

    let structure = values.structure;
    let data = importedRows;

    if(data) {
      data = data.map((row) => {
        let newRow = {};
        Object.keys(row).forEach((key) => {
          if(structure.find((s) => s.name == key))
            newRow[key] = row[key];
        });
        return parseRowByColumn(newRow, structure);
      });
    }

    callSet({
      ...values,
      flow_id: flowId,
      user_id: parseInt(userId),
      collection_id: collectionId,
      structure,
      data
    });

  }

  const { collection = {} } = reqPlayer.data || {};

  return <View
    formRef={formRef}
    error={error}
    loading={reqSet.loading}
    collection={collection}
    initialColumns={collection?.schema?.structure}
    onSubmit={onSubmit}
  />;

}

export default CollectionFormSet;